import {Row} from 'antd';
import React, {FC} from 'react';
import {JSection, JSectionsWrapper} from '@jnext/commons';
import {
    ProductContentSectionTypes as PCSectionTypes,
    Product,
    ProductContentSection,
    ProductContexts,
} from './../models';
import {
    BrandSection,
    CategoriesSection,
    DeliverySection,
    DescriptionsSection,
    FormSection,
    MainInfoSection
} from './components';
import {useMessages} from "../../../components/Messages/messageHooks";
import MessageBox from "../../../components/Messages/MessageBox";

export interface ProductContentProps {
    sections: ProductContentSection['sections'],
    onChangeProduct: (productLogicId: string) => void
    product: Product,
    context: ProductContexts
    initiativeLogicId: string;
}

const typeToShow = ["COMPANY_CLOSURE"]
export type ProductContentItem = ProductContentProps['product']

export const ProductContent: FC<ProductContentProps> = ({product, ...props}) => {

    const {messages} = useMessages();

    return (
        <div className="product-content">
            <Row className="product-info">
                <JSectionsWrapper>
                    {
                        props.sections.map((section, i) => {
                            let component = <span></span>
                            switch (section.type) {

                                case PCSectionTypes.PRODUCT_BRAND_INFO:
                                    component = (
                                        <BrandSection
                                            brands={product.brands}
                                        />
                                    )
                                    break;
                                case PCSectionTypes.PRODUCT_MAIN_INFO:
                                    component = (
                                        <MainInfoSection
                                            product={product}
                                        />
                                    )
                                    break;
                                case PCSectionTypes.PRODUCT_CATEGORIES:
                                    component = (
                                        <>
                                            <CategoriesSection
                                                categories={product.sectors}
                                            />
                                            {
                                                messages
                                                    .filter((message) => typeToShow.includes(message?.messageType ?? ''))
                                                    .map((message) => {
                                                        return (
                                                            <div style={{marginTop: "20px"}}>
                                                                <MessageBox
                                                                    msgTitle={message?.msgTitle ?? ''}
                                                                    msgSubtitle={message?.msgSubtitle ?? ''}
                                                                    msgBody={message?.msgBody ?? ''}
                                                                    msgTitleColor={message?.msgTitleColor ?? ''}
                                                                    msgSubtitleColor={message?.msgSubtitleColor ?? ''}
                                                                    msgBodyColor={message?.msgBodyColor ?? ''}
                                                                    fromDate={message?.fromDate ?? ''}
                                                                    toDate={message?.toDate ?? ''}
                                                                    msgLogoUrl={message?.msgLogoUrl ?? ''}
                                                                    msgDisclaimerColor={message?.msgDisclaimerColor ?? ''}
                                                                />
                                                            </div>
                                                        )
                                                    })}
                                        </>

                                    )
                                    break;
                                case PCSectionTypes.PRODUCT_DELIVERY_INFO:
                                    component = (
                                        <DeliverySection
                                            productType={product?.productType}
                                            deliveryDays={product?.deliveryDays}
                                            genericDeliveryDays={section?.options?.['genericDeliveryDays'] as number}
                                            icons={section.image}
                                        />
                                    )
                                    break;
                                case PCSectionTypes.PRODUCT_FORM:
                                    component = (
                                        <FormSection
                                            section={section}
                                            context={props.context}
                                            item={product}
                                            onChangeProduct={props.onChangeProduct}
                                            initiativeLogicId={props.initiativeLogicId}
                                        />
                                    )
                                    break;
                                case PCSectionTypes.PRODUCT_DESCRIPTIONS:
                                    component = (
                                        <DescriptionsSection
                                            section={section}
                                            brands={product.brands?.filter(b => b.description)}
                                            longDescription={product.longDescription}
                                            termsAndConditions={product.termsAndConditions}
                                        />
                                    )
                                    break;
                                default:
                                    break;
                            }
                            return <JSection key={i}>{component}</JSection>
                        })
                    }
                </JSectionsWrapper>
            </Row>
        </div>
    )
}
