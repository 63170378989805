import { JCmsFieldDTO, JFieldDTO as JFieldDTOModel, JFieldDTOType } from "@jnext/commons";

import { ComponentConfigOption } from '@jnext/ts-axios-coreuser/models';
const fields: JFieldDTOConstr[] = [{
    hurlId: "name",
    type: 'JTEXT',
    title: "CONTACTS_FIELD_NAME",
    mandatory: true
},
{
    hurlId: "lastname",
    type: 'JTEXT',
    title: "CONTACTS_FIELD_LASTNAME",
    mandatory: true
},
{
    hurlId: "email",
    type: 'JTEXT',
    title: "CONTACTS_FIELD_EMAIL",
    validationFunction: 'KGZ1bmN0aW9uIHZhbChwYXJhbSkgew0KICB2YXIgcmVnZXggPSAvXigoW148PigpW1xdXC4sOzpcc0BcIl0rKFwuW148PigpW1xdXC4sOzpcc0BcIl0rKSopfChcIi4rXCIpKUAoKFtePD4oKVtcXVwuLDs6XHNAXCJdK1wuKStbXjw+KClbXF1cLiw7OlxzQFwiXXsyLH0pJC9pOw0KICB2YXIgbWF0Y2hpbmdQYXR0ZXJucyA9IHJlZ2V4LmV4ZWMocGFyYW0udmFsdWUpOw0KICByZXR1cm4gISFtYXRjaGluZ1BhdHRlcm5zICYmIG1hdGNoaW5nUGF0dGVybnMubGVuZ3RoID4gMDsNCn0p',
    validationMessage: "CONTACTS_FIELD_EMAIL_VALIDATION_MESSAGE",
    mandatory: true
},
{
    hurlId: "reason",
    type: 'JSELECT',
    title: "CONTACTS_FIELD_REASON",
    mandatory: true
},
{
    hurlId: "subargument",
    type: 'JSELECT',
    title: "CONTACTS_FIELD_SUBARGUMENT",
    mandatory: true
},
{
    hurlId: "message",
    type: 'JTEXTAREA',
    title: "CONTACTS_FIELD_MESSAGE",
    mandatory: true
},
{
    hurlId: "files",
    type: 'JFILE',
    title: "CONTACTS_FIELD_FILES",
    componentConfig: {
        multiple: true,
        accept: 'image/jpeg,image/gif,image/png,application/pdf,image/x-eps',
        maxSize: 2097152,
        maxCount: 5
    }
},
]
export interface JFieldDTOConstr {
    hurlId: string,
    title: string,
    type: JFieldDTOType,
    mandatory?: boolean,
    validationFunction?: string,
    validationMessage?: string,
    options?: { id: number, label: string }[],
    componentConfig?: Record<string, any>
}
export class JFieldDTO implements JFieldDTOModel {
    cmsConfig!: JCmsFieldDTO
    type!: JFieldDTOType
    hurlId!: string

    mandatory!: boolean
    username = false
    validationFunction: string | undefined;
    constructor({
        hurlId,
        type,
        title,
        mandatory,
        validationFunction,
        validationMessage,
        options = [],
        componentConfig = {}
    }: JFieldDTOConstr, translate: (code: string) => string) {
        this.hurlId = hurlId
        this.type = type
        this.mandatory = !!mandatory
        this.validationFunction = validationFunction
        this.cmsConfig = {
            title: translate(title),
            order: 1,
            validationMessage: validationMessage ? translate(validationMessage) : '',
            componentConfig: {
                options: options.map(o => ({
                    value: o.id.toString(),
                    label: {
                        title: o.label
                    }
                })),
                ...componentConfig
            }
        }
    }
}

export const contactFields: (translate: (code: string) => string) => JFieldDTOModel[] = (translate) => fields.map(f => new JFieldDTO(f, translate))
export const getOrderField = (options: ComponentConfigOption[], translate: (code: string) => string) => new JFieldDTO({
    hurlId: "order",
    type: 'JSELECT',
    title: "CONTACTS_FIELD_ORDER",
    mandatory: true,
    componentConfig: {
        options
    }

}, translate)