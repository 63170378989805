import {MinusOutlined} from "@ant-design/icons";
import React, {Fragment} from "react";
import {useTranslationWord} from "utils/utilsTranslate";
import {MgmBoxStatus, MgmProgressBox} from "@jnext/ts-axios-mz";
import parse from 'html-react-parser';

export enum Position {
    left = 'left',
    right= 'right'
}

function translateInfoProgress(translateWord: any, option: any) {
    return(<label>
        {translateWord('friend', {count: option.requiredFriends})}
        {option?.rewards.map( (rew: any, key: number) => {
            return( <Fragment key={key}>
                {parse(translateWord(`friend${rew.type}`, {count: rew.value}))}
                {(key < option?.rewards.length -1) && translateWord('friend_connector')}
            </Fragment>)
        })}
    </label>)
}

export const InfoProgressSection = ({option, position}: {option: MgmProgressBox, position?: Position}) => {
    const translateWord = useTranslationWord();
    const active = option.reached;
    const status = (option?.status === MgmBoxStatus.Error )? 'error' : active ? 'active' : '';



    if(position === Position.left){
        return(<div className={`leftPos ${active && 'active'}`}>
            {translateWord('friendLabel', {count: option.requiredFriends})}
            <MinusOutlined />
        </div>)
    }
    return(<div className={`rightPos ${status}`}>
        <MinusOutlined />
        <div className={`background ${status}`}>
            {status === 'error'? translateWord('progressError'): translateInfoProgress(translateWord, option)}
        </div>
    </div>)
}