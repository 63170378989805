import {CMSSection} from "@jnext/commons";
import React, {useMemo} from "react";
import {EditOutlined} from "@ant-design/icons";
import {CheckoutDataMock} from "../../Mock";
import {CheckoutRequest} from "@jnext/ts-axios-formatdigitalcollection";
import {toJS} from "mobx";

interface DataBoxProps {
    cmsSection: CMSSection;
    dataForm: CheckoutRequest;
    onFormEdit: () => void;
}

export const DataBox: React.FC<DataBoxProps> = ({cmsSection, dataForm, onFormEdit}) => {
    // const []

    const data = useMemo(() =>
        [
        {key: 'Nome', value: dataForm?.address?.name},
        {key: 'Cognome', value: dataForm?.address?.surname},
        {key: 'Email', value: dataForm?.address?.email},
        {key: 'Cellulare', value: dataForm?.address?.phone},
        {key: 'Indirizzo', value: dataForm?.address?.street},
        {key: 'Provincia', value: dataForm?.address?.province},
        {key: 'Città', value: dataForm?.address?.city},
        {key: 'Nazione', value: dataForm?.address?.country},
        {key: 'Cap', value: dataForm?.address?.postalCode},
        {key: 'Note', value: dataForm?.address?.note},
    ].filter(el => el.value), [dataForm]);

    return <div className={'data-box'}>
        <div className={'box-head'}>
            <div className={'box-head-left'}>
                <h3 className={'box-title'}>{cmsSection?.title}</h3>
            </div>
            <div className={'box-head-right'}>
                <div className={'edit-icon-container'} onClick={onFormEdit}>
                    <EditOutlined />
                </div>
            </div>
        </div>

        <div className={'list-container'}>
            <ul>
                {
                    data.map((el, i) => (
                        <li key={i}>
                            <div className={'key'}>{el.key}</div>
                            <div className={'value'}>{el.value}</div>
                        </li>
                    ))
                }
            </ul>
        </div>
    </div>;
}