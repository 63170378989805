import React, {useMemo} from "react";
import {Navigate, Outlet} from "react-router-dom";
import {useCMSStore} from "../../../store/cmsStore";
import {CMSConfiguration} from "@jnext/commons";

const VasOutlet = () => {

    // Ottiene le pagine dal CMS store
    const { pages } = useCMSStore();
    // Usa useMemo per memorizzare la configurazione CMS della pagina VAS
    const cmsConfig: CMSConfiguration | undefined = useMemo(() => pages?.vas, [pages?.vas]);

    // Se la configurazione CMS non è disponibile, reindirizza alla pagina 404
    if (!cmsConfig) {
        return <Navigate to='/404' />
    }

    return (
        <>
            <Outlet />
        </>
    );
};

export default VasOutlet;
