import React, { memo } from "react";

const T1Style = () => {
  
  // @ts-ignore
  import("./index.scss");
  return <></>;
};

export default memo(T1Style);
