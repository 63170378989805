import axios from "axios";
import {HttpService} from "./HttpService";
import {InitiativesApi, ConsumerApi, MGMApi} from "@jnext/ts-axios-mz";

export class MGMService {
    static module= 'solution-mz';

    static async getMgmCodeInfo() {
        // @ts-ignore
        const consumerApi: ConsumerApi = new ConsumerApi(undefined,  HttpService.servicePath(this.module), axios);
        try {
            const res = await consumerApi.getMgmCodeInfo();
            return res?.data?.consumerMgmCodeInfo;
        }
        catch(e: any) {
            console.log('getMgmDashboard error: ', e)
        }
    };
    static async getMgmDashboard() {
        // @ts-ignore
        const consumerApi: ConsumerApi = new ConsumerApi(undefined,  HttpService.servicePath(this.module), axios);
        try {
            const res = await consumerApi.getMgmDashboard();
            return res?.data;
        }
        catch(e: any) {
            console.log('getMgmDashboard error: ', e)
        }
    };

    static async getMgmInitiative(initiativeLogicId: Array<string>, page: number = 0, size: number = 10) {
        // @ts-ignore
        const initiativeApi: InitiativesApi = new InitiativesApi(undefined,  HttpService.servicePath(this.module), axios);
        try {
            const res = await initiativeApi.getMgmInitiatives(initiativeLogicId, page, size);
            return res?.data;
        }
        catch(e: any) {
            console.log('getMgmInitiative error: ', e)
        }
    };

    static async getMGMEventExecutions() {
        // @ts-ignore
        const mgmApi: MGMApi = new MGMApi(undefined,  HttpService.servicePath(this.module), axios);
        try {
            const res = await mgmApi.getMGMEventExecutions();
            return res.data;
        }
        catch(e: any) {
            console.log('getMgmDashboard error: ', e)
        }
    };

}