import {Col, Row} from "antd";
import {RewardItemValue} from "../../../../../commons/ItemValue";
import {Item, Order, RedemptionResponse} from "@jnext/ts-axios-mz";
import {CMSSection, getProductImage} from "@jnext/commons";
import {RedemptionService} from "../../../../../service/RedemptionService";
import {generatePathUrl} from "../../../../../utils";
import {HistoryRewardType} from "../../../../../type/historyReward";
import {ComponentStatusTag, StatusTagType} from "../../../../../commons/ComponentStatusTag/ComponentStatusTag";


interface ProductProps {
    product: Item;
    type: HistoryRewardType;
}
const Product: React.FC<ProductProps> = ({product, type}) => {
    const thumbnailImage = getProductImage(product?.product);

    return (
        <Row className={'product-info-container'} justify={'space-between'} wrap={false}>
            <Col className={'product-info'}>
                <Row align={'middle'}>
                    {
                        thumbnailImage &&
                        <img className={'product-thumbnail'} src={generatePathUrl(thumbnailImage)} aria-hidden={true}/>
                    }

                    {
                        !thumbnailImage &&
                        <Col className={'image-container'} />
                    }

                    <Col className={'product-name'} dangerouslySetInnerHTML={{__html: product?.product?.title ?? ''}}/>

                </Row>
            </Col>
            <Col className={'product-quantity'}>{product?.quantity}</Col>
            {/*<Col className={'product-cost'}>{product.value ?  <AdditionalCost value={product.value} /> : '-'}</Col>*/}
            {
                type !== HistoryRewardType.CONTEST && product?.amount != 0 &&
                <Col className={'product-cost'}>
                    <RewardItemValue amount={product?.amount} cashAmount={product?.cashAmount} walletId={product?.referredWalletConfigurationLogicId} />
                </Col>
            }
        </Row>
    );
}

interface ElementBodyProps {
    redemption: RedemptionResponse;
    cms: CMSSection;
    type: HistoryRewardType;
}

export const ElementBody: React.FC<ElementBodyProps> = ({ redemption, cms, type }) => {
    const {friendlyId, orders, orderItems, issues} = redemption;

    // is a list of order?
    const isOrderType = RedemptionService.completed(redemption?.status);

    // simulate order to iterate if not exists
    const ordersToIterate: Order[] = isOrderType ? orders as Order[] : ([{ products: orderItems }] as Order[]);
    const showColValue = ordersToIterate.find(order => {
        return order?.products?.find(ord => ord?.amount && ord?.amount != 0)
    });

    return (
        <div className={'collapse-data'}>
            <div className={'collapse-data-header'}>
                <Row wrap={false}>
                    {isOrderType &&<Col className={'col col1'}>
                        {cms?.table?.orderN?.label}
                    </Col>}
                    <Col className={'col col2'}>
                        {cms?.table?.reward?.label}
                    </Col>
                    <Col className={'col col3'}>
                        {cms?.table?.quantity?.label}
                    </Col>
                    {
                        type !== HistoryRewardType.CONTEST && showColValue &&
                        <Col className={'col col4'}>
                            {cms?.table?.value?.label}
                        </Col>
                    }
                    {isOrderType && <Col className={'col col5'}>
                        {cms?.table?.status?.label}
                    </Col>}
                </Row>
            </div>
            <div className={'collapse-data-body'}>
                {
                    ordersToIterate?.map((order, i) => (
                            <Row key={i} className={'collapse-data-body-row'} wrap={false} align={'middle'}>
                                {isOrderType && <Col className={'col1'}>{order?.friendlyId}</Col>}
                                <Col className={'col2'}>
                                    {order.products?.map((product, internalIndex) => <Product product={product}
                                                                                              type={type}
                                                                                              key={internalIndex}/>)}
                                </Col>
                                {isOrderType && <Col className={'col3'}>
                                    <ComponentStatusTag type={StatusTagType.ORDER} orderStatus={order?.orderStatus}/>
                                </Col>}
                            </Row>
                        )
                    )
                }
            </div>
        </div>
    )
};