import './ProductDetail.scss';
import {Col, Row} from 'antd';
import {FC, Fragment, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {CMSPage, findSection, getNavPathFromType, JBreadcrumb, JBreadcrumbItem, JPage, JSection} from '@jnext/commons';
import {ProductService} from 'service/ProductService';
import {useConsumerStore} from 'store/consumerStore';
import {generatePathUrl, mzUseNavigate, useTranslationWord} from 'utils';
import {ProductContent} from './ProductContent';
import {Media, ProductMedia} from './ProductMedia';
import {
    Product,
    ProductCMS,
    ProductContentSection,
    ProductContexts,
    ProductMediaSection,
    ProductSections,
    ProductSectionTypes
} from './models';
import {MediaTypeEnum} from '@jnext/ts-axios-formatdigitalcollection';
import MetaTags from "../../components/MetaTags/MetaTags";
import {MetaPlaceholders} from "../../components/MetaTags/MetaTagsUtils";

interface ProductDetailProps {
    private?: boolean,
    productsCMS: ProductCMS,
    productSections: ProductSections[],
    context: ProductContexts,
    initiativeLogicId: string;
}


export const ProductDetail: FC<ProductDetailProps> = props => {

    const navigate = mzUseNavigate();

    const { consumerInfo } = useConsumerStore()
    const translate = useTranslationWord()

    const { id: productId, relatedId } = useParams()
    const [product, setProduct] = useState<Product>()
    const [currentProduct, setCurrentProduct] = useState<Product>()
    const [relatedProducts, setRelatedProducts] = useState<Product[]>([])
    const [cmsPage, setCMSPage] = useState<CMSPage>()
    const [mediaCMS, setMediaCMS] = useState<ProductMediaSection | undefined>()
    const [contentCMS, setContentCMS] = useState<ProductContentSection | undefined>()
    const vasLogicId = sessionStorage.getItem('vasLogicId');

    const basePath = useMemo(() => {
        let path = `catalog/${vasLogicId ? vasLogicId : props.context}/${props.initiativeLogicId}`
        return props.private ? `/${path}` : `/public/${path}`
    }, [])


    const productTitle = useMemo(() => product?.title || '', [product])

    const breadcrumbItems: JBreadcrumbItem[] = useMemo(() => {
        return [
            {
                label: translate('CATALOG', { defaultValue: 'Catalogo' }),
                navigateType: props.private ?
                    (vasLogicId ? `catalog/${props.context}/${vasLogicId}`  : `catalog/${props.context}/${props.initiativeLogicId}`)
                    : `public/catalog/${props.context}/${props.initiativeLogicId}`
            },
            {
                label: productTitle
            }
        ]
    }, [productTitle])

    const mediaList: Media[] | undefined = useMemo(() => {
        if (currentProduct) {
            // Removing media with preview = true (except those with default = true) and mediaType = CUSTOM
            return currentProduct.mediaContents?.filter(m => (m.flDefault || (!m.flDefault && !m.flPreview)) && m.mediaType !== MediaTypeEnum.Custom)
                .map<Media>(media => ({
                    url: media.mediaUrl || '',
                    type: media.mediaType,
                    default: media.flDefault,
                })) || []
        }
    }, [currentProduct])

    const colSectionProps = useMemo(() => {
        return {
            sm: 24,
            md: 12
        }
    }, [mediaCMS, contentCMS])

    function getProduct(id: string) {
        if (props.private && consumerInfo) {
            return ProductService.getPrivateProduct(props.initiativeLogicId, id)
        }
        return ProductService.getPublicProduct(props.initiativeLogicId, id)
    }

    function getRelatedProduct(initiativeLogicId: string, relatedProductId: string[] = []) {
        if (props.private && consumerInfo) {
            return ProductService.getRelatedPrivateProducts(initiativeLogicId, relatedProductId)
        }
        return ProductService.getRelatedPublicProducts(initiativeLogicId, relatedProductId)
    }

    useEffect(() => {
        (async () => {
            if (productId) {
                try {
                    const data = await getProduct(
                        productId
                    )
                    // const dataMock = await CatalogService.getPrivateProduct('')
                    // data.mediaContents = dataMock.mediaContents
                    if (data.relatedProducts?.length) {
                        const relatedProducts = await getRelatedProduct(props.initiativeLogicId, data.relatedProducts.map(rp => rp.logicId || ''))
                        // for (const relatedProduct of relatedProducts) {
                        //     relatedProduct.mediaContents = dataMock.mediaContents
                        // }

                        const relatedProduct = relatedProducts.find(rp => rp.logicId === relatedId) || relatedProducts[0]
                        urlReplace(relatedProduct?.logicId);
                        setCurrentProduct(relatedProduct || data)
                        setRelatedProducts(relatedProducts)
                    } else {
                        if (relatedId) {
                            urlReplace()
                        }
                        setCurrentProduct(data)
                    }

                    setProduct(data)

                    setCMSPage(props.productsCMS.page)
                    const sections = props.productSections
                    if (sections?.length) {
                        setMediaCMS(sections.find(findSection<ProductMediaSection>(ProductSectionTypes.PRODUCT_MEDIA)))
                        setContentCMS(sections.find(findSection<ProductContentSection>(ProductSectionTypes.PRODUCT_CONTENT)))
                    }
                } catch (error) {
                    console.error(error)
                    await navigate(getNavPathFromType('HOME'))
                }
            } else return;
        })()
    }, [])

    const urlReplace = (relatedProductId?: string) => {
        if (relatedProductId) {
            window.history.replaceState(null, '', `${basePath}/${productId}/${relatedProductId}`);
        } else {
            window.history.replaceState(null, '', `${basePath}/${productId}`);
        }
    }

    const onChangeProduct = (productLogicId: string) => {
        const relatedProduct = relatedProducts.find(rp => rp.logicId === productLogicId)
        if (productLogicId) {

            urlReplace(relatedProduct?.logicId)
            setCurrentProduct(relatedProduct)
        }
    }

    const metaTagsPlaceholders: MetaPlaceholders = useMemo(() => {
        if ( !currentProduct) {
            return {};
        }

        const productBrand = currentProduct.brands && currentProduct.brands.length > 0 ? currentProduct.brands[0] : null;

        const imagePath = mediaList ? (mediaList.find(m => m.default) || mediaList[0])?.url ?? "" : "";

        return {
            "product.id": currentProduct?.logicId ?? "",
            "product.title": currentProduct?.title ?? "",
            "product.shortDescription": currentProduct?.shortDescription ?? "",
            "product.longDescription": currentProduct?.longDescription ?? "",
            "product.brand": productBrand?.title ?? "",
            "product.image": !!imagePath ? generatePathUrl(imagePath) : null
        };

    }, [currentProduct, mediaList]);


    return (
        <Fragment>
            {cmsPage && (
                <JPage {...cmsPage}>
                    <MetaTags pageConfiguration={props.productsCMS.page} placeholders={metaTagsPlaceholders}/>
                    <JSection>
                        <div className="product-detail-container">
                            <Row>
                                <Col span={24}>
                                    <JBreadcrumb items={breadcrumbItems} />
                                </Col>
                            </Row>
                            <Row>
                                <Col {...colSectionProps}>
                                    {
                                        mediaCMS && mediaList && (
                                            <ProductMedia mediaList={mediaList} cms={mediaCMS} />
                                        )
                                    }
                                </Col>
                                <Col {...colSectionProps}>
                                    {
                                        contentCMS && currentProduct && (
                                            <ProductContent
                                                product={{
                                                    ...currentProduct,
                                                    relatedProducts: product?.relatedProducts
                                                }}
                                                context={props.context}
                                                onChangeProduct={onChangeProduct}
                                                sections={contentCMS?.sections?.filter(s => s.enabled !== false) || []}
                                                initiativeLogicId={props.initiativeLogicId}
                                            />
                                        )
                                    }
                                </Col>
                            </Row>

                        </div>

                    </JSection>
                </JPage>

            )
            }
        </Fragment>
    )
}