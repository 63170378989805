import React from "react";
import { Divider } from "antd";
import { mzUseNavigate, useTranslationWord } from "../../utils";
import { observer } from "mobx-react";
import { CMSCTA, JButton } from "@jnext/commons";
import { AuthService } from "../../service/AuthService";
import { getNavPathFromType } from "@jnext/commons";

export const CustomLink = ({
    link,
    children,
    className = "",
    onClick,
}: {
    link: CMSCTA;
    children: any;
    className?: string;
    onClick?: any;
}) => {
    const translateWord = useTranslationWord();
    const navigate = mzUseNavigate();
    /* const chooseLink = subString(window.location.pathname, '/');
     const routePath = link?.scope && getNavPathFromType(link.scope, link.scopeParameters);*/
    let isClickLink: boolean = false;
    const pathName = window.location.pathname;
    const routePath = link?.scope && getNavPathFromType(link.scope, link.scopeParameters);

    if (!AuthService.authorized) {
        if (pathName === '/public' && routePath === '/public') isClickLink = true
        else if (routePath && routePath !== '/public') {
            isClickLink = pathName.includes(routePath);
        }
    } else {
        if (pathName === '/' && routePath === '/') isClickLink = true
        else if (routePath && routePath !== '/') {
            isClickLink = pathName.includes(routePath);
        }
    }

    const internalPath = link?.href?.type === 'INTERNAL' ? link.href.value : null;


    return routePath || internalPath ? (
        <a
            //type={'link'}
            href={internalPath ? internalPath : routePath ?? '/'}
            onClick={(e) => {
                e.preventDefault()
                if (onClick) {
                    onClick();
                }
                if (link.scope === 'LOGOUT') return AuthService.logout()
                return navigate(internalPath ? internalPath : routePath ?? '/');
            }}
            className={`link${isClickLink ? " linkActive" : ""} ${className}`}
        >
            {children}
        </a>
    ) : (
        <a
            className={`link ${className}`}
            href={link?.href?.value || undefined}
            target={link?.href?.target || undefined}
            title={link.href?.value}
            aria-label={link.href?.target === "_blank" ? `${translateWord('GO_TO')} ${link.href?.value}` : undefined}
            onClick={() => {
                if (onClick) {
                    onClick();
                }
            }}
        >
            {children}
        </a>
    );
};

const LinksGenerate = ({
    links,
    divider = false,
}: {
    links: CMSCTA[];
    divider?: boolean;
}) => {
    return (
        <div className="linksGenerate">
            {/*<Anchor
                direction="horizontal"
                items={links.map((link, key) => {
                    return ({
                        key: key,
                        href: getNavPathFromType(link.scope, link.scopeParameters),
                        title: link?.title,
                    })
                })}
            />*/}
            {links?.map((link: CMSCTA, index: number) => {

                if (!link?.scope && !link?.href) return null;

                return (
                    <div
                        key={index}
                        className={"linkContainer " + (!divider ? "mrg" : "")}
                    >
                        {divider && index > 0 && <Divider className='divider' type="vertical" />}
                        <CustomLink
                            link={link}
                        >
                            {link?.title}
                        </CustomLink>
                    </div>
                );
            })}
        </div>
    );
};

export default observer(LinksGenerate);
