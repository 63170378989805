import {
    CheckoutCMSConfig,
    CheckoutConfirmSectionTypes,
    CheckoutDataInsertSectionTypes, CheckoutMainSectionTypes,
    CheckoutSectionTypes, CheckoutSummarySection, CheckoutSummarySectionTypes
} from "./models";
import {StepperSectionTypes} from "./stepperModel";
import {CartInfoSectionTypes, CartSectionTypes} from "../Cart/models";
import {Breakpoint, CMSImage} from "@jnext/commons/src/models/CMSConfig";

export const CMS_CONFIG: CheckoutCMSConfig = {
    enabled: true,
    page: {
        enabled: true,
        title: 'Checkout',
    },
    section: {
        enabled: true,
        type: 'CHECKOUT_SECTION',
        options: {
            background: 'primary-lighter'
        },
        sections: [
            {
                type: CheckoutMainSectionTypes.DIRECT_CHECKOUT,
                sections: [
                    {
                        type: CheckoutSectionTypes['DATA_INSERT'],
                        enabled: true,
                        sections: [
                            {
                                enabled: true,
                                type: CheckoutDataInsertSectionTypes['MAIN'],
                                title: 'Dati utente',
                            },
                        ],
                        cta: [
                            {
                                type: 'BUTTON',
                                title: 'Salva dati',
                                color: 'primary',
                                scope: 'SUBMIT'
                            }
                        ],
                    },

                    {
                        type: CheckoutSectionTypes['CONFIRM'],
                        enabled: true,
                        sections: [
                            {
                                enabled: true,
                                type: CheckoutConfirmSectionTypes['ORDERS'],
                                title: 'Ordine',
                                table: {
                                    products: { label: 'prodotti' },
                                    product: { label: 'prodotto' },
                                    delete: { label: 'ELimina'},
                                }
                            },
                            {
                                type: CheckoutConfirmSectionTypes['CART_INFO'],
                                enabled: true,
                                sections: [
                                    {
                                        enabled: true,
                                        type: CartInfoSectionTypes['CART_INFO_TOTAL'],
                                        title: 'Totale'
                                    },
                                    {
                                        enabled: true,
                                        type: CartInfoSectionTypes['CART_INFO_CURRENT_POINTS'],
                                        title: 'Saldo punti attuale'
                                    },
                                    {
                                        enabled: true,
                                        type: CartInfoSectionTypes['CART_INFO_REMAINING_POINTS'],
                                        title: 'Saldo punti rimanente'
                                    }
                                ],
                                cta: [
                                    {
                                        type: 'BUTTON',
                                        title: 'Concludi ordine',
                                        color: 'primary',
                                        scope: 'CHECKOUT'
                                    }
                                ],
                            },
                            {
                                enabled: true,
                                type: CheckoutConfirmSectionTypes['USER_DATA'],
                                title: 'Dati',
                            },
                        ],
                    },

                    {
                        type: CheckoutSectionTypes['SUMMARY'],
                        enabled: true,
                        sections: [
                            {
                                enabled: true,
                                type: CheckoutSummarySectionTypes['MAIN'],
                                image: [{
                                    src: 'https://i.ibb.co/GQWZv8w/avatar-missing.png',
                                }],
                                title: 'Grazie per il tuo ordine!',
                                text: 'Il tuo ordine potrebbe essere diviso in più spedizioni. Per conoscere il codice di tracking e lo stato del tuo ordine accedi alla sezioni I tuoi ordini',
                            },
                            {
                                enabled: true,
                                type: CheckoutSummarySectionTypes['ORDER'],
                                title: 'Ordine n° {{orderNumber}}',
                                cta: [
                                    {
                                        type: 'BUTTON',
                                        title: 'Visualizza ordine',
                                        color: 'primary',
                                        scope: 'HISTORY_REWARDS_CATALOG_DETAILS'
                                    }
                                ]
                            },
                        ],
                    },

                    {
                        type: StepperSectionTypes['STEPS'],
                        enabled: true,
                        sections: [
                            {
                                enabled: true,
                                type: StepperSectionTypes['STEP'],
                                title: 'Inserisci dati',
                            },
                            {
                                enabled: true,
                                type: StepperSectionTypes['STEP'],
                                title: 'Fatto!',
                            },
                        ],
                    },
                ],
            },

            {
                type: CheckoutMainSectionTypes.BASKET_CHECKOUT,
                sections: [
                    {
                        type: CheckoutSectionTypes['DATA_INSERT'],
                        enabled: true,
                        sections: [
                            {
                                enabled: true,
                                type: CheckoutDataInsertSectionTypes['MAIN'],
                                title: 'Dati utente',
                            },
                        ],
                        cta: [
                            {
                                type: 'BUTTON',
                                title: 'Salva dati',
                                color: 'primary',
                                scope: 'SUBMIT'
                            }
                        ],
                    },

                    {
                        type: CheckoutSectionTypes['CONFIRM'],
                        enabled: true,
                        sections: [
                            {
                                enabled: true,
                                type: CheckoutConfirmSectionTypes['ORDERS'],
                                title: 'Ordine',
                                table: {
                                    products: { label: 'prodotti' },
                                    product: { label: 'prodotto' },
                                    delete: { label: 'ELimina'},
                                }
                            },
                            {
                                type: CheckoutConfirmSectionTypes['CART_INFO'],
                                enabled: true,
                                sections: [
                                    {
                                        enabled: true,
                                        type: CartInfoSectionTypes['CART_INFO_TOTAL'],
                                        title: 'Totale'
                                    },
                                    {
                                        enabled: true,
                                        type: CartInfoSectionTypes['CART_INFO_CURRENT_POINTS'],
                                        title: 'Saldo punti attuale'
                                    },
                                    {
                                        enabled: true,
                                        type: CartInfoSectionTypes['CART_INFO_REMAINING_POINTS'],
                                        title: 'Saldo punti rimanente'
                                    }
                                ],
                                cta: [
                                    {
                                        type: 'BUTTON',
                                        title: 'Concludi ordine',
                                        color: 'primary',
                                        scope: 'CHECKOUT_COMPLETE'
                                    }
                                ],
                            },
                            {
                                enabled: true,
                                type: CheckoutConfirmSectionTypes['USER_DATA'],
                                title: 'Dati',
                            },
                        ],
                    },

                    {
                        type: CheckoutSectionTypes['SUMMARY'],
                        enabled: true,
                        sections: [
                            {
                                enabled: true,
                                type: CheckoutSummarySectionTypes['MAIN'],
                                title: 'Grazie per il tuo ordine!',
                                text: 'Il tuo ordine potrebbe essere diviso in più spedizioni. Per conoscere il codice di tracking e lo stato del tuo ordine accedi alla sezioni I tuoi ordini',
                            },
                            {
                                enabled: true,
                                type: CheckoutSummarySectionTypes['ORDER'],
                                title: 'Ordine n° {{orderNumber}}',
                                cta: [
                                    {
                                        type: 'BUTTON',
                                        title: 'Visualizza ordine',
                                        color: 'primary',
                                        scope: 'HISTORY_REWARDS_CONTEST'
                                    }
                                ]
                            },
                        ],
                    },

                    {
                        type: StepperSectionTypes['STEPS'],
                        enabled: true,
                        sections: [
                            {
                                enabled: true,
                                type: StepperSectionTypes['STEP'],
                                title: 'Inserisci dati',
                            },
                            {
                                enabled: true,
                                type: StepperSectionTypes['STEP'],
                                title: 'Conferma',
                            },
                            {
                                enabled: true,
                                type: StepperSectionTypes['STEP'],
                                title: 'Fatto!',
                            },
                        ],
                    },
                ],
            },
        ]
    }
}