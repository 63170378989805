import {JCMSCTA, JHTML} from "@jnext/commons";
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import ActivityHistoryModal from "./ActvityHistoryModal";
import {MGMService} from "../../../../service/MGMService";
import {MGMHistoryResponse} from "@jnext/ts-axios-mz/models";

interface ActvityHistoryProps{
    activityHistory: Record<string, any>
}

const ActivityHistory = ({activityHistory}:ActvityHistoryProps) => {
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [mgmHistory, setMgmHistory] = useState<MGMHistoryResponse>();
    const cta = activityHistory?.cta?.[0];

    useEffect(() => {
        if(isModalVisible){
            (async () => {
                setMgmHistory(await MGMService.getMGMEventExecutions() as any)
            })();
        }
    }, [isModalVisible]);

    return(<>
        {isModalVisible && <ActivityHistoryModal mgmHistory={mgmHistory} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />}
        <div className={'activityHistory'} onClick={()=>setIsModalVisible(true)}>
            <JHTML.Title html={activityHistory?.title || ''}/>

            {cta &&
                <JCMSCTA {...cta} action={()=>setIsModalVisible(true)}/>
            }
        </div>
    </>)
}

export default observer(ActivityHistory)