import React, {useMemo} from "react";
import {getNavPathFromType, NavigationTypes} from "@jnext/commons";
import {FORMAT_DATE, generatePathUrl, mzUseNavigate, TranslateComponent} from "../../utils";
import {HistoryRewardType} from "../../type/historyReward";
import {Divider} from "antd";
import moment from "moment/moment";
import {RedemptionService} from "../../service/RedemptionService";
import {InfoCircleOutlined} from "@ant-design/icons";
import {CardItemV2Props} from "./CardItemV2";
import {RewardItemValue} from "../ItemValue";
import {FooterCardV2} from "./FooterCardV2";
import {Product} from "@jnext/ts-axios-mz/dist/models/product";
import {Item, OrderOrderStatusEnum, RedemptionStatusEnum} from "@jnext/ts-axios-mz";

export const ContentComponentCardV2: React.FC<CardItemV2Props> = ({
                                                                      redemptionProps,
                                                                      orderProps,
                                                                      cms,
                                                                      type,
                                                                      options,
                                                                      onRedeem,
                                                                      brand
                                                                  }) => {

    const navigate = mzUseNavigate();

    /** important information about redemption **/
    const product: Item | undefined = orderProps?.product;
    const productInProduct: Product | undefined = product?.product;
    const dateTime: string | undefined = redemptionProps?.dateTime;
    const expirationDate: string | undefined = redemptionProps?.expirationDate;
    const friendlyId: string | undefined = redemptionProps?.friendlyId;

    /** state of redemption**/
    const completed = useMemo(() => RedemptionService.completed(redemptionProps?.status), [redemptionProps]);
    const needToFinalize = useMemo(() => RedemptionService.needToFinalize(redemptionProps?.status), [redemptionProps]);
    const showRawStatus = !completed && !needToFinalize;

    /**actions of btn**/
    const goToRedemption: string | undefined = redemptionProps?.approvalRequested === 'STANDARD' && cms?.table?.goToRedemptionWithAcceptance?.label
        ? cms?.table?.goToRedemptionWithAcceptance?.label
        : cms?.table?.goToRedemption?.label;

    const openDetailPage = () => {
        const sectionsTypes = {
            [HistoryRewardType.CONTEST]: NavigationTypes.HISTORY_REWARDS_CONTEST_DETAILS,
            [HistoryRewardType.CATALOG]: NavigationTypes.HISTORY_REWARDS_CATALOG_DETAILS,
            [HistoryRewardType.CATALOG_LOYALTY_COLLECTION]: NavigationTypes.HISTORY_REWARDS_CATALOG_LOYALTY_COLLECTION_DETAILS,
            [HistoryRewardType.CATALOG_DIGITAL_WALLET]: NavigationTypes.HISTORY_REWARDS_CATALOG_DIGITAL_WALLET_DETAILS,
        };

        return navigate(getNavPathFromType(sectionsTypes[type], {redemptionId: redemptionProps?.logicId}));
    }

    return (<>
            <div className={'bodyCardV2'}>
                <div className={'row'}>
                    <div className={'titleCardV2'}>{cms?.table?.orderRequest?.label} {friendlyId}</div>
                </div>
                <div className={'row'}>
                    <b>{cms?.table?.carriedOut?.label}</b>
                    <p>{moment(dateTime)?.format(FORMAT_DATE)}</p>
                </div>
                {expirationDate && <div className={'row'}>
                    <b>{cms?.table?.expirationDate?.label}</b>
                    <p>{moment(expirationDate)?.format(FORMAT_DATE)}</p>
                </div>}

                {productInProduct?.title && <>
                    <Divider/>
                    <div className={'col'}>
                        <b className={'title'} dangerouslySetInnerHTML={{__html: productInProduct?.title ?? ''}}></b>


                        {
                            brand &&
                            <div className={'card-brand'}>
                                {
                                    brand?.brandLogo &&
                                    <div className={'card-brand-image'}>
                                        <figure>
                                            <img src={generatePathUrl(brand?.brandLogo)} alt={brand?.title}
                                                 aria-hidden={true}/>
                                        </figure>
                                    </div>
                                }
                                {
                                    brand?.title &&
                                    <div className={'card-brand-name'}
                                         dangerouslySetInnerHTML={{__html: brand?.title ?? ''}}/>
                                }
                            </div>
                        }

                    </div>
                </>}

                {
                    (type !== HistoryRewardType.CONTEST) && !!product?.amount &&
                    <div className={'row rowAmount'}>
                        <b>{cms?.table?.value?.label}</b>
                        <RewardItemValue amount={product?.amount} cashAmount={product?.cashAmount}
                                         walletId={product?.referredWalletConfigurationLogicId}/>
                    </div>
                }

                {redemptionProps?.status !== OrderOrderStatusEnum.Expired &&
                    redemptionProps?.status !== OrderOrderStatusEnum.Annulled &&
                    redemptionProps?.status !== OrderOrderStatusEnum.Deleted &&
                    redemptionProps?.status !== RedemptionStatusEnum.NotAccepted &&

                    <div className={'btnCardV2'}>
                        <div
                            className='btn claimRewardBtn'
                            onClick={() => {
                                if (needToFinalize) return onRedeem();
                                if (completed) return openDetailPage();
                            }}>

                            {needToFinalize && <>
                                <label>{goToRedemption}</label>
                                <div className={'infoPoint'}><InfoCircleOutlined/></div>
                            </>}

                            {completed && (
                                <label>
                                    {cms?.table?.showDetail?.label}
                                </label>
                            )}

                            {
                                showRawStatus && (
                                    <TranslateComponent text={`REDEMPTION_${redemptionProps?.status}`}/>
                                )
                            }
                        </div>
                    </div>
                }

            </div>
            <FooterCardV2
                status={orderProps?.orderStatus || redemptionProps?.status}
                options={options?.status}
                orderDate={orderProps?.orderDate}/>
        </>
    );
}