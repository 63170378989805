import { RightOutlined } from "@ant-design/icons";
import { CMSCTA } from "@jnext/commons";
import { CustomLink } from "commons";
import { FC } from "react";

export interface MenuDrawerItemProps {
    cta: CMSCTA,
    onClose: () => void
}

export const MenuDrawerItem: FC<MenuDrawerItemProps> = (props) => (
    <div className="menu-item">
        <CustomLink link={props.cta} onClick={props.onClose}>
            <li>
                <div>
                    <div>
                        {props.cta.title}
                    </div>
                    {
                        props.cta.scope !== 'LOGOUT' && (
                            <div>
                                <RightOutlined />
                            </div>
                        )
                    }
                </div>
            </li>
        </CustomLink>

    </div>
)