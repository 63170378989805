import {MinusOutlined, PlusOutlined} from "@ant-design/icons";
import {generatePathUrl} from "../../../../utils";
import React from "react";

enum TransactionEnum {
    'POSITIVE'= 'POSITIVE',
    'NEGATIVE' = 'NEGATIVE'
}

export const PointRow = ({amount, src}:{amount: number, src?: string }) =>{

    let styleColor = (amount >=  0) ?  'toGreen' : 'toRed';
    let transaction = (amount >=  0) ?  TransactionEnum.POSITIVE : TransactionEnum.NEGATIVE;

    return(
        <div className={`pointRow`}>
            {transaction ===  TransactionEnum.POSITIVE && <PlusOutlined className={styleColor} />}

            {transaction ===  TransactionEnum.NEGATIVE && <MinusOutlined className={styleColor} />}

            {src && <img className={`walletImage ${styleColor}`} src={generatePathUrl(src)} aria-hidden={true}/>}

            {amount && <label className={`pointText ${styleColor}`}> {Math.abs(amount)} </label>}
        </div>
    )
}