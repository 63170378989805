import {Fragment, useMemo, useState} from "react";
import {observer} from "mobx-react";
import {CloseOutlined, MenuOutlined, UserOutlined} from "@ant-design/icons";
import {Drawer, Dropdown} from "antd";
import {
    breakpointsMax,
    CMSCTA,
    CMSSection,
    getNavPathFromType,
    JCMSCTA,
    JCMSImage,
    NavigationTypes
} from "@jnext/commons";
import {mzUseNavigate, useTranslationWord} from "../../utils";
import {SolutionProjectImage} from "../../commons";
import LinksGenerate from "../../commons/LinksGenerate";
import {MenuDrawerItem} from "./MenuDrawerItem";
import {AuthService} from "../../service/AuthService";
import {useCMSStore} from "store/cmsStore";
import {useConsumerStore} from "store/consumerStore";
import {Language} from "../Language";
import {CartDropdown} from "./components";
import {CartSection} from "pages/Cart/models";
import {useWindowSize} from '@react-hook/window-size';
import {findCartSection} from "pages/Cart/utils";

type Props = {
    className?: string;
    openHeroSection?: boolean;
    toggleHeroSection?: () => void
};

const profileMenu = (
    userNavigation: CMSCTA[] | undefined,
    navigate: (e: string) => Promise<void>,
) => {
    if (!userNavigation?.length) return [];
    const menu = userNavigation?.map((user: CMSCTA) => {
        return user?.scope && {
            key: user.title,
            label: (
                <div
                    onClick={() => {
                        if (user?.scope) {
                            if (user?.scope === NavigationTypes?.LOGOUT) return AuthService.logout();
                            return navigate(getNavPathFromType(user?.scope));
                        }
                    }}
                >
                    {user.title}
                </div>
            ),
        }
    });

    return menu;
};

const Navbar = ({className, openHeroSection, toggleHeroSection}: Props) => {
    const [width] = useWindowSize()
    const translateWord = useTranslationWord();
    const {avatarURL, consumerInfo} = useConsumerStore();
    const {structure, appSettings} = useCMSStore();

    const navigate = mzUseNavigate();

    const configHeader: CMSSection | undefined = useMemo(() => structure?.header, [structure]);
    const authorized = useMemo<boolean>(() => AuthService.authorized, []);
    const mainNavigation = structure?.mainNavigation?.filter(nav => nav.scope != NavigationTypes.FRIENDS ||
        (nav.scope === NavigationTypes.FRIENDS && consumerInfo?.gdprMgmConsent?.consent));

    // If the navigation contains 1 element, we treat it as a BUTTON indepently from its type
    const configButtonNavigation: CMSCTA | undefined = useMemo(() => (mainNavigation && mainNavigation.length === 1) ? mainNavigation[0] : undefined, [structure]);

    // If the navigation contains several elements (more than 1), we treat it as LINK indepently from their types
    const configMainNavigation: CMSCTA[] | undefined = useMemo(() => (mainNavigation && mainNavigation.length > 1) ? mainNavigation : undefined, [structure]);

    const userNavigation: CMSCTA[] | undefined = useMemo(() => structure?.userNavigation, [structure]);

    const languageSection: CMSSection | undefined = useMemo(() => configHeader?.sections?.find(e => e?.type === 'LANGUAGE_PICKER'), [configHeader]);
    const cartSection: CartSection | undefined = useMemo(() => configHeader?.sections?.find(findCartSection('CART_SECTION')) as CartSection, []);

    const photo = useMemo(() => avatarURL || appSettings?.avatarFallback, [avatarURL, appSettings]);
    const avatarShape =useMemo(() => appSettings?.avatarShape, [appSettings]);

    // Links Generator
    const linksGenerate = useMemo(() => {
        if (configMainNavigation)
            return (<LinksGenerate links={configMainNavigation}/>)
    }, [configMainNavigation]);

    // Boolean to set mobile layout according to breakpoints 
    const mobileLayout = useMemo(() => {
        // By default the mobile layout is triggered at md breakpoint. If the navigation items are 8 o more, we trigger it at lg breakpoint
        if((configMainNavigation && configMainNavigation.length > 6  && configMainNavigation.length < 9)){
            return width <=  breakpointsMax.md
        } else if(configMainNavigation && configMainNavigation.length >= 9){
            return width <=  breakpointsMax.lg
        }
        return width <= breakpointsMax.sm
    }, [width])

    // Drawer Visibility state and methods
    const [drawerVisibility, setDrawerVisibility] = useState(false);

    const showDrawer = () => {
        setDrawerVisibility(true);
    };

    const onClose = () => {
        setDrawerVisibility(false);
    };

    return (<>
            <div className={`navbarContainerRow ${mobileLayout ? "mobile" : ""} ${className ?? ''}`}>

                { // HAMBURGER MENU ICON
                    mobileLayout && !!configMainNavigation?.length && (
                        <button className="drawerMenuNavbarMobile" type="button"
                                aria-expanded={drawerVisibility}
                                onClick={() => showDrawer()}>
                            <MenuOutlined
                                aria-label={drawerVisibility ? translateWord('CLOSE_MENU') : translateWord('OPEN_MENU')}/>

                        </button>
                    )
                }


                {/* MENU LEFT SIDE */}
                <div
                    className={`leftSide ${authorized ? "authorized" : ""}`}
                    onClick={() => navigate(getNavPathFromType(authorized ? NavigationTypes.PRIVATE_HOME : NavigationTypes.PUBLIC_HOME))}
                >
                    {!!configHeader && configHeader?.enabled && <div className='logo'>
                        <JCMSImage image={configHeader?.image}/>
                    </div>}
                </div>

                {/* MENU CENTER SIDE */}
                <div
                    className={"centerSide"}
                >
                    {!mobileLayout && !!configMainNavigation?.length && (
                        <nav className="links">
                            {linksGenerate}
                        </nav>
                    )}
                </div>
                {/*<div
                    className={"centerSideMobile"}
                >
                    {store?.authStore?.authorized && (
                        <>
                            <div className="mobileNavbarActionIcon">
                                <HeartOutlined />
                            </div>
                            <div className="mobileNavbarActionIcon">
                                <ShoppingCartOutlined />
                            </div>
                            <div className="mobileNavbarActionIcon">
                                <BellOutlined />
                            </div>
                        </>
                    )}
                </div>*/}

                {/* MENU RIGHT SIDE */}
                <div
                    className={"rightSide"}
                >
                    <>
                        {   // CART RENDERING
                            AuthService?.authorized && cartSection && (
                                <Fragment>
                                    <CartDropdown section={cartSection as CartSection}/>
                                </Fragment>
                            )
                        }
                        {
                            !mobileLayout && authorized && userNavigation?.length === 0 &&
                            <div>
                                {photo && (
                                    <SolutionProjectImage
                                        className={`icon noPointer ${!avatarShape ? 'noBorder' : ''}`}
                                        name={photo}
                                    />
                                )}
                            </div>
                        }
                        {   // PROFILE DROPDOWN MENU
                            !mobileLayout && authorized && !!userNavigation?.length ? (
                                <Dropdown
                                    menu={{
                                        items: profileMenu(userNavigation, navigate) as any
                                    }}
                                    placement="bottomRight"
                                    arrow
                                    trigger={["click"]}
                                >
                                    <div>
                                        {photo && (
                                            <SolutionProjectImage
                                                className={`icon ${!avatarShape ? 'noBorder' : ''}`}
                                                name={photo}
                                            />
                                        )}
                                    </div>
                                </Dropdown>
                            ) : (
                                <>
                                    {   // BUTTON IN CASE OF SINGLE NAVIGATION ITEM
                                        configButtonNavigation && <JCMSCTA {...configButtonNavigation} />
                                    }
                                </>
                            )
                        }
                        {
                            // LANGUAGE SECTION
                            !mobileLayout && languageSection?.enabled && <Language/>
                        }
                        {mobileLayout && authorized && !!userNavigation?.length && <>
                            {!openHeroSection && <UserOutlined className={'iconMobile'} onClick={toggleHeroSection}/>}
                            {openHeroSection &&
                                <CloseOutlined className={'iconMobile close'} onClick={toggleHeroSection}/>}
                        </>}
                    </>
                </div>
            </div>

            {/* DRAWER FOR MOBILE LAYOUT */}
            <Drawer
                className="navbarDrawer"
                title={
                    <div className="drawerCloseIcon" onClick={onClose}>
                        <CloseOutlined aria-label={translateWord('CLOSE')}/>
                    </div>
                }
                placement={"left"}
                closable={false}
                onClose={onClose}
                open={drawerVisibility}
                extra={
                    <div>
                        {languageSection?.enabled && <Language/>}
                    </div>
                }
            >
                <ul>

                    {   // DRAWER LINKS
                        configMainNavigation && configMainNavigation.map((cta, i: number) => (
                            <MenuDrawerItem cta={cta} onClose={onClose} key={i}/>
                        ))
                    }

                    {   // DRAWER USER NAVIGATION
                        userNavigation?.map((cta: CMSCTA, i: number) => {
                            return (
                                <MenuDrawerItem cta={cta} key={i} onClose={() => {
                                    onClose();
                                    if (cta?.scope) {
                                        if (cta?.scope === NavigationTypes?.LOGOUT) return AuthService.logout();
                                        return navigate(getNavPathFromType(cta?.scope));
                                    }
                                }}/>
                            )
                        })
                    }
                </ul>
            </Drawer>
        </>
    );
};

export default observer(Navbar);
