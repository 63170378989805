import { action, computed, makeObservable, observable } from "mobx";
import { useContext, createContext } from "react";

export class LoadingStore {
    loadingTimes = 0;

    constructor() {
        makeObservable(this, {
            loadingTimes: observable,
            loading: computed,
            setLoading: action
        })
    }

    get loading() {
        return this.loadingTimes > 0;

    }
    setLoading = (value: boolean) => {

        this.loadingTimes += value ? 1 : this.loadingTimes > 0 ? -1 : 0;
    }
}

export const loadingStore = new LoadingStore();

export const useLoadingStore = () => useContext(
    createContext<LoadingStore>(loadingStore)
);
