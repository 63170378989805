import React from "react";
import {Col, Row} from "antd";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";

interface PaginationProps {
    page: number;
    totalPage: number;
    updatePagination: (pageNr: number) => void;
}

const nearbyPagesLimit = 2;

export const Pagination: React.FC<PaginationProps> = ({page, totalPage, updatePagination}) => {


    if ( totalPage <= 1) {
        return <></>;
    }

    const renderPageButton = (buttonPage: number) => {

        return <li
            className={`pointer ${buttonPage === page ? 'active' : ''}`}
            key={buttonPage}
            onClick={() => updatePagination(buttonPage)}
        >{buttonPage}</li>;
    }

    const renderInnerPageButtons = () => {

        if (totalPage < 2) {
            return <></>
        }

        const pages = Array.from(Array(totalPage).keys());

        return pages.map(pageNr => {

            const i = pageNr + 1;

            if (i < 2 || i === totalPage) {
                return;
            }

            if (((page - nearbyPagesLimit) - i === 0) || ((page + nearbyPagesLimit) - i === 0)) {

                return <li key={`page_${i}`}>...</li>;

            } else if ((0 < (page - nearbyPagesLimit) - i) || (0 > (page + nearbyPagesLimit) - i)) {

                return;

            } else {
                return renderPageButton(i)
            }

        });

    }

    return (
        <div className={'generic-pagination'}>
            <Row justify={'center'} align={'middle'}>
                <Col
                    className={`arrow left ${page > 1 ? 'active' : ''}`}
                    onClick={() => {
                        if( page == 1 ) { return; }
                        updatePagination(page-1);
                    }}
                >
                    <LeftOutlined />
                </Col>
                <Col className={'pages'}>
                    <ul>
                        {renderPageButton(1)}

                        {renderInnerPageButtons()}

                        {totalPage > 1 && renderPageButton(totalPage)}
                    </ul>
                </Col>
                <Col
                    className={`arrow right ${totalPage > 1 && page < totalPage ? 'active' : ''}`}
                    onClick={() => {
                        if( page == totalPage ) { return; }
                        updatePagination(page+1);
                    }}
                >
                    <RightOutlined />
                </Col>
            </Row>
        </div>
    );
}