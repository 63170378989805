import {FC, useMemo} from "react";
import './CartSummary.scss';
import {CartInfoSection, CartInfoSectionTypes} from "pages/Cart/models";
import {findCartSection} from "pages/Cart/utils";
import {JBox, JCMSImage} from "@jnext/commons";
import {useWalletStore} from "store/walletStore";
import getSymbolFromCurrency from "currency-symbol-map";
import {BasketResponse, BasketTypeEnum, BasketItemStatusCodeEnum} from "@jnext/ts-axios-formatdigitalcollection";

export interface CartSummaryProps {
    section: CartInfoSection,
    basket: BasketResponse,
    initiativeLogicId: string,
    ctaCallback?: () => void,
    basketType: BasketTypeEnum,
    completeCheckout?: () => void,
}

export const CartSummary: FC<CartSummaryProps> = props => {
    const walletStore = useWalletStore()
    const totalSection = useMemo(() => props.section?.sections?.find(findCartSection(CartInfoSectionTypes.CART_INFO_TOTAL)), [props.section])
    const currentPointsSection = useMemo(() => props.section?.sections?.find(findCartSection(CartInfoSectionTypes.CART_INFO_CURRENT_POINTS)), [props.section])
    const remainingPointsSection = useMemo(() => props.section?.sections?.find(findCartSection(CartInfoSectionTypes.CART_INFO_REMAINING_POINTS)), [props.section])
    const cartWallet = useMemo(() => walletStore.wallet.find(w => w.logicId?.toLowerCase() === props?.basket?.walletConfigurationLogicId?.toLowerCase()), [props?.basket?.walletConfigurationLogicId])
    const walletImage = useMemo(() => cartWallet?.cmsConfig?.imageRef, [props.basket, cartWallet])
    const walletTitle = useMemo(() => cartWallet?.cmsConfig?.title, [props.basket, cartWallet])

    const othersCTA = useMemo(() => props.section?.cta?.filter(cta => cta.scope !== 'CHECKOUT' && cta.scope !== 'CHECKOUT_COMPLETE') || [], [props.section])

    const totalPoints = useMemo(() => `${props.basket.pointsTotal} ${walletTitle ? walletTitle : ''}`, [props.basket, walletTitle])
    const totalCash = useMemo(() => !!props.basket.cashTotal && props.basket.cashTotal > 0 ? `${props.basket.cashTotal} ${getSymbolFromCurrency(props.basket?.currency || 'EUR')}` : '', [props.basket, walletTitle])
    const totalValue = useMemo(() => `${totalPoints || ''} ${totalCash && totalPoints && '+'} ${totalCash}`, [totalCash, totalPoints])

    const currentPoints = useMemo(() => cartWallet?.number, [cartWallet])
    const remainingPoints = useMemo(() => (cartWallet?.number || 0) - (props.basket?.pointsTotal || 0), [cartWallet, props.basket])

    const hasInvalidProduct = () => {
        return props.basket?.items?.find(el => el.statusCode !== BasketItemStatusCodeEnum.Valid) !== undefined;
    }

    const checkoutCTA = useMemo(() => {
        const cta: any = props.section?.cta?.find(cta => cta.scope === 'CHECKOUT' || cta.scope === 'CHECKOUT_COMPLETE')
        if (cta) {
            cta.disabled = !props.basket || remainingPoints < 0 || hasInvalidProduct()
            cta.scopeParameters = {
                initiativeLogicId: props.initiativeLogicId,
                basketType: props.basketType,
            }


            if (cta?.scope == 'CHECKOUT_COMPLETE') {
                cta.action = () => {

                    if (props?.completeCheckout !== undefined) {
                        props?.completeCheckout();
                    }

                }
            }
        }
        return cta
    }, [props.section, props.basket, remainingPoints, props?.completeCheckout])


    return (
        <div className="cart-summary">
            <JBox cta={[...othersCTA, checkoutCTA]}>

                {
                    totalSection && (
                        <div className="cart-summary-total">
                            <div>
                                {
                                    totalSection.title
                                }
                            </div>
                            <div className='cart-summary-right-align'>
                                {
                                    totalValue
                                }
                            </div>
                        </div>

                    )
                }
                <div style={{width: '100%'}}>
                    {
                        currentPointsSection && (
                            <div className="cart-summary-balance">
                                <div>
                                    {
                                        currentPointsSection.title
                                    }
                                </div>
                                <div className='cart-summary-wallet-value'>


                                    {
                                        <div>
                                            {
                                                currentPoints
                                            }
                                        </div>
                                    }
                                    {
                                        walletImage && (
                                            <div className="cart-summary-balance-wallet-image">
                                                <JCMSImage image={[{src: walletImage}]}/>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }
                    {
                        remainingPointsSection && (
                            <div className="cart-summary-balance">
                                <div>
                                    {
                                        remainingPointsSection.title
                                    }
                                </div>
                                <div className='cart-summary-wallet-value'>


                                    {
                                        <div>
                                            {
                                                remainingPoints
                                            }
                                        </div>
                                    }
                                    {
                                        walletImage && (
                                            <div className="cart-summary-balance-wallet-image">
                                                <JCMSImage image={[{src: walletImage}]}/>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
            </JBox>

        </div>
    )
}