import { Col } from 'antd';
import { FC } from 'react';
import { JHTML } from '@jnext/commons';
import { ProductContentItem } from './../ProductContent';
import {JBoxInfoWarning} from "../../../../components/Vas";
import {useTranslationWord} from "../../../../utils";


export type MainInfoSectionProps = {
    product: ProductContentItem

}
export const MainInfoSection: FC<MainInfoSectionProps> = (props) => {
    const translateWord = useTranslationWord();
    const {product} = props;
    const purchaseDisabledReason = product?.purchaseDisabledReason?.find(el => el.cause == 'NEED_PLAN');

    return(<Col span={24} className="product-main-info">
        {
            product.title && <JHTML.SectionTitle html={product.title}/>
        }
        {
            product.shortDescription && <JHTML.Text html={product.shortDescription}></JHTML.Text>
        }
        {purchaseDisabledReason &&
            <JBoxInfoWarning
                text={translateWord('purchaseDisabledReason', {title: purchaseDisabledReason?.props ? purchaseDisabledReason?.props[0] : ''})}
            />}

    </Col>)
}