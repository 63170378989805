import {EventsApi} from "@jnext/ts-axios-eventformat";
import {HttpService} from "./HttpService";
import axios, {AxiosRequestConfig} from "axios";

export class EventService {

    static async getPrivateEventByEventConfigurationLogicId(eventConfigurationLogicId: string, language?: string, options?: AxiosRequestConfig) {

        try {
            // @ts-ignore
            const eventApi = new EventsApi(undefined, HttpService.servicePath("format-event"), axios);
            // @ts-ignore
            const res = await eventApi.getPrivateEventByEventConfigurationLogicId(eventConfigurationLogicId, language, options);
            return res.data;

        } catch (ex: any) {
            console.log(ex)
        }
    }

    static async getPrivateEventMetadata(eventConfigurationLogicId: string, language?: string, options?: AxiosRequestConfig) {

        try {
            // @ts-ignore
            const eventApi = new EventsApi(undefined, HttpService.servicePath("format-event"), axios);
            // @ts-ignore
            const res = await eventApi.getPrivateEventMetadata(eventConfigurationLogicId, language, options);
            return res.data;

        } catch (ex: any) {
            console.log(ex)
        }
    }
}