import React, { useState } from "react";
import "./";
import { Dropdown } from "antd";
import { observer } from "mobx-react";
import {ProjectLanguageDto} from "@jnext/ts-axios-coreconfiguration";
import Flag from "react-flagkit";
import {useAppStore} from "../../store/appStore";
import {DownOutlined, UpOutlined} from "@ant-design/icons";

enum LanguageMenu {
    AF = 'US',
    AM = 'ET',
    AR = 'EG',
    AZ = 'AZ',
    BE = 'BY',
    BG = 'BG',
    BI = 'VU',
    BN = 'BD',
    BS = 'BA',
    CA = 'AD',
    CH = 'MP',
    CS = 'CZ',
    DA = 'DK',
    DE = 'DE',
    DV = 'MV',
    DZ = 'BT',
    EL = 'GR',
    EN = 'US',
    ES = 'ES',
    ET = 'EE',
    EU = 'ES',
    FA = 'IR',
    FF = 'GN',
    FI = 'FI',
    FJ = 'FJ',
    FO = 'FO',
    FR = 'FR',
    GA = 'IE',
    GL = 'ES',
    GN = 'ES',
    GV = 'IM',
    HE = 'IL',
    HI = 'IN',
    HR = 'HR',
    HT = 'HT',
    HU = 'HU',
    HY = 'AM',
    ID = 'ID',
    IS = 'IS',
    IT = 'IT',
    JA = 'JP',
    KA = 'GE',
    KG = 'CD',
    KK = 'KZ',
    KL = 'GL',
    KM = 'KH',
    KO = 'KR',
    KU = 'IQ',
    KY = 'KG',
    LA = 'VA',
    LB = 'LU',
    LN = 'CG',
    LO = 'LA',
    LT = 'LT',
    LU = 'CD',
    LV = 'LV',
    MG = 'MG',
    MH = 'MH',
    MI = 'NZ',
    MK = 'MK',
    MN = 'MN',
    MS = 'BN',
    MT = 'MT',
    MY = 'MM',
    NA = 'NR',
    NB = 'NO',
    ND = 'ZW',
    NE = 'NP',
    NL = 'NL',
    NN = 'NO',
    NO = 'SJ',
    NR = 'ZA',
    NY = 'MW',
    OC = 'ES',
    PA = 'CW',
    PL = 'PL',
    PS = 'AF',
    PT = 'PT',
    QU = 'BO',
    RN = 'BI',
    RO = 'RO',
    RU = 'RU',
    RW = 'RW',
    SG = 'CF',
    SI = 'LK',
    SK = 'SK',
    SL = 'SI',
    SM = 'WS',
    SN = 'ZW',
    SO = 'SO',
    SQ = 'AL',
    SR = 'RS',
    SS = 'SZ',
    ST = 'LS',
    SV = 'AX',
    SW = 'TZ',
    TA = 'LK',
    TG = 'TJ',
    TH = 'TH',
    TI = 'ER',
    TK = 'TM',
    TN = 'BW',
    TO = 'TO',
    TR = 'TR',
    TS = 'ZA',
    UK = 'UA',
    UR = 'PK',
    UZ = 'UZ',
    VE = 'ZA',
    VI = 'VN',
    XH = 'ZA',
    ZH = 'TW',
    ZU = 'ZA'
}

const menu = (languages: ProjectLanguageDto[] | undefined, setFlag: any) => {
    return !!languages?.length && languages?.map((language: ProjectLanguageDto, i: number) => {
        return {
            label: (
                <div
                    key={i}
                    className={`lnDisp ln--${language?.cd_lng?.toLowerCase()}`}
                    onClick={() => {
                        if(language?.cd_lng){
                            setFlag(language?.cd_lng);
                            localStorage.setItem("ln", language?.cd_lng?.toLowerCase());
                            location.reload();
                        }
                    }}
                >
                    {language?.cd_lng && <Flag country={LanguageMenu[language?.cd_lng.toUpperCase() as keyof typeof LanguageMenu]}/>}
                    {language?.name}
                </div>
            ),
            key: language?.defaultLanguage ? language?.cd_lng : '',
        };
    })
};


export const Language = observer(() => {
        const {languages} = useAppStore();

        if(!languages?.length || languages?.length === 0) {
            return <></>
        }

        const defaultLng: ProjectLanguageDto | undefined = languages?.find((language: ProjectLanguageDto)=> language?.defaultLanguage);
        let ln = localStorage.getItem("ln") || defaultLng?.cd_lng;
        const [flag, setFlag] = useState<string>(ln || '');

        return (
            <Dropdown
                className="languageContainer"
                menu={
                    {
                        items: menu(languages, setFlag) as any
                    }
                }
                placement="bottom"
                trigger={['click', 'hover']}
                arrow
            >
                <div className="languageSection">
                    {flag && <Flag country={LanguageMenu[flag?.toUpperCase()  as keyof typeof LanguageMenu]}/>}
                    <DownOutlined className='downOutline' />
                    <UpOutlined className='upOutline'/>
                </div>
            </Dropdown>
        );
    }
);
