import {generatePathUrl} from "../../../../utils/utils";
import {JHTML} from "@jnext/commons";
import React from "react";

interface CardBoxMgmProps{
    src?: string,
    title: string,
    point: number | undefined
}
export const CardBoxMgm = ({title, point, src}: CardBoxMgmProps)=> {
    return(<div className={'card'}>
        <div className={'upCard'}>
            {src && <img aria-hidden={true} src={generatePathUrl(src)}/>}
        </div>
        <div className={'downCard'}>
            {title && <JHTML.Text html={title}/>}
             <label className={'point'}>{point}</label>
        </div>
    </div>)
}