import { ProjectLanguageDto } from "@jnext/ts-axios-coreconfiguration";
import { action, makeObservable, observable } from 'mobx';
import { createContext, useContext } from 'react';
import { DEFAULT_LANGUAGE } from 'type/languages';
import { getErrorMessage, isHttpError } from 'utils';

export class AppStore {
    currentLanguage: string = localStorage?.getItem('ln') || DEFAULT_LANGUAGE;
    languages: ProjectLanguageDto[] | undefined;
    fileTranslate: Record<string, string> = {}
    modalMessage: any;
    mobileLayout: boolean = false;


    constructor() {
        this.modalMessage = null;

        makeObservable(this, {
            currentLanguage: observable,
            languages: observable,
            fileTranslate: observable,
            modalMessage: observable,
            mobileLayout: observable,
            setTranslateFile:action,
            setCurrentLanguage: action,
            setLanguages: action,
            setModalMessage: action,
            clearModalMessage: action,
            setMobileLayout: action
        });
    }
    setTranslateFile = (fileTranslate: AppStore['fileTranslate']) => {
        this.fileTranslate = fileTranslate
    }
    setCurrentLanguage = (ln: string) => {
        this.currentLanguage = ln;
    }

    setLanguages = (languages: ProjectLanguageDto[]) => {
        const defaultLng: ProjectLanguageDto | undefined = languages?.find((language: ProjectLanguageDto)=> language?.defaultLanguage);
        const currentLanguage =  localStorage.getItem("ln") || defaultLng?.cd_lng?.toLowerCase() || DEFAULT_LANGUAGE;
        if(currentLanguage){
            this.setCurrentLanguage(currentLanguage)
            localStorage.setItem('ln', currentLanguage);
        }
        this.languages = languages;
    }

    setModalMessage = (message: {title?: string, text?: string}, type: string) => {
        this.modalMessage = { message, type };
    }

    clearModalMessage = () => {
        this.modalMessage = null;
    }

    handleResponseError = (error: unknown, context?: string) => {
        if (error) {
            const text = isHttpError(error) ? (
                'statusErrorTitle'
            ) : (
                getErrorMessage(error) ?
                    `${context ? `${context}_` : ''}${getErrorMessage(error)}`
                    : 'statusErrorTitle'
            )
            this.setModalMessage({ text }, 'error' );
        }
    }

    setMobileLayout = (isMobileLayout: boolean) => {
        this.mobileLayout = isMobileLayout;
    }
}

export const appStore = new AppStore();

export const useAppStore = () => useContext(
    createContext<AppStore>(appStore)
);
