import React, { useEffect, useState } from "react";
import "./style.scss";
import { showBody, useTranslationWord } from "../../utils";
import {CMSImage, JCMSCTA, JCMSImage, JHTML} from "@jnext/commons";
import { useCMSStore } from "store/cmsStore";

export interface GenericErrorProps {
    image?: CMSImage[];
    title?: string;
    text?: string;
    showBtn?: boolean;
    cta?: {
        title?: string,
        scope?: string,
    };
}

export const GenericError: React.FC<GenericErrorProps> = ({image, title, text, showBtn, cta }) => {
    const translateWord = useTranslationWord();

    // State to track if the displayed image is broken
    const [isImageBroken, setIsImageBroken] = useState<boolean>(false)

    useEffect(() => {
        // show html body
        showBody();
    }, []);

    return (
        <div className="errorContainer">
            {
                image && image.length > 0 && !isImageBroken &&
                <JCMSImage image={image} onError={() => setIsImageBroken(true)}/>
            }

            <div className="lblContainer">
                {title && <JHTML.Title html={title} />}
                {text && <JHTML.Text html={text} />}

                {
                    showBtn && <>
                        {cta && <JCMSCTA {...{ type: 'BUTTON', title: cta?.title, scope: cta?.scope }} />}
                        {!cta && <JCMSCTA {...{ type: 'BUTTON', title: translateWord('returnHomepage'), scope: 'PUBLIC_HOME' }} />}
                    </>
                }
            </div>
        </div>
    )
}