import { CMSCTA, CMSPage, CMSSection } from "@jnext/commons";
import { Product } from "pages/ProductDetail/models";
import {ProductDetailRedemptionModalityDto} from "@jnext/ts-axios-formatdigitalcollection";

// CMS Models ----------------
export interface CartCMSConfig {
    enabled: boolean,
    page: CMSPage,
    section: CartSection
} 

export enum CartSectionTypes {
    CART_INITIATIVES_LIST = 'CART_INITIATIVES_LIST',
    CART_PRODUCTS_LIST = 'CART_PRODUCTS_LIST',
    CART_MAIN_INFO = 'CART_MAIN_INFO',
    CART_INFO = 'CART_INFO',
}
export enum CartInfoSectionTypes {
    CART_INFO_TOTAL = 'CART_INFO_TOTAL',
    CART_INFO_CURRENT_POINTS = 'CART_INFO_CURRENT_POINTS',
    CART_INFO_REMAINING_POINTS = 'CART_INFO_REMAINING_POINTS',
}
export enum CartProductsListSectionTypes {
    PRODUCT_FORM_QUANTITY = 'PRODUCT_FORM_QUANTITY'
}

export type CartSectionType =
    `${CartSectionTypes}` |
    `${CartInfoSectionTypes}` |
    `${CartProductsListSectionTypes}` |
    'CART_SECTION'

export interface CartSection extends CMSSection {
    sections: CartMainSection[],
    //type: 'CART_SECTION'
}
export type CartMainSection = CartInfoSection | CartProductsListSection | CartInitiativesSection

export interface CartInfoSectionCTA extends CMSCTA {    
    scope: 'CHECKOUT'
}

export interface CartMainInfoSection extends CMSSection {
    type: CartSectionTypes.CART_MAIN_INFO,
}

export interface CartInfoSection extends CMSSection {
    type: CartSectionTypes.CART_INFO,
    cta: (CartInfoSectionCTA | CMSCTA)[]
    sections?: (CMSSection & { type: `${CartInfoSectionTypes}` })[]
}

export interface CartInitiativesSection extends CMSSection {
    type: CartSectionTypes.CART_INITIATIVES_LIST,
}

export interface CartProductsListSectionCTA extends CMSCTA {
    type: 'WRAPLESS',
    color: 'primary',
    scope: 'REMOVE_ITEM'
}
export interface CartProductsListSection extends CMSSection {
    type: CartSectionTypes.CART_PRODUCTS_LIST,
    cta: [CartProductsListSectionCTA]
    sections?: (CMSSection & { type: CartProductsListSectionTypes })[]
}

