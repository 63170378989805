import { JComponentConfigOption, JFieldDTO } from "@jnext/commons";
import { ProductDetailRedemptionModalityDto } from "@jnext/ts-axios-mz";
import { WalletPoints } from "@jnext/ts-axios-wallet";
import { JFormCompositorWrapper } from "commons/JFormCompositorWrapper/JFormCompositorWrapper";
import getSymbolFromCurrency from "currency-symbol-map";
import { ProductFormRedemptionModesSection, ProductRedemptionModality } from "pages/ProductDetail/models";
import { FC, cloneElement, useMemo } from "react";
import { useWalletStore } from "store/walletStore";
import { DEFAULT_FORM_PROPS } from "../utils";
import {LiquidationTypeEnum} from "@jnext/ts-axios-formatdigitalcollection";
import {CatalogService} from "../../../../../../service/CatalogService";

type RedemptionModalityKeys = keyof Omit<ProductDetailRedemptionModalityDto, 'logicId' | 'walletConfigurationLogicId' | 'type'>
//type otherKeys = 'finalPointsPrice' | 'finalSelfCash' | 'finalSelfPoints' | 'finalSellPrice'
//type RedemptionModalityKeys = RedemptionModalityKeysOld & otherKeys;

function isRedemptionModalityKeys(listKey: RedemptionModalityKeys[], key: any): key is RedemptionModalityKeys {
    if( ['finalPointsPrice' , 'finalSelfCash' , 'finalSelfPoints' , 'finalSellPrice'].indexOf(key) != -1 ) return true;
    return listKey.includes(key)
}

export interface RedemptionModesFormProps {
    redemptionModalities: ProductRedemptionModality[]
    currencyCode?: string,
    section: ProductFormRedemptionModesSection
}
export const RedemptionModesForm: FC<RedemptionModesFormProps> = props => {

    const { wallet } = useWalletStore()
    const redemptionModalities = useMemo(
        getRepemptionField(props, wallet || []),
        [props.redemptionModalities]
    )
    return (
        <JFormCompositorWrapper
            {...DEFAULT_FORM_PROPS}
            formId="redemption-form"
            fields={redemptionModalities}
        />
    )
}


function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}
const redemptionModConfig: Record<`${LiquidationTypeEnum}`, any[]> = {
    POINTS: [
        'finalPointsPrice',
        'pointsPrice',
        'discountPoint'
    ],
    SELF: [
        'selfCash',
        'discountSelfCash',
        'selfPoints',
        'discountPoint'
    ],
    SELL: [
        'sellPrice',
        'discountSellPrice'
    ],
    //CASH: []
}

function getRepemptionField({ redemptionModalities = [], currencyCode, section }: RedemptionModesFormProps, wallet: WalletPoints[]): () => JFieldDTO[] {

    return () => {
        const currency = currencyCode || '-'
        const _currecySymbol = getSymbolFromCurrency(currency);
        const currencySymbol = _currecySymbol || currency;
        const classNames = 'price'
        const options: JComponentConfigOption[] = [];

        for (let i = 0; i < redemptionModalities.length; i++) {
            const redemptionModality = redemptionModalities[i];

            const pointLabel = (
                wallet.find(w => w.logicId?.toLowerCase() === redemptionModality.walletConfigurationLogicId?.toLowerCase())?.cmsConfig?.title || ''
            )

            // @ts-ignore
            /*const mapSymbols: Record<Partial<RedemptionModalityKeys>, string> = (() => {

                switch (redemptionModality?.type) {
                    case LiquidationTypeEnum.Points:
                        return {
                            pointsPrice: pointLabel || '',
                            finalPointsPrice: pointLabel || '',
                            discountPoint: pointLabel || '',
                        }
                    case LiquidationTypeEnum.Sell:
                        return {
                            sellPrice: currencySymbol || '',
                            discountSellPrice: currencySymbol || ''
                        }
                    case LiquidationTypeEnum.Self:
                        return {
                            selfCash: currencySymbol || '',
                            discountSelfCash: currencySymbol || '',
                        }
                    default:
                        return {
                            pointsPrice: pointLabel || '',
                            discountPoint: pointLabel || '',
                            discountSelfCash: currencySymbol || '',
                            discountSelfPoints: pointLabel || '',
                            selfCash: currencySymbol || '',
                            selfPoints: pointLabel || '',
                            sellPrice: currencySymbol || '',
                            discountSellPrice: currencySymbol || ''
                        }

                }

            })();*/

            //const mapSymbolsKeys = Object.keys(mapSymbols)

            let value = (redemptionModality as any).logicId || i;
            let label: JSX.Element[] = [];

            const price = CatalogService.getPrice(redemptionModality, wallet);
            const evaluated = price?.cost ? price?.cost : price?.points;

            if(evaluated?.originalValue !== evaluated?.value) {
                label.push(<span className={'price discounted'}>{evaluated?.originalValue}</span>);
            }
            label.push(<span>{evaluated?.value} {pointLabel}</span>);


            if (value) {
                options.push({
                    value,
                    label: {
                        title: label as any
                    }
                });
            }

            /*if (redemptionModality.type) {
                const redemptionKeys = redemptionModConfig[redemptionModality.type]

                for (let [key, value] of Object.entries(redemptionModality)) {
                    key = capitalizeFirstLetter(key.replace(/final/g,''));

                    if (isRedemptionModalityKeys(redemptionKeys, key)) {
                        if (mapSymbolsKeys.includes(key) && ![null, undefined].includes(value as any)) {
                            if (key.indexOf('discount') === 0) {

                                const ref = key.replace('discount', '').toLowerCase()
                                const pointAmountIndex = label.findIndex((l) => l.props.className.toLowerCase().indexOf(ref) > -1)

                                if (pointAmountIndex !== -1) {
                                    const className = label[pointAmountIndex].props.className
                                    const children = label[pointAmountIndex].props.children.split(' ')[0]
                                    const clonedElement = cloneElement(label[pointAmountIndex], { className: `${className} discounted`, children });
                                    label[pointAmountIndex] = clonedElement
                                }

                                const el = (
                                    <span className={`${classNames} ${key} discount`}>{` ${value} ${mapSymbols[key]}`}</span>
                                )

                                label.splice(pointAmountIndex + 1, 0, el)

                            } else {
                                if (label.length) {
                                    label.push(<span className='add'> + </span>);
                                }

                                label.push(
                                    <span className={`${classNames} ${key}`}>
                                        {`${value} ${mapSymbols[key]}`}
                                    </span>
                                );

                            }
                        }

                    }
                }
                if (value) {
                    options.push({
                        value,
                        label: {
                            title: label as any
                        }
                    });
                }
            }*/
        }

        const field: JFieldDTO = {
            hurlId: 'redemptionModality',
            mandatory: true,
            username: false,
            type: 'JRADIO',
            validationFunction: 'KGZ1bmN0aW9uIHRlc3QocGFyYW1zKSB7IGNvbnNvbGUubG9nKCJ0ZXN0IiwgcGFyYW1zKTsgcmV0dXJuICEhcGFyYW1zLnZhbHVlIH0p',
            cmsConfig: {
                title: section.title || '',
                text: section.text || '',
                validationMessage: section.text || '',
                order: 1,
                componentConfig: {
                    options,
                    radioStyle: 'BUTTON'
                }
            },
            //values: ["iujhy786tygtr"]
        };

        // If only one redeem option, set value
        if(options.length == 1) {
            field.values = [options?.[0]?.value];
        }

        return [field];
    };
}
