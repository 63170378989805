import React from "react";
import {AdditionalCost, Points} from "@jnext/ts-axios-mz";
import {ItemValue, ItemValueProps} from "./ItemValue";
import {add} from "husky";
import {useWalletStore} from "../../store/walletStore";

interface RewardItemValueProps {
    //points?: Points[];
    //additionalCost?: AdditionalCost;
    amount?: number;
    cashAmount?: number;
    walletId?: string;
}

export const RewardItemValue: React.FC<RewardItemValueProps> = ({amount, cashAmount, walletId}) => {

    /**
     * If no data exis
     */
    if(
        (!amount && !cashAmount)
    ) {
        return <></>;
    }

    const { wallet: wallets } = useWalletStore();
    const neededWallet = wallets?.find(wallet => wallet?.logicId?.toLowerCase() === walletId?.toLowerCase());

    const props: ItemValueProps = {}

    if(amount) {
        props.points = [
            {
                value: amount,
                image: neededWallet?.cmsConfig?.imageRef,
            }
        ]
    }

    if(cashAmount) {
        props.cost = {
            value: cashAmount,
            currency: '€'
        };
    }

    return <ItemValue {...props}/>
}