import { FC } from "react";
import { CartProductItemRenderProps } from "./../CartProductItemRenderData";
import './CartProductItemPreview.scss';

export interface CartProductItemPreviewProps extends Omit<CartProductItemRenderProps, 'quantityForm'> { }

export const CartProductItemPreview: FC<CartProductItemPreviewProps> = props => {

    return (
        <div className="cart-product-item-preview">
            {
                props.image
            }
            <div className="cart-product-item-info">
                {
                    props.brands
                }
                {
                    props.title
                }
                {
                    props.removeItemCTA
                }
            </div>
            {
                props.price
            }
        </div>
    )
}