import { useState } from "react";
import MicroApp from "commons/MicroApp";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { mzUseNavigate } from "utils";
import { ErrorResponse } from "@jnext/ts-axios-mz";
import { getNavPathFromType, NavigationType, NavigationTypes } from "@jnext/commons";
import { RedemptionService } from "../../service/RedemptionService";
import { HttpService } from "../../service/HttpService";
import { useAppStore } from "../../store/appStore";
import { useCMSStore } from "store/cmsStore";
import { useWalletStore } from "store/walletStore";

function InstantWinPage() {
  const [, setShowHowTo] = useState<boolean>(true);
  const { id } = useParams();
  const navigate = mzUseNavigate();
  const { pages } = useCMSStore();

  const cmsConfig = pages?.homepage;

  const { updateWallet } = useWalletStore();
  const { setModalMessage } = useAppStore();


  if (!cmsConfig) {
    return <div></div>
  }

  return (
    <>
      {HttpService.env?.formatContest &&
        <>
          <MicroApp
            entry={`${HttpService.env?.formatContest}`}
            loaderRows={10}
            props={{
              type: "instant-win",
              updateRedemprionAddress: (redemptionId: string, obj: any) => {
                return RedemptionService.updateRedemptionAddress(
                  redemptionId,
                  obj
                );
              },
              onContestSuccess: () => { },
              onContestError: (error: ErrorResponse) => {
                const { message } = error;
                setModalMessage(
                  { text: message },
                  "error"
                );
              },
              onContestComplete: async () => {
                await updateWallet();
                setShowHowTo(false);
              },
              onContestReset: () => {
                setShowHowTo(true);
              },
              onRedirect: (path: any) => {
                switch (path) {
                  case 'pda':
                    navigate(getNavPathFromType(NavigationTypes.CONTEST_EVENT_FORM, { contestLogicId: id }));
                    break;
                }
              },
              onNavigate(type: NavigationType, params?: Record<string, any>) {
                return navigate(getNavPathFromType(type, params))
              },
              contestLogicId: id || "",
            }}
          />
        </>
      }
    </>
  );
}

export default observer(InstantWinPage);
