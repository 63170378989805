import { ThankYouPageProps } from "./ThankYouPage";
import {CMSConfiguration} from "@jnext/commons";
import {CoreUserContext} from "../../../type/coreUserContext";

export function cmsConfigToThankProps(
    cmsConfig: CMSConfiguration | undefined,
    context: CoreUserContext
): ThankYouPageProps {
    
    const cmsSection = cmsConfig?.section
    const cmsPage = cmsConfig?.page
    const thankyouPageSection = cmsSection?.sections?.find((section) => section.type === 'THANKYOU_PAGE');
    const thankYouPageProps: ThankYouPageProps = {
        context
    };

    if (thankyouPageSection) {
        thankYouPageProps.cmsConfig = {
            enabled: true,
            page: cmsPage,
            section: {
                ...thankyouPageSection,
                cta: thankyouPageSection.cta?.filter((action) => action.scope === 'HOME') || []
            }
        };
    }
    return thankYouPageProps;
}