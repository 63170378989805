import { RightOutlined } from "@ant-design/icons";
import { CMSConfiguration, JModal, NavigationTypes, getNavPathFromType } from "@jnext/commons";
import { RedemptionResponse} from "@jnext/ts-axios-mz";
import { DeliveryDto } from "@jnext/ts-axios-mz/models/delivery-dto";
import { Order } from "@jnext/ts-axios-mz/models/order";
import { DocumentTypeEnum } from "@jnext/ts-axios-reward";
import { Breadcrumb, Col, Row } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppStore } from "store/appStore";
import { useLoadingStore } from "store/loadingStore";
import { RewardTotalsItemValueString } from "../../../../commons/ItemValue/RewardTotalsItemValue";
import { HttpService } from "../../../../service/HttpService";
import { RedemptionService } from "../../../../service/RedemptionService";
import { ResourceService } from "../../../../service/ResourseService";
import { downloadPrivateImg, useCMSStore } from "../../../../store/cmsStore";
import { HistoryRewardType } from "../../../../type/historyReward";
import { FORMAT_DATE, mzUseNavigate, useTranslationWord } from "../../../../utils";
import { TrackInfo } from "./trackInfo";
import {ProductGroupV2} from "./ProductGroupV2";
import {ProductGroupV1} from "./ProductGroupV1";


interface HistoryRewardsDetailProps {
    type: Exclude<HistoryRewardType, HistoryRewardType.ALL>;
}

// Method to download the Approval PDF
export const downloadApprovalPdf = async (redemption: RedemptionResponse) => {
    const approvalPdfLink = redemption?.approvalDetail?.pdfLink;

    if (!approvalPdfLink) {
        return;
    }

    const res = await ResourceService.download(approvalPdfLink, {
        responseType: 'blob',
        headers: HttpService.getHttpHeaders({})
    })
    if (res?.status === 500 || res?.data?.error) {
        return Promise.reject();
    }

    const link = document.createElement("a");
    link.href = URL.createObjectURL(res?.data);
    link.download = approvalPdfLink;
    link.click();
}

export const HistoryRewardsDetail: React.FC<HistoryRewardsDetailProps> = ({ type }) => {
    const { redemptionId } = useParams();
    const navigate = mzUseNavigate();
    const { handleResponseError } = useAppStore()
    const [jModalStyle, setJModalStyle] = useState({});

    const returnToList = () => {
        const sectionsTypes = {
            [HistoryRewardType.CONTEST]: NavigationTypes.HISTORY_REWARDS_CONTEST,
            [HistoryRewardType.CATALOG]: NavigationTypes.HISTORY_REWARDS_CATALOG,
            [HistoryRewardType.CATALOG_LOYALTY_COLLECTION]: NavigationTypes.HISTORY_REWARDS_CATALOG_LOYALTY_COLLECTION,
            [HistoryRewardType.CATALOG_DIGITAL_WALLET]: NavigationTypes.HISTORY_REWARDS_CATALOG_DIGITAL_WALLET,
        };

        navigate(getNavPathFromType(sectionsTypes[type]));
    };

    /**
     * Check params
     */
    if (!redemptionId) {
        return <></>;
    }


    const translateWord = useTranslationWord();
    const [orderTracking, setOrderTracking] = useState<Order>();
    const [redemption, setRedemption] = useState<RedemptionResponse>();
    const [showTotals, setShowTotals] = useState<boolean>(false);
    const [isRewardPhysical, setIsRewardPhysical] = useState<boolean>();
    const [showApprovalDetails, setShowApprovalDetails] = useState<boolean>(false);

    /** show cardV2 **/
    const [groupByOrders, setGroupByOrders] = useState<Object>();
    const [friendlyId, setFriendlyId] = useState<string>();


    // Method to download the image of Id Card/Passport 
    const documentDownload = async (imgUrl: string) => {
        setLoading(true);
        try {
            await downloadPrivateImg(imgUrl)
        }
        catch (error) {
            handleResponseError(error)
        }
        finally {
            setLoading(false);
        }
    }

    const documentTypeTranslations = {
        baseIndex: '',
        [DocumentTypeEnum.Passport]: translateWord('REDEMPTION_FIELD_PERSONAL_DATA_DOCUMENT_TYPE_OPTION_PASSPORT_TITLE'),
        [DocumentTypeEnum.IdCard]: translateWord('REDEMPTION_FIELD_PERSONAL_DATA_DOCUMENT_TYPE_OPTION_ID_CARD_TITLE'),
        [DocumentTypeEnum.DrivingLicense]: translateWord('REDEMPTION_FIELD_PERSONAL_DATA_DOCUMENT_TYPE_OPTION_DRIVING_LICENSE_TITLE'),
    }

    const approvalData = {
        main: [
            {
                key: translateWord('REDEMPTION_FIELD_PERSONAL_DATA_NAME_TITLE'),
                value: redemption?.approvalDetail?.address?.name
            },
            {
                key: translateWord('REDEMPTION_FIELD_PERSONAL_DATA_SURNAME_TITLE'),
                value: redemption?.approvalDetail?.address?.surname
            },
            {
                key: translateWord('REDEMPTION_FIELD_PERSONAL_DATA_BIRTH_PLACE_TITLE'),
                value: redemption?.approvalDetail?.birthplace
            },
            {
                key: translateWord('REDEMPTION_FIELD_PERSONAL_DATA_BIRTH_DATE_TITLE'),
                value: moment(redemption?.approvalDetail?.birthdate)?.format(FORMAT_DATE)
            },
            {
                key: translateWord('REDEMPTION_FIELD_PERSONAL_DATA_ADDRESS_STREET_TITLE'),
                value: redemption?.approvalDetail?.address?.street
            },
            {
                key: translateWord('REDEMPTION_FIELD_PERSONAL_DATA_ADDRESS_CITY_TITLE'),
                value: redemption?.approvalDetail?.address?.city
            },
            {
                key: translateWord('REDEMPTION_FIELD_PERSONAL_DATA_ADDRESS_PROVINCE_TITLE'),
                value: redemption?.approvalDetail?.address?.province
            },
            {
                key: translateWord('REDEMPTION_FIELD_PERSONAL_DATA_ADDRESS_POSTAL_CODE_TITLE'),
                value: redemption?.approvalDetail?.address?.postalCode
            },
            {
                key: translateWord('REDEMPTION_FIELD_PERSONAL_DATA_EMAIL_TITLE'),
                value: redemption?.approvalDetail?.address?.email
            },
            {
                key: translateWord('REDEMPTION_FIELD_PERSONAL_DATA_PHONE_TITLE'),
                value: (redemption?.approvalDetail as any)?.phone
            },
            {
                key: translateWord('REDEMPTION_FIELD_PERSONAL_DATA_DOCUMENT_TYPE_TITLE'),
                value: documentTypeTranslations?.[redemption?.approvalDetail?.documentType || 'baseIndex'],
                images: redemption?.approvalDetail?.documentImages.length ? redemption?.approvalDetail?.documentImages.map(docImg => docImg.image) : undefined
            },
            {
                key: translateWord('REDEMPTION_FIELD_PERSONAL_DATA_DOCUMENT_NUMBER_TITLE'),
                value: redemption?.approvalDetail?.documentCode
            },
            //{key: 'Immagini', value: redemption?.approvalDetail?.documentCode},
        ],
        delivery_address: [
            {
                key: translateWord('REDEMPTION_FIELD_DELIVERY_NAME_TITLE'),
                value: redemption?.approvalDetail?.delivery_address?.name
            },
            {
                key: translateWord('REDEMPTION_FIELD_DELIVERY_SURNAME_TITLE'),
                value: redemption?.approvalDetail?.delivery_address?.surname
            },
            {
                key: translateWord('REDEMPTION_FIELD_DELIVERY_EMAIL_TITLE'),
                value: redemption?.approvalDetail?.delivery_address?.email
            },
            {
                key: translateWord('REDEMPTION_FIELD_DELIVERY_PHONE_TITLE'),
                value: (redemption?.approvalDetail?.delivery_address as any)?.phone
            },
            {
                key: translateWord('REDEMPTION_FIELD_DELIVERY_ADDRESS_STREET_TITLE'),
                value: redemption?.approvalDetail?.delivery_address?.street
            },
            {
                key: translateWord('REDEMPTION_FIELD_DELIVERY_ADDRESS_CITY_TITLE'),
                value: redemption?.approvalDetail?.delivery_address?.city
            },
            {
                key: translateWord('REDEMPTION_FIELD_DELIVERY_ADDRESS_PROVINCE_TITLE'),
                value: redemption?.approvalDetail?.delivery_address?.province
            },
            {
                key: translateWord('REDEMPTION_FIELD_DELIVERY_ADDRESS_POSTAL_CODE_TITLE'),
                value: redemption?.approvalDetail?.delivery_address?.postalCode
            },
            {
                key: translateWord('REDEMPTION_FIELD_DELIVERY_ADDRESS_COUNTRY_TITLE'),
                value: redemption?.approvalDetail?.delivery_address?.country
            },
            {
                key: translateWord('REDEMPTION_FIELD_DELIVERY_NOTE_TITLE'),
                value: redemption?.approvalDetail?.delivery_address?.note,
                fullWidth: true
            },
        ],
    }


    /** CMS DATA **/
    const { pages } = useCMSStore();
    const config: CMSConfiguration | undefined = useMemo(() => pages?.profile, [pages?.profile]);
    const [profileOptions, setProfileOptions]= useState<any>();

    // All Sections
    const sections = useMemo(() => {
        if (!config) {
            return [];
        }

        const sectionsTypes = {
            [HistoryRewardType.CONTEST]: NavigationTypes.HISTORY_REWARDS_CONTEST,
            [HistoryRewardType.CATALOG]: NavigationTypes.HISTORY_REWARDS_CATALOG,
            [HistoryRewardType.CATALOG_LOYALTY_COLLECTION]: NavigationTypes.HISTORY_REWARDS_CATALOG_LOYALTY_COLLECTION,
            [HistoryRewardType.CATALOG_DIGITAL_WALLET]: NavigationTypes.HISTORY_REWARDS_CATALOG_DIGITAL_WALLET,
        };

        const sectionType = sectionsTypes[type] || null;

        if (!sectionType) {
            return [];
        }

        const  section= config?.section?.sections?.find(el => el.type == sectionType);
        setProfileOptions(section?.options)

        //return sections
        return section?.sections;
    }, [config]);

    // Specific section
    const cms = useMemo(() => sections?.find((el: any) => el.type == 'DETAILS'), [sections]);
    const options = cms?.options;

    const headCms = useMemo(() => cms?.sections?.find((el: any) => el.type == 'HEAD'), [cms]);
    const bodyCms = useMemo(() => cms?.sections?.find((el: any) => el.type == 'BODY'), [cms]);
    const approvalCms = useMemo(() => cms?.sections?.find((el: any) => el.type == 'APPROVAL_MODAL'), [cms]);

    const { setLoading } = useLoadingStore();

    /**
     * Load redemption
     */
    useEffect(() => {

        (async () => {
            try {
                // Show loader
                setLoading(true);

                const details = await RedemptionService.retrieveRedemptionDetails(redemptionId);
                setRedemption(details);

                /** show cardV2 **/
                if(options?.showHistoryCardV2 && details){
                    setFriendlyId(details?.orders?.[0]?.friendlyId);
                    const groupByOrders = details?.orders?.reduce((group, order) => {
                        const { orderStatus } = order;
                        // @ts-ignore
                        group[orderStatus] = group[orderStatus] ?? [];
                        // @ts-ignore
                        group[orderStatus].push(order);
                        return group;
                    }, {});
                    setGroupByOrders(groupByOrders)
                }
                //show totals info
                setShowTotals(RedemptionService.showTotals(details?.totals));

                // If reward is digital -> no need
                setIsRewardPhysical(RedemptionService.hasPhysicalProducts(details));

            } catch (err) {
            } finally {
                // Show loader
                setLoading(false);
            }
        })();

    }, []);

    const handleResize = () => {
        const width = window.innerWidth;
        if (width > 1020 || width < 638) {
            setJModalStyle({ marginTop: "60px" });
        } else {
            setJModalStyle({});
        }
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return <>
        {
            redemption &&
            <div className={'reward-detail'}>
                {options && !options.showHistoryCardV2 ? <>
                    <Breadcrumb className={'reward-breadcrumbs'} separator={<RightOutlined className={'path-separator'}/>}>
                        <Breadcrumb.Item onClick={returnToList}>Storico premi</Breadcrumb.Item>
                        <Breadcrumb.Item>{cms?.title} {redemption?.friendlyId}</Breadcrumb.Item>
                    </Breadcrumb>

                    <div className={'mobile-breadcrumbs'} onClick={returnToList}>
                        &lt; &nbsp; Storico premi
                    </div>
                </> : <>
                    <div className="title">{cms?.title}</div>
                    <div className="subtitle">{cms?.subTitle} {redemption?.friendlyId}</div>
                </>}

                <div className="title showMobile">{cms?.title} {redemption?.friendlyId}</div>
                <Row className={'main-info'} gutter={[100, 20]}>
                    <Col className={'col'}>
                        <div className={'info-key'}>{headCms?.table?.requestDate?.label}</div>
                        <div className={'info-value'}>{moment(redemption?.dateTime)?.format(FORMAT_DATE)}</div>
                    </Col>
                    {
                        isRewardPhysical &&
                        <Col className={'col'}>
                            <div className={'info-key'}>{headCms?.table?.shippingAddress?.label}</div>
                            <div
                                className={'info-value'}>{redemption?.address?.street}, {redemption?.address?.city} ({redemption?.address?.province}), {redemption?.address?.postalCode}</div>
                        </Col>
                    }
                    {
                        redemption?.address?.email &&
                        <Col className={'col'}>
                            <div className={'info-key'}>{headCms?.table?.email?.label}</div>
                            <div className={'info-value'}>{redemption?.address?.email}</div>
                        </Col>
                    }
                    {
                        (
                            (type != HistoryRewardType.CONTEST) && showTotals &&
                            (redemption?.totals?.requiredPoints || redemption?.totals?.additionalCost))
                        && (
                            <Col className={'col'}>
                                <div className={'info-key'}>{headCms?.table?.total?.label}</div>
                                <div className={'info-value '}>
                                    <RewardTotalsItemValueString points={redemption?.totals?.requiredPoints} additionalCost={redemption?.totals?.additionalCost} />
                                </div>
                            </Col>
                        )
                    }
                    {
                        redemption?.approvalDetail?.fl_acceptance &&
                        <Col className={'col'}>
                            <div className={'info-key'}>{headCms?.table?.approvalDetails?.label}</div>
                            <div className={'info-value pointer'}
                                 onClick={() => setShowApprovalDetails(true)}>{headCms?.table?.approvalDetailsCtaLabel?.label}</div>
                        </Col>
                    }
                </Row>

                <div className={'orders-list-container'}>
                    <ProductGroupV1 headCms={headCms}
                                    bodyCms={bodyCms}
                                    redemption={redemption}
                                    options={options}
                                    type={type}
                                    setOrderTracking={(order)=> setOrderTracking(order)}/>

                    <ProductGroupV2 bodyCms={bodyCms}
                                    groupByOrders={groupByOrders ? groupByOrders : {}}
                                    profileOptions={profileOptions}
                                    options={options}
                                    friendlyId={friendlyId}
                                    type={type}
                                    setOrderTracking={(order)=> setOrderTracking(order)}/>


                </div>


                {/*TRACK INFO*/}
                {orderTracking && <TrackInfo order={orderTracking} closeModal={() => setOrderTracking(undefined)} />}

            </div>
        }

        <JModal
            width={800}
            isModalVisible={showApprovalDetails}
            footer={null}
            handleCancel={() => setShowApprovalDetails(false)}
            style={jModalStyle}
        >
            <div className={'history-details-approval-details'}>
                <div className={'group'}>
                    <h3 className={'box-title'}>
                        {approvalCms?.table?.approvalMainTitle?.label}
                        {
                            redemption?.approvalDetail?.pdfLink &&
                            <span className={'link'}>
                                (<span className={'underline'}
                                       onClick={() => downloadApprovalPdf(redemption)}>{approvalCms?.table?.approvalPdfCtaLabel?.label}</span>)
                            </span>
                        }
                    </h3>
                    <div className={'list-container'}>
                        <ul>
                            {
                                approvalData.main.map((el, i) => {
                                    const isImage = el?.images?.find(img => img !== '')
                                    return (
                                        <li key={i}>
                                            <div className={'key'}>{el.key}</div>
                                            <div className={'value'}>{el.value}</div>
                                            {
                                                el.images && isImage &&
                                                <div className={'img-url-container'}>
                                                    {el.images.map((imgUrl, i) => {
                                                        return (
                                                            <div
                                                                key={i}
                                                                className={'img-url'}
                                                                onClick={() => documentDownload(imgUrl as string)}
                                                            >
                                                                {el.value} {i + 1}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            }
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>

                {
                    redemption?.approvalDetail?.fl_acceptance &&
                    <div className={'group'}>
                        <h3 className={'box-title'}> {approvalCms?.table?.approvalShippingTitle?.label}</h3>
                        <div className={'list-container'}>
                            <ul>
                                {
                                    approvalData.delivery_address.map((el, i) => (
                                        <li key={i} className={`${el?.fullWidth ? 'full-width' : ''}`}>
                                            <div className={'key'}>{el.key}</div>
                                            <div className={'value'}>{el.value}</div>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                }
            </div>
        </JModal>

    </>;
}