import { CMSSection } from "@jnext/commons";
import React, { useMemo } from "react";
import { SolutionProjectImage } from "../../../commons";
import { observer } from "mobx-react";
import { useCMSStore } from "store/cmsStore";
import { useConsumerStore } from "store/consumerStore";

const AvatarSection = observer(({ section }: { section: CMSSection }) => {
    const { appSettings } = useCMSStore();
    const { avatarURL, } = useConsumerStore();
    const photo = useMemo(() => avatarURL || appSettings?.avatarFallback, [avatarURL, appSettings]);
    if (!section?.enabled) return <React.Fragment/>;
    return (
        <React.Fragment>
            {
                photo && (
                    <SolutionProjectImage
                        className="avatar"
                        name={photo}
                    />
                )
            }
        </React.Fragment>)
})

export default AvatarSection;