import MicroApp from 'commons/MicroApp';
import { observer } from 'mobx-react';
import { getNavPathFromType, NavigationType } from "@jnext/commons";
import { mzUseNavigate } from '../../../utils';
import { HttpService } from "../../../service/HttpService";
import { useAppStore } from "../../../store/appStore";

const RegistrationPage = () => {
    const navigate = mzUseNavigate();
    const { handleResponseError } = useAppStore();

    return (
        <>
            {
                HttpService.env?.coreUser &&
                <MicroApp
                    entry={`${HttpService.env?.coreUser}`}
                    props={{
                        type: 'signup',
                        onError: handleResponseError,
                        onNavigate(type: NavigationType) {
                            return navigate(getNavPathFromType(type))
                        },
                        keyCloakClientId: HttpService.env?.keycloakClientID as string,
                    }}
                />
            }
        </>
    );
};

export default observer(RegistrationPage);


