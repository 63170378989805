import { observer } from "mobx-react";
import {
    CMSConfiguration,
    JPage,
} from "@jnext/commons";
import React, {Fragment, useMemo} from "react";
import { useCMSStore } from "store/cmsStore";
import {mzUseNavigate} from "utils/utils";
import {JVasCardSection} from "../../../components/Vas";

const VasPage = () => {
    // Ottiene le pagine dal CMS store
    const { pages } = useCMSStore();
    // Funzione di navigazione personalizzata
    const mzNavigate = mzUseNavigate();

    // Usa useMemo per memorizzare la configurazione CMS della pagina VAS
    const cmsConfig: CMSConfiguration | undefined = useMemo(() => pages?.vas, [pages?.vas]);

    return (
        <Fragment>
            <JPage enabled {...cmsConfig?.page} options={{headerAlign: 'center'}}>
                {/* Se ci sono sezioni abilitate, renderizza JVasSection per ciascuna */}
                {!!cmsConfig?.section?.sections?.length && <>
                    {cmsConfig?.section?.sections.map((section, key) => {
                        if(section?.enabled){
                            return( <JVasCardSection key={key} section={section} />)
                        }
                    })}
                </>
                }
            </JPage>
        </Fragment>
    );
}

export default observer(VasPage);