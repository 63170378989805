import { observer } from "mobx-react";
import {
    CMSConfiguration,
    CMSSection, getNavPathFromType,
    JPage,
    JSkeletonSlider, NavigationTypes
} from "@jnext/commons";
import React, {Fragment, useEffect, useMemo, useState} from "react";
import { Navigate } from "react-router-dom";
import { useCMSStore } from "store/cmsStore";
import {getMGMSectionDom, mzUseNavigate} from "utils/utils";
import MGMDashboard from "../../components/MGMDasboard";
import {MgmInitiativeDto} from "@jnext/ts-axios-mz";
import {MGMService} from "../../service/MGMService";
import { useConsumerStore } from "store/consumerStore";

const MGMPage = () => {
    const { pages } = useCMSStore();
    const {consumerInfo} = useConsumerStore()
    const mzNavigate = mzUseNavigate();

    const cmsConfig: CMSConfiguration | undefined = useMemo(() => pages?.friends, [pages?.friends]);

    if (!cmsConfig || !consumerInfo?.gdprMgmConsent || !consumerInfo?.gdprMgmConsent?.consent) {
        return <Navigate to='/404' />
    }
   /* if(consumerInfo && !consumerInfo?.gdprMgmConsent.consent){
        return <Navigate to={'/registration/finalize'} />
    }*/

    const [initiatives, setInitiative] = useState<MgmInitiativeDto>()
    const [isSkeleton, setISSkeleton] = useState<boolean>(true)
    const cmsPageSections: CMSSection[] | undefined = useMemo(() => cmsConfig?.page?.sections, [cmsConfig?.page?.sections]);

    //get all section
    const sections = useMemo(() => cmsConfig?.section?.sections, [cmsConfig?.section?.sections]);
    const optionCatalogProps = useMemo(() => sections?.find(section => section.type === 'DIGITAL_WALLET' || section.type === 'LOYALTY_COLLECTION'), [sections])?.options;
    const [allLogicId, setAllLogicId] = useState<string[]>([])

    useEffect(() => {
        if(allLogicId && allLogicId?.length > 0){
            // @ts-ignore
            MGMService.getMgmInitiative(allLogicId, optionCatalogProps?.page, optionCatalogProps?.size).then((data)=>{
                setInitiative(data);
                setISSkeleton(false);
            }).catch(()=>{
                setISSkeleton(false);
            })
        }else {
            setISSkeleton(false);
        }
    }, [allLogicId]);

    return (
        <Fragment>
            <JPage className={'mgmPage'} enabled {...cmsConfig.page} options={{headerAlign: 'center'}}>

                <MGMDashboard cmsPageSections={cmsPageSections} setAllLogicId={setAllLogicId} />

                {isSkeleton && <JSkeletonSlider hasTitle={true} />}

                {!!cmsConfig?.section?.sections?.length && !isSkeleton && <>
                    {cmsConfig?.section?.sections.map((section, key) => {
                        if(section?.enabled){
                            return(<div key={key} className={`${(key%2 === 0) ? 'background': ''}`}>
                                {getMGMSectionDom(section, initiatives, mzNavigate, key)}
                            </div>)
                        }
                    })}
                </>
                }
            </JPage>
        </Fragment>
    );
}

export default observer(MGMPage);