import {CMSSection} from "@jnext/commons";
import ElementHead from "./elementHead";
import React from "react";
import {ElementBody} from "./elementBody";
import {RedemptionResponse} from "@jnext/ts-axios-mz";
import {HistoryRewardType} from "../../../../../type/historyReward";

interface ListProps {
    redemptions: RedemptionResponse[];
    onRedeem: (redemptionId: string) => void;
    cms: CMSSection;
    type: Exclude<HistoryRewardType, HistoryRewardType.ALL>;
}

export const DesktopList: React.FC<ListProps> = ({redemptions, onRedeem, cms, type}) => {

    return (
        <>
            {
                redemptions?.map((redemption, i) => (
                    <div className={'redemption-element'} key={redemption?.logicId}>
                        <ElementHead redemption={redemption} onRedeem={() => onRedeem(redemption?.logicId)} cms={cms}
                                     type={type}/>
                        <ElementBody redemption={redemption} cms={cms} type={type}/>
                    </div>
                ))
            }

        </>
    );
}