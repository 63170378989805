import axios from "axios";
import {HttpService} from "./HttpService";
import {AuthService} from "./AuthService";
import {DigitalCollectionApi, SubscriptionApi, VasApi, SubscriptionRequestTypeEnum} from "@jnext/ts-axios-formatdigitalcollection";
import {TransactionApi} from "@jnext/ts-axios-mz";
import {GetProductsFilters} from "./CatalogService";

export class VasService {
    static module = 'format-digital-collection';

    // Method to get VAS cards
    static async getVasProducts(initiativeLogicId: string, page: number = 0, size: number = 10) {
        // Create an instance of DigitalCollectionApi
        // @ts-ignore
        const vasApi: DigitalCollectionApi = new DigitalCollectionApi(undefined, HttpService.servicePath(this.module), axios);
        try {
            // Fetch VAS cards based on authorization
            const res = AuthService.authorized
                ? await vasApi?.getProductsPreviewPrivate(page, size, initiativeLogicId)
                : await vasApi?.getProductsPreviewPublic(page, size, initiativeLogicId);
            // Return the results
            return res?.data?.results;
        } catch (e: any) {
            // Log any errors
            console.log('getVasProducts error: ', e)
        }
    };

    static async getProductsEligible(initiativeLogicId: string, clusterLogicId: string, page: number = 0, size: number = 10, filters?: GetProductsFilters) {
        // @ts-ignore
        const vasApi: DigitalCollectionApi = new DigitalCollectionApi(undefined, HttpService.servicePath(this.module), axios);
        try {
            // Fetch VAS cards based on authorization
            const res = await vasApi?.getProductsPreviewPrivateEligible(
                initiativeLogicId,
                page,
                size,
                true,
                [],
                false,
                filters?.searchText || '',
                filters?.tagLogicIds || [],
                filters?.brandLogicIds || [],
                filters?.walletConfigurationLogicIds || [],
                filters?.sectorLogicIds || [],
                filters?.minAmount,
                filters?.maxAmount,
                [clusterLogicId]);
            // Return the results
            return res?.data;
        } catch (e: any) {
            // Log any errors
            console.log('getVasProducts error: ', e)
        }
    };

    static async getSubscriptionDetails(initiativeLogicId: string) {
        // @ts-ignore
        const subscriptionApi: SubscriptionApi = new SubscriptionApi(undefined, HttpService.servicePath(this.module), axios);
        try {
            const response = await subscriptionApi?.getSubscriptionForUser(initiativeLogicId);
            return response.data;
        } catch (error) {
            console.log('getSubscriptionDetails error: ', error);
            throw error;
        }
    }

    static async getInvoice(transactionLogicId: string) {
        // @ts-ignore
        const transactionApi: TransactionApi = new TransactionApi(undefined, HttpService.servicePath('solution-mz'), axios);
        try {
            const response = await transactionApi?.getInvoiceForPayment(transactionLogicId)
            return response.data;
        } catch (error) {
            console.log('getSubscriptionDetails error: ', error);
            throw error;
        }
    }

    static async customerPortal(initiativeLogicId: string) {
        // @ts-ignore
        const vasApi: VasApi = new VasApi(undefined, HttpService.servicePath(this.module), axios);

        try {
            const response = await vasApi?.getCustomerPortalUrl(initiativeLogicId)
            return response.data;
        } catch (error) {
            console.log('getSubscriptionDetails error: ', error);
            throw error;
        }

    }

    static async getPurchaseParametersForProduct(initiativeLogicId: string, catalogGroupLogicId: string) {
        // @ts-ignore
        const purchaseParams: DigitalCollectionApi = new DigitalCollectionApi(undefined, HttpService.servicePath(this.module), axios);
        try {
            const response = await purchaseParams?.getPurchaseParametersForProduct(initiativeLogicId, catalogGroupLogicId);
            return response.data;
        } catch (error) {
            console.log('getSubscriptionDetails error: ', error);
            throw error;
        }
    }

    // Method to delete VAS subscription
    static async delVasSubscription(initiativeLogicId: string, redemptionId: string) {
        // Create an instance of SubscriptionApi
        // @ts-ignore
        const subscriptionApi: SubscriptionApi = new SubscriptionApi(undefined, HttpService.servicePath(this.module), axios);
        try {
            // Fetch VAS cards based on authorization
            const res = await subscriptionApi?.voidSubscriptionRequest(initiativeLogicId, redemptionId, SubscriptionRequestTypeEnum.ChangePlan)

            // Return the results
            return res?.status;
        } catch (e: any) {
            // Log any errors
            console.log('getVasProducts error: ', e)
        }
    };
}