import {FC, Fragment, useMemo} from "react"
import {ProductDetail} from "./ProductDetail"
import {ProductCMS, ProductContexts, ProductMainSectionTypes} from "./models"
import {useParams} from "react-router-dom";
import {useCMSStore} from "../../store/cmsStore";

export interface CollectionProductProps {
    private: boolean
}

export const CollectionProduct: FC<CollectionProductProps> = props => {
    const {pages} = useCMSStore();
    const cmsConfig: ProductCMS = useMemo(() => pages?.productDetails as ProductCMS, [pages]);

    const {initiativeLogicId} = useParams();

    const collectionCms = useMemo(() => {

        // Search needed section
        const neededSection = cmsConfig?.section?.sections.find(el => el.type == `INITIATIVE_${initiativeLogicId}`);

        // Fallback section
        const fallBackSection = cmsConfig?.section?.sections.find(el => el.type == ProductMainSectionTypes.LOYALTY_COLLECTION)

        return neededSection ? neededSection : fallBackSection;

    }, [cmsConfig, initiativeLogicId]);

    return (
        <Fragment>
            {
                cmsConfig && collectionCms && collectionCms?.sections && (
                    <ProductDetail
                        context={ProductContexts.COLLECTION}
                        productsCMS={cmsConfig}
                        productSections={collectionCms?.sections}
                        private={props.private}
                        initiativeLogicId={initiativeLogicId!}
                    />
                )
            }
        </Fragment>
    )
}