import React, {FC, useMemo} from "react";
import {CMSConfiguration, CMSCTA, CMSSection, getNavPathFromType, JTabs, NavigationType} from "@jnext/commons";
import {mzUseNavigate} from "../../utils";
import {observer} from "mobx-react";
import {useLoadingStore} from "store/loadingStore";

const getItems = (
    sections: CMSSection[] | undefined
) => {
    const items: any[] = [];

    sections?.map((section) => {
        items.push({
            key: section?.type,
            label: section?.title,
            path: section?.type && getNavPathFromType(section?.type)
        })

    });
    return items;
}

function tmpNormalizer(config: CMSConfiguration | undefined, tabsCTA: CMSCTA[], titlePlaceholder: string) {

    const _config: CMSConfiguration = JSON.parse(JSON.stringify(config || {
        enabled: true
    }))
    if (!_config.page) {
        _config.page = {
            enabled: true,
            title: '',
            subTitle: ''
        }
    }
    if (!_config.section) {
        _config.section = {
            enabled: true,
            type: 'TABS_LIST',
            sections: tabsCTA.map(cta => ({
                enabled: true,
                type: cta.scope,
                title: cta.title,
                cta: [cta]
            }))
        }
    }

    return _config

}

interface ProfileContainerProps {
    mobileView: boolean,
    sections: CMSSection[]
}

const ProfileContainer: FC<ProfileContainerProps> = (props) => {
    const navigate = mzUseNavigate();
    const path = window?.location?.pathname;

    const menuItems = useMemo(() => getItems(props.sections), [props.sections]);
    const tabActive = useMemo(() => {

        // Get the longest matching url
        const items = menuItems?.filter(menu => path?.startsWith(menu?.path))
        if (items?.length > 0) {
            const longestMatchingItem = items.reduce((a, b) =>
                a.path.length > b.path.length ? a : b
            );
            return longestMatchingItem;
        }

    }, [path, menuItems]);

    const {setLoading} = useLoadingStore();


    React.useEffect(() => {
        window.onpopstate = e => {
            if (e?.isTrusted) {
                setLoading(true);
                return window.location.reload();
            }
        };
    });


    return (
        <JTabs
            activeKey={tabActive?.key}
            tabPosition={props.mobileView ? 'top' : 'left'}
            defaultKey={tabActive?.key}
            items={menuItems}
            onTabClick={(path: NavigationType) => navigate(getNavPathFromType(path))}
        />
    )
}

export const ProfileContent = observer(ProfileContainer);