import { observer } from "mobx-react";
import {
    breakpointsMax,
    CMSSection,
    JSection
} from "@jnext/commons";
import {FC, useEffect, useMemo, useState} from "react";
import FriendBoard from "./FriendBoard/FriendBoard";
import CodeBoard from "./CodeBoard/CodeBoard";
import ShareBoard from "./ShareBoard/ShareBoard";
import {useWindowSize} from "@react-hook/window-size";
import {MGMService} from "../../service/MGMService";
import {ConsumerMgmCodeInfoDto} from "@jnext/ts-axios-mz/models/consumer-mgm-code-info-dto";

interface MGMDashboardProps{
    cmsPageSections: CMSSection[] | undefined
    infoMgm?: ConsumerMgmCodeInfoDto;
    setAllLogicId: (logicIds: string[]) => void
}

const DesktopView: FC<MGMDashboardProps> = ({cmsPageSections, infoMgm, setAllLogicId})=>{
    return (<JSection>
            <div className={'mgmDashboard'}>
                <div className={'mgmRowUp'}>
                    <CodeBoard  sections={cmsPageSections} codeCopy={infoMgm?.code}/>
                    <ShareBoard  sections={cmsPageSections} codeCopy={infoMgm?.code}/>
                </div>
                {infoMgm &&
                    <FriendBoard sections={cmsPageSections} setAllLogicId={setAllLogicId}/>
                }
            </div>
        </JSection>
    );
}

const MobileView: FC<MGMDashboardProps> = ({cmsPageSections, infoMgm, setAllLogicId})=>{
    return (<JSection>
            <div className={'mgmDashboard'}>
                <CodeBoard  sections={cmsPageSections} codeCopy={infoMgm?.code}/>
                <ShareBoard  sections={cmsPageSections} codeCopy={infoMgm?.code}/>
                {infoMgm && <FriendBoard sections={cmsPageSections} setAllLogicId={setAllLogicId}/>}
            </div>
        </JSection>
    );
}
const MGMDashboard: FC<MGMDashboardProps> = ({cmsPageSections, setAllLogicId}) => {
    const [width] = useWindowSize();
    const [infoMgm, setInfoMgm] = useState<ConsumerMgmCodeInfoDto>();

    useEffect(() => {
        (async () => {
            try{
                await MGMService.getMgmCodeInfo().then(res => {
                    return setInfoMgm(res);
                })
            }
            catch (e){}
        })();
    }, []);

    const Component = useMemo(() => {
        if (width <= breakpointsMax.md) {
            return <MobileView cmsPageSections={cmsPageSections} infoMgm={infoMgm} setAllLogicId={setAllLogicId} />
        }
        return <DesktopView cmsPageSections={cmsPageSections} infoMgm={infoMgm} setAllLogicId={setAllLogicId}/>
    }, [width, infoMgm])


    return (<>{infoMgm && Component}</>)

}

export default observer(MGMDashboard);