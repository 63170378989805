import React, {useMemo} from 'react';
import {CMSSection, JHTML} from '@jnext/commons';
import {observer} from "mobx-react";
import Copyable from "../../../commons/Copyable/Copyable";

interface MGMCodeBoardProps {
    sections: CMSSection[] | undefined;
    codeCopy?: string;
}

const CodeBoard = ({ sections, codeCopy }: MGMCodeBoardProps) => {

    const mgmCodeSection: CMSSection | undefined = useMemo(() => sections?.find((section: CMSSection) => (section?.enabled && section?.type === 'CODE_BOARD')), [sections]);

    if(!mgmCodeSection){
        return <></>
    }

    return (
        <div className={'codeBoard'}>
            {mgmCodeSection?.title &&
                <JHTML.Title html={mgmCodeSection?.title} />
            }
            {codeCopy ? <div className="codeCopy">
                <Copyable text={codeCopy}/>
            </div> : <JHTML.SubTitle html={'Nessun codice disponibile'}/>}
        </div>
    )
};

export default observer(CodeBoard);