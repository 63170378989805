import {CMSSection, getNavPathFromType, NavigationTypes} from "@jnext/commons";
import {Item, RedemptionResponseStatusEnum} from "@jnext/ts-axios-mz";
import React from "react";
import {EventResponse} from "@jnext/ts-axios-eventformat";
import {ProductCard} from "./ProductCard";
import {EventExecutionHistoryDto} from "@jnext/ts-axios-eventformat/models/event-execution-history-dto";
import {RedemptionService} from "../../../../service/RedemptionService";
import {mzUseNavigate} from "../../../../utils";

interface ProductListProps {
    event: EventResponse
    eventExecution: EventExecutionHistoryDto | undefined
    bodyCms?: CMSSection
}

export const ProductList: React.FC<ProductListProps> = ({event, bodyCms, eventExecution}) => {
    const navigate = mzUseNavigate();

    const onClickCb = async (redemptionId: string) => {
        try {
            const res = await RedemptionService.retrieveRedemptionDetails(redemptionId);
            if (res) {
                if (res?.status === RedemptionResponseStatusEnum.WaitingForUser) {
                    return navigate(getNavPathFromType(NavigationTypes.REDEEM, { redemptionId }));
                } else if (res?.status === RedemptionResponseStatusEnum.Completed) {
                    return navigate(getNavPathFromType(NavigationTypes.HISTORY_REWARDS_CATALOG_DETAILS, { redemptionId }));
                }
            }
            return null;
        } catch (error: any) {
            if (error.response && error.response.status === 404) {
                console.error('Resource not found:', error.response.data);
            } else {
                console.error('An error occurred:', error);
            }
            return null;
        }
    };

    return (
        <ul className={'reward-list'}>
            {
                event?.rewards?.map((reward, i) => (
                    <li key={i}>
                        <div className={'products-groups'}>
                            <div className={'product-group'}>
                                <div className={'left-side'}>
                                    {/*PRODUCTS IN EVENT*/}
                                    {
                                        reward.rewardItems?.map((item: Item) => {
                                            let redemptionId: string | undefined;

                                            if (eventExecution) {
                                                eventExecution.assignedBehaviourList?.map(behaviour => {
                                                    behaviour.reward?.rewardItems?.map(executionItem => {
                                                        if (executionItem.product?.logicId === item.product?.logicId) {
                                                            redemptionId = executionItem.redemptionLogicId;
                                                        }
                                                    })
                                                });
                                            }

                                            return (
                                                <ProductCard key={item.product?.logicId} item={item} bodyCms={bodyCms}
                                                             onClick={async() => {
                                                                 if(redemptionId){
                                                                     await onClickCb(redemptionId)
                                                                 }
                                                             }}
                                                />
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </li>
                ))
            }
        </ul>
    )
}