import { JFormCompositorProps } from "@jnext/commons";

export const MAX_QUANTITY = 10

export const DEFAULT_FORM_PROPS: Omit<JFormCompositorProps, 'formId' | 'fields'> = {
    withoutFormComponent: true,
    overrideColsProps: {
        redemptionModality: {
            span: 24
        }
    },
    providerProps: {
        showFieldsLabel: true
    }
}
