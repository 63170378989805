import {useTranslation} from "react-i18next";

export function TranslateComponent({text, fallbackText, className, options}: {text: any, fallbackText?:string, className?: any, options?: any}){
    const { t, i18n } = useTranslation();
    if(!text && !fallbackText) {
        return null
    }
    const translateText = (text && i18n?.exists(text)) ? t(text, options) : undefined;
    const translateFallbackText =  (fallbackText && i18n?.exists(fallbackText)) ?  t(fallbackText, options) : undefined;

    if(translateText) {
        return <div className={className} dangerouslySetInnerHTML={{__html: translateText}} />
    }

    if(translateFallbackText) {
        return <div className={className} dangerouslySetInnerHTML={{__html: translateFallbackText}} />
    }
    return null
}

export function SentencesComposeComponent({text, className, options}: {text: any, fallbackText?:string, className?: any, options?: any}){
    const { t } = useTranslation();

    return <div className={className} dangerouslySetInnerHTML={{__html: t(text, options)}} />

}

export function useTranslationWord(){
    const { t, i18n } = useTranslation();
    return (text?: string, options?: any):string => {
        /*if(!text || !i18n?.exists(text)) return t('');*/
        // @ts-ignore
        return t(text, options);
    }
}