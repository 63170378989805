import React, {useMemo, useState} from "react";
import {PlanStatusEnum, ProductPreviewDto} from "@jnext/ts-axios-formatdigitalcollection";
import "./cardSubscription.scss";
import {CMSCTA, CMSSection, FORMAT_DATE, getNavPathFromType, JCMSCTA, JHTML, NavigationTypes} from "@jnext/commons";
import {JModalVas} from "../JModalVas";
import {getBackgroundCard, mzUseNavigate, useTranslationWord} from "../../../../utils";
import {useCMSStore} from "../../../../store/cmsStore";
import moment from "moment";

export const CardSubscription = ({product, section}: {
    product: ProductPreviewDto;
    section?: CMSSection;
}) =>{
    const navigate = mzUseNavigate();
    const translateWord = useTranslationWord();

    // Ottiene la struttura del CMS dal CMSStore
    const {structure} = useCMSStore();


    // Stato per gestire la visibilità del Modal
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const [infoProducts, setInfoProducts] = useState<any[]>([])

    let purchaseBtn = section?.cta?.find((cta: CMSCTA ) => ((cta?.scope === NavigationTypes.CATALOG_LOYALTY_COLLECTION
        || cta?.scope === NavigationTypes.CATALOG_DIGITAL_WALLET) && !cta?.options?.isPurchased));

    const moreInfoBtn = section?.cta?.find((cta: CMSCTA ) => cta?.scope === 'MORE_INFO');

    const manageBtn = section?.cta?.find((cta: CMSCTA ) => cta?.scope === 'SUBSCRIPTION_DETAILS');

    const status = useMemo(() => product?.serviceDetails?.status, [product?.serviceDetails?.status]);


    return(<>
        <div  className={'card-subscrpition'} style={{background : `${ getBackgroundCard(structure, product.logicId as string)?.backgroundLinear}`}}>
            <div className="card-leftSide">

                <JHTML.Text html={translateWord('vas-card-title_upper')}/>

                {/* Mostra il titolo del prodotto */}
                {product?.title && <JHTML.Title level={1} html={product?.title}/>}

                {/* Mostra la descrizione del prodotto */}
                {product?.description && <JHTML.SubTitle html={product?.description}/>}
            </div>

            <div className="card-rightSide">
                {moreInfoBtn && <div className={'btn-more-info'}>
                    {/* Pulsante per scoprire di più */}
                    <JCMSCTA
                        {...moreInfoBtn}
                        action={() => {
                            if(product?.cardMetadata) {
                                setInfoProducts(product.cardMetadata)
                            }
                            setIsModalVisible(true)
                        }}/>
                </div>}

                {purchaseBtn && (status === PlanStatusEnum.AvailableForPurchase) &&
                    <div className={'btn-purchase'}>
                        {/* Pulsante per acquistare */}
                        <JCMSCTA
                            {...purchaseBtn}
                            action={()=> {
                                sessionStorage.setItem('refClusterLogicId', product?.serviceDetails?.clusteredInitiatives?.[0]?.refClusterLogicId as string);
                                navigate(getNavPathFromType(purchaseBtn?.scope as NavigationTypes, {initiativeLogicId: purchaseBtn?.scopeParameters?.initiativeLogicId}));
                            }}/>
                    </div>}

                {manageBtn && (status === PlanStatusEnum.Purchased || status === PlanStatusEnum.Scheduled) &&
                    <div className={'btn-purchase'}>
                        {/* Pulsante per gestire l'abbonamento */}
                        <JCMSCTA
                            {...manageBtn}
                            title={manageBtn?.title || translateWord(`subscription_${product?.serviceDetails?.status}`,
                                {date: moment(product?.serviceDetails?.activationDate)?.format(FORMAT_DATE)})} />
                    </div>}

            </div>
        </div>

        {/* Mostra il modal se isModalVisible è true */}
        {isModalVisible &&
            <JModalVas
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                infoProducts={infoProducts} />}

    </>)
}