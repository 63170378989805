import { JFormCompositor, JFormCompositorProps } from '@jnext/commons';
import { FC } from 'react';
import { AuthService } from 'service/AuthService';
import { HttpService } from 'service/HttpService';
import { useTranslationWord } from 'utils';


export const JFormCompositorWrapper: FC<JFormCompositorProps> = ({ providerProps, ...props }) => {
    const translate = useTranslationWord()
    return (
        <JFormCompositor
            providerProps={{
                ...(providerProps || {}),
                translate,
                contentIOConfig: {
                    httpHeaders: HttpService.getHttpHeaders(),
                    servicePath: HttpService.servicePath('core-contentio'),
                    authContext: AuthService.authorized ? 'PRIVATE' : 'PUBLIC'
                }
            }}
            {...props}
        />
    )
}
