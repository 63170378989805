import {CMSSection, getNavPathFromType, NavigationTypes} from "@jnext/commons";
import React, {useMemo, useRef, useState} from "react";
import {Col, Row} from "antd";
import {Categories} from "./Categories";
import {CatalogList} from "../../components/CatalogList";
import {CMSCatalogOptions} from "./CatalogPage";
import {Filters} from "./Filters";
import {GetProductsFilters} from "../../service/CatalogService";
import {mzUseNavigate} from "../../utils";
import {observer} from "mobx-react";
import {CatalogType} from "../../type/catalogTypes";
import MetaTags from "../../components/MetaTags/MetaTags";
import MessageBox from "../../components/Messages/MessageBox";
import {useMessages} from "../../components/Messages/messageHooks";
import {CardSubscription} from "../../components/Vas/Components/CardSubcription";
import {ProductPreviewDto} from "@jnext/ts-axios-formatdigitalcollection";
import _ from "lodash";


interface CatalogProps {
    section: CMSSection;
    type: CatalogType;
    initiativeLogicId: string;
    isPrivate: boolean;
}


const typeToShow = ["COMPANY_CLOSURE"]

// Definisce il componente Catalog che accetta le proprietà type, section, initiativeLogicId e isPrivate
export const Catalog: React.FC<CatalogProps> = observer(({ type, section, initiativeLogicId, isPrivate }) => {

    // Ottiene le opzioni del catalogo dalla sezione
    const options = section?.options as any as CMSCatalogOptions;
    // Stato per memorizzare i filtri applicati ai prodotti
    const [filters, setFilters] = useState<GetProductsFilters>({});
    // Ottiene la funzione di navigazione
    const navigate = mzUseNavigate();
    // Riferimento al contenitore del catalogo
    const wrapperRef = useRef(null);

    // Stato per memorizzare il prodotto acquistato
    const [purchased, setPurchased] = useState<ProductPreviewDto>();

    const [initLogicId, setInitLogicId] = useState<string>(initiativeLogicId);

    // Funzione per aprire il dettaglio di un prodotto
    const openProduct = (productId: string, refInitiativeRegistryLogicId?: string) => {
        const paths = {
            [CatalogType.digitalWallet]: isPrivate ? NavigationTypes.CATALOG_DIGITAL_WALLET_DETAILS : NavigationTypes.PUBLIC_CATALOG_DIGITAL_WALLET_DETAILS,
            [CatalogType.loyaltyCollection]: isPrivate ? NavigationTypes.CATALOG_LOYALTY_COLLECTION_DETAILS : NavigationTypes.PUBLIC_CATALOG_LOYALTY_COLLECTION_DETAILS,
        }

        if(refInitiativeRegistryLogicId){
            initiativeLogicId = refInitiativeRegistryLogicId;
        }
        navigate(getNavPathFromType(paths[type], {initiativeLogicId, productId})).then();
    }


    const {messages} = useMessages();

    // Usa useMemo per memorizzare la sezione di acquisto
    const purchaseBox = useMemo(() => section?.sections?.find(section => section.type === "PURCHASE_BOX_VAS"), [section?.sections])

    return (
        <div>
            {/* MetaTags per la configurazione della sezione */}
            <MetaTags sectionConfiguration={section}/>
            {/* Mostra la CardSubscription se c'è un prodotto acquistato e una sezione di acquisto */}
            {!!purchased && purchaseBox?.enabled &&  <CardSubscription product={purchased} section={purchaseBox} />}
            {/* Mostra il titolo del catalogo se presente */}
            {
                section?.title &&
                <h1 className={'catalog-title'}>{section?.title}</h1>
            }
            {/* Mostra la descrizione del catalogo se presente */}
            {
                section?.text &&
                <div className={'catalog-description'} dangerouslySetInnerHTML={{__html: section.text}}/>
            }
            {/* Mostra i filtri standard se configurati */}
            {
                messages?.filter((message) => typeToShow?.includes(message?.messageType ?? ''))
                    ?.map((message) => {
                        return (
                            <div className={"message-box-container"} >
                                <MessageBox
                                    msgTitle={message?.msgTitle ?? ''}
                                    msgSubtitle={message?.msgSubtitle ?? ''}
                                    msgBody={message?.msgBody ?? ''}
                                    msgTitleColor={message?.msgTitleColor ?? ''}
                                    msgSubtitleColor={message?.msgSubtitleColor ?? ''}
                                    msgBodyColor={message?.msgBodyColor ?? ''}
                                    fromDate={message?.fromDate ?? ''}
                                    toDate={message?.toDate ?? ''}
                                    msgLogoUrl={message?.msgLogoUrl ?? ''}
                                    msgDisclaimerColor={message?.msgDisclaimerColor ?? ''}
                                />
                            </div>
                        )
                    })}
            {
                options?.hasStandardFilters && initLogicId &&
                <Filters
                    section={section}
                    initiativeLogicId={initLogicId}
                    isPrivate={isPrivate}
                    onChange={(newFilters) => {
                        const clonedFilters = _.clone(filters);

                        // Se il prezzo non è presente, rimuovilo dai filtri
                        if (typeof newFilters?.minAmount == 'undefined' || typeof newFilters?.maxAmount == 'undefined') {
                            delete clonedFilters?.minAmount;
                            delete clonedFilters?.maxAmount;
                        }

                        setFilters({
                            ...clonedFilters,
                            ...newFilters,
                        });
                    }}
                />
            }
            <div className={'catalog-component'} ref={wrapperRef}>
                <div className={'catalog-component-content'}>
                    <Row>
                        {/* Mostra le categorie se configurate */}
                        {
                            options?.hasCategoryFilters && initLogicId &&
                            <Col span={6} className={'categories-container'}>
                                <Categories
                                    initiativeLogicId={initLogicId}
                                    isPrivate={isPrivate}
                                    onChoose={sectorId => {
                                        const newFilters = _.clone(filters);

                                        // Rimuove i vecchi settori dai filtri
                                        if (!sectorId && newFilters?.sectorLogicIds) {
                                            delete newFilters.sectorLogicIds;
                                        }
                                        // Imposta i nuovi settori nei filtri
                                        else if (sectorId) {
                                            newFilters.sectorLogicIds = [sectorId];
                                        }

                                        setFilters({
                                            ...newFilters,
                                        });
                                    }}/>
                            </Col>
                        }
                        {/* Mostra la lista dei prodotti del catalogo */}
                        <Col className={'list-container'} span={options?.hasCategoryFilters ? 18 : 24}>
                            {
                                wrapperRef?.current &&
                                <CatalogList
                                    colNumbers={options?.hasCategoryFilters ? 3 : 4}
                                    pagination={{
                                        hasPagination: options?.pagination,
                                        type: options?.paginationType,
                                        size: options?.paginationSize,
                                    }}
                                    filters={filters}
                                    onProductClick={(productId: string, refInitiativeRegistryLogicId?: string) => openProduct(productId, refInitiativeRegistryLogicId)}
                                    containerElement={wrapperRef.current}
                                    type={type}
                                    initiativeLogicId={initiativeLogicId}
                                    isPrivate={isPrivate}
                                    section={section}
                                    setPurchased={setPurchased}
                                    setInitLogicId={setInitLogicId}
                                />
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
})