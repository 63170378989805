import {ShoppingCartOutlined} from "@ant-design/icons";
import {Badge, Button, Dropdown} from "antd";
import {CartSection} from "pages/Cart/models";
import {FC, useEffect, useMemo, useState} from "react";
import {breakpointsMax, getNavPathFromType, JCMSImage} from "@jnext/commons";
import {useWindowSize} from "@react-hook/window-size";
import {mzUseNavigate} from "utils";
import './CartDropdown.scss';
import {CartSummaryPreview} from "./components";
import {CartInitiativesPreview} from "./components/CartInitiativesPreview";
import {CatalogService, LocalStorageCatalogData} from "../../../../service/CatalogService";
import {CatalogType} from "../../../../type/catalogTypes";
import {useCartStore} from "../../../../store/cartStore";
import {BasketTypeEnum} from "@jnext/ts-axios-formatdigitalcollection";
import {observer} from "mobx-react";

export interface CartDropdownProps {
    section: CartSection
}

export const CartDropdown: FC<CartDropdownProps> = observer( props => {
    const [open, setOpen] = useState(false);
    const [width] = useWindowSize();
    const mzNavigate = mzUseNavigate();
    const {basketItems, updateData} = useCartStore();

    const [initiativeId, setInitiativeId] = useState<string|undefined>((() => {
        // Get initiatives list. If one set it
        const initiatives: LocalStorageCatalogData | null = CatalogService.getCatalogInfo(CatalogType.loyaltyCollection);

        if(initiatives?.initiatives?.length == 1) {
            return initiatives?.initiatives?.[0]?.logicId;
        }
        return undefined;
    })());

    useEffect(() => {
        if(initiativeId){
            updateData(initiativeId, BasketTypeEnum.BasketPurchase);
        }
    }, [,initiativeId]);

    const mobileLayout = useMemo(() => width <= breakpointsMax.xs, [width]);

    const toggleDropdown = () => setOpen(prev => !prev);
    const navigate = () => mzNavigate(getNavPathFromType('CART'));

    // When closed -> reset initial status
    useEffect(() => {
        if(!open) {
            setTimeout(() => {
                setInitiativeId(undefined);
            }, 500)
        }
    }, [open]);

    const dropdownRender = useMemo(() => () => {
        if(open) {
            // Get initiatives list. If one shwo it
            const initiatives: LocalStorageCatalogData | null = CatalogService.getCatalogInfo(CatalogType.loyaltyCollection);
            if (initiatives?.initiatives?.length == 1) {
                return <CartSummaryPreview section={props.section} onClose={toggleDropdown}
                                           initiativeId={initiatives?.initiatives?.[0]?.logicId!}/>
            }

            if (!initiativeId) {
                return <CartInitiativesPreview
                    section={props.section}
                    onClose={toggleDropdown}

                    // Set initiative to show
                    onInitiativeChoose={(_initiativeId => setInitiativeId(_initiativeId))}
                />;
            }

            return <CartSummaryPreview section={props.section} onClose={toggleDropdown} initiativeId={initiativeId}/>
        }
        return <></>
    }, [props.section, initiativeId, open]);

    const renderIcon = useMemo(() => {
        if (props.section.image) {
            return (
                <Button className={"cart-dropdown__button"} onClick={mobileLayout ? navigate : undefined }>
                    <div className="cart-dropdown-image">
                        <JCMSImage image={props.section.image}/>
                    </div>
                </Button>
            )
        }
        return <Button className={"cart-dropdown__button"} icon={<ShoppingCartOutlined />} onClick={mobileLayout ? navigate : undefined } />
    }, [mobileLayout, props.section])

    return (
        <Badge count={basketItems}>
            <div className="cart-dropdown">
                {
                    !mobileLayout ? (
                        <Dropdown
                            trigger={['click']}
                            placement="bottomRight"
                            overlayClassName="cart-dropdown-dropdown"
                            open={open}
                            onOpenChange={ (open) => {
                                setOpen(open)
                            } }
                            dropdownRender={dropdownRender}
                        >
                            {
                                renderIcon
                            }
                        </Dropdown>

                    ) : (
                        renderIcon
                    )
                }
            </div>
        </Badge>
    )
})