import {JSX} from "react";
import {isPlainObject, mapValues} from "lodash";

export const isDevEnvironment =
    window.location.hostname.includes('-dev-') ||
    window.location.hostname.includes('-stage-') ||
    window.location.hostname.includes('localhost');


export enum SupportedMetaTags {
    TITLE = "title",
    DESCRIPTION = "description",
    CANONICAL = "canonical",

    OG_TYPE = "og:type",
    OG_URL = "og:url",
    OG_SITE_NAME = "og:site_name",
    OG_TITLE = "og:title",
    OG_DESCRIPTION = "og:description",
    OG_IMAGE = "og:image",

    STRUCTURED_DATA = "structuredData",
}

export interface JsonLD extends Record<string, any> {

}

export type MetaValue = null | string | JsonLD;

export type MetaPlaceholders = Record<string, string|undefined|null>;

export const assertMetaValueIsString = (value: MetaValue): value is string => value !== null && typeof value === "string" && value !== "";

export const assertMetaValueIsJsonLd = (value: MetaValue): value is JsonLD => value !== null && typeof value !== "string" && '@context' in value;

const mapValuesDeep = (obj: Record<string, any>, cb: (...args: any[]) => any): Record<string, any> => {
    return mapValues(obj, (val: any, key: any) =>
        isPlainObject(val)
            ? mapValuesDeep(val, cb)
            : cb(val, key, obj)
    )
}

export const replacePlaceholders = (text: string, placeholders?: MetaPlaceholders): string => {
    if (!placeholders) {
        return text;
    }
    return Object.entries(placeholders).reduce((acc, [placeholderKey, placeholderValue]) => {
        acc = acc.replaceAll(`{{${placeholderKey}}}`, (placeholderValue ?? "").replaceAll(/(<([^>]+)>)/gi, " ").toString());
        return acc;
    }, text);
}

export const META_TAGS_CONFIGURATION: Record<SupportedMetaTags, (value: MetaValue, placeholders?: MetaPlaceholders) => JSX.Element | null> = {
    [SupportedMetaTags.TITLE]: (value: MetaValue, placeholders) => {
        if (!assertMetaValueIsString(value)) {
            isDevEnvironment && console.warn(`Wrong value type for meta tag ${[SupportedMetaTags.TITLE]}`);
            return null;
        }
        return <title key={`j-meta-tag-${SupportedMetaTags.TITLE}`}>{replacePlaceholders(value, placeholders)}</title>
    },
    [SupportedMetaTags.DESCRIPTION]: (value: MetaValue, placeholders) => {
        if (!assertMetaValueIsString(value)) {
            isDevEnvironment && console.warn(`Wrong value type for meta tag ${[SupportedMetaTags.DESCRIPTION]}`);
            return null;
        }
        return <meta key={`j-meta-tag-${SupportedMetaTags.DESCRIPTION}`} name="description"
                     content={replacePlaceholders(value, placeholders)}/>
    },
    [SupportedMetaTags.CANONICAL]: (value: MetaValue) => {
        if (!assertMetaValueIsString(value)) {
            isDevEnvironment && console.warn(`Wrong value type for meta tag ${[SupportedMetaTags.CANONICAL]}`);
            return null;
        }
        return !!value ? <link key={`j-meta-tag-${SupportedMetaTags.CANONICAL}`} rel="canonical"
                               href={value === "url" ? window.location.href : value}/> : <></>
    },
    [SupportedMetaTags.OG_TYPE]: (value: MetaValue, placeholders) => {
        if (!assertMetaValueIsString(value)) {
            isDevEnvironment && console.warn(`Wrong value type for meta tag ${[SupportedMetaTags.OG_TYPE]}`);
            return null;
        }
        return <meta key={`j-meta-tag-${SupportedMetaTags.OG_TYPE}`} property="og:type"
                     content={replacePlaceholders(value, placeholders)}/>
    },
    [SupportedMetaTags.OG_URL]: (value: MetaValue) => {
        if (!assertMetaValueIsString(value)) {
            isDevEnvironment && console.warn(`Wrong value type for meta tag ${[SupportedMetaTags.OG_URL]}`);
            return null;
        }
        return <meta key={`j-meta-tag-${SupportedMetaTags.OG_URL}`} property="og:url"
                     content={value === "url" ? window.location.href : value}/>
    },
    [SupportedMetaTags.OG_SITE_NAME]: (value: MetaValue, placeholders) => {
        if (!assertMetaValueIsString(value)) {
            isDevEnvironment && console.warn(`Wrong value type for meta tag ${[SupportedMetaTags.OG_SITE_NAME]}`);
            return null;
        }
        return <meta key={`j-meta-tag-${SupportedMetaTags.OG_SITE_NAME}`} property="og:site_name"
                     content={replacePlaceholders(value, placeholders)}/>
    },
    [SupportedMetaTags.OG_TITLE]: (value: MetaValue, placeholders) => {
        if (!assertMetaValueIsString(value)) {
            isDevEnvironment && console.warn(`Wrong value type for meta tag ${[SupportedMetaTags.OG_TITLE]}`);
            return null;
        }
        return <meta key={`j-meta-tag-${SupportedMetaTags.OG_TITLE}`} property="og:title"
                     content={replacePlaceholders(value, placeholders)}/>
    },
    [SupportedMetaTags.OG_DESCRIPTION]: (value: MetaValue, placeholders) => {
        if (!assertMetaValueIsString(value)) {
            isDevEnvironment && console.warn(`Wrong value type for meta tag ${[SupportedMetaTags.OG_DESCRIPTION]}`);
            return null;
        }
        return <meta key={`j-meta-tag-${SupportedMetaTags.OG_DESCRIPTION}`} property="og:description"
                     content={replacePlaceholders(value, placeholders)}/>
    },
    [SupportedMetaTags.OG_IMAGE]: (value: MetaValue, placeholders) => {
        if (!assertMetaValueIsString(value)) {
            isDevEnvironment && console.warn(`Wrong value type for meta tag ${[SupportedMetaTags.STRUCTURED_DATA]}`);
            return null;
        }
        return <meta key={`j-meta-tag-${SupportedMetaTags.OG_IMAGE}`} property="og:image"
                     content={replacePlaceholders(value, placeholders)}/>
    },
    [SupportedMetaTags.STRUCTURED_DATA]: (object: MetaValue, placeholders) => {
        if (!assertMetaValueIsJsonLd(object)) {
            isDevEnvironment && console.warn(`Wrong value type for meta tag ${[SupportedMetaTags.STRUCTURED_DATA]}`);
            return null;
        }

        return <script type="application/ld+json" key={`j-meta-tag-${SupportedMetaTags.STRUCTURED_DATA}`}>
            {JSON.stringify(mapValuesDeep(object, (val) => replacePlaceholders(val, placeholders)))}
        </script>;
    }
}
