//template2
import React from "react";

export default {
  "--primary": "#FFF800",
  "--primary-light": "#f8f051",
  "--primary-lighter": "#f6f293",
  "--primary-dark": "#988a09",
  "--primary-darker": "#5b5105",
  "--secondary": "#052850",
  "--secondary-light": "#056EB5",
  "--secondary-lighter": "#aac3f3",
  "--textPrimary": '#052850',
  "--textSecondary": '#333333',
  "--textInvert": '#FFFFFF',
  "--success": '#2e8605',
  "--error": '#ff4d4f',
  "--warning": '#ecc819',
  "--gradientTemplate": "linear-gradient(var(--secondary) 0%, var(--primary) 100%)",
  "--colorBtmNavBar": "var(--primary-dark)",

  // Card expiration header colors
  "--expiringHeaderDisabledColor": "white",
  "--expiringHeaderDisabledBackground": "var(--grayDark)",
  "--expiringHeaderOpenColor": "white",
  "--expiringHeaderOpenBackground": "var(--yellowSolid)",
  "--expiringHeaderWillOpenColor": "white",
  "--expiringHeaderWillOpenBackground": "var(--blue)",
  "--expiringHeaderCompletedColor": "white",
  "--expiringHeaderCompletedBackground": "var(--grayDark)",
} as React.CSSProperties;
