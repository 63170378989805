import {FileOutlined, FilePdfOutlined, VideoCameraOutlined} from "@ant-design/icons";
import {Col, ColProps, Row} from "antd";
import {FC, useEffect, useMemo, useState} from "react";
import mime from 'mime/lite';
import {breakpointsMax, CMSSection, JCMSImage, JSlider} from "@jnext/commons";
import {useWindowSize,} from '@react-hook/window-size';
import {ProductMediaDisplayType} from "../models";
import {MediaTypeEnum} from "@jnext/ts-axios-formatdigitalcollection";
import {generatePathUrl, useTranslationWord} from "../../../utils";

export interface Media {
    url: string,
    type?: MediaTypeEnum,
    default?: boolean
}

export interface ProductMediaProps {
    mediaList: Media[],
    cms: CMSSection
}

export interface MediaContentProps extends Media {
}

export const MediaPreview: FC<MediaContentProps> = (props) => {

    const translateWord = useTranslationWord();
    const iconSrc = useMemo(() => translateWord(`ICON_${props.type?.toUpperCase()}`), [props.type]);

    return (

        <div className="media-content">
            {
                (() => {
                    switch (props.type) {
                        case MediaTypeEnum.SelfHostedVideo:
                        case MediaTypeEnum.EmbedVideo:
                            return (
                                <img className="media-item" src={generatePathUrl(iconSrc)} aria-hidden={true}/>
                            )

                        case MediaTypeEnum.Pdf:
                            return (
                                <img className="media-item" src={generatePathUrl(iconSrc)} aria-hidden={true}/>
                            )
                        case MediaTypeEnum.Image:
                            return (
                                <img className="media-item" src={generatePathUrl(props.url)} aria-hidden={true}/>
                            )
                        default:
                            <FileOutlined className="media-item"/>
                            break;
                    }
                })()
            }
        </div>
    )
}
const mediaContentStyleProps = {
    height: '100%',
    width: '100%',
    style: {
        border: 0
    }
}
export const MediaContent: FC<MediaContentProps> = (props) => (
    <div className="media-content">
        {
            (() => {
                switch (props.type) {
                    case MediaTypeEnum.SelfHostedVideo:
                        return (
                            <video autoPlay controls {...mediaContentStyleProps} >
                                <source src={generatePathUrl(props.url)}/>
                            </video>
                        )
                    case MediaTypeEnum.Pdf:
                        return (
                            <object
                                type="application/pdf"
                                data={`${props.url}#toolbar=0&navpanes=0&scrollbar=0`}
                                {...mediaContentStyleProps}
                            />
                        )
                    case MediaTypeEnum.Image:
                        return (
                            <img className="media-item" src={generatePathUrl(props.url)} aria-hidden={true}/>
                        )

                    case MediaTypeEnum.EmbedVideo:
                    default:
                        const mimetype = mime.getType(props.url)
                        if (mimetype) {
                            return (
                                <object type={mimetype} data={props.url} {...mediaContentStyleProps} />
                            )
                        }
                        return <object data={props.url} {...mediaContentStyleProps} />
                }
            })()
        }

    </div>
)

interface LayoutColProps {
    slider: ColProps,
    main: ColProps
}

export const ProductMedia: FC<ProductMediaProps> = (props) => {
    const [width] = useWindowSize();
    const [currentMedia, setCurrentMedia] = useState<Media>()
    const isMobileLayout = useMemo(() => (
        width < breakpointsMax.lg
    ), [width])

    const noImages = useMemo(() => !props.mediaList.length, [props.mediaList])

    useEffect(() => {
        setCurrentMedia(props.mediaList.find(m => m.default) || props.mediaList[0])
    }, [])

    const colProps: LayoutColProps = useMemo(() => {
        if (isMobileLayout) {
            return {
                slider: {
                    xs: 24
                },
                main: {
                    xs: 24
                }
            }
        }
        return {
            slider: {
                span: 4
            },
            main: {
                span: !noImages ? 20 : 24
            }
        }
    }, [isMobileLayout, noImages])

    const imageCssClass = useMemo(() => props.cms?.options?.imageType == ProductMediaDisplayType.CONTAIN ? 'contain' : 'cover', [props.cms?.options?.imageType])

    const mediaSlider = useMemo(() => {
        const className = (isMobileLayout ? 'horizontal' : 'vertical') + ' ' + imageCssClass;
        return (
            <Col {...colProps.slider} className={`product-media-thumbnail-slider ${className}`}>
                <JSlider
                    vertical={!isMobileLayout}
                    dots={false}
                    slidesToShow={isMobileLayout ? 4.5 : 5}
                    infinite={false}
                    arrows={!isMobileLayout}
                >
                    {
                        props.mediaList.map((media, i) => (
                            <div
                                key={i}
                                className={`media-thumbnail ${media.url === currentMedia?.url ? 'active' : ''}`}
                                onClick={() => setCurrentMedia(media)}
                            >
                                <MediaPreview {...media} />
                            </div>
                        ))
                    }
                </JSlider>
            </Col>
        )
    }, [props.mediaList, isMobileLayout, currentMedia])


    return (
        <Row className="product-media-container">
            {
                !noImages && !isMobileLayout && (
                    mediaSlider
                )
            }
            <Col {...colProps.main}>
                <div className={`product-media-selected ${imageCssClass}`}>
                    {
                        !noImages && currentMedia ? (
                            <MediaContent {...currentMedia} />
                        ) : (
                            <JCMSImage image={[{src: ''}]} usePlaceholder/>
                        )
                    }
                </div>
            </Col>
            {
                !noImages && isMobileLayout && (
                    mediaSlider
                )
            }
        </Row>
    )
}