import {Row} from "antd";
import React from "react";
import RowElement from "./rowElement";
import {RedemptionResponse} from "@jnext/ts-axios-mz";
import {CMSSection} from "@jnext/commons";
import {HistoryRewardType} from "../../../../../type/historyReward";

interface MobileListProps {
    redemptions: RedemptionResponse[];
    onRedeem: (redemptionId: string) => void;
    cms: CMSSection;
    type: Exclude<HistoryRewardType, HistoryRewardType.ALL>;
}

export const MobileList: React.FC<MobileListProps> = ({redemptions, onRedeem, cms, type}) => {
    return (
        <Row gutter={[0, 40]}>
            {redemptions?.map((redemption, i: number) => (
                <RowElement key={i} redemption={redemption} onRedeem={() => onRedeem(redemption?.logicId)} cms={cms} type={type}/>
            ))}
        </Row>
    )
}