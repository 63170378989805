const FORMAT_DATE = 'DD/MM/YYYY';

export {
    FORMAT_DATE
}

export const MOCK_CHECKOUT_VAS_FIELDS =
    [
        {
            "customData": null,
            "hurlId": "streetAddress",
            "type": "JTEXT",
            "validationFunction": null,
            "cmsConfig": {
                "customData": null,
                "logicId": "streetAddress",
                "tenant": "pkabTc23dshAd",
                "project": "pkabPrjA23d2s",
                "clusterLogicId": null,
                "flowHurlId": "WEB-INS",
                "language": "it",
                "hurlId": "streetAddress",
                "title": "",
                "tooltip": null,
                "text": "Indirizzo di residenza*",
                "imageRef": null,
                "imageUrl": "",
                "optionHidden": null,
                "hidden": false,
                "additionalText": "",
                "order": 0,
                "validationMessage": "",
                "defaultValue": null,
                "requiredMessage": null,
                "labelTooltip": null,
                "layoutConfig": null,
                "attachments": null,
                "componentConfig": {
                    "customData": null,
                    "showTime": null,
                    "enableCrop": null,
                    "multiple": null,
                    "minimal": null,
                    "maxSize": null,
                    "minDate": null,
                    "maxDate": null,
                    "accept": null,
                    "maxCount": null,
                    "phoneWithDialCode": null,
                    "phoneShowDialCode": null,
                    "phoneShowCountry": null,
                    "phoneAllowCountrySelection": null,
                    "phoneDefaultCountry": null,
                    "countryShowFlags": null,
                    "searchable": null,
                    "closeOnSelection": null,
                    "displayMode": null,
                    "options": []
                }
            },
            "values": null,
            "mandatory": true,
            "username": false,
            "confirmed": false,
            "readOnly": false,
            "children": null,
            "logicId": "Az03D56SD0400",
            "isGdpr": false,
            "overrideValue": null
        },
        {
            "customData": null,
            "hurlId": "provinceCode",
            "type": "JTEXT",
            "validationFunction": null,
            "cmsConfig": {
                "customData": null,
                "logicId": "provinceCode",
                "tenant": "",
                "project": "",
                "clusterLogicId": null,
                "flowHurlId": "WEB-INS",
                "language": "it",
                "hurlId": "provinceCode",
                "title": "",
                "tooltip": null,
                "text": "Provincia*",
                "imageRef": null,
                "imageUrl": "",
                "optionHidden": null,
                "hidden": false,
                "additionalText": "",
                "order": 1,
                "validationMessage": "",
                "defaultValue": null,
                "requiredMessage": null,
                "labelTooltip": null,
                "layoutConfig": null,
                "attachments": null,
                "componentConfig": {
                    "customData": null,
                    "showTime": null,
                    "enableCrop": null,
                    "multiple": null,
                    "minimal": null,
                    "maxSize": null,
                    "minDate": null,
                    "maxDate": null,
                    "accept": null,
                    "maxCount": null,
                    "phoneWithDialCode": null,
                    "phoneShowDialCode": null,
                    "phoneShowCountry": null,
                    "phoneAllowCountrySelection": null,
                    "phoneDefaultCountry": null,
                    "countryShowFlags": null,
                    "searchable": null,
                    "closeOnSelection": null,
                    "displayMode": null,
                    "options": []
                }
            },
            "values": null,
            "mandatory": true,
            "username": false,
            "confirmed": false,
            "readOnly": false,
            "children": null,
            "logicId": "",
            "isGdpr": false,
            "overrideValue": null
        },
        {
            "customData": null,
            "hurlId": "city",
            "type": "JTEXT",
            "validationFunction": null,
            "cmsConfig": {
                "customData": null,
                "logicId": "city",
                "tenant": "",
                "project": "",
                "clusterLogicId": null,
                "flowHurlId": "WEB-INS",
                "language": "it",
                "hurlId": "city",
                "title": "",
                "tooltip": null,
                "text": "Città*",
                "imageRef": null,
                "imageUrl": "",
                "optionHidden": null,
                "hidden": false,
                "additionalText": "",
                "order": 2,
                "validationMessage": "",
                "defaultValue": null,
                "requiredMessage": null,
                "labelTooltip": null,
                "layoutConfig": null,
                "attachments": null,
                "componentConfig": {
                    "customData": null,
                    "showTime": null,
                    "enableCrop": null,
                    "multiple": null,
                    "minimal": null,
                    "maxSize": null,
                    "minDate": null,
                    "maxDate": null,
                    "accept": null,
                    "maxCount": null,
                    "phoneWithDialCode": null,
                    "phoneShowDialCode": null,
                    "phoneShowCountry": null,
                    "phoneAllowCountrySelection": null,
                    "phoneDefaultCountry": null,
                    "countryShowFlags": null,
                    "searchable": null,
                    "closeOnSelection": null,
                    "displayMode": null,
                    "options": []
                }
            },
            "values": null,
            "mandatory": true,
            "username": false,
            "confirmed": false,
            "readOnly": false,
            "children": null,
            "logicId": "",
            "isGdpr": false,
            "overrideValue": null
        },
        {
            "customData": null,
            "hurlId": "zipcode",
            "type": "JTEXT",
            "validationFunction": null,
            "cmsConfig": {
                "customData": null,
                "logicId": "zipCode",
                "tenant": "",
                "project": "",
                "clusterLogicId": null,
                "flowHurlId": "WEB-INS",
                "language": "it",
                "hurlId": "zipcode",
                "title": "",
                "tooltip": null,
                "text": "CAP*",
                "imageRef": null,
                "imageUrl": "",
                "optionHidden": null,
                "hidden": false,
                "additionalText": "",
                "order": 3,
                "validationMessage": "",
                "defaultValue": null,
                "requiredMessage": null,
                "labelTooltip": null,
                "layoutConfig": null,
                "attachments": null,
                "componentConfig": {
                    "customData": null,
                    "showTime": null,
                    "enableCrop": null,
                    "multiple": null,
                    "minimal": null,
                    "maxSize": null,
                    "minDate": null,
                    "maxDate": null,
                    "accept": null,
                    "maxCount": null,
                    "phoneWithDialCode": null,
                    "phoneShowDialCode": null,
                    "phoneShowCountry": null,
                    "phoneAllowCountrySelection": null,
                    "phoneDefaultCountry": null,
                    "countryShowFlags": null,
                    "searchable": null,
                    "closeOnSelection": null,
                    "displayMode": null,
                    "options": []
                }
            },
            "values": null,
            "mandatory": true,
            "username": false,
            "confirmed": false,
            "readOnly": false,
            "children": null,
            "logicId": "",
            "isGdpr": false,
            "overrideValue": null
        },
    ]