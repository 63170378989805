import { Fragment } from "react";
import MicroApp from "commons/MicroApp";
import { observer } from "mobx-react";
import { mzUseNavigate } from "../../utils";
import { CMSSection } from "@jnext/commons";
import { useParams } from "react-router-dom";
import { getNavPathFromType, NavigationType } from "@jnext/commons";
import { HttpService } from "../../service/HttpService";
import {EventDetails} from "@jnext/ts-axios-mz/models/event-details";

function Events({ component, section, events, loaderRows = 0, minHeight = 0 }:
                    { component?: string, section?: CMSSection, events?: EventDetails[], loaderRows?: number, minHeight?: number }) {
    const navigate = mzUseNavigate();
    const { id } = useParams();

    return (
        <Fragment>
            {HttpService.env?.formatEvent && (
                <MicroApp
                    entry={`${HttpService.env?.formatEvent}`}
                    props={{
                        type: component || "carousel",
                        id: id,
                        section,
                        events,
                        onNavigate(type: NavigationType, params?: Record<string, any>) {
                            return navigate(getNavPathFromType(type, params))
                        },
                    }}
                />
            )}
        </Fragment>
    );
}

export default observer(Events);
