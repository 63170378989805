import React, {useEffect, useMemo, useState} from "react";
import {CMSConfiguration, CMSSection, getNavPathFromType, JCMSCTA, JHTML} from "@jnext/commons";
import {useNavigate} from "react-router-dom";
import {VasService} from "../../service/VasService";
import {useCMSStore} from "../../store/cmsStore";
import {browserRedirect, useTranslationWord} from "../../utils";
import {UserSubscriptionOverviewDto} from "@jnext/ts-axios-formatdigitalcollection";
import SubscriptionDetail from "../../components/Vas/Components/SubscriptionDetail";

const SubscriptionDetails = () => {
    const navigate = useNavigate();
    const [subscriptionData, setSubscriptionData] = useState<UserSubscriptionOverviewDto | undefined>(undefined);
    const {pages} = useCMSStore();
    const translateWord = useTranslationWord();

    const cmsConfigVas: CMSConfiguration | undefined = useMemo(() => pages?.vas, [pages?.vas]);
    const cmsConfig: CMSConfiguration | undefined = useMemo(() => pages?.profile, [pages?.profile]);
    const initiativeLogicId: string = cmsConfigVas?.section?.sections?.[0]?.options?.logicId as string;

    const activeSubscription = useMemo(() => subscriptionData?.hasSubscription, [subscriptionData]);
    const scheduledChange = useMemo(() => subscriptionData?.scheduledChange, [subscriptionData]);
    const subscriptionDetail = useMemo(() => subscriptionData?.currentSubscription, [activeSubscription]);

    const cmsSection: CMSSection | undefined = cmsConfig?.section?.sections?.[0]?.sections?.[0];
    const goToVas = cmsSection?.cta?.find(cta => cta.scope === 'VAS');

    useEffect(() => {
        (async () => {
            if (initiativeLogicId) {
                await getSubscriptionDetails()
            } else {
                console.log('Invalid initiativeLogicId:', initiativeLogicId);
            }
        })();
    }, [initiativeLogicId]);

    async function getSubscriptionDetails() {
        try {
            const data = await VasService.getSubscriptionDetails(initiativeLogicId);
            setSubscriptionData(data);
        } catch (e: any) {
            console.log('Error fetching subscription details:', e);
        }

    }


    const goToVasUserPortal = async () => {
        const res = await VasService.customerPortal(initiativeLogicId);

        if (res?.url) {
            browserRedirect(res.url);
        }
    }

    const cancelSubscription = async () => {
        if(initiativeLogicId && scheduledChange?.referredRedemptionLogicId){
            const resp = await VasService.delVasSubscription(initiativeLogicId, scheduledChange?.referredRedemptionLogicId);
            if(resp === 200){
                await getSubscriptionDetails();
            }
        }
    }

    return (
        <div className="general-info-container">
            <label className="title">
                {cmsConfig?.section?.sections?.[0]?.sections?.[0]?.title}
            </label>

            {activeSubscription ?
                <>
                    {subscriptionDetail &&
                        <SubscriptionDetail
                            subscriptionDetail={subscriptionDetail}
                            onClick={goToVasUserPortal}
                            cmsSection={cmsSection}
                        />}

                    {scheduledChange && <>
                        <div className={'line'}/>
                        <SubscriptionDetail
                            scheduledChange={scheduledChange}
                            onClick={cancelSubscription}
                            cmsSection={cmsSection}
                        />
                    </>}
                </>
                :
                <div className="details-wrapper">
                    <div className="no-subscription-text-container">
                        <div className="text-no-subscription">
                            <JHTML.Text html={translateWord("NO_SUBSCRIPTION_PURCHASED_TEXT")}/>
                        </div>
                        <div className="button-container">
                            {goToVas && <JCMSCTA {...goToVas} />}
                        </div>
                    </div>
                </div>}
        </div>
    );
};

export default SubscriptionDetails;
