import React, {useEffect, useMemo, useState} from 'react';
import {CMSConfiguration, CMSSection, getNavPathFromType, JTable, NavigationTypes} from "@jnext/commons";
import {FORMAT_DATE, mzUseNavigate, useTranslationWord} from "../../../../utils";
import moment from "moment/moment";
import {useCMSStore} from "../../../../store/cmsStore";
import {PDAStatusLabels} from "./HistoryPDAInterface";
import {PdaService} from "../../../../service/PdaService";
import {EventApprovalStatusEnum, EventResponse, GetEventApprovalsResponse} from "@jnext/ts-axios-eventformat";
import {EventService} from "../../../../service/EventService";
import FiltersHeader from "./filtersHeader";
import {Pagination} from "../../../Pagination";

const HistoryPDA: React.FC = () => {

    const navigate = mzUseNavigate();
    const translateWord = useTranslationWord();

    const [options, setOptions] = useState<any>();
    const [historyPdaData, setHistoryPdaData] = useState<GetEventApprovalsResponse | undefined>(undefined);
    const [pdaListLoaded, setPdaListLoaded] = useState<boolean>(false);
    const [eventIds, setEventIds] = useState<string[]>([]);
    const [eventsList, setEventsList] = useState<{ [key: string]: EventResponse }>({});
    const [isLoading, setLoading] = useState<boolean>(true);

    //pagination
    const [totalPage, setTotalPage] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [lastPage, setLastPage] = useState<number>(0);

    // Page CMS
    const cmsStore = useCMSStore();
    const {pages} = cmsStore;

    // const config: CMSConfiguration | undefined = useMemo(() => pages?.profile, [pages?.profile]);
    const config: CMSConfiguration | undefined = pages?.profile;

    const sections = useMemo(() => {
        if (!config) {
            return [];
        }
        const section = config?.section?.sections?.find(el => el.type == NavigationTypes?.HISTORY_PDA);
        setOptions(section?.options)

        // Return sections
        return section?.sections;
    }, [config]);

    // Specific section
    const historyPdaCMS = useMemo(() => sections?.find((el: any) => el.type == 'MAIN'), [sections]);
    const historyNoPdaCMS = useMemo(() => sections?.find((el: any) => el.type == 'NO_DATA'), [sections]);

    const getRewardTitle = (record: any): string => {
        const event: EventResponse = eventsList[record.eventConfigurationLogicId];
        return event?.rewards?.[0]?.rewardItems?.[0]?.product?.title || '-';
    };

    const renderTableColumns = (cms?: CMSSection) => {
        return [
            {
                title: cms?.table?.date?.label,
                dataIndex: 'occurrenceTimestamp',
                render: (date: string) => {
                    return <label className={`dateTransactionText`}>{moment(date)?.format(FORMAT_DATE)}</label>
                },
            },
            {
                title: cms?.table?.status?.label,
                dataIndex: 'status',
                render: (status: EventApprovalStatusEnum) => {
                    return <label className={`statusText status--${status}`}>{PDAStatusLabels[status]}</label>
                },
            },
            {
                title: cms?.table?.initiative?.label,
                dataIndex: 'eventConfigurationLogicId',
                render: (eventConfigurationLogicId: string) => {
                    return <label className={`eventName`}>{eventsList[eventConfigurationLogicId]?.name}</label>
                },
            },
            {
                title: cms?.table?.reward?.label,
                dataIndex: 'eventExecution.assignedBehaviourList',
                render: (_: any[], record: any) => {
                    const rewardTitle = getRewardTitle(record);
                    return <label className={`rewardText`}>{rewardTitle}</label>
                },
            },
            {
                render: (record: any) => {
                    return (
                        <a
                            className={`goToPdaDetails`}
                            onClick={() => navigate(getNavPathFromType('HISTORY_PDA_DETAILS', {logicId: record.logicId}))}>
                            {cms?.table?.pdaDetailsCta?.label}
                        </a>
                    );
                }
            }
        ];
    };

    useEffect(() => {
        (async () => {
            if (page || page !== lastPage) {
                setLastPage(page)
                await getPdaHistoryList();
            }
        })()
    }, [page]);

    const handleFiltersChange = async (eventConfigurationLogicId?: string, status?: EventApprovalStatusEnum, dateValue?: string[]) => {
        await getPdaHistoryList(eventConfigurationLogicId, status, dateValue);
    };

    async function getPdaHistoryList(eventConfigurationLogicId?: string, status?: EventApprovalStatusEnum, dateValue?: string[]) {

        try {

            setLoading(true);
            setPdaListLoaded(false);
            setEventIds([]);

            const size = options?.size || 10;
            let fromDate: string | undefined;
            let toDate: string | undefined;
            if (dateValue) {
                fromDate = dateValue[0];
                toDate = dateValue[1];
            }

            const params = {
                page,
                size,
                status,
                eventConfigurationLogicId,
                fromDate,
                toDate,
            };

            const response = await PdaService.searchPdaHistoryList(params);

            setHistoryPdaData(response);
            setEventIds(
                response.results && response.results.length > 0
                    ? [...new Set([...response.results.filter(pdaDetailsData => !!pdaDetailsData.eventConfigurationLogicId).map(pdaDetailsData => pdaDetailsData.eventConfigurationLogicId!)])]
                    : []
            );
            setTotalPage((response as any)?.customData?.pageCount ?? 0);
            setPdaListLoaded(true);

        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getPdaHistoryList().then();
    }, []);

    useEffect(() => {

        if (!pdaListLoaded || eventIds.length === 0) {
            setEventsList({});
            return;
        }

        eventIds.forEach(eventId => {

            EventService.getPrivateEventByEventConfigurationLogicId(eventId).then((event) => {
                setEventsList(prev => ({
                    ...prev,
                    [eventId]: event!
                }))
            });

        });

    }, [pdaListLoaded, eventIds]);

    return (
        <div className={'historyPda'}>

            <div className="title historyPda__title">{historyPdaCMS?.title}</div>

            <div className={'historyPda__main'}>

                <div className={`popup-filters historyPda__filters`}>
                    <FiltersHeader
                        statuses={Object.values(EventApprovalStatusEnum)}
                        initiatives={eventsList}
                        handleChange={handleFiltersChange}
                        cms={historyPdaCMS}
                    />
                </div>

                {isLoading &&
                    <div className='historyPda__loading'>
                        <span>{translateWord("LOADING_PDA_LIST")}</span>
                    </div>
                }

                {!isLoading && (!historyPdaData || !historyPdaData?.results || historyPdaData?.results.length === 0) &&
                    <div>
                        <div className='historyPda__no-data'>
                            <span>{historyNoPdaCMS?.title}</span>
                        </div>
                    </div>
                }

                {!isLoading && historyPdaData?.results && historyPdaData.results.length > 0 &&
                    <div className={"historyPda__table-wrapper"}>
                        <JTable
                            className={"historyPda__table"}
                            columns={renderTableColumns(historyPdaCMS)}
                            dataSource={historyPdaData?.results as unknown as readonly Record<string, unknown>[]}
                            pagination={false}
                        />
                    </div>
                }

                {totalPage > 1 &&
                    <div className={"historyPda__pagination"}>
                        <Pagination
                            page={page + 1}
                            totalPage={totalPage}
                            updatePagination={(pageNr) => setPage(pageNr - 1)}
                        />
                    </div>
                }

            </div>
        </div>
    );
};

export default HistoryPDA;