import { useState, useEffect } from 'react';
import {HttpService} from "../../service/HttpService";
import {MessageFlatResponseArray} from "@jnext/ts-axios-coreconfiguration/models/message-flat-response-array";
import {AuthService} from "../../service/AuthService";


export const useMessages = () => {
    const [messages, setMessages] = useState<MessageFlatResponseArray>([]);

    useEffect(() => {
        if(AuthService.authorized){
            const fetchMessages = async () => {
                try {
                    const data = await HttpService.searchMessages();
                    setMessages(data);
                } catch (error: any) {
                    console.log('API MESSAGE ERROR', error.message);
                }
            };
            fetchMessages();
        }
    }, []);

    return { messages };
};
