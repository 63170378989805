import ScoreCounter from "components/ScoreCounter";
import {observer} from "mobx-react";
import React from "react";
import {WalletPoints} from "@jnext/ts-axios-wallet";

type ScoreCounterContestProps = {
    walletPoints: WalletPoints[],
    inNavbar?: boolean,
    scrolled?:boolean
}

const ScoreCounterContest = ({walletPoints, inNavbar, scrolled}: ScoreCounterContestProps) => {
    return (
        <div className={`scoreCounterContestContainer ${inNavbar ? 'fixed': ''}`}>
            <div className="scoreCounterContestPointsContainer">
                <ScoreCounter title="available_beats" walletPoints={walletPoints} inNavbar={inNavbar} scrolled={scrolled} />
            </div>
        </div>
    );
};

export default observer(ScoreCounterContest);
