import {CartProductsListSection} from "pages/Cart/models";
import {FC, useMemo} from "react";
import {useWindowSize} from '@react-hook/window-size';
import {breakpointsMax, JBox} from "@jnext/commons";
import './CartProductItem.scss';
import {CartProductItemDesktop, CartProductItemMobile, CartProductItemRenderData} from "./components";
import {GetBasketResponseItem} from "@jnext/ts-axios-formatdigitalcollection";

export interface CartProductItemProps {
    section: CartProductsListSection,
    item: GetBasketResponseItem,
    removeItem: () => void;
    updateItemQuantity: (quantity: number) => void;
}

export const CartProductItem: FC<CartProductItemProps> = props => {
    const [width] = useWindowSize()
    const Component = useMemo(() => {
        if (width < breakpointsMax.xs) {
            return CartProductItemMobile
        }
        return CartProductItemDesktop
    }, [width])
    return (
        <div className="cart-product-item">
            <JBox>
                <div className="cart-product-item-container">
                    <CartProductItemRenderData {...props} removeItem={props.removeItem}>
                        {(elements) => <Component {...elements} />}
                    </CartProductItemRenderData>
                </div>
            </JBox>
        </div>
    )
}