import { CMSConfiguration } from "@jnext/commons";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { useCMSStore } from "store/cmsStore";
import HomePageStandard from "./HomePageStandard";
import { Custom } from "../../../pages/Custom";
import { HttpService } from "../../../service/HttpService";

const HomePagePrivate = () => {
    const { pages } = useCMSStore();
    const cmsConfig: CMSConfiguration | undefined = useMemo(() => pages?.homepage, [pages]);
    const { options } = cmsConfig?.page as any;
    const customProject = HttpService?.customProject;

    if (!cmsConfig || !cmsConfig.page) return <div />;

    return (
        <>
            {options?.custom ?
                <>
                    {customProject?.customFrontend &&
                        <Custom customFrontendUrl={customProject?.customFrontendUrl} type={options?.customComponent} />}
                </>
                : <HomePageStandard />
            }
        </>
    );
};

export default observer(HomePagePrivate);
