import MicroApp from "../../commons/MicroApp";
import {mzUseNavigate} from "../../utils";
import React from "react";
import {useParams} from "react-router-dom";
import {getNavPathFromType, NavigationType} from "@jnext/commons";
import {RedemptionService} from "../../service/RedemptionService";
import {HttpService} from "../../service/HttpService";

const EventExtraForm: React.FC = () => {
    const { id } = useParams();
    const navigate = mzUseNavigate();

    return (
        <>
            {HttpService.env?.formatContest && (
                <MicroApp
                    entry={`${HttpService.env?.formatContest}`}
                    props={{
                        type: "event-extra-form",
                        updateRedemprionAddress: (redemptionId: string, obj: any) => {
                            return RedemptionService.updateRedemptionAddress(
                                redemptionId,
                                obj
                            );
                        },
                        onNavigate(type: NavigationType, params?: Record<string, any>) {
                            return navigate(getNavPathFromType(type, params))
                        },
                        id: id || "",
                    }}
                />
            )}
        </>
    );
}


export default EventExtraForm;