import React from "react";
import {CMSSection, getProductImage, JCard} from "@jnext/commons";
import {generatePathUrl} from "../../utils";
import {HistoryRewardType} from "../../type/historyReward";
import {OrderProps, RedemptionProps} from "../../components/Profile/Sections/HistoryRewards/CardList";
import {ContentComponentCardV2} from "./ContentComponentCardV2";
import {Product} from "@jnext/ts-axios-mz/dist/models/product";
import {ProductDetailBrandDto} from "@jnext/ts-axios-mz/models/product-detail-brand-dto";


export interface CardItemV2Props {
    redemptionProps: RedemptionProps|undefined;
    orderProps: OrderProps;
    cms: CMSSection;
    type: Exclude<HistoryRewardType, HistoryRewardType.ALL>;
    options?: any;
    onRedeem: () => void;
    brand: ProductDetailBrandDto | undefined;
}

export const CardItemV2: React.FC<CardItemV2Props> = ({
                                                          redemptionProps,
                                                          orderProps,
                                                          cms,
                                                          type,
                                                          options,
                                                          onRedeem
                                                      }) => {

    /** important information about redemption **/
    const product: Product | undefined = orderProps?.product?.product || redemptionProps?.product;
    const brand: ProductDetailBrandDto | undefined = orderProps?.product?.product?.brands?.[0] || redemptionProps?.product?.brands?.[0];

    return (
        <div className={'cardV2'}>
            {/* CARD COMPONENT */}
            <JCard
                type={"vertical"}
                coverImageUrl={generatePathUrl(getProductImage(product))}
                contentComponent={
                    <ContentComponentCardV2
                        redemptionProps={redemptionProps}
                        orderProps={orderProps}
                        cms={cms}
                        brand={brand}
                        onRedeem={onRedeem}
                        options={options}
                        type={type}
                    />}
            />
        </div>
    );
}