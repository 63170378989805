import './ThankYouPage.scss';
import { FC } from 'react';
import Helmet from "react-helmet";
import React from 'react';
import {CMSConfiguration, JCMSCTA, useJCommonContext} from "@jnext/commons";
import {CoreUserContext} from "../../../type/coreUserContext";
import {GenericPage} from "../GenericPage";

export interface ThankYouPageProps {
    context: CoreUserContext
    title?: string,
    cmsConfig?: CMSConfiguration
}
export const ThankYouPage: FC<ThankYouPageProps> = (props) => {
    const { navigate } = useJCommonContext()

    const buttonCTA = props.cmsConfig?.section?.cta?.find(action => action.scope == 'HOME');

    const cmsConfig = { ...props.cmsConfig };

    const onClick = () => {
        if (buttonCTA) {
            navigate('PUBLIC_HOME')
        }
    }
    return (
        <GenericPage
            cmsConfig={props.cmsConfig}
        >
            {
                cmsConfig?.page?.options?.metaTags?.title
                    ?
                    <Helmet>
                        <title>{cmsConfig?.page?.options?.metaTags?.title}</title>
                        {cmsConfig?.page?.options?.metaTags?.description && <meta name="description" content={cmsConfig?.page?.options?.metaTags?.description} />}
                    </Helmet>
                    :
                    <Helmet>
                        <title></title>
                        <meta name="description" content={''} />
                    </Helmet>
            }

            <div className='thankyou-page-cta-wrapper'>
                {
                    buttonCTA && <JCMSCTA {...buttonCTA} action={onClick} />
                }
            </div>

        </GenericPage>
    )
}