import React, { FC } from "react";
import { Typography } from "antd";

const { Text } = Typography;

type CopyableProps = {
    text: string
}

/**
 * Copyable component
 *
 * @param {CopyableProps} props
 * @return {*}
 */
const Copyable: FC<CopyableProps> = (props) => {
    return (
        <Text code copyable={{tooltips: false}}>
            {props.text}
        </Text>
    )
}

export default Copyable;
