import { CartCMSConfig, CartInfoSectionTypes, CartProductsListSectionTypes, CartSectionTypes } from "./models"

export const CMS_CONFIG: CartCMSConfig = {
    enabled: true,
    page: {
        enabled: true
    },
    section: {
        enabled: true,
        title: 'Il tuo carrello',
        type: 'CART_SECTION',
        cta: [
            {
                type: 'BUTTON',
                title: 'Visualizza carrello',
                color: 'primary',
                scope: 'CART'
            }
        ],
        image: [{
            src: 'https://i.ibb.co/hY4C4nV/icons8-help-100.png'
        }],
        options: {
            background: 'primary-lighter'
        },
        sections: [
            {
                type: CartSectionTypes['CART_PRODUCTS_LIST'],
                enabled: true,
                sections: [
                    {
                        enabled: true,
                        type: CartProductsListSectionTypes['PRODUCT_FORM_QUANTITY'],
                        title: 'Quantita',
                        text: '• {{availability}} disponibili'
                    }
                ],
                cta: [
                    {
                        type: 'WRAPLESS',
                        title: 'Elimina',
                        color: 'primary',
                        scope: 'REMOVE_ITEM'
                    }
                ],
            },
            {
                type: CartSectionTypes['CART_INFO'],
                enabled: true,
                sections: [
                    {
                        enabled: true,
                        type: CartInfoSectionTypes['CART_INFO_TOTAL'],
                        title: 'Totale'
                    },
                    {
                        enabled: true,
                        type: CartInfoSectionTypes['CART_INFO_CURRENT_POINTS'],
                        title: 'Saldo punti attuale'
                    },
                    {
                        enabled: true,
                        type: CartInfoSectionTypes['CART_INFO_REMAINING_POINTS'],
                        title: 'Saldo punti rimanente'
                    }
                ],
                cta: [
                    {
                        type: 'BUTTON',
                        title: 'Procedi al checkout',
                        color: 'primary',
                        scope: 'CHECKOUT'
                    }
                ],
            }
        ]
    }
}