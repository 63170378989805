import { StarOutlined } from "@ant-design/icons";
import {
    getProductImage,
    CMSSection
} from "@jnext/commons";
import {Item} from "@jnext/ts-axios-mz";
import { Order, OrderOrderStatusEnum } from "@jnext/ts-axios-mz";
import { RewardItemValue } from "../../../../commons/ItemValue";
import { HistoryRewardType } from "../../../../type/historyReward";
import { generatePathUrl, useTranslationWord } from "../../../../utils";
import {FooterCardV2} from "../../../../commons/JCardV2/FooterCardV2";
import MessageBox from "../../../Messages/MessageBox";
import React, {useState} from "react";
import {useMessages} from "../../../Messages/messageHooks";


interface ProductGroupV2Props {
    groupByOrders: Object;
    profileOptions: any;
    options: any;
    bodyCms?: CMSSection;
    friendlyId?: string ;
    type: Exclude<HistoryRewardType, HistoryRewardType.ALL>;
    setOrderTracking: (order: Order) => void;
}

const typeToShow = ["COMPANY_CLOSURE"];
const statesToHideMessageBox = ["SENT", "DELIVERED", "ANNULLED", "EXPIRED"];

export const ProductGroupV2: React.FC<ProductGroupV2Props> = ({ groupByOrders,
                                                                  profileOptions,
                                                                  options,
                                                                  bodyCms,
                                                                  friendlyId,
                                                                  type,
                                                                  setOrderTracking,
                                                              }) => {

    if(!options || !options.showHistoryCardV2){
        return <></>
    }
    const [orderStatus, setOrderStatus] = useState("");

    const translateWord = useTranslationWord();

    const {messages} = useMessages();
    const shouldShowMessageBox = !statesToHideMessageBox.includes(orderStatus || "");

    const OrderStatus = ({orderStatus}:{orderStatus: OrderOrderStatusEnum})=>{
        setOrderStatus(orderStatus);
        return(<></>)
    }

    return (
        <ul className={'orders-list'}>
            {shouldShowMessageBox &&
                messages?.filter((message) => typeToShow?.includes(message?.messageType ?? ''))
                    ?.map((message) => {
                        return (
                            <div style={{width: '100%'}}>
                                <MessageBox
                                    msgTitle={message?.msgTitle ?? ''}
                                    msgSubtitle={message?.msgSubtitle ?? ''}
                                    msgBody={message?.msgBody ?? ''}
                                    msgTitleColor={message?.msgTitleColor ?? ''}
                                    msgSubtitleColor={message?.msgSubtitleColor ?? ''}
                                    msgBodyColor={message?.msgBodyColor ?? ''}
                                    fromDate={message?.fromDate ?? ''}
                                    toDate={message?.toDate ?? ''}
                                    msgLogoUrl={message?.msgLogoUrl ?? ''}
                                    msgDisclaimerColor={message?.msgDisclaimerColor ?? ''}
                                />
                            </div>
                        )
                    })}
            <div
                className={'order-number'}>
                {bodyCms?.table?.orderN?.label} {friendlyId}
            </div>
            {
                Object?.keys(groupByOrders)?.map((orderStatus, key) => {
                    const order = (groupByOrders as any)?.[orderStatus][0];
                    return(
                        <li key={key}>
                            <div className={'products-groups-v2'}>
                                <OrderStatus orderStatus={order?.orderStatus}/>
                                <div className={'product-group-v2'}>
                                    {
                                        (groupByOrders as any)[orderStatus]?.map((order: Order, i: number) => {
                                            return (<>
                                                    {
                                                        order.products?.map((product: Item) => {
                                                            const thumbnailImage = getProductImage(product?.product);

                                                            return (
                                                                <div className={'product-row'}
                                                                     key={product?.product?.logicId}>
                                                                    <div className={'product-main-data'}>


                                                                        {
                                                                            thumbnailImage &&
                                                                            <img className={'product-thumbnail'}
                                                                                 src={generatePathUrl(thumbnailImage)} aria-hidden={true}/>
                                                                        }

                                                                        {
                                                                            !thumbnailImage &&
                                                                            <figure />
                                                                        }

                                                                        <div className={'product-info'}>
                                                                            <div
                                                                                className={'product-brand'}>{product?.product?.brands?.[0]?.title}</div>
                                                                            <div className={'product-name'}>
                                                                                <div dangerouslySetInnerHTML={{__html: product?.product?.title ?? ''}}/>

                                                                                {
                                                                                    product?.vouchers && <div>
                                                                                        {
                                                                                            product?.vouchers?.map((el, i) => (
                                                                                                <a key={i} href={el.content}
                                                                                                   target={'_blank'}
                                                                                                   title={el?.content}
                                                                                                   aria-label={`${translateWord('GO_TO')} ${el?.content}`}
                                                                                                   className={'voucher-link'}>{bodyCms?.table?.voucherLink?.label}</a>
                                                                                            ))
                                                                                        }
                                                                                    </div>
                                                                                }

                                                                            </div>

                                                                            {
                                                                                (product?.value?.wallets || product?.value?.additionalCost) && (
                                                                                    <div className={'stars-container mobile'}>
                                                                                        <StarOutlined />
                                                                                        <label>-</label>
                                                                                    </div>
                                                                                )
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                    <div className={'product-other-data'}>
                                                                        <div className={'quantity-container'}>
                                                                            <label>{bodyCms?.table?.quantity?.label}</label>
                                                                            <div
                                                                                className={'quantity'}>{product?.quantity}</div>
                                                                        </div>
                                                                        {

                                                                            type !== HistoryRewardType.CONTEST &&
                                                                            (!!product?.amount || !!product?.cashAmount) &&
                                                                            (
                                                                                <div className={'stars-container'}>
                                                                                    <RewardItemValue
                                                                                        amount={product?.amount}
                                                                                        cashAmount={product?.cashAmount}
                                                                                        walletId={product?.referredWalletConfigurationLogicId}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </div>
                                <div className={'footerCardContainer'} onClick={()=>setOrderTracking(order)}>
                                    <FooterCardV2 status={order?.orderStatus} options={options?.status || profileOptions?.status} orderDate={order?.date}/>
                                </div>
                            </div>
                        </li>
                    )

                })
            }
        </ul>)
}