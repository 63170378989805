import {getNavPathFromType, NavigationType, NavigationTypes} from "@jnext/commons";
import {GetConsumerDto} from "@jnext/ts-axios-coreuser";
import {FC, Fragment} from "react";
import {useSearchParams} from "react-router-dom";
import {useConsumerStore} from "store/consumerStore";
import MicroApp from "../../commons/MicroApp";
import {LoginType} from "../../enums/LoginType";
import {AuthService} from "../../service/AuthService";
import {HttpService} from "../../service/HttpService";
import {browserRedirect, goToNoAuth, mzUseNavigate} from "../../utils";
import {ConsumerService} from "../../service/ConsumerService";

export const AccessCode: FC = () => {

    const navigate = mzUseNavigate();
    const [params] = useSearchParams()
    const { updateProfile } = useConsumerStore();

    const onErrorAccess = () : void => {
        goToNoAuth();
    }

    // if accessCode not preset exit
    if (!params.get('accessCode')) {
        onErrorAccess()
    }

    return (
        <>
            {HttpService.env?.coreUser && (
                <MicroApp
                    entry={`${HttpService.env?.coreUser}`}
                    props={{
                        type: 'access-check',
                        onSuccess: async(token: string, refreshToken: string, exp: number, consumerInfo: GetConsumerDto, flFinalization: boolean = false) => {

                            // set finalize status
                            if (flFinalization) {
                                localStorage.setItem('tmp_refresh_token', refreshToken || '');
                                // pass tokens to finalize
                                AuthService.setTempToken(token);
                                // pass tokens to finalize
                                return navigate(getNavPathFromType(NavigationTypes.REGISTRATION_FINALIZE));

                            }
                            else
                            {
                                AuthService.setToken(token);
                                AuthService.setRefreshToken(refreshToken);
                                AuthService.setLoginType(LoginType.standard);
                                await ConsumerService.retrieveConsumer();
                                await updateProfile();
                                await AuthService.timerRefreshToken(exp);

                                // Navigate into website
                                const redirectParam = params.get('redirect')
                                const redirectPath = redirectParam?.startsWith('/') && redirectParam

                                if(HttpService.landingPageScope)
                                {
                                    browserRedirect(getNavPathFromType(HttpService.landingPageScope));
                                }
                                else
                                {
                                    browserRedirect(redirectPath || getNavPathFromType(NavigationTypes.PRIVATE_HOME));
                                }

                            }

                        },
                        onError: onErrorAccess,
                        onNavigate(type: NavigationType) {
                            return navigate(getNavPathFromType(type))
                        },
                        keyCloakClientId: HttpService.env?.keycloakClientID as string,
                        accessCode: params.get('accessCode')
                    }}/>)
            }
        </>
    )
}