import React from "react";
import {CatalogPage, CatalogPageProps} from "./CatalogPage";
import {useParams} from "react-router-dom";


export const CatalogPageContainer: React.FC<CatalogPageProps> = props => {

    const {initiativeLogicId} = useParams();

    return <CatalogPage {...props} key={initiativeLogicId}/>;
}