import {PlanStatusEnum, ProductPreviewDto} from "@jnext/ts-axios-formatdigitalcollection";
import {useEffect, useState} from "react";
import {generatePathUrl, getBackgroundCard, mzUseNavigate, useTranslationWord} from "../../utils";
import {FORMAT_DATE, getNavPathFromType, JCMSCTA, NavigationTypes} from "@jnext/commons";
import {useCMSStore} from "../../store/cmsStore";
import moment from "moment";

interface TabBtnProps {
    products: ProductPreviewDto[] | undefined
    onClickTab: (logicId: string) => void
    indexActiveTab: number;
    containerElement?: HTMLElement
}

const BtnUnlocked = ({products, indexChoose}:{products: ProductPreviewDto[] | undefined, indexChoose: number})=>{
    const navigate = mzUseNavigate();
    const translateWord = useTranslationWord();
    const status = products?.[indexChoose]?.serviceDetails?.status;
    return(<>
        {(status !== PlanStatusEnum.Purchased) &&
            <div className={`toBuyContainer`}>
                {(status === PlanStatusEnum.AvailableForPurchase) && <JCMSCTA
                    type={'BUTTON'}
                    color={'primary'}
                    title={`${translateWord('buyNewPurchase') + ' ' + products?.[indexChoose]?.title}`}
                    action={() => navigate(getNavPathFromType(NavigationTypes.VAS))}
                /> }
                {(status === PlanStatusEnum.Scheduled) && <JCMSCTA
                    type={'BUTTON'}
                    color={'primary'}
                    title={translateWord(`subscription_${products?.[indexChoose]?.serviceDetails?.status}`,
                        {date: moment(products?.[indexChoose]?.serviceDetails?.activationDate)?.format(FORMAT_DATE)})
                    }
                    action={() => navigate(getNavPathFromType(NavigationTypes.SUBSCRIPTION_DETAILS))}
                /> }
                <div className={'imgLocked'}>
                    <img className={'locked'} src={generatePathUrl('locked.svg')} aria-hidden={true}/>
                </div>
            </div>}
    </>)
}

// Definisce il componente TabBtn che accetta le proprietà products e onClickTab
export function TabBtn({products, onClickTab, indexActiveTab, containerElement}: TabBtnProps) {
    // Ottiene la funzione di traduzione
    const translateWord = useTranslationWord();

    const {structure} = useCMSStore();

    // Stato per memorizzare l'indice del tab selezionato
    const [indexChoose, setIndexChoose] = useState<number>(0);

    useEffect(() => {
        console.log(indexActiveTab)
        setIndexChoose(indexActiveTab);
    }, [indexActiveTab]);

    return (
        <div className={'tabBtnContainer'}>
            <div className={'tabBtnSpace'}>
                {/* Mappa i prodotti per creare i pulsanti dei tab */}
                {products?.map((product, key) => {
                    if(containerElement && indexChoose === key){
                        containerElement.style.background = getBackgroundCard(structure, product.logicId as string)?.background
                    }
                    // Verifica se il prodotto è stato acquistato
                    return (
                        <div key={product?.logicId}
                             className={`tabBtn ${indexChoose === key ? 'active' : 'noActive'}`}
                             style={{background: `${getBackgroundCard(structure, product.logicId as string)?.backgroundLinear}`}}
                             onClick={() => {
                                 // Imposta l'indice del tab selezionato e chiama la funzione onClickTab
                                 setIndexChoose(key)
                                 sessionStorage.setItem('refClusterLogicId', product?.serviceDetails?.clusteredInitiatives?.[0]?.refClusterLogicId as string);
                                 onClickTab(product?.logicId as string)
                             }}
                        >
                            <div className={'titleTabBtn'}>
                                {/* Mostra il titolo del tab con la traduzione appropriata */}
                                {`${translateWord(product.serviceDetails?.status === PlanStatusEnum.Purchased ? 'vas-card-title_upper' : 'selectPurchasePlan')}`}
                                <span>{product?.title}</span>
                            </div>
                        </div>
                    )
                })}
            </div>
            <BtnUnlocked  products={products} indexChoose={indexChoose}/>
        </div>
    )
}