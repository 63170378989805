import { JCMSCTA } from "@jnext/commons";
import { Col } from "antd";
import { ProductFormSection } from "pages/ProductDetail/models";
import {FC, Fragment, useEffect, useMemo} from "react";
import { useTranslation } from "react-i18next";
import { AuthService } from "service/AuthService";
import {ProductTypeEnum} from "@jnext/ts-axios-formatdigitalcollection";

interface FormCTAProps {
    cta: ProductFormSection['cta'],
    purchaseEnabled: boolean,
    productType?: ProductTypeEnum,
    quantity: number;
    maxAvailabilitySelect?: number | null;

}


export const FormCTA: FC<FormCTAProps> = props => {
    const { t: translate } = useTranslation()

    const submitCTA = useMemo(() => (
        props.cta?.find(cta => ['SUBMIT', 'REDEEM'].includes(cta.scope || ''))
    ), [props.cta])

    const loginCTA = useMemo(() => props.cta?.find(cta => cta.scope === 'LOGIN'), [props.cta]);

    const isDisabled = useMemo(() => {
        if(!props?.quantity || props?.quantity === 0) return true;
        else if(props?.maxAvailabilitySelect && props?.quantity > props?.maxAvailabilitySelect){
            return true
        }

        return false
    }, [props.quantity])

    return (
        <Fragment>
            {
                AuthService.authorized ? (
                    props.purchaseEnabled && submitCTA ? (
                        <Col span={24} className="actions">
                            <JCMSCTA {...submitCTA} disabled={isDisabled}/>
                        </Col>

                    ) : (
                        <Col span={24} className="product-purchase-disabled">
                            {translate('PRODUCT_PURCHASE_DISABLED')}
                        </Col>
                    )
                ) : (
                    loginCTA && (
                        <Col span={24} className="actions">
                            <JCMSCTA {...loginCTA} scopeParameters={{ redirect: window.location.pathname.replace('/public', '') }} />
                        </Col>
                    )
                )


            }
        </Fragment>
    )
}