
import { isUploadValue } from '@jnext/commons';
import { IssueTypesApi } from '@jnext/ts-axios-coreconfiguration';
import { ZendeskApi } from '@jnext/ts-axios-corecontentio';
import { RedemptionResponse } from '@jnext/ts-axios-mz';
import { NewTicketDto, TicketConsumerApi } from '@jnext/ts-axios-solution-backoffice';
import { UploadFile } from 'antd';
import axios, { Axios } from 'axios';
import { ContactFormDataOutput } from 'pages/Contacts/Contacts';
import { HttpService } from "./HttpService";
import { RedemptionService } from './RedemptionService';
import {HistoryRewardType} from "../type/historyReward";

export class ContactService {
    static async getIssueTypes() {
        const headers: any = HttpService.getHttpHeaders({});
        headers['jak-language'] = headers['jak-language'].toUpperCase()

        const axiosInstance = new Axios({
            responseType: 'json',
            headers
        })
        const issueTypesApi = new IssueTypesApi(undefined, HttpService.servicePath('core-configuration'), axiosInstance as any)
        const rs = await issueTypesApi.getIssueTypes()
        return  typeof rs.data === 'string' ? JSON.parse(rs.data) : rs.data

    }
    static async getOrders() {
        const redemptions: RedemptionResponse[] = await RedemptionService.retrieveRedemptionList(HistoryRewardType.ALL);
        const orders = []
        for (const redemption of redemptions) {
            if ((redemption as any).status === 'COMPLETED' && redemption.orders?.length) {
                orders.push(...redemption.orders)
            }
        }
        return orders
    }

    static async postIssue(payload: ContactFormDataOutput) {
        const body: NewTicketDto = {
            ticketBody: {
                message: payload.message,
                reason: payload.reason,
                secondaryReason: payload.subargument,
                orderId: payload.order,
            },
            requester: {
                email: payload.email,
                name: payload.name,
                surname: payload.lastname,
            }
        }
        if (isUploadValue(payload.files)) {
            const uploadFilesResponse = await this.upload(payload.files)
            if (uploadFilesResponse.length) {
                body.ticketBody.files = (
                    uploadFilesResponse
                        .filter(uf => uf.data[0].uploaded)
                        .map(uf => uf.data[0].fileRef)
                        .filter(fileRef => !!fileRef) as string[]
                )
            }
        }

        const consumerApi = new TicketConsumerApi(undefined, HttpService.servicePath("solution-cmr-backoffice"), axios as any)
        return consumerApi.newTicketForConsumer(body)
    }

    static async upload(files: UploadFile[]) {
        const zendeskApi = new ZendeskApi(undefined, HttpService.servicePath("core-contentio"), axios as any)
        return Promise.all(files.map(file => zendeskApi.zendeskUpload('jnext', file.originFileObj, 'contact-uploads')))
    }
}