import {FC, useMemo} from "react";
import {CartSection, CartSectionTypes} from "pages/Cart/models";
import {JBox, JCMSCTA} from "@jnext/commons";
import {useTranslationWord} from "utils";
import './CartInitiativesPreview.scss';
import {CloseOutlined} from "@ant-design/icons";
import {CatalogService, LocalStorageCatalogData} from "../../../../../../service/CatalogService";
import {CatalogType} from "../../../../../../type/catalogTypes";
import {Col, Row} from "antd";

export interface CartInitiativesPreviewProps {
    section?: CartSection,
    onClose: () => void,
    onInitiativeChoose: (initiativeId: string) => void;
}

export const CartInitiativesPreview: FC<CartInitiativesPreviewProps> = props => {
    const translate = useTranslationWord()
    const catalogInfo = useMemo<LocalStorageCatalogData | null>(() => CatalogService.getCatalogInfo(CatalogType.loyaltyCollection), []);
    const infoSection: any = useMemo(() => props.section?.sections.find(section => section.type == CartSectionTypes.CART_INITIATIVES_LIST), [props.section])

    if (!props?.section) return <div></div>

    return (
        <div className="cart-preview">
            <JBox>
                <div className="cart-preview-content">

                    <div className="cart-preview-header">
                        <div className="cart-preview-title">
                            {infoSection?.title}
                        </div>
                        <CloseOutlined onClick={props.onClose}/>
                    </div>
                    {
                        catalogInfo && catalogInfo?.initiatives?.length ? (
                            <div className={'initiatives-list'}>
                                {catalogInfo?.initiatives?.map((item, i) => (
                                    <Row className={'initiative-row'} justify={'space-between'} align={'middle'} key={i}>
                                        <Col className={'initiative-name'}>{item.name}</Col>
                                        <Col>
                                            <JCMSCTA
                                                type={'BUTTON'}
                                                title={'Mostra iniziativa'}
                                                size={'small'}
                                                action={() => props.onInitiativeChoose(item.logicId!)}
                                            />
                                        </Col>
                                    </Row>
                                ))}
                            </div>
                        ) : (
                            <div className="cart-isempty">
                                {
                                    translate('NO_INITIATIVES')
                                }
                            </div>
                        )
                    }
                </div>
            </JBox>

        </div>
    )
}