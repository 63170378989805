import React, {FC, useMemo, useState} from "react";
import {CMSCTA, CMSSection, FORMAT_DATE, getNavPathFromType, JCMSCTA, JHTML, NavigationTypes} from "@jnext/commons";
import {JModalVas} from "../JModalVas";
import {AuthService} from "../../../../service/AuthService";
import "./cardVas.scss";
import {getBackgroundCard, mzUseNavigate, useTranslationWord} from "../../../../utils";
import {useCMSStore} from "../../../../store/cmsStore";
import {CartService} from "../../../../service/CartService";
import {
    BasketResponse,
    BasketTypeEnum,
    PlanStatusEnum,
    ProductPreviewDto
} from "@jnext/ts-axios-formatdigitalcollection";
import {useVasStore} from "../../../../store/VasStore";
import moment from "moment";

interface JCardVasProps {
    card?: ProductPreviewDto;
    section?: CMSSection;
    isSubscriptionPurchased?: boolean;
}

// Definisce il componente JCardVas che accetta le proprietà card, section e isSubscriptionPurchased
export const JCardVas: FC<JCardVasProps> = ({card, section, isSubscriptionPurchased}) => {
    // Ottiene la funzione di navigazione
    const navigate = mzUseNavigate();
    // Ottiene la funzione di traduzione
    const translateWord = useTranslationWord();
    // Ottiene la struttura del CMS dal CMSStore
    const {structure} = useCMSStore();

    // Stato per gestire la visibilità del Modal
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    // Usa useMemo per memorizzare se il piano è stato acquistato
    const isPurchased = useMemo(() => (card?.serviceDetails?.status === PlanStatusEnum.Purchased), [card?.serviceDetails]);

    // Usa useMemo per memorizzare il prezzo del prodotto
    const price = useMemo(() => card?.redemptionModalities?.[0], [card?.redemptionModalities]);

    // Usa useMemo per memorizzare lo stato di autenticazione
    const isAuth = useMemo(()=>AuthService.authorized, []);

    // Trova il pulsante CTA per selezionare un abbonamento
    let selectSubscriptionCMS = section?.cta?.find((cta: CMSCTA ) => cta?.scope === NavigationTypes.CHECKOUT_VAS);

    // Trova il pulsante CTA per gestire un abbonamento
    let schedulateSubscriptionCMS = section?.cta?.find((cta: CMSCTA ) => cta?.scope === NavigationTypes.SUBSCRIPTION_DETAILS);

    // Trova il pulsante CTA per ulteriori informazioni
    const moreInfoBtn = section?.cta?.find((cta: CMSCTA ) => cta?.scope === 'MORE_INFO');

    // Funzione per aggiungere un prodotto, se la risposta contiene i parametri di acquisto, naviga alla pagina di checkout, altrimenti naviga alla pagina di selezione dell'abbonamento altrimenti direttamente al checkout
    async function addToBasket() {
        await CartService.add( card?.serviceDetails?.initiativeLogicId as string, BasketTypeEnum.DirectPurchase, {
            productLogicId:  card?.serviceDetails?.productLogicId,
            redemptionModalityLogicId: card?.redemptionModalities?.[0]?.logicId,
            quantity: 1
        }).then((res: BasketResponse) => {
            const isPurchaseParameters= res?.items?.[0]?.purchaseParameters;
            if(!!isPurchaseParameters && isPurchaseParameters?.length > 0){
                return navigate(getNavPathFromType(selectSubscriptionCMS?.scope as string, {
                    initiativeLogicId: card?.serviceDetails?.initiativeLogicId,
                    catalogGroupProductLogicId: res?.items?.[0]?.catalogGroupProductLogicId
                }))
            }

            return navigate(getNavPathFromType(NavigationTypes.CHECKOUT as string, {
                initiativeLogicId: card?.serviceDetails?.initiativeLogicId,
                basketType: BasketTypeEnum.DirectPurchase
            }))
        });
    }

    const BtnSection = ()=> {
        switch (card?.serviceDetails?.status) {
            case PlanStatusEnum.Purchased:
            case PlanStatusEnum.AvailableForPurchase: return (<>
                {selectSubscriptionCMS &&
                    <JCMSCTA
                        {...selectSubscriptionCMS}
                        disabled={isPurchased}
                        title={translateWord(`subscription_${card?.serviceDetails?.status}`)}
                        action={addToBasket}/>
                }</>);
            case PlanStatusEnum.Scheduled: return (<>
                {schedulateSubscriptionCMS &&
                    <JCMSCTA
                        {...schedulateSubscriptionCMS}
                        title={translateWord(`subscription_${card?.serviceDetails?.status}`, {date: moment(card?.serviceDetails?.activationDate)?.format(FORMAT_DATE)})}                    />
                }</>);
            default: return (<></>);
        }
    }

    return (
        <div className={'vas-card'} style={{background : `${getBackgroundCard(structure, card?.logicId as string)?.backgroundLinear}`}}>
            {/* Mostra il sottotitolo */}
            <JHTML.SubTitle html={translateWord(`vas-card-title`)}/>
            {/* Mostra il titolo del prodotto */}
            {card?.title && <JHTML.Title html={card?.title}/>}
            {/* Mostra la descrizione del prodotto */}
            {card?.description && <JHTML.Text html={card?.description}/>}

            {/* Mostra il contenitore del prezzo se disponibile */}
            <div className={'vas-price-container'}>
                {price?.sellPrice && <div className={'price'}>{'€'+price?.sellPrice}</div>}
                {price?.pricingModel?.cronDecription && <div className={'cronDescription'}>{price?.pricingModel?.cronDecription}</div>}
            </div>

            {/* Sezione contenente i pulsanti per selezionare/acquistare un piano se sono configurati da CMS per utenti autenticati */}
            {isAuth && <>
                {/* Mostra il pulsante per selezionare un piano se non è stato acquistato un abbonamento oppure per acquistare un piano se è stato acquistato almeno un abbonamento */}
                <div className={`${!isPurchased ? 'vas-cta-purchase' : 'vas-cta-purchased'}`}>
                    {!isSubscriptionPurchased ?
                        <>
                            {selectSubscriptionCMS && <JCMSCTA
                                {...selectSubscriptionCMS}
                                action={addToBasket}
                            />}
                        </>
                        :
                        <BtnSection />
                    }
                </div>
            </>}

            {/* Mostra la lista degli elementi inclusi se disponibile */}
            {card?.longDescription && <div className={'vas-included'}>
                <JHTML.Title html={'Cosa include'}/>
                <JHTML.Text html={card?.longDescription}/>
            </div>}

            {/* Mostra il pulsante per ulteriori informazioni */}
            {moreInfoBtn && <div className={'vas-card-more-info'}>
                <JCMSCTA {...moreInfoBtn} action={() => setIsModalVisible(true)}/>
            </div>}
            {/* Mostra il modal se isModalVisible è true */}
            {isModalVisible &&
                <JModalVas
                    title={card?.title}
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    infoProducts={card?.cardMetadata} />}
        </div>
    )
}