import React from "react";
import {FORMAT_DATE, getProductImage, JHTML, JModal} from "@jnext/commons";
import {observer} from "mobx-react";
import {MGMHistoryResponse} from "@jnext/ts-axios-mz/models";
import {useTranslationWord} from "../../../../utils";
import moment from "moment";
import {PointRow} from "../../../Profile/Sections/HistoryPoints/PointRow";
import {generatePathUrl} from "../../../../utils/utils";

interface ActivityHistoryModalProps {
    mgmHistory: MGMHistoryResponse | undefined;
    isModalVisible: boolean;
    setIsModalVisible: (isModalVisible: boolean)=> void;
}

const ActivityHistoryModal = ({mgmHistory, isModalVisible, setIsModalVisible }: ActivityHistoryModalProps) => {
    const translate = useTranslationWord();
    return( <JModal
        width={800}
        isModalVisible={isModalVisible}
        footer={null}
        handleCancel={() => setIsModalVisible(false)}
    >
        <div className={'titleHistoryModal'}>
            <JHTML.Title html={translate('activityHistory')}/>
        </div>
        <div className={'bodyHistoryModal'}>
            {!mgmHistory && translate('noActivityHistory')}
            {mgmHistory && Object.keys(mgmHistory).map(( key, index) => {
                // @ts-ignore
                return mgmHistory?.[key]?.map(referrer => {
                    const reward = referrer?.reward;
                    const rewardWallet = reward?.rewardWallets[0];
                    const rewardItem = reward?.rewardItems[0];

                    return(<div className={'historyInfo'}>
                        <div className={'leftSide'}>
                            <label className={'title'}>{translate(index === 0 ? 'activityHistoryRegistration' :'activityHistoryFriend')}</label>
                            <label className={'text'}>{moment(referrer?.date)?.format(FORMAT_DATE)}</label>
                        </div>
                        {reward && <div className={'rightSide'}>
                            {rewardWallet && <PointRow amount={rewardWallet?.amount} src={rewardWallet?.walletCms?.imageRef}/>}
                            {rewardItem && <div className={'pointRow'}>
                                <label className={'pointText'}>{rewardItem?.quantity}</label>
                                {
                                    getProductImage(rewardItem?.product) &&
                                    <img className={'productImage'} src={generatePathUrl(getProductImage(rewardItem?.product))} aria-hidden={true}/>
                                }
                            </div>}
                        </div>}

                    </div>)
                })
            })}
        </div>
    </JModal>)
}

export default observer(ActivityHistoryModal);