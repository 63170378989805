import { Fragment } from 'react';
import { FC } from 'react';
import {cmsConfigToThankProps} from "../ThankYouPage/utils";
import {GenericPage} from "../GenericPage";
import React from 'react';
import {CMSConfiguration, FieldDTOPayload, JFieldDTO, JFormCompositorProps} from "@jnext/commons";
import {ThankYouPage, ThankYouPageProps} from "../ThankYouPage";
import {CoreUserContext} from "../../../type/coreUserContext";
import {JFormCompositorWrapper} from "../../../commons/JFormCompositorWrapper/JFormCompositorWrapper";


export type FormWrapperOnSubmit = (output: FieldDTOPayload) => Promise<void>
export type GenericPageFormProps = Omit<JFormCompositorProps, 'fields' | 'onSubmit' | 'submitProps' | 'context' | 'formId'>
export interface GenericPageFormWrapperProps {
    formProps?: GenericPageFormProps
    fields: JFieldDTO[],
    cmsConfig: CMSConfiguration | undefined,
    errorCode?: string,
    onSubmit: FormWrapperOnSubmit,
    context: CoreUserContext,
    disableForm?: boolean,
    title?: string,
    additionalText?: string,
    options?: any
    showThankYouPage?: boolean
}

export const GenericPageFormWrapper: FC<GenericPageFormWrapperProps> = (props) => {
    const onSubmit = async (output: FieldDTOPayload) => {
        await props.onSubmit(output)
    }
    const cmsSection = props?.cmsConfig?.section

    const thankYouPageProps: ThankYouPageProps = cmsConfigToThankProps(props?.cmsConfig, props.context)

    return (
        <Fragment>
            {
                props?.showThankYouPage ? (
                    <ThankYouPage {...thankYouPageProps} />
                ) : (
                    <GenericPage cmsConfig={props.cmsConfig} title={props.title} additionalText={props.additionalText} errorCode={props.errorCode}>
                        {
                            Array.isArray(props.fields) && cmsSection?.enabled !== false && (
                                <JFormCompositorWrapper
                                    {...props}
                                    providerProps={{
                                        ...(props.formProps?.providerProps || {}),
                                    }}
                                    disableForm={props.disableForm}
                                    fields={props.fields}
                                    options={props.options}
                                    formId={props.context}
                                    onSubmit={onSubmit}
                                    cta={cmsSection?.cta}
                                    {...(props.formProps || {})}
                                />
                            )
                        }
                    </GenericPage>
                )
            }

        </Fragment>
    )
}