import React from "react";
import { ItemValue, ItemValueProps } from "./ItemValue";
import { CatalogService } from "../../service/CatalogService";
import { useWalletStore } from "store/walletStore";
import { observer } from "mobx-react";
import {ProductPreviewRedemptionModalityDto} from "@jnext/ts-axios-formatdigitalcollection";

interface CatalogItemValueProps {
    redemptionModality: ProductPreviewRedemptionModalityDto
}

export const CatalogItemValue: React.FC<CatalogItemValueProps> = observer(({redemptionModality}) => {
    const { wallet } = useWalletStore()

    const prices = CatalogService.getPrice(redemptionModality, wallet);
    
    const props: ItemValueProps = {
        points: prices?.points ? [prices.points] : [],
        cost: prices?.cost
    }

    return <ItemValue {...props}/>
})