import React, {PropsWithChildren} from "react";
import {Col, Row} from "antd";

interface FilterBoxProps {
    onReset: () => void;
    onConfirm: () => void;
}

export const FilterBox: React.FC<PropsWithChildren<FilterBoxProps>> = ({onReset, onConfirm, children}) => {
    return <div className={'input-details-select'}>
        {children}
        <div className={'actions'}>
            <Row>
                <Col span={12} className={'col reset'} onClick={onReset}>
                    Azzera
                </Col>
                <Col span={12} className={'col submit'} onClick={onConfirm}>
                    Salva
                </Col>
            </Row>
        </div>
    </div>;
}