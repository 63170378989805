import { AnalyticsEventsEnum, CustomAnalyticEventPayload } from "@jnext/commons";
import { toJS } from "mobx";
import { useEffect, useState } from "react";
import { useAppStore } from "store/appStore";
import { useCMSStore } from "store/cmsStore";
import { useLoadingStore } from "store/loadingStore";
import { AuthService } from "../service/AuthService";
import { HttpService } from "../service/HttpService";
import { MicroAppBaseConfigProps } from "../type/MicroAppInterfaces";
import { trackingEventsMaps } from "../utils";

export const useMicroApp = (): {
    baseProps: MicroAppBaseConfigProps,
} => {
    const authStore = useAppStore();
    const { loading, setLoading } = useLoadingStore();
    const [props, setProps] = useState<MicroAppBaseConfigProps>();
    const { appSettings } = useCMSStore();


    /**
     * On loading change
     */
    useEffect(() => {

        setProps({
            type: "",
            baseAPIUrl: HttpService.env?.baseUrl as string,
            imagePathUrl: HttpService.env?.pathImg as string,
            entries: {
                formatEvent: HttpService.env?.formatEvent,
                coreUser: HttpService.env?.coreUser,
                formatContest: HttpService.env?.formatContest,
                pda: HttpService.env?.pda,
                formatReward: HttpService.env?.formatReward
            },
            setLoading,
            loading,
            template: appSettings?.template?.code,
            authorized:
                AuthService.authorized || !!AuthService?.accessToken,
            httpHeaders: HttpService.getHttpHeaders({}),
            language: authStore.currentLanguage,
            fileTranslate: authStore?.fileTranslate,
            loginInfo: toJS(AuthService.loginInfo),
            trackingEventsMaps: (event: AnalyticsEventsEnum, customEventInfo?: CustomAnalyticEventPayload) => trackingEventsMaps(event, customEventInfo)
        })
    }, [loading]);


    return {
        baseProps: props as MicroAppBaseConfigProps
    };
}