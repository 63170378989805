import React from "react";

export default {
    "--background": "#FFFFFF",
    '--white': '#FFFFFF',
    '--white50': 'rgba(255, 255, 255, 0.53)',
    '--black': '#000000',
    '--darkGray': '#333333',
    '--grey32': '#323232',
    '--gray94': '#f0f0f0',
    '--wallis': '#c2bdbe',
    '--grayBD': '#BDB4BD',
    '--grayDF': '#DFDFDF',
    "--gray85":"#d9d9d9"
} as React.CSSProperties;

