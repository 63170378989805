import { FC } from "react";
import { CartProductItemRenderProps } from "./../CartProductItemRenderData";
import './CartProductItemDesktop.scss';

export interface CartProductItemDesktopProps extends CartProductItemRenderProps { }

export const CartProductItemDesktop: FC<CartProductItemDesktopProps> = props => {

    return (
        <div className="cart-product-item-desktop">
            {
                props.image
            }
            <div className="cart-product-item-info">
                {
                    props.brands
                }
                {
                    props.title
                }
                {
                    props.removeItemCTA
                }
            </div>
            {
                props.quantityForm
            }
            {
                props.price
            }
        </div>
    )
}