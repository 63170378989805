import React, {useEffect, useMemo, useState} from 'react';
import {Navigate, useParams} from 'react-router-dom';
import {CMSConfiguration, NavigationTypes} from "@jnext/commons";
import {downloadPrivateImg, useCMSStore} from "../../../../store/cmsStore";
import {useLoadingStore} from "../../../../store/loadingStore";
import {Button} from "antd";
import moment from "moment";
import {FORMAT_DATE} from "../../../../utils";
import {EventApproval, PDAStatusLabels} from "../HistoryPDA/HistoryPDAInterface";
import {PdaService} from "../../../../service/PdaService";
import {EventService} from "../../../../service/EventService";
import {EventApprovalDto, EventResponse, Field, FieldCms} from "@jnext/ts-axios-eventformat";
import {useAppStore} from "../../../../store/appStore";
import {isNull} from "lodash";
import {ProductList} from "./ProductList";

const HistoryPDADetail: React.FC = () => {
    const {setLoading} = useLoadingStore();
    const {currentLanguage, handleResponseError} = useAppStore();

    const [mainPayload, setMainPayload] = useState<any>();
    const [renderMainPayload, setRenderMainPayload] = useState<any>();

    const [trackingPayloads, setTrackingPayloads] = useState<any[]>();

    const [pdaDetailsData, setPdaDetailsData] = useState<EventApproval | undefined>(undefined);
    const [event, setEvent] = useState<EventResponse>({});
    const [eventMetadata, setEventMetadata] = useState<any | undefined>(undefined);

    const {logicId} = useParams<{ logicId: string }>();

    if (typeof logicId !== 'string') {
        return <Navigate to='/404'/>
    }

    const {pages} = useCMSStore();
    const config: CMSConfiguration | undefined = useMemo(() => pages?.profile, [pages]);

    const mergeValues = (payload: any, metadata: any, operationTimestamp?: string): any => {
        const payloadMap = new Map<string, string[]>();
        //@ts-ignore
        payload.fields.forEach(field => {
            if (field.hurlId && field.values) {
                payloadMap.set(field.hurlId, field.values);
            }
        });

        //@ts-ignore
        metadata.fields.forEach(field => {
            if (field.hurlId && payloadMap.has(field.hurlId)) {
                field.values = payloadMap.get(field.hurlId);
            }
        });

        return {
            metadata,
            operationTimestamp
        };
    }

    const sections = useMemo(() => {
        if (!config) {
            return [];
        }
        const section = config?.section?.sections?.find(el => el.type == NavigationTypes?.HISTORY_PDA_DETAILS);

        return section?.sections;
    }, [config]);

    const documentDownload = async (imgUrl: string) => {
        setLoading(true);
        try {
            await downloadPrivateImg(imgUrl)
        } catch (error) {
            handleResponseError(error)
        } finally {
            setLoading(false);
        }
    }

    const renderPayload = (field: Field, index: number) => {
        //@ts-ignore
        const cmsConfig = field?.cmsConfig?.[0] as FieldCms;
        let value;

        switch (field.type) {
            case 'JDATE':
                value = moment(field.values?.[0]).format(FORMAT_DATE);
                break;
            case 'JFILE':
                value = (
                    <Button
                        color="default"
                        size={'small'}
                        key={index}
                        onClick={() => documentDownload(field.values?.[0] as string)}
                    >
                        Scarica allegato
                    </Button>
                );
                break;
            default:
                value = field.values?.[0];
        }

        return (
            <div className={`historyPdaDetail__section__info info--title`} key={`pda-form-field-${index}`}>
                <span className={`historyPdaDetail__section__info__label`}>{cmsConfig?.title ?? "-"}</span>
                <div className={`historyPdaDetail__section__info__data`}>
                    {value}
                </div>
            </div>
        )
    }

    useEffect(() => {
        PdaService.searchPdaDetails(logicId).then(async (response: EventApprovalDto | EventApproval | undefined) => {
            if (response) {
                // @ts-ignore
                setPdaDetailsData(response);
            }
        });
    }, []);

    useEffect(() => {
        if (pdaDetailsData?.eventConfigurationLogicId) {
            const eventConfigId = String(pdaDetailsData.eventConfigurationLogicId);

            EventService.getPrivateEventMetadata(eventConfigId).then(async (response: any | undefined) => {
                response?.fields?.forEach((field: { cmsConfig: any[]; }) => {
                    field.cmsConfig = field.cmsConfig.filter(config => config.language === currentLanguage);
                });
                setEventMetadata(response);
            });

            EventService.getPrivateEventByEventConfigurationLogicId(eventConfigId).then(async (response: EventResponse | undefined) => {
                if (response) {
                    setEvent(response);
                }
            });
        }
    }, [pdaDetailsData]);

    useEffect(() => {

        if (pdaDetailsData && pdaDetailsData.payload && eventMetadata) {
            const payload = JSON.parse(pdaDetailsData.payload);
            setMainPayload(mergeValues(payload, eventMetadata).metadata);
        }
        if (pdaDetailsData && pdaDetailsData.trackingList && eventMetadata) {
            const trackingFormattedPayloads: any[] = [];
            // TODO: fix list merge
            pdaDetailsData.trackingList.forEach((tracking: any) => {
                const payloadList = JSON.parse(tracking.payload);
                if (payloadList && !isNull(payloadList)) {
                    const trackingFormattedPayload = mergeValues(payloadList, eventMetadata, tracking.operationTimestamp);
                    trackingFormattedPayloads.push(trackingFormattedPayload);
                }
            });

            setTrackingPayloads(trackingFormattedPayloads);
        }
    }, [event, eventMetadata, pdaDetailsData]);

    useEffect(() => {
        if (mainPayload) {
            const renderMainPayload = mainPayload.fields.map((field: Field, i: number) => {
                return renderPayload(field, i);
            });
            setRenderMainPayload(renderMainPayload);
        }
    }, [mainPayload]);

    const pageCms = useMemo(() => sections?.find((el: any) => el.type == 'MAIN'), [sections]);

    const allSectionsCms = useMemo(() => pageCms?.sections?.find((el: any) => el.type == 'BODY'), [pageCms?.sections]);

    /**
     * Main section cms - type: BODY > MAIN
     */
    const sectionMainCms = useMemo(() => allSectionsCms?.sections?.find((el: any) => el.type == 'MAIN'), [allSectionsCms?.sections]);
    const sectionMainDateCms = useMemo(() => sectionMainCms?.sections?.find((el: any) => el.type == 'INSERT_DATE'), [sectionMainCms?.sections]);
    const sectionMainStatusCms = useMemo(() => sectionMainCms?.sections?.find((el: any) => el.type == 'STATUS'), [sectionMainCms?.sections]);

    /**
     * Rewards section cms - type: BODY > REWARD_DETAIL
     */
    const sectionRewardsCms = useMemo(() => allSectionsCms?.sections?.find((el: any) => el.type == 'REWARD_DETAIL'), [allSectionsCms?.sections]);

    /**
     * User data section cms - type: BODY > RECEIPT_DATA
     */
    const sectionUserFormCms = useMemo(() => allSectionsCms?.sections?.find((el: any) => el.type == 'RECEIPT_DATA'), [allSectionsCms?.sections]);

    /**
     * Event section cms - type: BODY > INITIATIVE_INFO
     */
    const sectionEventCms = useMemo(() => allSectionsCms?.sections?.find((el: any) => el.type == 'INITIATIVE_INFO'), [allSectionsCms?.sections]);
    const sectionEventTitleCms = useMemo(() => sectionEventCms?.sections?.find((el: any) => el.type == 'INITIATIVE_NAME'), [sectionEventCms?.sections]);
    const sectionEventDescriptionCms = useMemo(() => sectionEventCms?.sections?.find((el: any) => el.type == 'INITIATIVE_DESCRIPTION'), [sectionEventCms?.sections]);
    const sectionEventDatesCms = useMemo(() => sectionEventCms?.sections?.find((el: any) => el.type == 'INITIATIVE_VALIDITY'), [sectionEventCms?.sections]);

    /**
     * Tracking section cms - type: BODY > CHANGE_HISTORY
     */
    const sectionTrackingCms = useMemo(() => allSectionsCms?.sections?.find((el: any) => el.type == 'CHANGE_HISTORY'), [allSectionsCms?.sections]);

    const statusKey = pdaDetailsData?.status as keyof typeof PDAStatusLabels;
    const statusLabel = PDAStatusLabels[statusKey];

    if (!config) {
        return <Navigate to='/404'/>
    }

    return (
        <div className={'historyPdaDetail'}>

            <h1 className="historyPdaDetail__title" dangerouslySetInnerHTML={{__html: pageCms?.title ?? ""}}/>

            {/* Main section */}
            <div className="historyPdaDetail__section historyPdaDetail__main">
                <h2 className="historyPdaDetail__section__title"
                    dangerouslySetInnerHTML={{__html: sectionMainCms?.title ?? ""}}/>
                <div className="historyPdaDetail__section__content">

                    <div className={`historyPdaDetail__section__info info--title`}>
                        <span
                            className={`historyPdaDetail__section__info__label`}>{sectionMainDateCms?.title ?? "-"}</span>
                        <div className={`historyPdaDetail__section__info__data`}>
                            {moment(pdaDetailsData?.occurrenceTimestamp)?.format(FORMAT_DATE) ?? ""}
                        </div>
                    </div>

                    <div className={`historyPdaDetail__section__info info--date`}>
                        <span
                            className={`historyPdaDetail__section__info__label`}>{sectionMainStatusCms?.title ?? "-"}</span>
                        <div className={`historyPdaDetail__section__info__data`}>
                            {statusLabel ?? ""}
                        </div>
                    </div>

                </div>
            </div>

            {/* Rewards section */}
            <div className="historyPdaDetail__section historyPdaDetail__rewards">
                <h2 className="historyPdaDetail__section__title"
                    dangerouslySetInnerHTML={{__html: sectionRewardsCms?.title ?? ""}}/>
                <div className="historyPdaDetail__section__content">

                    <ProductList event={event} bodyCms={allSectionsCms}
                                 eventExecution={pdaDetailsData?.eventExecution}/>

                </div>
            </div>

            {/* User data section */}
            <div className="historyPdaDetail__section historyPdaDetail__user-form">
                <h2 className="historyPdaDetail__section__title"
                    dangerouslySetInnerHTML={{__html: sectionUserFormCms?.title ?? ""}}/>
                <div className="historyPdaDetail__section__content">

                    {!!renderMainPayload && renderMainPayload}

                </div>
            </div>

            {/* Event section */}
            <div className="historyPdaDetail__section historyPdaDetail__event">
                <h2 className="historyPdaDetail__section__title"
                    dangerouslySetInnerHTML={{__html: sectionEventCms?.title ?? ""}}/>
                <div className="historyPdaDetail__section__content">

                    <div className={`historyPdaDetail__section__info info--title`}>
                        <span
                            className={`historyPdaDetail__section__info__label`}>{sectionEventTitleCms?.title ?? "-"}</span>
                        <div className={`historyPdaDetail__section__info__data`}>
                            {event.name ?? ""}
                        </div>
                    </div>

                    <div className={`historyPdaDetail__section__info info--description`}>
                        <span
                            className={`historyPdaDetail__section__info__label`}>{sectionEventDescriptionCms?.title ?? "-"}</span>
                        <div className={`historyPdaDetail__section__info__data`}>
                            <small>{event.cmsConfig?.longDescription ?? ""}</small>
                        </div>
                    </div>

                    <div className={`historyPdaDetail__section__info info--dates}`}>
                        <span
                            className={`historyPdaDetail__section__info__label`}>{sectionEventDatesCms?.title ?? "-"}</span>
                        <div className={`historyPdaDetail__section__info__data`}>
                            {moment(event.begin)?.format(FORMAT_DATE) ?? ""} - {moment(event.end)?.format(FORMAT_DATE) ?? ""}
                        </div>
                    </div>

                </div>
            </div>

            {/* Tracking section */}
            <div className="historyPdaDetail__section historyPdaDetail__tracking">
                <h2 className="historyPdaDetail__section__title"
                    dangerouslySetInnerHTML={{__html: sectionTrackingCms?.title ?? ""}}/>
                <div className="historyPdaDetail__section__content">

                    {!trackingPayloads || trackingPayloads.length === 0  &&
                        <div className={"no-data"}>
                            <p>{sectionTrackingCms?.text ?? ""}</p>
                        </div>
                    }

                    {!!trackingPayloads && trackingPayloads.length > 0 && trackingPayloads?.map((fieldsFull: any, i: number) => {
                        const fields = fieldsFull.metadata.fields;
                        if (!fields || fields.length === 0) {
                            return null;
                        }
                        return (
                            <div className={`historyPdaDetail__section__subsection`} key={`pda-form-subsection-${i}`}>
                                <h3 className="historyPdaDetail__section__subsection__title">{moment(fieldsFull?.operationTimestamp)?.format(FORMAT_DATE)}</h3>
                                <div className="historyPdaDetail__section__subsection__content">
                                    {fields.map((field: Field, i: number) => {
                                        return renderPayload(field, i);
                                    })}
                                </div>
                            </div>
                        );
                    })}

                </div>
            </div>

        </div>
    );
};

export default HistoryPDADetail;