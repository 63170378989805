import {setI18Resources} from "../utils";
import {HttpService} from "./HttpService";
import axios from "axios";
import {AuthService} from "./AuthService";
import {TranslationsCmsApi} from "@jnext/ts-axios-mz";

export class TranslationService {



    static async loadAllTranslationFiles(language: string): Promise<Record<string, any>[] | false> {
        const fileList = [];

        const itJson =  await TranslationService.loadFile(
            language + ".json",
            language
        );

        if(!itJson) {
            return false;
        }

        fileList.push(itJson);

        return fileList;
    }


    // @ts-ignore
    static async loadFile(fileName:string, language: string): Promise<Record<string, any> | false>{

        const setTranslation = (json: any) => setI18Resources(language, json);

        try {

            const translateApi: TranslationsCmsApi = new TranslationsCmsApi(
                undefined,
                HttpService.servicePath('solution-mz'),
                axios as any
            );

            const response: any = AuthService.authorized ? await translateApi.getTranslationPrivate(fileName) : await translateApi.getTranslationPublic(fileName);

            // set translation in core
            setTranslation(response?.data);
            return response?.data;

        } catch(e) {
            console.error('error retrieveTranslationFiles: ', fileName, e);
            return false;
        } finally {

        }
    }

}