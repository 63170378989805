import React, {useMemo} from "react";
import {Col, Row} from "antd";
import {RewardItemValue} from "../../../../../commons/ItemValue";
import moment from "moment";
import {FORMAT_DATE, mzUseNavigate, TranslateComponent, useTranslationWord} from "../../../../../utils";
import {SolutionProjectImage} from "../../../../../commons";
import {Order, RedemptionResponse} from "@jnext/ts-axios-mz";
import {CMSSection, getNavPathFromType, getProductImage, NavigationTypes} from "@jnext/commons";
import {RedemptionService} from "../../../../../service/RedemptionService";
import {HistoryRewardType} from "../../../../../type/historyReward";
import {ComponentStatusTag, StatusTagType} from "../../../../../commons/ComponentStatusTag/ComponentStatusTag";
import {downloadApprovalPdf} from "../../HistoryRewardsDetail";

interface RowElementProps {
    redemption: RedemptionResponse;
    onRedeem: () => void;
    cms: CMSSection;
    type: Exclude<HistoryRewardType, HistoryRewardType.ALL>;
}

const RowElement: React.FC<RowElementProps> = ({redemption, onRedeem , cms, type}) => {
    const translateWord = useTranslationWord();

    const {approvalDetail} = redemption;

    // is a list of order?
    const isOrderType = RedemptionService.completed(redemption?.status);

    // simulate order to iterate if not exists
    const ordersToIterate: Order[] = isOrderType ? redemption.orders as Order[] : ([{ products: redemption?.orderItems }] as Order[]);

    const completed = useMemo(() => RedemptionService?.completed(redemption?.status), [redemption]);
    const deleted = useMemo(() => RedemptionService.deleted(redemption?.status), [redemption]);
    const needToFinalize = useMemo(() => RedemptionService?.needToFinalize(redemption?.status), [redemption]);
    const showRawStatus = !completed && !needToFinalize;

    const navigate = mzUseNavigate();
    const openDetailPage = () => {

        const sectionsTypes = {
            [HistoryRewardType.CONTEST]: NavigationTypes.HISTORY_REWARDS_CONTEST_DETAILS,
            [HistoryRewardType.CATALOG]: NavigationTypes.HISTORY_REWARDS_CATALOG_DETAILS,
            [HistoryRewardType.CATALOG_LOYALTY_COLLECTION]: NavigationTypes.HISTORY_REWARDS_CATALOG_LOYALTY_COLLECTION_DETAILS,
            [HistoryRewardType.CATALOG_DIGITAL_WALLET]: NavigationTypes.HISTORY_REWARDS_CATALOG_DIGITAL_WALLET_DETAILS,
        };

        navigate(getNavPathFromType(sectionsTypes[type], {redemptionId: redemption?.logicId}));
    }

    return (
        <Col span={24}>
            <Row className={'detailGroup'}>

                <Col className={'groupHead'} span={24}>
                    <Row className={'headRow'}>
                        <Col className={'key-value'}>
                            <span>{cms?.table?.requestId?.label}</span> {redemption?.friendlyId}
                        </Col>
                        {
                            type !== HistoryRewardType.CONTEST &&
                            <Col className={'key-value'}>
                                <Row>
                                    <Col>
                                        <span>{cms?.table?.total?.label}</span>
                                    </Col>
                                    {/*
                                        <Col>
                                            <RewardItemValue points={redemption?.totals?.requiredPoints} additionalCost={redemption?.totals?.additionalCost} />
                                        </Col>
                                    */}
                                </Row>
                            </Col>
                        }
                    </Row>
                    <Row className={'headRow'}>
                        <Col className={'key-value'}>
                            <span>{cms?.table?.carriedOut?.label}</span> { moment(redemption?.dateTime)?.format(FORMAT_DATE)}
                        </Col>
                        {redemption?.expirationDate && <Col className={'key-value'}>
                            <span>{cms?.table?.expirationDate?.label}</span> {moment(redemption?.expirationDate)?.format(FORMAT_DATE)}
                        </Col>}
                        <Col className={'key-value'}>
                            <span>{cms?.table?.initiative?.label}</span> {redemption?.initiativeName}
                        </Col>
                    </Row>
                    <Row>
                        {(deleted || completed) && <h3 className={'box-title'}>
                            <b>{cms?.options?.releaseTitle}</b>
                            {
                                approvalDetail?.pdfLink &&
                                <span className={'link'}>
                                <span className={'underline'}
                                      onClick={() => downloadApprovalPdf(redemption)}>{cms?.options?.releaseLabel}</span>
                                </span>
                            }
                        </h3>}
                    </Row>
                </Col>

                {
                    ordersToIterate?.map((order, internalIndex: number) => (
                        <Col className={'groupBody'} span={24} key={internalIndex}>
                            <Row>
                                <Col className={'bodyMainData'} span={24}>
                                    {
                                        isOrderType &&
                                        <Row justify={'space-between'} align={'middle'}>
                                            <Col className={'orderNumber'}>
                                                <span>{translateWord("orderN")}</span>
                                                {order?.friendlyId}
                                            </Col>
                                            <Col className={'orderStatus'}>
                                                <ComponentStatusTag type={StatusTagType.ORDER} orderStatus={order?.orderStatus}/>
                                            </Col>
                                        </Row>
                                    }

                                    {
                                        order.products?.map((product, i) => {
                                            const thumbnailImage = getProductImage(product?.product);


                                            return (
                                                <Row key={i} className={'itemRow'} justify={'space-between'} align={'middle'}>
                                                    <Col className={'itemImg'}>
                                                        {thumbnailImage && (
                                                            <Col xs={24} md={3}>
                                                                <SolutionProjectImage name={thumbnailImage} />
                                                            </Col>
                                                        )}
                                                    </Col>
                                                    <Col className={'itemName'} dangerouslySetInnerHTML={{__html: product?.product?.title ?? ''}}/>

                                                    <Col className={'itemQty'}>
                                                        {product?.quantity}x
                                                    </Col>
                                                    {
                                                        type !== HistoryRewardType.CONTEST &&
                                                        <RewardItemValue amount={product?.amount} cashAmount={product?.cashAmount} walletId={product?.referredWalletConfigurationLogicId} />
                                                    }
                                                    {/*<Col className={'itemStars'}>*/}
                                                    {/*  5*/}
                                                    {/*</Col>*/}
                                                </Row>
                                            )
                                        })
                                    }

                                </Col>
                            </Row>
                        </Col>
                    ))
                }

                {needToFinalize && <Col span={24} className={'showDetails pointer'} onClick={onRedeem}>{cms?.table?.goToRedemption?.label}</Col>}

                {completed && (
                    <Col span={24} className={'showDetails pointer'} onClick={openDetailPage}>
                        {cms?.table?.showDetail?.label}
                    </Col>
                )}

                {
                    showRawStatus && (
                        <Col span={24} className={'showDetails'}>
                            <TranslateComponent text={`REDEMPTION_${redemption?.status}`} />
                        </Col>
                    )
                }
            </Row>
        </Col>
    );
}

export default RowElement;