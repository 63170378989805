import React, {useMemo} from 'react';
import {CMSSection, JHTML} from '@jnext/commons';
import {
    TwitterShareButton,
    WhatsappShareButton,
    FacebookMessengerShareButton, FacebookShareButton,
    TelegramShareButton,
    LinkedinShareButton, EmailShareButton,
} from 'react-share';
import {generatePathUrl, useTranslationWord} from "../../../utils";
import x_Icon from "./../../../assets/MGMSocialIcons/x_icon.svg";
import facebook_Icon from "./../../../assets/MGMSocialIcons/facebook_icon.svg";
import instagram_Icon from "./../../../assets/MGMSocialIcons/instagram_icon.svg";
import messenger_Icon from "./../../../assets/MGMSocialIcons/messenger_icon.svg";
import other_Icon from "./../../../assets/MGMSocialIcons/other_icon.svg";
import telegram_Icon from "./../../../assets/MGMSocialIcons/telegram_icon.svg";
import linkedin_Icon from "./../../../assets/MGMSocialIcons/linkedin_icon.svg";
import whatsapp_Icon from "./../../../assets/MGMSocialIcons/whatsapp_icon.svg";
import email_Icon from "./../../../assets/MGMSocialIcons/email_icon.svg";

const shareUrl = window.location.origin;

interface ShareBoardProps {
    sections: CMSSection[] | undefined,
    codeCopy: string | undefined
}

const
    ShareIcon = ({src, type, generatePath}: { src: string, type: string, generatePath: boolean }) => {
        return (<div className={'shareDiv'}>
            <div className={'shareImg'}>
                <img src={generatePath ? generatePathUrl(src) : src} aria-hidden={true} alt={''}/>
            </div>
            <label className={'name'}> {type}</label>
        </div>)
    }

const ShareBoard = ({sections, codeCopy}: ShareBoardProps) => {

    const translateWord = useTranslationWord();
    const textFromTranslations = translateWord("FRIENDS_SHARE_CODE_MESSAGE");
    const subjectFromTranslations = translateWord("FRIENDS_SUBJECT_MESSAGE")

    const text = textFromTranslations !== 'FRIENDS_SHARE_CODE_MESSAGE' ? textFromTranslations : 'Utilizza il mio codice amico codice amico {codice_amico} per registrarti su ';
    const subject = subjectFromTranslations !== 'FRIENDS_SUBJECT_MESSAGE' ? subjectFromTranslations : 'Presentaci un amico';

    const shareSection: CMSSection | undefined = useMemo(() => sections?.find((section: CMSSection) => (section?.enabled && section?.type === 'SHARE_BOARD')), [sections]);
    const socials: any[] = (shareSection?.options?.social as any[]);
    if (!shareSection) {
        return <></>
    }

    const shareText = text.replace("{codice_amico}", codeCopy ?? '');

    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: subject,
                    text: shareText,
                    url: shareUrl,
                });
            } catch (error) {
                console.error('Errore durante la condivisione', error);
            }
        }
    };

    return (
        <div className={'shareBoard'}>
            {shareSection?.title &&
                <JHTML.Title html={shareSection?.title} key={'social_mgm'}/>
            }
            <div className={'shareContainer'}>
                {socials?.length > 0 && socials?.slice()?.sort((a, b) => a.order > b.order ? 1 : -1)?.map((social, key) => {
                    switch (social.type) {
                        case 'FACEBOOK': {
                            return (<FacebookShareButton key={social.order} url={shareUrl} title={shareText}>
                                <ShareIcon src={social?.image?.[0].src ?? facebook_Icon} type={social.socialName}
                                           generatePath={social?.image?.[0]?.src ?? false}/>
                            </FacebookShareButton>)
                        }
                        case 'FACEBOOK_MESSENGER': {
                            return (
                                <FacebookMessengerShareButton appId={social?.options?.appId} key={social.order}
                                                              url={shareUrl}
                                                              title={shareText}>
                                    <ShareIcon src={social?.image?.[0].src ?? messenger_Icon} type={social.socialName}
                                               generatePath={social?.image?.[0]?.src ?? false}/>
                                </FacebookMessengerShareButton>)
                        }
                        case 'TWITTER': {
                            return (<TwitterShareButton key={social.order} url={shareUrl} title={shareText}>
                                <ShareIcon src={social?.image?.[0]?.src ?? x_Icon} type={social.socialName}
                                           generatePath={social?.image?.[0]?.src ?? false}/>
                            </TwitterShareButton>)
                        }
                        case 'WHATSAPP': {
                            return (<WhatsappShareButton key={social.order} url={shareUrl} title={shareText}>
                                <ShareIcon src={social?.image?.[0]?.src ?? whatsapp_Icon} type={social.socialName}
                                           generatePath={social?.image?.[0]?.src ?? false}/>
                            </WhatsappShareButton>)
                        }
                        case 'EMAIL': {
                            return (<EmailShareButton
                                url={shareUrl}
                                subject={subject}
                                body={`${shareText} ${shareUrl}`}
                                key={social.order}
                            >
                                <ShareIcon
                                    src={social?.image?.[0].src ?? email_Icon}
                                    type={social.socialName}
                                    generatePath={social?.image?.[0]?.src ?? false}/>
                            </EmailShareButton>);
                        }
                        case 'ALTRO': {
                            return <div onClick={handleShare}>
                                <ShareIcon key={social.order} src={social?.image?.[0].src ?? other_Icon}
                                           type={social.socialName}
                                           generatePath={social?.image?.[0]?.src ?? false}/>
                            </div>
                        }
                        case 'TELEGRAM': {
                            return (<TelegramShareButton key={social.order} url={shareUrl} title={shareText}>
                                <ShareIcon src={social?.image?.[0]?.src ?? telegram_Icon} type={social.socialName}
                                           generatePath={social?.image?.[0]?.src ?? false}/>
                            </TelegramShareButton>)
                        }
                        case 'LINKEDIN': {
                            return (<LinkedinShareButton key={social.order} url={shareUrl} title={shareText}>
                                <ShareIcon src={social?.image?.[0]?.src ?? linkedin_Icon} type={social.socialName}
                                           generatePath={social?.image?.[0]?.src ?? false}/>
                            </LinkedinShareButton>)
                        }
                        case 'INSTAGRAM': {
                            return <ShareIcon key={social.order} src={social?.image?.[0]?.src ?? instagram_Icon}
                                              type={social.socialName}
                                              generatePath={social?.image?.[0]?.src ?? false}/>
                        }
                    }
                })}
            </div>
        </div>
    )
};

export default ShareBoard;