import React, {Fragment, Suspense, useState} from "react";
import {catalogSlickConfig, mzUseNavigate, useTranslationWord} from "../../utils";
import {
    CMSSection, getNavPathFromType,
    JSection,
    JSlider, NavigationTypes, RewardModal,
    SLIDER_4X,
} from "@jnext/commons";
import {observer} from "mobx-react";
import {LoyaltyCollectionCardItem} from "../CatalogList/LoyaltyCollectionCardItem";
import {Item, RedemptionResponseStatusEnum, RedemptionRewardCmsDTO} from "@jnext/ts-axios-mz";
import {ProductPreviewDto} from "@jnext/ts-axios-formatdigitalcollection";
import {Options} from "../CatalogSlider/LoyaltyCollectionSlider";
import {Item as ItemContest} from "@jnext/ts-axios-contestformat";
import {RedemptionService} from "../../service/RedemptionService";
import {CTAType} from "@jnext/commons/src/models";


function RewardAwardedSlider({ section, redemptions }: {section?: CMSSection,redemptions: RedemptionRewardCmsDTO[] }) {

    const navigate = mzUseNavigate();
    const translateWord = useTranslationWord();
    const sectionOptions = section?.options as unknown as Options;
    const {slidesToScroll} = sectionOptions;
    const [showModal, setShowModal]= useState<boolean>(false);
    const [productItem, setProductItem]= useState<Item>();
    const [btnProps, setBtnProps]= useState<{
        title?: string;
        action?: () => void;
        type?: CTAType
    }>();

    const getRetrieveRedemptionDetails = async(redemptionId: string)=> {
        await RedemptionService.retrieveRedemptionDetails(redemptionId).then(res =>{
            if(res && (res?.status === RedemptionResponseStatusEnum.WaitingForUser)){
                setBtnProps({
                    action: ()=> navigate(getNavPathFromType(NavigationTypes.REDEEM, {redemptionId})),
                    title: translateWord('claimThePrize')
                } )
            }
        })
            .catch((e)=> console.log('error RewardMgmModal: ', e))
            .finally(()=> {
                setShowModal(true)
            });
    }

    return (<>
        {!!section && section?.enabled && <Suspense fallback={null}>
            <JSection {...section}>
                <JSlider {...catalogSlickConfig(SLIDER_4X, {slidesToScroll: slidesToScroll})}>
                    {redemptions?.map((red: RedemptionRewardCmsDTO, key: number) =>{
                        const items = red.items;
                        const redemptionId = red.redemptionLogicId;
                        return(<Fragment key={key}>
                            {items?.map((el: Item, i) => {
                                return(<><LoyaltyCollectionCardItem
                                        key={i}
                                        data={el.product as ProductPreviewDto}
                                        pricePosition={'TOPRIGHT'}
                                        showTag={false}
                                        isRewardMgm={true}
                                        onClick={async() => {
                                            if(redemptionId){
                                                setProductItem(el);
                                                await getRetrieveRedemptionDetails(redemptionId)
                                            }
                                        }}
                                    />
                                    </>
                                )
                            })}
                        </Fragment>)
                    })}
                </JSlider>
            </JSection>
            {
                showModal && (
                    <RewardModal
                        onClose={() => setShowModal(false)}
                        rewardItem={productItem as ItemContest}
                        text={productItem?.product?.shortDescription}
                        btnProps={btnProps}
                    />
                )
            }
        </Suspense>
        }
    </>)
}

export default observer(RewardAwardedSlider);