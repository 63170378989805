import React from "react";
import { Col, Row } from "antd";
import { JButton } from "@jnext/commons";
import { CatalogPaginationType } from "../../pages/Catalog/CatalogPage";
import { Pagination } from "../Pagination";

interface CatalogPaginationProps {
    page: number,
    setPage: (pageNr: number) => void,
    totalPage: number,
    paginationType: CatalogPaginationType,
}

export const CatalogPagination: React.FC<CatalogPaginationProps> = ({page, setPage, totalPage, paginationType}) => {

    if( !page || (totalPage <= 1) ) {
        return <></>;
    }

    return (
        <Row justify={'center'}>
            <Col span={24}>
                <Pagination
                    page={page}
                    totalPage={totalPage}
                    updatePagination={(pageNr) => {
                        setPage(pageNr);
                    }}
                />
            </Col>
        </Row>
    );
}