import {Item} from "@jnext/ts-axios-mz";
import {CMSSection, getProductImage} from "@jnext/commons";
import {StarOutlined} from "@ant-design/icons";
import {generatePathUrl, mzUseNavigate, useTranslationWord} from "../../../../utils";
import React from "react";
import {EventResponse} from "@jnext/ts-axios-eventformat";

interface ProductCardProps {
    item: Item
    bodyCms?: CMSSection;
    onClick: () => void;
}

export const ProductCard: React.FC<ProductCardProps> = ({ item, bodyCms, onClick }) => {
    const thumbnailImage = getProductImage(item?.product);
    const translateWord = useTranslationWord();

    return (
        <div className={'product-row'} key={item?.product?.logicId} onClick={() => {onClick()}}>
            <div className={'product-main-data'}>
                {
                    thumbnailImage &&
                    <img className={'product-thumbnail'}
                         src={generatePathUrl(thumbnailImage)} aria-hidden={true}/>
                }

                {
                    !thumbnailImage &&
                    <figure/>
                }

                <div className={'product-info'}>
                    <div className={'product-brand'}>{item?.product?.brands?.[0]?.title}</div>
                    <div className={'product-name'}>
                        <div dangerouslySetInnerHTML={{__html: item?.product?.title ?? ''}}/>

                        {
                            item?.vouchers && <div>
                                {
                                    item?.vouchers?.map((el, i) => (
                                        <a key={i} href={el.content}
                                           target={'_blank'}
                                           title={el?.content}
                                           aria-label={`${translateWord('GO_TO')} ${el?.content}`}
                                           className={'voucher-link'}>{bodyCms?.table?.voucherLink?.label}</a>
                                    ))
                                }
                            </div>
                        }

                    </div>

                    {
                        (item?.value?.wallets || item?.value?.additionalCost) && (
                            <div className={'stars-container mobile'}>
                                <StarOutlined/>
                                <label>-</label>
                            </div>
                        )
                    }

                </div>
            </div>
            <div className={'product-other-data'}>
                <div className={'quantity-container'}>
                    <label>{bodyCms?.table?.quantity?.label}</label>
                    <div
                        className={'quantity'}>{item?.quantity}</div>
                </div>
            </div>
        </div>
    )
};