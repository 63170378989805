import {getProductImage, JCMSCTA, JCMSImage, JHTML} from "@jnext/commons";
import {CatalogItemValue} from "commons/ItemValue/CatalogItemValue";
import {QuantityInput} from "pages/ProductDetail/ProductContent/components/FormSection/QuantityForm";
import React, {FC, useEffect, useMemo, useState} from "react";
import {CartProductsListSection} from "../../../../models";
import {BasketItemStatusCodeEnum, GetBasketResponseItem} from "@jnext/ts-axios-formatdigitalcollection";
import {useTranslationWord} from "../../../../../../utils";
import {getMinAvailability} from "../../../../../ProductDetail/ProductContent/components";
import {ProductContentItem} from "../../../../../ProductDetail/ProductContent";

export interface CartProductItemRenderProps {
    image: JSX.Element,
    brands: JSX.Element,
    title: JSX.Element,
    removeItemCTA: JSX.Element,
    quantityForm: JSX.Element,
    price: JSX.Element
}

export interface CartProductItemRenderDataProps {
    section: CartProductsListSection,
    item: GetBasketResponseItem & ProductContentItem,
    children: (elements: CartProductItemRenderProps) => JSX.Element,
    removeItem: () => void;
    updateItemQuantity: (quantity: number) => void;

}

export const CartProductItemRenderData: FC<CartProductItemRenderDataProps> = props => {

    const translateWord = useTranslationWord();
    const [quantity, setQuantity] = useState<number>(1);
    //set availability props
    const maxProduct: number =  10;
    const maxAvailabilitySelect = useMemo(()=> getMinAvailability(props.item.availability, props.item.obtainableQuantity,  maxProduct),[props])
    const maxAvailabilityDisplay = useMemo(()=> getMinAvailability(props.item.availability, props.item.obtainableQuantity, props.item.availabilityThreshold),[props])


    useEffect(() => {
        if(props.item.quantity){
            setQuantity(props.item.quantity)
        }
    }, [props.item.quantity]);

    /*  const quantityField = useMemo(
          getQuantityField(props.section as any, props.item.availability, props.item.quantity),
          [props.item.availability, props.item.quantity]
      )*/
    const imgSrc = getProductImage(props.item?.cms) || '';

    const removeItemCTA = useMemo(() => props.section?.cta?.find((cta: any) => cta.scope === 'REMOVE_ITEM'), [props.section])
    const renderElements: CartProductItemRenderProps = useMemo(() => ({
        image: (
            <div className="cart-product-item-image">
                <JCMSImage image={[{src: imgSrc}]} usePlaceholder/>
            </div>
        ),
        brands: (
            <div className="cart-product-item-info-brands">
                {
                    props.item.brands?.map(b => b.title).join(' ')
                }
            </div>
        ),
        title: (
            <div className="cart-product-item-info-title">
                {
                    <JHTML.Title html={props.item.cms?.title || ''} level={5}/>
                }
                {
                    props.item.statusCode !== BasketItemStatusCodeEnum.Valid &&
                    <div
                        className={'not-available'}>{translateWord(`CART_PRODUCT_STATUS_${props.item.statusCode}`)}</div>
                }
            </div>
        ),
        removeItemCTA: (
            <div className="cart-product-item-info-actions">
                {
                    removeItemCTA && (
                        <JCMSCTA {...removeItemCTA} action={() => props.removeItem()}/>
                    )
                }
            </div>
        ),
        quantityForm: (
            <div className="cart-product-item-quantity">
                <QuantityInput
                    quantity={quantity}
                    setQuantity={(e)=> {
                        const newQuantity = e;
                        setQuantity(e);
                        if(newQuantity && maxAvailabilitySelect && (newQuantity <= maxAvailabilitySelect)) {
                            props.updateItemQuantity(parseInt(newQuantity.toString()));
                        }
                    }}
                    section={props.section as any}
                    maxAvailabilitySelect={maxAvailabilitySelect}
                    maxAvailabilityDisplay={maxAvailabilityDisplay}
                />
                {/* <JFormCompositor
                    fields={quantityField}
                    formId="quantity"
                    onFieldsChange={(fields) => {
                        const newQuantity = fields?.[0]?.value;

                        if(newQuantity) {
                            props.updateItemQuantity(parseInt(newQuantity));
                        }
                    }}
                    overrideColsProps={{
                        quantity: {
                            span: 24
                        }
                    }}
                />*/}
            </div>
        ),
        price: (
            <div className="cart-product-item-price">
                {
                    props.item.redemptionModality && (
                        <CatalogItemValue redemptionModality={props.item.redemptionModality}/>
                    )
                }
            </div>
        )
    }), [quantity, removeItemCTA, props])

    return props.children(renderElements)
}