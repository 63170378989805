import { Typography } from 'antd';
import { FC, Fragment, useMemo } from 'react';
import { ProductContentDescrSectionTypes, ProductContentDescriptionsSection } from '../../models';

import { JAccordion, JAccordionItem, JHTML } from '@jnext/commons';
import {ProductContentItem} from "../ProductContent";

const { Text } = Typography

export interface DescriptionsSectionProps extends Pick<Partial<ProductContentItem>, (
    'brands' |
    'longDescription' |
    'termsAndConditions'
)> {
    section: ProductContentDescriptionsSection
}

export const DescriptionsSection: FC<DescriptionsSectionProps> = (props) => {
    const accordionItems: JAccordionItem[] = useMemo(
        getAccordionContentItem(props, props.section.sections?.filter(s => s.enabled !== false)) || [],
        [props.longDescription, props.termsAndConditions, props.brands]
    )


    return (
        <JAccordion items={accordionItems} />
    )
}

function getAccordionContentItem(
    props: DescriptionsSectionProps,
    sections: ProductContentDescriptionsSection['sections']
): () => JAccordionItem[] {
    const brands = props.brands || []
    return () => {
        if (!sections) return [];

        const items: JAccordionItem[] = []
        for (const section of sections) {
            const title = section.title || ''
            if (
                section.type === ProductContentDescrSectionTypes.PRODUCT_BRAND_DESCRIPTION &&
                brands?.length
            ) {
                items.push({
                    title,
                    content: (
                        <Fragment>
                            {
                                brands.map((brand, i) => (
                                    <Fragment key={i}>
                                        {brands.length > 1 && (
                                            <Fragment>
                                                <Text>{brand.title?.toUpperCase() || '-'}</Text>
                                                <br />
                                            </Fragment>
                                        )}
                                        <JHTML.Text html={brand.description || ''} />
                                    </Fragment>
                                ))
                            }
                        </Fragment>
                    )
                })
            }
            if (
                section.type === ProductContentDescrSectionTypes.PRODUCT_ITEM_DESCRIPTION &&
                props.longDescription
            ) {
                items.push({
                    title,
                    content: <JHTML.Text html={props.longDescription || '-'} />
                })
            }
            if (
                section.type === ProductContentDescrSectionTypes.PRODUCT_TERMS_CONDITIONS &&
                props.termsAndConditions
            ) {
                items.push({
                    title,
                    content: <JHTML.Text html={props.termsAndConditions || '-'} />
                })
            }
        }
        return items
    };
}
