import React from "react";
import {HistoryRewardType} from "../../../../type/historyReward";
import HistoryRewardsContent from "./HistoryRewardsContent";

export const HistoryRewardsContest = () => {
    return <HistoryRewardsContent type={HistoryRewardType.CONTEST} />;
}


export const HistoryRewardsCatalog = () => {
    return <HistoryRewardsContent type={HistoryRewardType.CATALOG} />;
}

export const HistoryRewardsCatalogLC = () => {
    return <HistoryRewardsContent type={HistoryRewardType.CATALOG_LOYALTY_COLLECTION} />;
}

export const HistoryRewardsCatalogDW = () => {
    return <HistoryRewardsContent type={HistoryRewardType.CATALOG_DIGITAL_WALLET} />;
}