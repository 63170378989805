import React, { useMemo } from "react";
import { CMSSection } from "@jnext/commons";
import { SolutionProjectImage } from "../../../commons";
import {getCluster, SentencesComposeComponent} from "../../../utils";
import { useConsumerStore } from "store/consumerStore";
import { observer } from "mobx-react";

export enum UserSectionType {
    'HERO_SECTION_USER_FULL' = 'HERO_SECTION_USER_FULL',
    'HERO_SECTION_USER_NAME' = 'HERO_SECTION_USER_NAME',
    'HERO_SECTION_USER_CLUSTER' = 'HERO_SECTION_USER_CLUSTER',
}

export const UserSection = observer(({ section, key, inNavbar }: { section: CMSSection, key?: number, inNavbar?: boolean }) => {
    const { consumerInfo } = useConsumerStore();
    const clusterCms = useMemo(() => getCluster(consumerInfo)?.cmsClusterConfig, [consumerInfo]);

    if (!section?.enabled) return <React.Fragment key={key} />;

    const getTitle = (title?: string, name?: string, clusterTitle?: string) => {
        return <SentencesComposeComponent className="userSection" text={title} options={{ name: name, cluster: clusterTitle }} />
    }

    const getSectionUserDom = (sections?: CMSSection[] | undefined) => {
        let section: CMSSection | undefined = {}
        if (consumerInfo?.name && clusterCms?.title) {
            section = sections?.find(section => section?.type === UserSectionType.HERO_SECTION_USER_FULL);
        }
        if (consumerInfo?.name && !clusterCms?.title) {
            section = sections?.find(section => section?.type === UserSectionType.HERO_SECTION_USER_NAME);
        }
        if (!consumerInfo?.name && clusterCms?.title) {
            section = sections?.find(section => section?.type === UserSectionType.HERO_SECTION_USER_CLUSTER);
        }
        return getTitle(section?.title, consumerInfo?.name, clusterCms?.title)
    }

    return (
        <React.Fragment key={key}>
            {clusterCms?.imageRef && (
                <SolutionProjectImage name={clusterCms?.imageRef} className="coccarda" />
            )}
            {getSectionUserDom(section?.sections)}
        </React.Fragment>
    )
})