import { ContentioApi } from "@jnext/ts-axios-corecontentio";
import axios, { AxiosRequestConfig } from "axios";
import { HttpService } from "./HttpService";

export class ResourceService {


    static async download(fileRef: string, requestConfig?: AxiosRequestConfig) {
        const instance = new axios.Axios(requestConfig || {})
        try{
            const contentioApi: ContentioApi = new ContentioApi(
                undefined,
                HttpService.servicePath("core-contentio"),
                instance as any
            );
            const requestParams: [string] = [fileRef]
            return contentioApi.privateDownload(...requestParams);
        }catch (e) {
            console.log('error download')
            return;
        }

    };

    static async uploadPrivateFile(files: File[], moduleName: string = 'test', middlePath : string = 'documents/default'){
        // @ts-ignore
        const contentioApi: ContentioApi = new ContentioApi(undefined,  HttpService.servicePath(this.module), axios);
        const res = await contentioApi.privateUpload( moduleName,
            files,
            middlePath);
        return res?.data
    }

}