import {
    AnalyticsEventsEnum, CMSConfiguration, getNavPathFromType,
    JFieldDTO, NavigationTypes,
} from '@jnext/commons';
import {FC, Fragment, useEffect, useMemo, useState} from 'react';
import './style/T1.scss';
import Helmet from "react-helmet";
import {
        mzUseNavigate,
    populateFieldValues,
    trackingEventsMaps,
    useTranslationWord
} from "../../utils";
import {
    FormWrapperOnSubmit,
    GenericPageFormWrapper,
    GenericPageFormWrapperProps
} from "../GenericPageForm/GenericPageFormWrapper";
import {useCMSStore} from "../../store/cmsStore";
import {useParams} from "react-router-dom";
import {CartService} from "../../service/CartService";
import {BasketTypeEnum, FieldDto} from "@jnext/ts-axios-formatdigitalcollection";
import {VasService} from "../../service/VasService";

const INVALID_FORM_CONFIGURATION = 'INVALID_FORM_CONFIGURATION';

interface CheckoutVasProps {
    isThankyouPage?: boolean
}

export const CheckoutVas: FC<CheckoutVasProps> = (props) => {

    // Ottiene le pagine dal CMS store
    const { pages } = useCMSStore();
    const navigate = mzUseNavigate();

    const {initiativeLogicId, catalogGroupProductLogicId} = useParams();

    const translate = useTranslationWord();
    const [disableForm, setDisableForm] = useState<boolean>(false);
    const [fields, setFields] = useState<FieldDto[]>([]);
    const [loading, setLoading] = useState<boolean>(true)
    const [errorMessage, setErrorMessage] = useState<string>()

    // Usa useMemo per memorizzare la configurazione CMS della pagina VAS
    const cmsConfig: CMSConfiguration | undefined = useMemo(() => pages?.vasCheckout, [pages?.vasCheckout]);
    const sectionTitle = useMemo(() => cmsConfig?.section?.title, [])
    const sectionText = useMemo(() => cmsConfig?.section?.text, [])


    useEffect(() => {
        (async () => {
            if(initiativeLogicId && catalogGroupProductLogicId){
                const data = await VasService.getPurchaseParametersForProduct(initiativeLogicId, catalogGroupProductLogicId);

                //TODO: controllare i fields e mapparli correttamente
                const fieldsResponse = data?.fields as unknown as FieldDto[];
                console.log(JSON.stringify(fieldsResponse));

                const unorderedFields = (fieldsResponse && fieldsResponse.length > 0 ? fieldsResponse.map(field => ({
                    ...field,
                    type: field?.cmsConfig?.type as string,
                    cmsConfig: {
                        ...field?.cmsConfig,
                        "hurlId": field?.hurlId,
                    }
                })) : []);

                const fields = unorderedFields.sort((a, b) => {
                    // @ts-ignore
                    return a?.cmsConfig?.order - b?.cmsConfig?.order;
                });

                if (!fields || fields?.length === 0) {
                    setErrorMessage(translate(INVALID_FORM_CONFIGURATION));
                    setLoading(false);
                    return;
                }
                setFields(fields as JFieldDTO[]);

                setLoading(false);
            }
            else if(props.isThankyouPage){
                setLoading(false);
            }
        })();
    }, []);

    const onSubmit: FormWrapperOnSubmit = async (fieldsPayload) => {

        const purchaseParameters = fieldsPayload.map((field) => {

            return {
                logicId: field.hurlId,
                value: field.values?.[0],
                basketItemQuantity: fields.find(fieldOrigin => fieldOrigin.hurlId === field.hurlId)?.externalId
            }
        })

        try {
            setDisableForm(true)
            trackingEventsMaps(AnalyticsEventsEnum.REGISTRATION_FORM);
            const item = {catalogGroupProductLogicId: catalogGroupProductLogicId}

            //TODO: implementare il metodo update correttamente
            await CartService.update(item,initiativeLogicId as string, BasketTypeEnum.DirectPurchase, 1, purchaseParameters)
                .then((res) => {
                    return navigate(getNavPathFromType(NavigationTypes.CHECKOUT as string, {
                        initiativeLogicId: initiativeLogicId,
                        basketType: BasketTypeEnum.DirectPurchase
                    }))
                })
                .catch((e) => {
                    console.log('e', e)
                });

        } catch (error) {

        } finally {
            setDisableForm(false)
        }
    }

    const genericPageFormProps = useMemo(() => {
        const tmp: GenericPageFormWrapperProps = {
            cmsConfig: cmsConfig,
            context: 'CHECKOUT_WITH_CONFIRM',
            fields: populateFieldValues([...fields] as JFieldDTO[]) as JFieldDTO[],
            onSubmit,
            disableForm,
            options: cmsConfig?.section?.options,
            formProps: {
                providerProps: {
                    showFieldsLabel: true
                }
            }
        };

        return tmp;

    }, [onSubmit])

    return (
        <Fragment>

            {
                cmsConfig?.page?.options?.metaTags?.title
                    ?
                    <Helmet>
                        <title>{cmsConfig?.page?.options?.metaTags?.title}</title>
                        {cmsConfig?.page?.options?.metaTags?.description && <meta name="description" content={cmsConfig?.page?.options?.metaTags?.description} />}
                    </Helmet>
                    :
                    <Helmet>
                        <title></title>
                        <meta name="description" content={''} />
                    </Helmet>
            }

            {
                loading || (
                    <GenericPageFormWrapper
                        {...genericPageFormProps}
                        showThankYouPage={props.isThankyouPage}
                        errorCode={errorMessage}
                        title={sectionTitle}
                        additionalText={sectionText}
                    />
                )
            }
        </Fragment>

    )
}