import React, {useEffect, useState} from "react";
import {CatalogService, GetProductsFilters} from "../../service/CatalogService";
import {CMSSection, scrollTo} from "@jnext/commons";
import {CatalogType} from "../../type/catalogTypes";
import {
    GetProductsPreviewPagedResponse,
    PlanStatusEnum,
    ProductPreviewDto
} from "@jnext/ts-axios-formatdigitalcollection";
import {CatalogPaginationType, CMSCatalogOptions} from "../../pages/Catalog/CatalogPage";
import {CatalogBody} from "./CatalogBody";
import {CatalogPurchaseBody} from "./CatalogPurchaseBody";

var _ = require('lodash');

export interface Pagination {
    hasPagination?: boolean,
    type?: CatalogPaginationType,
    size?: number,
}

export interface CatalogProps {
    colNumbers: number;
    pagination?: Pagination,
    filters?: GetProductsFilters,
    onProductClick: (productId: string, refInitiativeRegistryLogicId?: string) => void,
    setPurchased?: (purchase: ProductPreviewDto ) => void,
    containerElement: HTMLElement,
    type: CatalogType;
    initiativeLogicId: string;
    isPrivate: boolean;
    section: CMSSection;
    setInitLogicId?: (initiativeLogicId: string) => void;
}

// Definisce il componente CatalogList che accetta diverse proprietà per configurare la visualizzazione del catalogo
export const CatalogList: React.FC<CatalogProps> = ({
                                                        colNumbers, // Numero di colonne da visualizzare
                                                        pagination, // Configurazione della paginazione
                                                        filters, // Filtri applicati ai prodotti
                                                        onProductClick, // Funzione chiamata quando un prodotto viene cliccato
                                                        containerElement, // Elemento HTML che contiene il catalogo
                                                        type, // Tipo di catalogo (es. digital wallet, loyalty collection)
                                                        initiativeLogicId, // ID logico dell'iniziativa
                                                        isPrivate, // Indica se il catalogo è privato
                                                        section,
                                                        setPurchased, // Funzione per impostare il prodotto acquistato
                                                        setInitLogicId
                                                    }) => {
    const rewardSpan = 24 / colNumbers; // Calcola la larghezza delle colonne

    const paginationType: CatalogPaginationType = pagination?.type ? pagination?.type : 'LAZY';

    const [catalog, setCatalog] = useState<GetProductsPreviewPagedResponse>({ results: [] }); // Stato per i dati del catalogo
    const [products, setProducts] = useState<ProductPreviewDto[]>(); // Stato per i dati dei prodotti
    const [isProductVas, setIsProductVas] = useState<boolean>(false); // Stato  per indicare la struttura vas

    const [page, setPage] = useState<number>(0); // Stato per la pagina corrente
    const [lastPage, setLastPage] = useState<number>(); // Stato per l'ultima pagina caricata
    const [totalPage, setTotalPage] = useState<number>(); // Stato per il numero totale di pagine

    const [lastFilters, setLastFilters] = useState<GetProductsFilters>(); // Stato per gli ultimi filtri applicati
    const [loadTime, setLoadTime] = useState<number>(0); // Stato per il numero di caricamenti

    useEffect(() => {
        const filterEquals = _.isEqual(lastFilters, filters); // Confronta i filtri attuali con gli ultimi filtri

        // Se i filtri sono gli stessi e la pagina è la stessa, non fare nulla
        if (filterEquals && lastPage === page) {
            return;
        }

        // Se i filtri sono cambiati, resetta la pagina
        let newPage = page;
        if (!filterEquals) {
            newPage = 0;
        }

        // Salva lo stato degli ultimi filtri e dell'ultima pagina
        setLastFilters(filters);
        setPage(newPage);
        setLastPage(newPage);

        (async () => {
            // Ottiene i prodotti dal servizio CatalogService
            const data = await CatalogService.getProducts(initiativeLogicId, !isProductVas ? filters || {}: {}, {
                page: newPage,
                pageSize: pagination?.size || 9
            }, isPrivate);

            // Se la paginazione è di tipo lazy load, aggiungi i nuovi dati ai vecchi
            if (data && data?.results && catalog && catalog?.results && paginationType == 'LAZY') {
                data.results?.splice.apply(data, [0, 0, ...catalog.results]);
            }

            setCatalog(data as GetProductsPreviewPagedResponse);

            // Controlla se ci sono abbonamenti
            const products = data?.results?.filter(el => el.productType === 'SERVICE');

            if(!!products && products?.length>0){
                setIsProductVas(!!products?.find(product => !!product?.serviceDetails))
                setProducts(products);

                // Controlla se c'è un abbonamento acquistato e aggiorna il catalogo
                const purchased = products?.find(el => el.serviceDetails?.status === PlanStatusEnum.Purchased);

                if(purchased && setPurchased){
                    setPurchased(purchased);
                }
            }


            // Imposta i dati della paginazione
            setTotalPage(data?.pagination?.pageCount);

            // Re-centra l'elemento nella schermata
            if (containerElement) {
                if (loadTime != 0) {
                    scrollTo(containerElement);
                }

                setLoadTime(loadTime + 1);
            }
        })();
    }, [filters, page]);


    return (
        <div className={'catalog-list'}>
            {isProductVas ?
                <CatalogPurchaseBody
                    filters={filters}
                    setInitLogicId={setInitLogicId}
                    containerElement={containerElement}
                    page={page}
                    setPage={setPage}
                    onProductClick={onProductClick}
                    rewardSpan={rewardSpan}
                    type={type}
                    products={products}
                    pagination={pagination}
                    paginationType={paginationType}/>
                :
                <CatalogBody
                    page={page}
                    setPage={setPage}
                    onProductClick={onProductClick}
                    totalPage={totalPage}
                    rewardSpan={rewardSpan}
                    type={type}
                    catalog={catalog}
                    pagination={pagination}
                    paginationType={paginationType}
                    section={section}
                />}
        </div>
    )
}