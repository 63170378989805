import {CMSCTA, CMSPage, CMSSection} from "@jnext/commons";
import {
    GetProductResponse,
    MediaTypeEnum,
    ProductDetailAttributeDto,
    ProductDetailMediaContentDto,
    ProductDetailRedemptionModalityDto
} from "@jnext/ts-axios-formatdigitalcollection";

export interface Product extends GetProductResponse {
}

export interface ProductRedemptionModality extends ProductDetailRedemptionModalityDto {
}

export interface ProductAttribute extends ProductDetailAttributeDto {
}

export interface ProductMediaContent extends ProductDetailMediaContentDto {
}

export type ProductMediaType = MediaTypeEnum

export enum ProductContexts {
    DIGITAL_WALLET = 'digital-wallet',
    COLLECTION = 'loyalty-collection'
}

export interface ProductRelatedProduct {
    logicId: string;
    attributes: ProductAttribute[];
}

export interface ProductTag {
    position?: string;
    title: string;
    value: string;
    imgUrl: string;
}

// CMS models

export interface ProductCMS {
    page: CMSPage,
    section: ProductMainSection
}

export enum ProductMainSectionTypes {
    LOYALTY_COLLECTION = 'LOYALTY_COLLECTION',
    DIGITAL_WALLET = 'DIGITAL_WALLET',
}

// Section types enum

export enum ProductSectionTypes {
    PRODUCT_MEDIA = 'PRODUCT_MEDIA',
    PRODUCT_CONTENT = 'PRODUCT_CONTENT',
}
export enum ProductMediaDisplayType {
    CONTAIN = 'CONTAIN',
    COVER = 'COVER',
}
export enum ProductContentSectionTypes {
    PRODUCT_BRAND_INFO = 'PRODUCT_BRAND_INFO',
    PRODUCT_MAIN_INFO = 'PRODUCT_MAIN_INFO',
    PRODUCT_CATEGORIES = 'PRODUCT_CATEGORIES',
    PRODUCT_FORM = 'PRODUCT_FORM',
    PRODUCT_DESCRIPTIONS = 'PRODUCT_DESCRIPTIONS',
    PRODUCT_DELIVERY_INFO = 'PRODUCT_DELIVERY_INFO'
}

export enum ProductFormSectionTypes {
    PRODUCT_FORM_ATTRIBUTES = 'PRODUCT_FORM_ATTRIBUTES',
    PRODUCT_FORM_REDEMPTIONS = 'PRODUCT_FORM_REDEMPTIONS',
    PRODUCT_FORM_QUANTITY = 'PRODUCT_FORM_QUANTITY',
    PRODUCT_FORM_REDEEM = 'PRODUCT_FORM_REDEEM',
}

export enum ProductContentDescrSectionTypes {
    PRODUCT_BRAND_DESCRIPTION = 'PRODUCT_BRAND_DESCRIPTION',
    PRODUCT_ITEM_DESCRIPTION = 'PRODUCT_ITEM_DESCRIPTION',
    PRODUCT_TERMS_CONDITIONS = 'PRODUCT_TERMS_CONDITIONS',
}


// Form Sections

export interface ProductFormRedemptionModesSection extends CMSSection {
    type: ProductFormSectionTypes.PRODUCT_FORM_REDEMPTIONS,
    options: {
        pointLabel: string
    }
}

export interface ProductFormAttributesSection extends CMSSection {
    type: ProductFormSectionTypes.PRODUCT_FORM_ATTRIBUTES
}

export interface ProductFormQuantitySection extends CMSSection {
    type: ProductFormSectionTypes.PRODUCT_FORM_QUANTITY,
    title: string,
    text: string
}

export interface ProductFormSectionsRedeemSubmitCTASection extends CMSCTA {
    scope: 'SUBMIT'
}

export interface ProductFormSectionsRedeemCloseCTASection extends CMSCTA {
    scope: 'CLOSE'
}

export interface ProductFormRedeemSection extends CMSSection {
    type: ProductFormSectionTypes.PRODUCT_FORM_REDEEM,
    title: string
    cta: [ProductFormSectionsRedeemCloseCTASection, ProductFormSectionsRedeemSubmitCTASection]

}

export type ProductFormSections = (
    ProductFormRedemptionModesSection |
    ProductFormAttributesSection |
    ProductFormQuantitySection |
    ProductFormRedeemSection
    )

export interface ProductFormSectionSubmitCTA extends CMSCTA {
    scope: 'SUBMIT'
}

export interface ProductFormSectionRedeemCTA extends CMSCTA {
    scope: 'REDEEM'
}

export interface ProductFormSectionLoginCTA extends CMSCTA {
    scope: 'LOGIN'
}

export type ProductFormSectionCTA =
    ProductFormSectionSubmitCTA
    | ProductFormSectionLoginCTA
    | ProductFormSectionRedeemCTA

export interface ProductFormSection extends CMSSection {
    type: ProductContentSectionTypes.PRODUCT_FORM,
    sections?: ProductFormSections[],
    cta: ProductFormSectionCTA[]

}

// Description sections 

export interface ProductBrandDescriptionSection extends CMSSection {
    type: ProductContentDescrSectionTypes.PRODUCT_BRAND_DESCRIPTION,
    title: string
}

export interface ProductItemDescriptionSection extends CMSSection {
    type: ProductContentDescrSectionTypes.PRODUCT_ITEM_DESCRIPTION,
    title: string
}

export interface ProductTermsConditionsSection extends CMSSection {
    type: ProductContentDescrSectionTypes.PRODUCT_TERMS_CONDITIONS,
    title: string
}

export type ProductContentDescriptionsSections = (
    ProductBrandDescriptionSection |
    ProductItemDescriptionSection |
    ProductTermsConditionsSection
    )

export interface ProductContentDescriptionsSection extends CMSSection {
    type: ProductContentSectionTypes.PRODUCT_DESCRIPTIONS,
    sections?: ProductContentDescriptionsSections[]
}

// Content sections

export interface ProductContentBrandInfoSection extends CMSSection {
    type: ProductContentSectionTypes.PRODUCT_BRAND_INFO,
}

export interface ProductContentMainInfoSection extends CMSSection {
    type: ProductContentSectionTypes.PRODUCT_MAIN_INFO,
}

export interface ProductContentCategoriesSection extends CMSSection {
    type: ProductContentSectionTypes.PRODUCT_CATEGORIES,
}

export interface ProductContentDeliverySection extends CMSSection {
    type: ProductContentSectionTypes.PRODUCT_DELIVERY_INFO,
}

export type ProductContentSections = (
    ProductContentBrandInfoSection |
    ProductContentMainInfoSection |
    ProductContentCategoriesSection |
    ProductFormSection |
    ProductContentDescriptionsSection |
    ProductContentDeliverySection
    )

export interface ProductContentSection extends CMSSection {
    type: ProductSectionTypes.PRODUCT_CONTENT,
    sections: ProductContentSections[]
}

// Produc sections

export interface ProductMediaSection extends CMSSection {
    type: ProductSectionTypes.PRODUCT_MEDIA,
    options: {
        imageType: ProductMediaDisplayType,
    }
}

export type ProductSections = (ProductMediaSection | ProductContentSection)

export interface ProductSection extends CMSSection {
    sections: ProductSections[]
    type: ProductMainSectionTypes
}

export interface ProductMainSection extends CMSSection {
    sections: ProductSection[]
}



