import React, {FC, useEffect, useMemo, useState} from "react";
import {
    CMSSection,
    JSection,
    JSkeletonSlider,
} from "@jnext/commons";
import {VasService} from "../../service/VasService";
import {PlanStatusEnum, ProductPreviewDto} from "@jnext/ts-axios-formatdigitalcollection";
import {JCardSubscriptionPurchased} from "./Components/JCardSubscriptionPurchased";
import {JCardSubscription} from "./Components/JCardSubscription";
import "./vas.scss";

interface JVasSectionProps {
    section?: CMSSection;
}

export const JVasBoxSection: FC<JVasSectionProps> = ({section}) => {

    // Usa useMemo per memorizzare le opzioni della sezione
    const options = useMemo(() => section?.options, [section]);

    // Stato per memorizzare l'abbonamento
    const [subscriptions, setSubScriptions] = useState< ProductPreviewDto[]>();

    // Stato per memorizzare i prodotti
    const [products, setProducts] = useState< ProductPreviewDto[]>([]);

    useEffect(() => {
        (async () => {
            if(!!options?.logicId){
                // Recupera i prodotti VAS usando il logicId delle opzioni
                const data = await VasService.getVasProducts(options.logicId as string);
                if(data){
                    // Imposta l'abbonamento nello stato
                    await setSubScriptions(data.filter((product) => product?.serviceDetails?.status === PlanStatusEnum.Purchased));
                    // Imposta i prodotti nello stato
                   await setProducts(data.filter((product) => product?.serviceDetails?.status != PlanStatusEnum.Purchased));
                }
            }
        })();
    }, [options?.logicId]);

    return (
        <JSection {...section}>
            {/* Mostra uno scheletro di caricamento se non ci sono prodotti */}
            {!products || products.length === 0 && <JSkeletonSlider hasTitle={true} />}

            <div className={'subscription-container'}>
                {/* Mostra l'abbonamento acquistato */}
                {subscriptions &&  subscriptions.length > 0 && subscriptions?.map((subscription, key) =>
                    <JCardSubscriptionPurchased key={key} product={subscription} section={section}/>
                )}

                {/* Mostra i paini d'abbonamento disponibili */}
                {products && products.length > 0 && <JCardSubscription products={products} section={section}/> }
            </div>
        </JSection>
    )
}