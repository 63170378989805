import React, {useMemo} from "react";
import {CMSFilter, CMSFilters, CMSPage, CMSSection, getNavPathFromType, HowTo, JPage, JSection} from "@jnext/commons";
import {Catalog} from "./Catalog";
import {mzUseNavigate} from "../../utils";
import {Navigate, useParams} from "react-router-dom";
import {useCMSStore} from "store/cmsStore";
import {CatalogType} from "../../type/catalogTypes";
import {Limits} from "./Limits";
import {CatalogService} from "../../service/CatalogService";
import MessageBox from "../../components/Messages/MessageBox";

export type CatalogPaginationType = 'STANDARD' | 'LAZY';

export interface CMSCatalogOptions {
    hasCategoryFilters: boolean;
    hasStandardFilters: boolean;
    pagination: boolean;
    paginationSize: number;
    paginationType: CatalogPaginationType;
}

export interface CatalogCMSFilters extends CMSFilters {
    brand: CMSFilter;
    freeText: CMSFilter;
    price: CMSFilter;
    tags: CMSFilter;
}


export interface CatalogPageProps {
    type: CatalogType;
    isPrivate: boolean;
}

export const CatalogPage: React.FC<CatalogPageProps> = ({type, isPrivate}) => {
    const {pages} = useCMSStore();
    const {initiativeLogicId: initiativeGetParam} = useParams();

    const initiativeLogicId = useMemo(() => {
        if (initiativeGetParam) {
            return initiativeGetParam;
        }

        // Get it from localStorage if not exists in GET
        const initiativeData = CatalogService.getCatalogInfo(type);
        return initiativeData?.initiatives?.[0]?.logicId;

    }, [initiativeGetParam]);


    const config = useMemo(() => pages?.catalog, [pages]);

    if (!config) {
        return <Navigate to='/404'/>
    }

    const section = useMemo(() => {

        // Search needed section
        const neededSection = config.section?.sections?.find(el => el.type == `INITIATIVE_${initiativeLogicId}`);

        // Fallback section
        const fallBackType = type == CatalogType.digitalWallet ? 'DIGITAL_WALLET' : 'LOYALTY_COLLECTION';
        const fallBackSection = config.section?.sections?.find(el => el.type == fallBackType);

        return neededSection ? neededSection : fallBackSection;

    }, [config]);

    const navigate = mzUseNavigate();

    const configPage = useMemo(() => {

        const newObj: CMSPage = JSON.parse(JSON.stringify(config.page));

        // Check banner section
        const banner = section?.sections?.find(el => el.type == 'BANNER');
        if(banner) {

            if(!newObj?.banner) {
                newObj.banner = {};
            }

            newObj.banner = {
                ...newObj.banner,
                ...banner
            };
        }

        return newObj;

    }, [config.page, section]);

    return <>
        <JPage {...configPage} enabled>
            <JSection>
                {
                    section && section?.enabled !== false && !!section?.sections?.length &&
                    <div className="catalogBody">
                        {
                            section?.sections?.map((el: CMSSection, i) =>
                                <div key={i}>
                                    {el.type == 'LIMITS' && el?.enabled && initiativeLogicId &&
                                        <Limits initiativeLogicId={initiativeLogicId!} section={el}/>}
                                    {el.type == 'CATALOG' && initiativeLogicId &&
                                        <Catalog initiativeLogicId={initiativeLogicId!} type={type} section={el} isPrivate={isPrivate} />}
                                    {el.type == 'HOW_TO' && <HowTo {...el} onNavigate={(scope, params) => {
                                        navigate(getNavPathFromType(scope, params));
                                    }}/>}
                                </div>
                            )
                        }
                    </div>

                }
            </JSection>
        </JPage>
    </>
}

