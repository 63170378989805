import {CMSSection, JRangePicker, JSelect, OptionDto} from "@jnext/commons";
import {Col, Row} from 'antd';
import locale from 'antd/es/date-picker/locale/it_IT';
import 'moment/locale/it';
import React, {useState} from "react";
import {FORMAT_DATE} from "../../../../utils";
import {EventApprovalStatusEnum, EventResponse} from "@jnext/ts-axios-eventformat";
import {PDAStatusLabels} from "./HistoryPDAInterface";

const _ = require('lodash');

interface FiltersHeaderProps {
    statuses: EventApprovalStatusEnum[];
    initiatives: { [key: string]: EventResponse };
    handleChange: any;
    cms?: CMSSection;
}

const FiltersHeader: React.FC<FiltersHeaderProps> = ({statuses, initiatives, handleChange, cms}) => {

    // filters values
    const [initiative, setInitiative] = useState<undefined | string>();
    const [status, setStatus] = useState<undefined | EventApprovalStatusEnum>();
    const [date, setDate] = useState<string[]>();

    return (
        <Row className="filter" gutter={[32, 12]}>
            <Col className="selectStyle" sm={24} md={8} xl={6}>
                <JSelect
                    value={initiative}
                    label={cms?.filters?.initiative?.label ?? ""}
                    onChange={(e: any) => {
                        if (e === 0) {
                            setInitiative(undefined);
                            return handleChange(undefined, status, date);
                        }
                        setInitiative(e);
                        handleChange(e, status, date);
                    }}
                    options={
                        [
                            {
                                id: "",
                                label: cms?.filters?.initiative?.placeholder[0],
                            } as OptionDto
                        ].concat(
                            Object.values(initiatives).map((el: EventResponse) => ({
                                id: el.logicId || '',
                                label: el.name || '-',
                            }))
                        )
                    }
                    placeholder={cms?.filters?.initiative?.label}
                />
            </Col>
            <Col className="selectStyle" sm={24} md={8} xl={6}>
                <JSelect
                    value={status}
                    label={cms?.filters?.status?.label ?? ""}
                    options={
                        [
                            {
                                id: "",
                                label: cms?.filters?.status?.placeholder[0],
                            } as OptionDto
                        ].concat(
                            statuses.filter(status => {
                                const state = _.startCase(_.toLower(status));
                                if (Object.keys(EventApprovalStatusEnum).includes(state)) {
                                    return status
                                }
                            }).map(el => {
                                return {
                                    id: el,
                                    label: PDAStatusLabels[el],
                                }
                            })
                        )
                    }
                    onChange={(e) => {
                        let name = statuses.find(c => c === e);
                        setStatus(name);
                        handleChange(initiative, name, date);
                    }}
                    placeholder={cms?.filters?.status?.label ?? ""}
                />
            </Col>
            <Col className="selectStyle" sm={24} md={8} xl={6}>
                <JRangePicker
                    showTime={false}
                    format={FORMAT_DATE}
                    label={cms?.filters?.date?.label}
                    onChange={(period: any) => {
                        const fromDate = period?.[0]?.format();
                        const toDate = period?.[1]?.format();
                        setDate([fromDate, toDate]);
                        handleChange(initiative, status, [fromDate, toDate]);
                    }}
                    placeholder={[cms?.filters?.date?.placeholder?.[0] || '', cms?.filters?.date?.placeholder?.[1] || '']}
                    locale={locale}
                />
            </Col>
        </Row>
    );
}

export default FiltersHeader;