import {Typography} from 'antd';
import React, {FC} from 'react';
import {ProductType} from "../../../../enums/ProductType";
import {useTranslationWord} from "../../../../utils";
import {CMSImage} from "@jnext/commons/src/models/CMSConfig";
import {useJCommonContext} from "@jnext/commons";

const { Text } = Typography

export interface DeliverySectionProps {
    productType?: string;
    deliveryDays?: number;
    genericDeliveryDays?: number
    icons?: CMSImage[]
}
export const DeliverySection: FC<DeliverySectionProps> = ({ productType, deliveryDays , genericDeliveryDays, icons}) => {

    const translateWord = useTranslationWord();
    const {getImageURL} = useJCommonContext();

    return (
        <div className="delivery-info">
            <div className={'icon-container'}>
                {
                    icons?.map((image, i) => (
                        <div key={i}>
                            {
                                image.src && (
                                    <img src={getImageURL(image.src)}  alt={'image'} aria-hidden={true}/>
                                )
                            }
                        </div>
                    ))
                }
            </div>
            <div className={'description-container'}>
                {
                    (productType ?? 'ITEM') === ProductType.PHYSICAL
                        ?
                        <Text>{ deliveryDays ? translateWord('deliveryDaysItemDescription', { count: deliveryDays }) : translateWord('deliveryDaysItemDescriptionGeneric', { count: genericDeliveryDays }) }</Text>
                        :
                        <Text>{ translateWord('deliveryDaysItemDescription_vouchers') }</Text>

                }
            </div>
        </div>
    )
}
