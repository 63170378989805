import {CMSSection, JRangePicker, JSelect, OptionDto} from "@jnext/commons";
import {OrderOrderStatusEnum, RedemptionResponse} from "@jnext/ts-axios-mz";
import {RedemptionStatusEnum} from "@jnext/ts-axios-reward";
import {Col, Row} from 'antd';
import locale from 'antd/es/date-picker/locale/it_IT';
import moment from "moment";
import 'moment/locale/it';
import React, {useState} from "react";
import {HttpService} from "../../../../../service/HttpService";
import {FORMAT_DATE, useTranslationWord} from "../../../../../utils";

const _ = require('lodash');

const disabledDate = (current: any) =>{
    return current > moment();
}

interface FiltersHeaderProps {
    statuses: OrderOrderStatusEnum[];
    initiatives: RedemptionResponse[];
    handleChange:any;
    closeFilters: () => void;
    cms: CMSSection;
    showInitiative: boolean;
}

const FiltersHeader: React.FC<FiltersHeaderProps> = ({statuses, initiatives, handleChange, closeFilters, cms, showInitiative}) => {
    const translateWord = useTranslationWord();
    const initiativeFormat = HttpService.initiativeFormat;

    // filters values
    const [initiative, setInitiative] = useState<undefined|string>();
    const [status, setStatus] = useState<undefined|string>();
    const [date, setDate] = useState<string[]>();

    return(
        <Row className="filter" gutter={[32,12]}>
            <Col span={24}>
                <div className={'backButton'} onClick={closeFilters}>
                    &#60; &nbsp; {translateWord("goBack")}
                </div>
            </Col>
            {
                showInitiative &&
                <Col className="selectStyle" sm={24} md={8} xl={6}>
                    <JSelect
                        value={initiative}
                        label={translateWord('INITIATIVE')}
                        onChange={(e: any)=>{
                            if(e === 0) {
                                setInitiative(undefined);
                                return handleChange(undefined, status, date);
                            }
                            setInitiative(e);
                            handleChange(e, status, date);
                        }}
                        options={
                            [
                                {
                                    id: 0,
                                    label: cms?.filters?.initiative?.placeholder[0],
                                } as OptionDto
                            ].concat(
                                initiatives?.map((el: RedemptionResponse) => ({
                                    id: el.referredInitiativeLogicId,
                                    label:el.initiativeName,
                                }))
                            )
                        }
                        placeholder={cms?.filters?.initiative?.label}
                    />
                </Col>
            }
            <Col className="selectStyle" sm={24} md={8} xl={6}>
                <JSelect
                    value={status}
                    label={translateWord('STATUS')}
                    options={
                        [
                            {
                                id: 0,
                                label: cms?.filters?.status?.placeholder[0],
                            } as OptionDto
                        ].concat(
                            statuses.filter(status => {
                                const state = _.startCase(_.toLower(status));
                                if(Object.keys(RedemptionStatusEnum).includes(state)){
                                    return status
                                }
                            }).map(el => {
                                return {
                                    id: el,
                                    label: translateWord('ORDER_STATUS_' + el),
                                }
                            })
                        )
                    }
                    onChange={(e)=>{
                        let name = statuses.find(c => c === e);
                        setStatus(name as string);
                        handleChange(initiative, name, date);
                    }}
                    placeholder={cms?.filters?.status?.label}
                />
            </Col>
            <Col className="selectStyle" sm={24} md={8} xl={6}>
                <JRangePicker
                    showTime={false}
                    format={FORMAT_DATE}
                    label={translateWord('DATES_RANGE')}
                    // disabledDate={(current) => {
                    //     return disabledDate(current);
                    // }}
                    onChange={(period: any) => {
                        const fromDate =  period?.[0]?.format();
                        const toDate = period?.[1]?.format();
                        setDate([fromDate, toDate]);
                        handleChange(initiative, status, [fromDate, toDate]);
                    }}
                    placeholder={[cms?.filters?.date?.placeholder?.[0] || '', cms?.filters?.date?.placeholder?.[1] || '']}
                    locale={locale}
                />
            </Col>
        </Row>
    );
}

export default FiltersHeader;