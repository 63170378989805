import React, {Fragment} from "react";;
import {generatePathUrl} from "../../utils";

interface Point {
    value: number;
    originalValue?: number;
    image?: string;
}

interface Cost {
    value: number;
    originalValue?: number;
    currency: string;
}

export interface ItemValueProps {
    points?: Point[];
    cost?: Cost;
}

export const ItemValueString: React.FC<ItemValueProps> = ({points, cost}) => {
    const hasCostDiscount = cost?.originalValue !== cost?.value;

    return <>
        {
            points?.map((point, i) => {
                const hasPointDiscount = point?.value !== point?.originalValue;

                return (
                    <Fragment key={i}>

                        {point?.image && <img src={generatePathUrl(point?.image)} className={'wallet-icon'} alt={''} aria-hidden={true}/>}
                        <span className={`${hasPointDiscount ? 'lineThrough' : ''}`}>{point?.originalValue}</span>
                        {hasPointDiscount && <span>{point?.value}</span>}
                    </Fragment>
                )
            })
        }
        {
            cost && cost?.value &&
		    <>
                {points && points.length > 0 && ' + '}
			    <span className={`${hasCostDiscount ? 'lineThrough' : ''}`}>{cost?.value}</span>
                {hasCostDiscount && <span>{cost?.originalValue}</span>}
                {cost?.currency}
		    </>
        }
    </>
}

export const ItemValue: React.FC<ItemValueProps> = props => {

    return <div className={'item-value'}>
        <div className={'item-value-content'}>
            <ItemValueString {...props} />
        </div>
    </div>;
}