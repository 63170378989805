import { JFieldDTO } from "@jnext/commons";
import { JFormCompositorWrapper } from "commons/JFormCompositorWrapper/JFormCompositorWrapper";
import { ProductAttribute } from "pages/ProductDetail/models";
import { FC, useMemo } from "react";
import { DEFAULT_FORM_PROPS } from "../utils";
import {ProductDetailRelatedProductDto} from "@jnext/ts-axios-formatdigitalcollection";

export interface AttrbutesFormProps {
    relatedProducts: ProductDetailRelatedProductDto[]
    attributes: ProductAttribute[]
}
export const AttrbutesForm: FC<AttrbutesFormProps> = props => {

    const attributesFields = useMemo(
        getAttributesFields(props),
        [props.attributes]
    )
    return (
        <JFormCompositorWrapper
            {...DEFAULT_FORM_PROPS}
            formId="redemption-form"
            fields={attributesFields}
        />
    )
}

function getAttributesFields({ relatedProducts, attributes }: AttrbutesFormProps): () => JFieldDTO[] {
    return () => {
        const fieldsMap: Record<string, JFieldDTO> = {}
        for (const relatedProduct of relatedProducts) {
            for (const attribute of relatedProduct.attributes || []) {
                if (attribute.logicId && relatedProduct.logicId) {
                    if (!fieldsMap[attribute.logicId]) fieldsMap[attribute.logicId] = {
                        type: 'JSELECT',
                        mandatory: true,
                        username: false,
                        hurlId: 'attributes',
                        cmsConfig: {
                            title: attribute.title || '',
                            order: 1,
                            componentConfig: {
                                disableAllowClear: true,
                                options: []
                            }
                        }
                    }
                    for (const value of attribute.values || []) {
                        fieldsMap[attribute.logicId].cmsConfig.componentConfig.options.push({
                            value: relatedProduct.logicId,
                            label: {
                                title: value
                            }
                        })
                    }
                }
            }
        }

        for (const [attributeId, field] of Object.entries(fieldsMap)) {
            const attributeValue = attributes?.find(attribute => attribute.logicId === attributeId)?.values?.[0]
            field.values = [
                attributeValue || field.cmsConfig.componentConfig.options[0].value
            ]


        };
        return Object.values(fieldsMap)
    }
}
