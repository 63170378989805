import {CMSSection} from "@jnext/commons";
import {observer} from "mobx-react";
import {useEffect, useMemo, useRef, useState} from "react";
import {Outlet, useLocation} from "react-router-dom";
import {useAppStore} from "../../store/appStore";
import {useCMSStore} from "../../store/cmsStore";
import {getHeaderSectionDom} from "../../utils";
import Footer from "../Footer";
import Navbar from "../Navbar";
import {HttpService} from "../../service/HttpService";
import {Custom} from "../../pages/Custom";

const PageLayoutTemplate = () => {
    const heroRef = useRef<HTMLDivElement>(null);
    const {structure} = useCMSStore();
    const {mobileLayout} = useAppStore();
    const [openHeroSection, setOpenHeroSection] = useState<boolean>(false);
    const heroSection: CMSSection | undefined = useMemo(() => structure?.header?.sections?.find(e => e?.type === 'HERO_SECTION'), [structure]);
    const headerOptions = useMemo(() => structure?.header?.options, [structure?.header]);
    const footerOptions = useMemo(() => structure?.footer?.options, [structure?.footer]);
    const isNavbar = !!heroSection && heroSection?.enabled;
    const customProject = HttpService?.customProject;

    // Tracking scrolling
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => window.scrollY === 0 ? setScrolled(false) : setScrolled(true));
    }, [scrolled]);

    // Track location changes
    const location = useLocation();

    // Reset hero section when location changes
    useEffect(() => {
        setOpenHeroSection(false); // Close hero section when the route changes
    }, [location]);

    const toggleHeroSection = () => {
        setOpenHeroSection(prevState => !prevState);
    }

    // Close hero section when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (heroRef.current && !heroRef.current.contains(event.target as Node)) {
                setOpenHeroSection(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="template">

            {headerOptions?.custom ? (
                <>
                    {customProject?.customFrontend && (
                        <div id={"rootHeaderCustom"}>
                            <Custom
                                customFrontendUrl={customProject?.customFrontendUrl}
                                type={headerOptions?.customComponent as string | undefined}
                                rootCustom={"rootHeaderCustom"}
                            />
                        </div>
                    )}
                </>
            ) : (
                !!heroSection ? (
                    <div className={`header-fixed${scrolled ? ' scrolled' : ''}`}>
                        <header className={`navbar ${mobileLayout ? 'mobile' : ''}`}>
                            <Navbar openHeroSection={openHeroSection} toggleHeroSection={toggleHeroSection}/>
                            {getHeaderSectionDom(heroSection, isNavbar, mobileLayout, openHeroSection, heroRef)}
                        </header>
                    </div>
                ) : (
                    <header className="navbar">
                        <Navbar/>
                    </header>
                )
            )}

            <Outlet/>

            {footerOptions?.custom ?
                <>
                    {customProject?.customFrontend &&
                        <div id={"rootFooterCustom"}>
                            <Custom customFrontendUrl={customProject?.customFrontendUrl}
                                    type={footerOptions?.customComponent as string | undefined}
                                    rootCustom={"rootFooterCustom"}
                            />
                        </div>
                    }
                </>
                :
                <footer className="footer">
                    <Footer/>
                </footer>
            }

        </div>
    );
};

export default observer(PageLayoutTemplate);
