import {Helmet} from "react-helmet";
import {useEffect, useRef} from "react";
import {useAnalytics} from "../../store/analytics";
import {generateScript} from "../../utils";

export const ScriptGenerator = () => {
    const {analytics, cookieBanner} = useAnalytics();
    const ref = useRef(0);

    useEffect(() => {
        ref.current++;
    }, []);

    return (
        <>
            {(analytics || cookieBanner) &&(ref.current < 2) &&
                <Helmet>
                    {generateScript({...analytics, ...cookieBanner})}
                </Helmet>
            }
        </>
    )
}