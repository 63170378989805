import { CMSSection } from "@jnext/commons";
import React from "react";
import ScoreCounterContest from "../../HeroCardContest/ScoreCounterContest";
import { useWalletStore } from "store/walletStore";
import {observer} from "mobx-react";

export const WalletSection = observer(({ section, inNavbar, scrolled }: { section: CMSSection, inNavbar?: boolean, scrolled?:boolean }) => {
    const { wallet } = useWalletStore();

    if (!section?.enabled || !wallet || wallet?.length === 0) return <React.Fragment />;

    return <ScoreCounterContest walletPoints={wallet} inNavbar={inNavbar} scrolled={scrolled}/>;
});