import {CMSSection, JTextField} from "@jnext/commons";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {CatalogCMSFilters} from "../CatalogPage";
import {Col, Row, Slider} from "antd";
import {ArrowDownOutlined, DownOutlined, UpOutlined} from "@ant-design/icons";
import {onClickOutside} from "../../../utils";
import {FilterBox} from "./FilterBox";

interface PriceFilterProps {
    section: CMSSection;
    min: number;
    max: number;
    onChange: (hasRest: boolean, from: number, to: number) => void;
}

export const PriceFilter: React.FC<PriceFilterProps> = ({section, min, max, onChange}) => {
    const filters = section?.filters as any as CatalogCMSFilters;
    const [open, setOpen] = useState<boolean>(false);

    const [from, setFrom] = useState<string>(min.toString());
    const [to, setTo] = useState<string>(max.toString());
    const [toTmp, setToTmp] = useState<string>();
    const fromNumber = useMemo(() => parseInt(from) ,[from]);
    const toNumber = useMemo(() => parseInt(to) ,[to]);

    //User input visible value
    const [priceValue, setPriceValue] = useState<string>();
    const [hasReset, setHasReset] = useState<boolean>(false);


    const updateInputPrice = () => { setPriceValue(`Da ${from} a ${to}`); }
    const resetInputInterface = () => { setPriceValue(undefined); }
    const sendDataToParent = () => { onChange(hasReset, parseInt(from), parseInt(to)); }


    const getValidNumber = (value: string, min: number, max: number): string | null => {
        /**
         * If is not a number return null
         */
        if(isNaN(Number(value)) ) {
            return null;
        }

        const valueNum = parseInt(value);

        /**
         * Check min and max
         */
        if( valueNum < min || valueNum > max ) {
            return null;
        }

        return value;
    }

    // Dependent value
    useEffect(() => { setToTmp(to); }, [to]);

    // Click Outside handler
    const wrapperRef = useRef(null);
    onClickOutside(wrapperRef, () => {
        setOpen(false);
    });

    return (
        <Col className={'freetext-field-container form-control'} xs={24} md={6}>
            <div className={'freetext-field form-control'} ref={wrapperRef}>
                <div onClick={()=>setOpen(!open)}>
                    <JTextField
                        name={'price'}
                        placeholder={filters?.price?.placeholder?.[0]}
                        value={priceValue}
                        onChange={() => {
                            // NOTHING TO DO
                        }}
                    />
                </div>

                <div className={`search-icon ${open ? "rotate" : ""}`} onClick={()=>setOpen(!open)}>
                    <DownOutlined />
                </div>

                {
                    open &&
                    <FilterBox
                        onReset={() => {
                            setFrom(min.toString());
                            setTo(max.toString());

                            // Set has reset flag
                            setHasReset(true);
                        }}
                        onConfirm={() => {

                            sendDataToParent();

                            // If has reset
                            if( hasReset ) {
                                resetInputInterface();
                            } else {
                                updateInputPrice();
                            }

                            // CLose also box
                            setTimeout(() => {
                                setOpen(false);
                            }, 200);

                            // Remove reset flag
                            setHasReset(false);
                        }}
                    >
                        <div className={'price-filters'}>

                            <Row className={'range-inputs'} justify={'space-between'} align={'middle'}>
                                <Col className={'single-input'}>
                                    <JTextField
                                        name={'from'}
                                        placeholder={''}
                                        value={from}
                                        onChange={(e) => {
                                            const value = getValidNumber(e.target.value, min, toNumber);
                                            if( value !== null ) {
                                                setFrom(value);
                                            }
                                        }}
                                    />
                                </Col>
                                <Col className={'separator'} />
                                <Col className={'single-input'}>
                                    <JTextField
                                        name={'to'}
                                        placeholder={''}
                                        value={toTmp}
                                        onChange={(e) => {
                                            const value = getValidNumber(e.target.value, fromNumber, max);

                                            if( value !== null ) {
                                                setTo(value);
                                                setToTmp(value);
                                            } else {
                                                setToTmp(e.target.value);
                                            }
                                        }}
                                    />
                                </Col>

                            </Row>
                            <div className={'range-slider'}>
                                <Slider
                                    range={true}
                                    min={min} max={max}
                                    defaultValue={[min, max]}
                                    value={[fromNumber, toNumber]}
                                    onChange={(value) => {
                                        setFrom(value[0].toString());
                                        setTo(value[1].toString());
                                    }}
                                />
                            </div>
                        </div>
                    </FilterBox>
                }

            </div>

        </Col>
    )
}
