import {generatePathUrl} from "../../../../utils";
import {JHTML} from "@jnext/commons";
import React from "react";
import './infoBoxWarning.scss';

export function JBoxInfoWarning({text}: {text: string}) {
    return (
        <div className={'info-box'}>
            <img src={generatePathUrl('warning-error.svg')} aria-hidden={true}/>
            <JHTML.Text html={text}/>
        </div>
    )
}