import React, {useEffect, useMemo, useState} from "react";
import {CatalogService} from "../../service/CatalogService";
import {GetLimitsResponse} from "@jnext/ts-axios-formatdigitalcollection";
import {CMSSection, replacer} from "@jnext/commons";
import {FORMAT_DATE, generatePathUrl} from "../../utils";
import {useWalletStore} from "../../store/walletStore";
import moment from "moment";
import {AuthService} from "../../service/AuthService";

interface LimitsProps {
    section: CMSSection;
    initiativeLogicId: string;
}

export const Limits: React.FC<LimitsProps> = ({section, initiativeLogicId}) => {

    /**
     * If user is not authenticated -> return
     */
    if( !AuthService.authorized ) {
        return <></>;
    }

    const [limits, setLimits] = useState<GetLimitsResponse>();
    const {findWalletConfig, wallet} = useWalletStore();

    /**
     * CMS section to show
     */
    const neededSection  = useMemo(() => {
        const defaultSection = section?.sections?.find(el => el.type == 'LIMIT_VALUE' && el?.options?.limit === undefined);

        if(limits && limits?.purchasesAvailable != undefined && !isNaN(limits?.purchasesAvailable)) {
            const withValueSection = section?.sections?.find(el => el.type == 'LIMIT_VALUE' && el?.options?.limit === limits?.purchasesAvailable);

            if(withValueSection) {
                return withValueSection;
            }
        }

        return defaultSection;
    }, [section, limits]);

    /**
     * Replacer Object with Data
     */
    const getUpdatedReplacerObject = () => {

        const foundWallet  = findWalletConfig(limits?.walletConfigurationLogicId);
        const imgSrc = foundWallet?.cmsConfig?.imageRef;

        const beginTimeStamp = limits?.limitsPeriod?.beginTimestamp?.toString();
        const endTimestamp = limits?.limitsPeriod?.endTimestamp?.toString();

        return  {
            purchasesAvailable: limits?.purchasesAvailable?.toString() || '',
            beginTimestamp: beginTimeStamp ? moment(beginTimeStamp)?.format(FORMAT_DATE) : '',
            endTimestamp: endTimestamp ? moment(endTimestamp)?.format(FORMAT_DATE) : '',
            walletBalance: limits?.walletBalance?.toString() || '',
            walletName: foundWallet?.cmsConfig?.title || '',
            walletImg: imgSrc ? `<img src="${generatePathUrl(imgSrc)}" aria-hidden={true}/>` : '',
        }

    }

    const title = useMemo(() => replacer(neededSection?.title || '', getUpdatedReplacerObject()), [neededSection, limits])
    const subTitle = useMemo(() => replacer(neededSection?.subTitle || '', getUpdatedReplacerObject()), [neededSection, limits])

    useEffect(() => {
        (async () => {
            const limitsResponse = await CatalogService.getLimits(initiativeLogicId);
            setLimits(limitsResponse);
        })();
    }, []);

    return <>
        {
            limits &&
            <div className={'catalog-limits-container'}>
                <div className={'data-container'}>
                    <div className={'content'}>
                        {
                            title &&
                            <span className='value' dangerouslySetInnerHTML={{__html: title}} />
                        }
                        {
                            subTitle &&
                            <div className='subTitle' dangerouslySetInnerHTML={{__html: subTitle}} />
                        }
                    </div>
                </div>
            </div>
        }
    </>;
}