import {CMSPage, CMSSection} from "@jnext/commons";
import {useMemo} from "react";
import {Helmet} from "react-helmet";
import {
    isDevEnvironment,
    META_TAGS_CONFIGURATION,
    MetaPlaceholders,
    MetaValue,
    SupportedMetaTags
} from "./MetaTagsUtils"

interface MetaTagsProps {
    pageConfiguration?: CMSPage;
    sectionConfiguration?: CMSSection;
    placeholders?: MetaPlaceholders;
}

const MetaTags = ({pageConfiguration, sectionConfiguration, placeholders}: MetaTagsProps) => {

    if (!pageConfiguration && !sectionConfiguration) {
        isDevEnvironment && console.warn("MetaTags: No PageConfiguration & SectionConfiguration provided");
        return <></>;
    }

    const sectionMetaTags = sectionConfiguration && sectionConfiguration.options && sectionConfiguration.options.metaTags ? sectionConfiguration.options.metaTags : null;
    const pageMetaTags = pageConfiguration && pageConfiguration.options && pageConfiguration.options.metaTags ? pageConfiguration.options.metaTags : null;

    if (!sectionMetaTags && !pageMetaTags) {
        isDevEnvironment && console.warn("MetaTags: No PageConfiguration & SectionConfiguration configured");
        return <></>;
    }

    const metaTags: Record<SupportedMetaTags, MetaValue> = (pageMetaTags ?? sectionMetaTags) as any;

    const tags = useMemo(() => {
        if (!metaTags) {
            return [];
        }

        return Object.entries(metaTags).map(([tagName, tagValue]) => {
            const tagConfiguration = META_TAGS_CONFIGURATION[tagName as SupportedMetaTags];

            if (!tagConfiguration || !tagValue) {
                isDevEnvironment && console.warn("MetaTags: Invalid tag or empty tag value found:", tagName, tagValue);
                return null;
            }

            return tagConfiguration(tagValue as unknown as MetaValue, placeholders);
        }).filter(t => !!t);

    }, [metaTags]);

    if (tags.length === 0) {
        isDevEnvironment && console.warn("MetaTags: No valid tags configured");
        return <></>;
    }

    return (
        <>
            <Helmet>
                {tags.map(tag => tag)}
            </Helmet>
        </>
    );

}

export default MetaTags;