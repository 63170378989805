import { action, makeObservable, observable } from 'mobx';
import {GetConsumerDto} from "@jnext/ts-axios-coreuser";
import { ConsumerService } from "service/ConsumerService";
import { createContext, useContext } from 'react';
import { AuthService } from 'service/AuthService';
import { RequestStatus } from 'type/request-status';
import { ResourceService } from 'service/ResourseService';
import { HttpService } from 'service/HttpService';
import { CartStore, cartStore } from './cartStore';
import {getCluster} from "../utils";


export class ConsumerStore {
    consumerInfo: GetConsumerDto | undefined;
    avatarURL?: string;
    status: RequestStatus = RequestStatus.NOT_PERFORMED;
    cartStore!: CartStore
    constructor(cartStore: CartStore) {


        this.cartStore = cartStore

        makeObservable(this, {
            consumerInfo: observable,
            avatarURL: observable,
            status: observable,
            setAvatarURL: action,
            setConsumerInfo: action,
            setStatus: action
        })
    }

    setConsumerInfo = (consumerInfo: GetConsumerDto) => {
        this.consumerInfo = consumerInfo;
    }

    setStatus = (status: RequestStatus) => {
        this.status = status
    }

    setAvatarURL = async (profilePictureRef: string) => {
        try {
            const res = await ResourceService.download(profilePictureRef, {
                responseType: 'blob',
                headers: HttpService.getHttpHeaders({})
            })
            if (res?.status === 500 || res?.data?.error) {
                return Promise.reject();
            }
            const avatarURL = URL.createObjectURL(res?.data);
            if (avatarURL) {
                this.avatarURL = avatarURL
            }
        } catch (error) {
            console.error(error)
        }
    }


    saveClusterId = (consumer: GetConsumerDto) => {
        const clusterId = getCluster(consumer)?.clusterCategoryLogicId;
        if (clusterId) {
            AuthService.saveProfileFields({
                clusterId
            });
        }
    }

    updateProfile = async () => {
        try {
            this.setStatus(RequestStatus.PENDING)
            const consumer = await ConsumerService.retrieveConsumer()
            if (consumer) {
                this.saveClusterId(consumer);

                if (consumer.profilePictureRef && this.consumerInfo?.profilePictureRef !== consumer.profilePictureRef) {
                    this.setAvatarURL(consumer.profilePictureRef)
                }
                // this.cartStore.updateData(consumer) // TODO
                this.setConsumerInfo(consumer)
                this.setStatus(RequestStatus.SUCCESS)
            }

        } catch (error) {
            console.error(error)
            this.setStatus(RequestStatus.ERROR)
        }
    }

}


export const consumerStore = new ConsumerStore(cartStore);

export const useConsumerStore = () => useContext(
    createContext<ConsumerStore>(consumerStore)
);
