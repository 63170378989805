import React from "react";
import {CheckOutlined} from "@ant-design/icons";

export interface Step {
    title: string;
}

interface StepperProps {
    steps: Step[];
    activeStep: number;
    disabled?: boolean;
    onChange: (step: number) => void;
}

export const Stepper: React.FC<StepperProps> = ({steps, activeStep, disabled = false, onChange}) => {
    return (
        <div className={'jstepper'}>
            <ul>
                {
                    steps.map((el, i) => (
                        <li
                            key={i}
                            className={`
                                ${i <= activeStep && !disabled ? 'enabled' : ''}
                                ${i == activeStep && !disabled ? 'active' : ''}
                            `}
                            onClick={() => {
                                if(i < activeStep && !disabled) {
                                    onChange(i);
                                }
                            }}
                        >
                            <div className={'counter'}>
                                {i < activeStep && <CheckOutlined />}
                                {i >= activeStep && i+1}
                            </div>
                            <div className={'label'}>{el.title}</div>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
}