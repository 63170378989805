import {action, makeObservable, observable} from "mobx";
import {createContext, useContext} from "react";
import {CartService} from "../service/CartService";
import {BasketTypeEnum} from "@jnext/ts-axios-formatdigitalcollection";

export class CartStore {
    data?: any;
    basketItems?: number | undefined;

    constructor() {
        makeObservable(this, {
            data: observable,
            basketItems: observable,
            setData: action
        });
    }

    setData = (data: any) => {
        this.data = data;
    }

    setBasketItems = (basketItems: any | undefined) => {
        let quantity = 0;
        basketItems?.map((basket: any) => {
            quantity += basket?.quantity;
            return quantity
        });
        this.basketItems = quantity;
    }

    updateData = async (initiativeLogicId: string | undefined, basketType: BasketTypeEnum) => {
        if (initiativeLogicId) {
            const data = await CartService.getCart(initiativeLogicId, basketType);
            if (basketType === BasketTypeEnum.BasketPurchase) {
                this.setBasketItems(data?.items);
            }
            this.setData(data);
        }
    }
}


export const cartStore = new CartStore();

export const useCartStore = () => useContext(
    createContext<CartStore>(cartStore)
);


