import {
    ProductCMS,
    ProductContentDescrSectionTypes,
    ProductContentSectionTypes,
    ProductFormSectionTypes,
    ProductMainSectionTypes, ProductMediaDisplayType,
    ProductSectionTypes
} from "./models";

export const PRODUCT_CMS: ProductCMS = {
    page: {
        enabled: true
    },
    section: {
        enabled: true,
        sections: [
            {
                type: ProductMainSectionTypes.LOYALTY_COLLECTION,
                sections: [
                    {
                        enabled: true,
                        type: ProductSectionTypes.PRODUCT_MEDIA,
                        options: {
                            imageType: ProductMediaDisplayType.COVER,
                        }
                    },
                    {
                        enabled: true,
                        type: ProductSectionTypes.PRODUCT_CONTENT,
                        sections: [
                            {
                                enabled: true,
                                type: ProductContentSectionTypes.PRODUCT_BRAND_INFO
                            },
                            {
                                enabled: true,
                                type: ProductContentSectionTypes.PRODUCT_MAIN_INFO
                            },
                            {
                                enabled: true,
                                type: ProductContentSectionTypes.PRODUCT_CATEGORIES
                            },
                            {
                                enabled: true,
                                type: ProductContentSectionTypes.PRODUCT_FORM,
                                sections: [
                                    {
                                        enabled: true,
                                        type: ProductFormSectionTypes.PRODUCT_FORM_ATTRIBUTES
                                    },
                                    {
                                        enabled: true,
                                        type: ProductFormSectionTypes.PRODUCT_FORM_REDEMPTIONS,
                                        title: 'Seleziona metodo di pagamento',
                                        text: 'Seleziona un metodo di pagamento tra quelli dispobili',
                                        options: {
                                            pointLabel: 'punti'
                                        }
                                    },
                                    {
                                        enabled: true,
                                        type: ProductFormSectionTypes.PRODUCT_FORM_QUANTITY,
                                        title: 'Quantita',
                                        text: '• {{availability}} disponibili'
                                    }
                                ],
                                cta: [
                                    {
                                        type: 'BUTTON',
                                        scope: 'SUBMIT',
                                        title: 'Aggiungi al carrello'
                                    },
                                    {
                                        type: 'BUTTON',
                                        scope: 'LOGIN',
                                        title: 'Esegui l\'accesso'
                                    }
                                ]
                            },
                            {
                                enabled: true,
                                type: ProductContentSectionTypes.PRODUCT_DESCRIPTIONS,
                                sections: [
                                    {
                                        enabled: true,
                                        type: ProductContentDescrSectionTypes.PRODUCT_BRAND_DESCRIPTION,
                                        title: 'Descrizione brand'
                                    },
                                    {
                                        enabled: true,
                                        type: ProductContentDescrSectionTypes.PRODUCT_ITEM_DESCRIPTION,
                                        title: 'Descrizione prodotto'
                                    },
                                    {
                                        enabled: true,
                                        type: ProductContentDescrSectionTypes.PRODUCT_TERMS_CONDITIONS,
                                        title: 'Termini e condizioni'
                                    }
                                ]
                            }

                        ]
                    }
                ]
            },
            {
                type: ProductMainSectionTypes.DIGITAL_WALLET,
                sections: [
                    {
                        enabled: true,
                        type: ProductSectionTypes.PRODUCT_MEDIA,
                        options: {
                            imageType: ProductMediaDisplayType.CONTAIN,
                        }
                    },
                    {
                        enabled: true,
                        type: ProductSectionTypes.PRODUCT_CONTENT,
                        sections: [
                            {
                                enabled: true,
                                type: ProductContentSectionTypes.PRODUCT_BRAND_INFO
                            },
                            {
                                enabled: true,
                                type: ProductContentSectionTypes.PRODUCT_MAIN_INFO
                            },
                            {
                                enabled: true,
                                type: ProductContentSectionTypes.PRODUCT_CATEGORIES
                            },
                            {
                                enabled: true,
                                type: ProductContentSectionTypes.PRODUCT_FORM,
                                sections: [
                                    {
                                        enabled: true,
                                        type: ProductFormSectionTypes.PRODUCT_FORM_ATTRIBUTES
                                    },
                                    {
                                        enabled: true,
                                        type: ProductFormSectionTypes.PRODUCT_FORM_REDEEM,
                                        title: 'Riscatta',
                                        cta: [
                                            {
                                                scope: 'CLOSE',
                                                type: 'BUTTON',
                                                color: 'default',
                                                title: 'Cancella'
                                            },
                                            {
                                                scope: 'SUBMIT',
                                                type: 'BUTTON',
                                                title: 'Procedi'
                                            }
                                        ]
                                    }
                                ],
                                cta: [
                                    {
                                        type: 'BUTTON',
                                        scope: 'REDEEM',
                                        title: 'Riscatta'
                                    },
                                    {
                                        type: 'BUTTON',
                                        scope: 'LOGIN',
                                        title: 'Esegui l\'accesso'
                                    }
                                ]
                            },
                            {
                                enabled: true,
                                type: ProductContentSectionTypes.PRODUCT_DESCRIPTIONS,
                                sections: [
                                    {
                                        enabled: true,
                                        type: ProductContentDescrSectionTypes.PRODUCT_BRAND_DESCRIPTION,
                                        title: 'Descrizione brand'
                                    },
                                    {
                                        enabled: true,
                                        type: ProductContentDescrSectionTypes.PRODUCT_ITEM_DESCRIPTION,
                                        title: 'Descrizione prodotto'
                                    },
                                    {
                                        enabled: true,
                                        type: ProductContentDescrSectionTypes.PRODUCT_TERMS_CONDITIONS,
                                        title: 'Termini e condizioni'
                                    }
                                ]
                            }

                        ]
                    }
                ]
            },

        ]
    }
}

