import {JButton, JComponentConfigOption, JFieldDTO, JNumberField, replacer} from "@jnext/commons";
import { JFormCompositorWrapper } from "commons/JFormCompositorWrapper/JFormCompositorWrapper";
import { ProductFormQuantitySection } from "pages/ProductDetail/models";
import {FC, useEffect, useMemo, useState} from "react";
import { DEFAULT_FORM_PROPS, MAX_QUANTITY } from "../utils";
import {JTextField} from "@jnext/commons";
import {JNumber} from "@jnext/commons/dist/JFormCompositor/components/Fields";
import {Button, Col, Form} from "antd";

export interface QuantityFormProps {
    section: ProductFormQuantitySection
    availability?: number
}

export interface QuantityInputProps {
    section: ProductFormQuantitySection
    maxAvailabilitySelect?: number | null;
    maxAvailabilityDisplay?: number | null;
    quantity: number;
    setQuantity: (e: number)=> void
}

export const QuantityForm: FC<QuantityFormProps> = props => {

    const quantityField = useMemo(
        getQuantityField(props.section, props.availability),
        [props.availability]
    )
    return (
        <JFormCompositorWrapper
            {...DEFAULT_FORM_PROPS}
            formId="quantity-form"
            fields={quantityField}
        />
    )
}

export const QuantityInput: FC<QuantityInputProps> = props => {
    const title = props?.section?.title || '';
    const text = props?.section?.text || '';
    const [error, setError] = useState<boolean>(false)


    useEffect(() => {
        setError(false);
        if((props?.maxAvailabilitySelect && props.quantity > props.maxAvailabilitySelect) || (!props.quantity) || (props.quantity === 0)){
            return setError(true)
        }
    }, [props.quantity]);

    return (<Col>
            {title &&
                <div className="font-bold text-gray-dark uppercase availability-title">
                    {title}
                    {text && ![undefined, null].includes(props?.maxAvailabilityDisplay as any) && (
                        <span className="availability-label">
                                {replacer(text, {availability: props?.maxAvailabilityDisplay as any})}
                            </span>
                    )}
                </div>
            }
            <div className='quantityForm'>
                <JButton
                    className='buttonInput'
                    shape={"circle"}
                    onClick={()=> {
                        if(props.quantity > 1){
                            props.setQuantity(props.quantity - 1)
                        }
                    }}>
                    -
                </JButton>

                <Form.Item id='quantity'>
                    <JNumberField
                        name='quantity'
                        inputProps={{status: `${error ? 'error' :''}`}}
                        value={props.quantity}
                        onChange={(e)=>props.setQuantity(e)} />
                </Form.Item>

                <JButton
                    className='buttonInput'
                    shape={"circle"}
                    onClick={()=> {
                        if(props?.maxAvailabilitySelect && props.quantity < props?.maxAvailabilitySelect){
                            props.setQuantity(props.quantity + 1)
                        }
                    }}>
                    +
                </JButton>
            </div>
        </Col>
    )
}

export function getQuantityField(section: ProductFormQuantitySection, availability?: number, quantity = 1): () => JFieldDTO[] {
    return () => {
        const title = section?.title || ''
        const text = section?.text || ''
        return [{
            hurlId: 'quantity',
            mandatory: true,
            values: [quantity],
            username: false,
            readOnly: availability === quantity,
            type: 'JSELECT',
            cmsConfig: {
                text: title || '',
                order: 1,
                title: (
                    <div
                        className="font-bold text-gray-dark uppercase"
                    >
                        {title}
                        {text && ![undefined, null].includes(availability as any) && (
                            <span className="availability-label">
                                {replacer(text, { availability: availability as any })}
                            </span>
                        )}
                    </div>
                ) as any,
                componentConfig: {
                    disableAllowClear: true,
                    options: (() => {
                        const options: JComponentConfigOption[] = [];
                        const count = (availability || MAX_QUANTITY) >= MAX_QUANTITY ? MAX_QUANTITY : (availability || 0);
                        for (let index = 0; index < count || 0; index++) {
                            const value = (index + 1).toString();
                            options.push({
                                value,
                                label: {
                                    title: value
                                }
                            });

                        }
                        return options;
                    })()
                }
            }
        }];
    };
}



