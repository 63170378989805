import { useContext, createContext } from "react";
import {matomoTrackingView} from "../utils";

export class Analytics {
    page: string= '';
    cookieBanner: Record<string, any> | undefined;
    analytics: Record<string, any> | undefined;

    setCookieBanner = (cookieBanner:  Record<string, any>) => {
        this.cookieBanner = {...this.cookieBanner, ...cookieBanner};
    }

    setAnalytics = (analytics: Record<string, any>) => {
        this.analytics = {...this.analytics, ...analytics};
    }
}

export const analytics = new Analytics();

export const useAnalytics = () => useContext(
    createContext<Analytics>(analytics)
);


