import {FC, useEffect, useMemo, useState} from "react";
import {CartInfoSectionTypes, CartProductsListSection, CartSection} from "pages/Cart/models";
import {findCartSection, isInfoSection, isMainInfoSection, isProductsListSection} from "pages/Cart/utils";
import {getNavPathFromType, JBox, JCMSCTAProps} from "@jnext/commons";
import {mzUseNavigate, useTranslationWord} from "utils";
import './CartSummaryPreview.scss';
import {CloseOutlined} from "@ant-design/icons";
import {BasketResponse, BasketTypeEnum} from "@jnext/ts-axios-formatdigitalcollection";
import {CartService} from "../../../../../../service/CartService";
import getSymbolFromCurrency from "currency-symbol-map";
import {useWalletStore} from "../../../../../../store/walletStore";
import {CartProductItemPreview, CartProductItemRenderData} from "../../../../../../pages/Cart/components";

export interface CartSummaryPreviewProps {
    section?: CartSection,
    onClose: () => void,
    initiativeId: string,
}

export const CartSummaryPreview: FC<CartSummaryPreviewProps> = props => {
    const mzNavigate = mzUseNavigate()
    const translate = useTranslationWord()
    const walletStore = useWalletStore()
    const [basket, setBasket] = useState<BasketResponse>();
    const [loaded, setLoaded] = useState<boolean>(false);

    if (!props.section) return <div></div>


    const mainInfoSection: any = useMemo(() => {
        const section = props.section?.sections.find(findCartSection('CART_MAIN_INFO'))
        if (section && isMainInfoSection(section)) {
            return section
        }
        return undefined
    }, [props.section])

    const infoSection: any = useMemo(() => {
        const section = props.section?.sections.find(findCartSection('CART_INFO'))
        if (section && isInfoSection(section)) {
            return section
        }
        return undefined
    }, [props.section])

    const listSection: CartProductsListSection | undefined = useMemo(() => {
        const section = props.section?.sections.find(findCartSection('CART_PRODUCTS_LIST'))
        if (section && isProductsListSection(section)) {
            return section
        }
        return undefined
    }, [props.section])

    useEffect(() => {
        (async () => {

            const cartResponse = await CartService.getCart(props.initiativeId, BasketTypeEnum.BasketPurchase);
            setBasket(cartResponse);
            setLoaded(true);

        })()
    }, [])


    const totalSection = useMemo(() => infoSection && infoSection?.sections?.find(findCartSection(CartInfoSectionTypes.CART_INFO_TOTAL)), [infoSection])
    const cta = useMemo(() => {
        const ctas: JCMSCTAProps[] = listSection?.cta || []

        for (const cta of ctas) {
            cta.action = () => {
                if (cta.scope) {
                    mzNavigate(getNavPathFromType(cta.scope, {initiativeLogicId: props.initiativeId}))
                }
                props.onClose()
            }
        }
        return listSection?.cta
    }, [listSection])

    const cartWallet = useMemo(() => walletStore.wallet.find(w => w.logicId?.toLowerCase() === basket?.walletConfigurationLogicId?.toLowerCase()), [basket?.walletConfigurationLogicId])
    const walletImage = useMemo(() => cartWallet?.cmsConfig?.imageRef, [basket, cartWallet])
    const walletTitle = useMemo(() => cartWallet?.cmsConfig?.title, [basket, cartWallet])
    const totalPoints = useMemo(() => `${basket?.pointsTotal} ${walletTitle ? walletTitle : ''}`, [basket, walletTitle])
    const totalCash = useMemo(() => !!basket?.cashTotal && basket?.cashTotal > 0 ? `${basket?.cashTotal} ${getSymbolFromCurrency(basket?.currency || 'EUR')}` : '', [basket, walletTitle])
    const totalValue = useMemo(() => `${totalPoints || ''} ${totalCash && totalPoints && '+'} ${totalCash}`, [totalCash, totalPoints])
    return (
        <div className="cart-preview">
            <JBox cta={cta}>
                <div className="cart-preview-content">

                    <div className="cart-preview-header">
                        <div className="cart-preview-title">
                            {mainInfoSection?.title}
                        </div>
                        <CloseOutlined onClick={props.onClose}/>
                    </div>
                    {
                        listSection && !!basket && !!basket.items && basket?.items.length ? (
                            basket.items.map((item, i) => (
                                <CartProductItemRenderData section={listSection} item={item} key={i} removeItem={() => {
                                }} updateItemQuantity={() => {
                                }}>
                                    {(elements) => <CartProductItemPreview {...elements} />}
                                </CartProductItemRenderData>
                            ))
                        ) : <>
                            {
                                loaded &&
								<div className="cart-isempty">
                                    {
                                        translate('CART_ISEMPTY')
                                    }
								</div>
                            }
                        </>
                    }
                </div>
                {
                    loaded && totalSection && totalValue && basket?.pointsTotal && (
                        <div className="cart-summary-total">
                            <div className="cart-summary-total-label">
                                {
                                    totalSection?.title
                                }
                            </div>
                            <div className='cart-summary-right-align'>
                                {totalValue}
                            </div>
                        </div>
                    )
                }
            </JBox>

        </div>
    )
}