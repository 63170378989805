import {CMSSection, JTable} from "@jnext/commons";
import {ContestPlaysResponse} from "@jnext/ts-axios-contestformat";
import React from "react";
import {FORMAT_DATE, generatePathUrl, mzUseNavigate} from "../../../../../utils";
import moment from "moment";
import {PointRow} from "../PointRow";
import {OrderOrderStatusEnum} from "@jnext/ts-axios-mz/models/order";
import {ComponentStatusTag, StatusTagType} from "../../../../../commons/ComponentStatusTag/ComponentStatusTag";

const columns = (onOpenDetails: (contest: ContestPlaysResponse) => void, cms: CMSSection, navigate: any) => {
    return [
        {
            title: cms?.table?.causal?.label,
            dataIndex: 'initiative',
            render: (initiative: any) => {
                return(
                    <div className='causalInfo'>
                        {initiative?.cms?.image &&  <img className='causalImage' src={generatePathUrl( initiative?.cms?.image)} aria-hidden={true}/>}
                        {initiative?.cms?.title && <label className='causalText'>{ initiative?.cms?.title}</label>}
                    </div>
                )
            },
        },
        {
            title: cms?.table?.start_date?.label,
            dataIndex: 'transactionDate',
            render: (transactionDate: Date) => {
                return <label className='dateTransactionText'>{moment(transactionDate)?.format(FORMAT_DATE)}</label>
            },
        },
        /*  {
              title: cms?.table?.point_date?.label,
              dataIndex: 'dateValidatePoint',
              render: (beginTimestamp: Date) => {
                  return <label className='dateValidatePointText'>{moment(beginTimestamp)?.format(FORMAT_DATE)}</label>
              },
          },*/
        {
            title: cms?.table?.points?.label,
            dataIndex: 'amount',
            render: (amount: number, row: any) => {
                return <PointRow amount={amount} src={row?.cmsDto?.imageRef}/>
            },
        },
        {
            title: cms?.table?.status?.label,
            dataIndex: 'statusExternalId',
            render: (status: OrderOrderStatusEnum) => {
                return <ComponentStatusTag type={StatusTagType.TRANSACTION} orderStatus={status}/>
            },
        },
        {
            render: (col: any) => {
                return <>
                    {col?.initiative?.type === 'catalog' &&
                        <div
                            className={'goToProduct'}
                            onClick={()=>/*navigate(getNavPathFromType(NavigationTypes.CATALOG, col?.initiative?.sourceLogicId)*/ console.log('show Product')}>
                            {cms?.table?.goToProduct?.label}
                        </div>}
                </>
            }
        }
    ]
};

type TableDesktopViewProps = {
    historyPoints: any[],
    historyPointsCMS: CMSSection,
}
export const TableDesktopView : React.FC<TableDesktopViewProps> = ({historyPoints, historyPointsCMS}) =>{
    const navigate = mzUseNavigate();
    return <>
        {
            historyPoints.length > 0 && (
                <div className={'profile-table'}>
                    <JTable
                        columns={columns((resp: ContestPlaysResponse) => {
                            console.log(resp)
                        }, historyPointsCMS, navigate)}
                        dataSource={historyPoints}
                        pagination={false}
                    />
                </div>
            )
        }
    </>
}