import { Col, Typography } from 'antd';
import { FC } from 'react';
import {generatePathUrl} from "../../../../utils";
import {ProductContentItem} from "../ProductContent";

const { Text } = Typography

export interface BrandSectionProps {
    brands: ProductContentItem['brands']
}
export const BrandSection: FC<BrandSectionProps> = ({ brands = [] }) => (
    <div className="brands-info">
        <Col span={24} className="brands-logo">
            {
                brands.map((brand, i) => (
                    <img src={generatePathUrl(brand?.brandLogo)} className="brand-logo" key={i} aria-hidden={true} alt={brand?.logicId}/>
                ))
            }
        </Col>
        <Col span={24} className="brands-name">
            {
                brands.map((brand, i) => (
                    <Text className="brand-name" key={i}>{brand.title?.toUpperCase() || '-'}</Text>
                ))
            }
        </Col>
    </div>
)