import React from 'react';
import './MessageBox.scss';

interface MessageBoxProps {
    msgTitle: string;
    msgSubtitle?: string;
    msgBody?: string;
    msgTitleColor?: string;
    msgSubtitleColor?: string;
    msgBodyColor?: string;
    msgDisclaimerColor?: string;
    msgLogoUrl?: string;
    fromDate: string;
    toDate: string;
    roundedVersion?: boolean;
    autoWidth?: boolean;
}

const MessageBox: React.FC<MessageBoxProps> = ({
                                                   msgTitle,
                                                   msgSubtitle,
                                                   msgBody,
                                                   msgTitleColor,
                                                   msgSubtitleColor,
                                                   msgBodyColor,
                                                   msgDisclaimerColor = "#ebf3fa",
                                                   msgLogoUrl,
                                                   fromDate,
                                                   toDate,
                                                   roundedVersion,
                                                   autoWidth
                                               }) => {
    const now = new Date();
    const from = new Date(fromDate);
    const to = new Date(toDate);

    const isVisible = now >= from && now <= to;

    return isVisible ? (
        <div
            className={`message-box ${roundedVersion ? 'roundedVersion' : ''} ${autoWidth ? 'autoWidth' : ''}`}
            style={{backgroundColor: msgDisclaimerColor}}
        >
            {msgLogoUrl ? (
                <img
                    src={msgLogoUrl}
                    alt="Icon"
                    className="icon"
                />
            ) : (
                <span className="icon-placeholder">i</span>
            )}
            <div className="content">
                <h1 style={{color: msgTitleColor}} dangerouslySetInnerHTML={{__html: msgTitle}}/>
                {msgSubtitle && (
                    <p style={{color: msgSubtitleColor}} dangerouslySetInnerHTML={{__html: msgSubtitle}}/>
                )}
                {msgBody && (
                    <p style={{color: msgBodyColor}} dangerouslySetInnerHTML={{__html: msgBody}}/>
                )}
            </div>
        </div>
    ) : null;
};

export default MessageBox;
