import { FC } from "react";
import { CartProductItemRenderProps } from "./../CartProductItemRenderData";
import './CartProductItemMobile.scss';

export interface CartProductItemMobileProps extends CartProductItemRenderProps { }

export const CartProductItemMobile: FC<CartProductItemMobileProps> = props => {

    return (
        <div className="cart-product-item-mobile">
            <div className="cart-product-item-top-section">
                {
                    props.image
                }
                <div className="cart-product-item-info">
                    {
                        props.brands
                    }
                    {
                        props.title
                    }
                </div>
            </div>
            <div className="cart-product-item-middle-section">
                {
                    props.quantityForm
                }
                {
                    props.price
                }
            </div>
            {
                props.removeItemCTA
            }
        </div>
    )
}