import {ConsumerV4Api} from "@jnext/ts-axios-coreuser/dist/apis/consumer-v4-api";
import axios from "axios";
import {HttpService} from "./HttpService";

export class ConsumerService {

    static async retrieveConsumer() {
        // @ts-ignore
        const consumerResponse: ConsumerV4Api = new ConsumerV4Api(undefined, HttpService.servicePath('core-user'), axios);
        try {
            const res = await consumerResponse.getConsumerByExternalId();
            return res?.data;
        }
        catch(e: any) {
            switch(e.response.status) {
                case 404:
                    return null;
                default:
                    throw new Error('Consumer error', e.response.status);
            }
        }
    };

}