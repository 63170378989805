import {CMSConfiguration, CMSCTA, CMSSection, setPageTitle} from "@jnext/commons";
import {action, makeObservable, observable} from "mobx";
import {createContext, useContext} from "react";
import {CMSService} from "service/CMSService";
import {RequestStatus} from "type/request-status";
import {getEnabledScript, printStyleInDom, setGlobalVars} from "utils";
import {HttpService} from "../service/HttpService";
import {ResourceService} from "../service/ResourseService";
import {analytics} from "./analytics";

export interface CMSColorsConfiguration {
    background?: string,
    primary?: string,
    primaryDark?: string,
    secondary?: string,
    tertiary?: string,
    "textPrimary"?: string,
    "textSecondary"?: string,
    "textInvert"?: string,
    "success"?: string,
    "error"?: string,
    "warning"?: string,
}

export interface CMSFontsConfiguration {
    "url": string,
    "primary": string
}
export interface CMSTemplateConfiguration {
    code: string
    colors: CMSColorsConfiguration
    fonts: CMSFontsConfiguration
    customCss: string
}

export interface CMSAppSettingConfiguration {
    avatarFallback: string
    avatarShape: string
    formatDate: string
    formatDatetime: string
    template: CMSTemplateConfiguration
    appTitle?: string
    favicon?: string
    analytics?: Record<string, any>[]
    cookieBanner?: Record<string, any>[]
    scripts?: CustomScripts;
}

interface CustomScripts {
    head?: string | string[];
    body?: {
        top?: string | string[];
        bottom?: string | string[];
    }
}

export interface CMSConfig {
    appSettings: CMSAppSettingConfiguration,
    pages: CMSPagesConfig,
    publicStructure: CMSStructureConfig,
    structure: CMSStructureConfig
}
export enum CMSPages {
    'HOW_TO' = 'howTo',
    'CONTACT' = 'contact',
    'FAQ' = 'faq',
    'CMS' = 'cms',
    'HOMEPAGE' = 'homepage',
    'PROFILE' = 'profile',
    'friends' = 'friends',
    'catalog' = 'catalog',
    'notFound' = 'notFound',
    'unauthorized' = 'unauthorized',
    'serverError' = 'serverError',
    'productDetails' = 'productDetails',
    'checkout' = 'checkout',
    'cart' = 'cart',
    'vas'= 'vas',
    'vasCheckout'= 'vasCheckout'

}

export type CMSPagesConfig = {
    [type in `${CMSPages}`]: CMSConfiguration
}

export enum CMSStructureSection {
    'FOOTER' = 'footer',
    'HEADER' = 'header',
}

export enum CMSStructureCta {
    'FOOTER_NAVIGATION' = 'footerNavigation',
    'MAIN_NAVIGATION' = 'mainNavigation',
    'USER_NAVIGATION' = 'userNavigation',
    'CMS_NAVIGATION' = 'cmsNavigation',
    'PROFILE_NAVIGATION' = 'profileNavigation',
}

export type CMSStructure = `${CMSStructureSection}` | `${CMSStructureCta}`;

export type CMSStructureSectionConfig = {
    [type in `${CMSStructureSection}`]?: CMSSection
}

export type CMSStructureCtaConfig = {
    [type in `${CMSStructureCta}`]?: CMSCTA[]
}

export type CMSStructureConfig = CMSStructureSectionConfig & CMSStructureCtaConfig;

export class CMSStore {
    appSettings: CMSAppSettingConfiguration | undefined;
    pages: {
        [type in `${CMSPages}`]?: CMSConfiguration
    } = {};
    structure: CMSStructureConfig = {};
    status: RequestStatus = RequestStatus.NOT_PERFORMED;

    constructor() {

        makeObservable(this, {
            appSettings: observable,
            status: observable,
            pages: observable,
            structure: observable,
            setAppSettings: action,
            setPages: action,
            setStatus: action,
            setStructure: action
        });
    }

    setStatus = (status: RequestStatus) => {
        this.status = status
    }
    setAppSettings = (appSetting: CMSAppSettingConfiguration) => {
        this.appSettings = appSetting;
    }

    setPages = (pagesConfig: CMSPagesConfig) => {
        this.pages = pagesConfig;
    }

    setStructure = (structureConfig: CMSStructureConfig) => {
        this.structure = structureConfig;
    }

    updateCMS = async () => {
        this.setStatus(RequestStatus.PENDING)
        try {
            const { appSettings, pages, structure } = await CMSService.retrieveCMSConfig();
            setGlobalVars(appSettings)
            // Set data in CMSStore
            this.setAppSettings(appSettings);
            this.setPages(pages);
            this.setStructure(structure);
            const format_date = appSettings?.formatDate;
            localStorage.setItem('format_date', format_date);
            const urlFontFamily = appSettings?.template?.fonts?.url;
            const customCss = appSettings?.template?.customCss;
            printStyleInDom(`@import url(${urlFontFamily})`);
            if(customCss){
                printStyleInDom(`${customCss}`);
            }

            // Set title and favicon
            if (appSettings?.appTitle) {
                setPageTitle((appSettings as any)?.appTitle);
            }

            // Removed dynamic favicon - see index.html
            // if (appSettings?.favicon) {
            //     setFavicon((appSettings as any)?.favicon);
            // }

            if(appSettings?.analytics && Object.keys(appSettings?.analytics).length > 0){
                 analytics.setAnalytics(getEnabledScript(appSettings?.analytics))
            }

            if(appSettings?.cookieBanner && Object.keys(appSettings?.cookieBanner).length > 0){
                analytics.setCookieBanner(getEnabledScript(appSettings?.cookieBanner))
            }

            this.setStatus(RequestStatus.SUCCESS)
        } catch (error) {

            this.setStatus(RequestStatus.ERROR)
        }
    }
}

export const downloadPrivateImg = async (url?: string) => {
    if(!url) return

    const res = await ResourceService.download(url, {
        responseType: 'blob',
        headers: HttpService.getHttpHeaders({})
    })
    if (res?.status === 500 || res?.data?.error) {
        return Promise.reject();
    }

    const link = document.createElement("a");
    link.href = URL.createObjectURL(res?.data);
    link.download = url;
    link.click();

    //return  URL.createObjectURL(res?.data);
}


export const cmsStore = new CMSStore();

export const useCMSStore = () => useContext(
    createContext<CMSStore>(cmsStore)
);