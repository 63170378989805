import React from 'react';
import MicroApp from "commons/MicroApp";
import { mzUseNavigate } from "utils";
import { HttpService } from "../../../service/HttpService";
import { getNavPathFromType, NavigationType } from "@jnext/commons";
import { useAppStore } from 'store/appStore';

export const ForgotPassword: React.FC = () => {
    const { handleResponseError } = useAppStore()
    const navigate = mzUseNavigate()

    return (<>
        {HttpService.env?.coreUser && (
            <MicroApp
                entry={`${HttpService.env?.coreUser}`}
                props={{
                    type: 'forgot-password',
                    onNavigate(type: NavigationType) {
                        return navigate(getNavPathFromType(type))
                    },
                    onError: handleResponseError,
                    keyCloakClientId: HttpService.env?.keycloakClientID as string
                }}
            />
        )}
    </>
    )
}
