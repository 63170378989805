import React, {FC, useEffect, useMemo, useState} from "react";
import {
    ClusteredInitiative,
    GetProductsPreviewPagedResponse,
    MediaTypeEnum,
    ProductPreviewDto
} from "@jnext/ts-axios-formatdigitalcollection";
import {Col, Row} from "antd";
import {CatalogPagination} from "./CatalogPagination";
import {Pagination} from "./index";
import {CatalogType} from "../../type/catalogTypes";
import {DigitalWalletCardItem} from "./DigitalWalletCardItem";
import {LoyaltyCollectionCardItem} from "./LoyaltyCollectionCardItem";
import {CatalogPaginationType} from "../../pages/Catalog/CatalogPage";
import {CatalogService, GetProductsFilters} from "../../service/CatalogService";
import {VasService} from "../../service/VasService";
import {CMSSection, getNavPathFromType, JSkeletonSlider, JTabs, NavigationType, scrollTo} from "@jnext/commons";
import {PaginatedResponse} from "../../type/pagination";
import {ServiceDetails} from "@jnext/ts-axios-formatdigitalcollection/models/service-details";
import {TabBtn} from "./TabBtn";

interface CatalogPurchaseBodyProps {
    products: ProductPreviewDto[] | undefined;
    pagination?: Pagination;
    type: CatalogType;
    rewardSpan: number;
    page: number;
    setPage: (page: number) => void;
    onProductClick: (logicId: string, refInitiativeRegistryLogicId?: string) => void;
    paginationType: CatalogPaginationType;
    containerElement?: HTMLElement;
    setInitLogicId?: (initiativeLogicId: string) => void;
    filters?: GetProductsFilters;
}

// Definisce il componente CatalogPurchaseBody che accetta diverse proprietà per configurare la visualizzazione del catalogo acquistato
export function CatalogPurchaseBody({
                                        products, // Lista dei prodotti disponibili
                                        pagination, // Configurazione della paginazione
                                        type, // Tipo di catalogo (es. digital wallet, loyalty collection)
                                        rewardSpan, // Larghezza delle colonne
                                        page, // Pagina corrente
                                        setPage, // Funzione per impostare la pagina corrente
                                        onProductClick, // Funzione chiamata quando un prodotto viene cliccato
                                        paginationType, // Tipo di paginazione
                                        containerElement,
                                        setInitLogicId,
                                        filters
                                    }: CatalogPurchaseBodyProps) {

    const [catalog, setCatalog] = useState<ProductPreviewDto[]>([]); // Stato per i dati del catalogo

    const [refInitiativeRegistryLogicId, setRefInitiativeRegistryLogicId] = useState<string>(''); // Stato per il logiId del catalogo

    const [totalPage, setTotalPage] = useState<number>(); // Stato per il numero totale di pagine

    const [indexActiveTab, setIndexActiveTab] = useState<number>(0); // Stato per tab selezionato

    // Determina il componente della carta in base al tipo di catalogo
    const CardComponent = type == CatalogType.digitalWallet ? DigitalWalletCardItem : LoyaltyCollectionCardItem;

    useEffect(() => {
        (async () => {
            if(!!products && products?.length > 0){
                let serviceSelected: ProductPreviewDto | undefined;
                if(!!sessionStorage.getItem('refClusterLogicId')){
                    serviceSelected = products?.find(el =>
                        el?.serviceDetails?.clusteredInitiatives?.find(cluster => cluster.refClusterLogicId === sessionStorage.getItem('refClusterLogicId')));
                    setIndexActiveTab(products?.findIndex(el => el?.logicId === serviceSelected?.logicId));
                }
                await getCatalogTabs(serviceSelected?.logicId ?? products[0]?.logicId);
            }
        })();
    }, [products]);


    // Funzione per ottenere le schede del catalogo
    async function getCatalogTabs(logicId?: string){
        const clusterInitiativeByLogicId : ClusteredInitiative[] | undefined = products
            ?.find(product => product.logicId === logicId)?.serviceDetails?.clusteredInitiatives;

        const clusterInitiative : ClusteredInitiative | undefined = clusterInitiativeByLogicId
            ?.find(cluster => cluster.refInitiativeRegistryType === 'CATALOG');

        if (clusterInitiative?.refInitiativeRegistryLogicId && clusterInitiative?.refClusterLogicId) {
            setInitLogicId && setInitLogicId(clusterInitiative.refInitiativeRegistryLogicId);
            setRefInitiativeRegistryLogicId(clusterInitiative.refInitiativeRegistryLogicId);
            const data = await VasService.getProductsEligible(clusterInitiative.refInitiativeRegistryLogicId, clusterInitiative.refClusterLogicId, 0, 10, filters);

            if(data){
                setTotalPage((data as any)?.customData?.pagination?.pageCount);
                setCatalog(data?.results || []);
            }
        }
    }

    return(
        <>
            {/* Componente per i pulsanti delle schede */}
            {(!!products && products?.length > 1) &&<TabBtn
                containerElement={containerElement}
                products={products}
                indexActiveTab={indexActiveTab}
                onClickTab={async (logicId: string) => {
                    setCatalog([])
                    await getCatalogTabs(logicId)
                }}
            />}

            {/* Mostra uno scheletro di caricamento se il catalogo è vuoto */}
            {catalog?.length ===  0?
                <JSkeletonSlider hasTitle={true} />
                :
                <Row gutter={[32, 32]} align={'stretch'}>
                    {/* Mappa i prodotti del catalogo */}
                    {
                        catalog?.map((el, idx: number) => {
                            // Rimuove i media con tipo CUSTOM dai risultati del catalogo
                            const ProductsWithFilteredMedia = ({ ...el, mediaContents: el.mediaContents?.filter(media => media.mediaType !== MediaTypeEnum.Custom) })

                            return <Col xs={24} sm={12} md={rewardSpan} key={ProductsWithFilteredMedia.logicId || idx} className={'catalog-col'}>
                                <CardComponent
                                    data={ProductsWithFilteredMedia}
                                    pricePosition={'TOPRIGHT'}
                                    noOverlay={true}
                                    showTag={true}
                                    isPurchase={true}
                                    onClick={() =>
                                        onProductClick(ProductsWithFilteredMedia.logicId as string, refInitiativeRegistryLogicId)
                                    }
                                />
                            </Col>
                        })
                    }
                    {/* Mostra la paginazione se configurata */}
                    {
                        pagination?.hasPagination && (totalPage && totalPage > 1) &&
                        <Col className='catalogPagination' span={24}>
                            <CatalogPagination
                                page={page + 1}
                                setPage={(page: number) => setPage(page - 1)}
                                totalPage={totalPage}
                                paginationType={paginationType} />
                        </Col>
                    }
                </Row>}
        </>
    )
}

