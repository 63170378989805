import "./vas.scss";
import React, {FC, useEffect, useMemo, useState} from "react";
import {
    breakpointsMin,
    CMSCTA,
    CMSSection,
    JCMSCTA,
    JCMSImage,
    JSection,
    JSkeletonSlider,
    JSlider,
    NavigationTypes
} from "@jnext/commons";
import {useWindowSize} from "@react-hook/window-size";
import {JCardVas} from "./Components/JCardVas";
import {VasService} from "../../service/VasService";
import {PlanStatusEnum, ProductPreviewDto} from "@jnext/ts-axios-formatdigitalcollection";
import {AuthService} from "../../service/AuthService";
import {useTranslationWord} from "../../utils";
import {JBoxInfoWarning} from "./Components/JBoxInfoWarning";

interface JVasSectionProps {
    section?: CMSSection;
}

// Definisce il componente JVasCardSection che accetta una proprietà section di tipo CMSSection
export const JVasCardSection: FC<JVasSectionProps> = ({section}) => {
    // Ottiene la funzione di traduzione
    const translateWord = useTranslationWord();

    // Trova i pulsanti CTA (Call To Action) specifici nella sezione per utenti non autenticati
    const howToBtn = !AuthService.authorized && section?.cta?.find((cta) => cta?.scope === NavigationTypes.PUBLIC_HOW_TO);
    const registrationBtn =  !AuthService.authorized && section?.cta?.find((cta: CMSCTA ) => cta?.scope === NavigationTypes.REGISTRATION);

    // Usa useMemo per memorizzare le options della sezione
    const options = useMemo(() => section?.options, [section]);

    // Stato per memorizzare le carte
    const [cards, setCards] = useState< ProductPreviewDto[]>([]);

    // Stato per indicare se è stato acquistato un abbonamento
    const [isSubscriptionPurchased, setIsSubscriptionPurchased] = useState<boolean>(false);

    const slideShow = useMemo(() => (cards.length < 3) ? cards.length : 3,[cards])

    // Ottiene la larghezza della finestra
    const [width] = useWindowSize();

    // Determina se il layout è mobile
    const isMobileLayout = useMemo(() =>(
        width < breakpointsMin.xs
    ), [width]);

    // Determina se il layout è tablet
    const isTabletLayout = useMemo(() =>(
        width < breakpointsMin.md
    ), [width]);

    // Effettua una chiamata API per ottenere le carte VAS e aggiorna lo stato
    useEffect(() => {
        (async () => {
            if(!!options?.logicId){
                const data = await VasService.getVasProducts(options.logicId as string);
                if(data){
                    setCards(data);
                    setIsSubscriptionPurchased(data?.filter(card => card?.serviceDetails?.status === PlanStatusEnum.Purchased).length > 0);
                }
            }
        })();
    }, [options?.logicId]);

    return (
        <JSection {...section} title={isSubscriptionPurchased ? translateWord('buyNewPurchase') : section?.title}>
            <div className={'vas-card-page'}>
                <div className={'vas-header'}>
                    {/* Mostra l'immagine dell'intestazione se presente */}
                    {section?.image &&
                        <div className={'vas-header-image'}>
                            <JCMSImage image={section?.image} />
                        </div>
                    }
                    {/* Mostra il pulsante CTA "How To" se presente e l'utente non è autenticato */}
                    {howToBtn &&
                        <div className={'vas-header-cta'}>
                            <JCMSCTA  {...howToBtn} />
                        </div>
                    }
                    {/* Mostra un messaggio di avviso se è stato acquistato un abbonamento */}
                    {isSubscriptionPurchased && <JBoxInfoWarning text={translateWord('charge_next_renewal')}/>}
                </div>

                {/* Mostra uno scheletro di caricamento se non ci sono carte */}
                {!cards || cards.length === 0 && <JSkeletonSlider hasTitle={true} />}

                {/* Mostra le carte VAS in un JSlider */}
                <JSlider
                    slidesToShow={isMobileLayout ? 1 : isTabletLayout ? 2 : slideShow}
                    vertical={isMobileLayout}
                    dots={false}
                    arrows={!isMobileLayout && cards.length > 3 }
                    centerMode={isMobileLayout || isTabletLayout}
                >
                    {cards.map((card, index) =>
                        {
                            return (
                                <JCardVas
                                    key={index}
                                    card={card}
                                    section={section}
                                    isSubscriptionPurchased={isSubscriptionPurchased} />
                            )
                        }
                    )}
                </JSlider>

                {/* Mostra il pulsante di registrazione se presente e l'utente non è autenticato */}
                {registrationBtn &&
                    <div className={'vas-registration-cta'}>
                        <JCMSCTA {...registrationBtn} />
                    </div>
                }
            </div>
        </JSection>
    )
}