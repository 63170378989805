import MicroApp from "../../commons/MicroApp";
import { toJS } from "mobx";
import React, { useEffect, useState } from "react";
import { ContestResponse } from "@jnext/ts-axios-contestformat";
import { useParams } from "react-router-dom";
import { RedemptionService } from "../../service/RedemptionService";
import { ContestService } from "../../service/ContestService";
import { HttpService } from "../../service/HttpService";
import { useLoadingStore } from "store/loadingStore";

const ContestResult: React.FC = () => {

    const [contest, setContest] = useState<ContestResponse>();
    const { id } = useParams();
    const { setLoading } = useLoadingStore();

    useEffect(() => {
        // Show loader
        setLoading(true);
    }, []);


    useEffect(() => {

        (async () => {

            // Load contests lists if needed
            const list = await ContestService.retrievePrivateContestList();

            // Save contest locally
            const contestFound = list.find((contest: ContestResponse) => contest?.contest?.logicId == id);
            setContest(contestFound);


        })();

    }, []);


    // Hide loader when finish
    useEffect(() => {
        if (contest) {
            // Hide loader
            setLoading(false);
        }
    }, [contest]);


    return (
        <>
            {contest && HttpService.env?.formatContest && (
                <MicroApp
                    entry={`${HttpService.env?.formatContest}`}
                    props={{
                        contest: toJS(contest),
                        type: "contest-result",
                        updateRedemprionAddress: (redemptionId: string, obj: any) => {
                            return RedemptionService.updateRedemptionAddress(
                                redemptionId,
                                obj
                            );
                        },
                        id: id || "",
                    }}
                />
            )}
        </>
    );
}


export default ContestResult;