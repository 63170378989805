import {useMemo} from "react";
import {useTranslationWord, generatePathUrl} from "utils";
import "../style.scss";
import {GenericError, GenericErrorProps} from "../GenericError";
import {CMSConfiguration} from "@jnext/commons";
import {useCMSStore} from "store/cmsStore";

export const ErrorNoAuth = () => {
    const translateWord = useTranslationWord();

    const {pages} = useCMSStore();
    const config: CMSConfiguration | undefined = useMemo(() => pages?.unauthorized, [pages]);
    const data: GenericErrorProps = useMemo(() => {
        return {
            title: config?.enabled ? config?.page?.title : translateWord('NO_AUTH_PERMISSION'),
            text: config?.enabled ? config?.page?.subTitle : translateWord('NO_AUTH_PERMISSION'),
            image: config?.enabled ? (config?.page?.banner?.image) : [{src: generatePathUrl('assets/error/illustrazioneManutenzione.png')}],
        }
    }, [config]);

    return <GenericError
        title={data.title}
        text={data.text}
        image={data.image}
    />

};

