import React, { Suspense, useMemo } from "react";
import { observer } from "mobx-react";
import "./style.scss";
import { getAppStyle } from "../../utils";
import { TemplateType } from "../../enums/TemplateType";
import { useCMSStore } from "store/cmsStore";
import PageLayoutTemplate from "../../components/PageLayoutTemplate";


const PrivateHome = () => {
    const { appSettings } = useCMSStore();

    const templateName = useMemo(() => appSettings?.template?.code, [appSettings]);

    /** Lazy style load **/
    const templateStyle = useMemo(() => getAppStyle(templateName as TemplateType), [templateName]);
    const Style = useMemo(() => templateStyle ? React.lazy(() => templateStyle()) : null, [templateStyle]);


    return (
        <div className="app-private-wrapper">
            <Suspense fallback="">{Style !== null && <Style />}</Suspense>
            <PageLayoutTemplate />
        </div>
    );
};

export default observer(PrivateHome);
