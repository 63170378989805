import { jModalError, jModalSuccess, jModalWarning } from "@jnext/commons";
import { CMSAppSettingConfiguration } from "../../store/cmsStore";

export const modalMessageDialog = (modalMessage: any, clearModalMessage: any, appSettings: CMSAppSettingConfiguration | undefined, translateWord: (e?:string)=>string) => {

    const title = modalMessage?.message?.title;
    const text = modalMessage?.message?.text;
    switch (modalMessage?.type) {
        case 'success':
            jModalSuccess(translateWord(title ? title : 'modalSuccessTitle'),
                translateWord(text ? text : 'modalActionSuccess'),
                () => {clearModalMessage()},
                '')
            break;
        case 'error':
            jModalError(
                translateWord(title ? title : 'modalErrorTitle'),
                translateWord(text? text : 'modalErrorContent'),
                () => {clearModalMessage()},
                '')
            break;
        case 'warning':
            jModalWarning(
                "WARNING", translateWord('modalActionWarning'),
                () => {clearModalMessage()},
                '')
            break;
        default:
            break;
    }

};
