import {
    CMSConfiguration,
    getNavPathFromType,
    JPage,
    NavigationTypes
} from '@jnext/commons';
import {observer} from 'mobx-react';
import React, {useEffect, useMemo, useState} from 'react';
import {getHomeSectionDom, mzUseNavigate} from "../../../utils";
import {useCMSStore} from "../../../store/cmsStore";
import {AuthService} from "../../../service/AuthService";
import {Login} from "../../../pages/Login";
import {HttpService} from "../../../service/HttpService";
import {Custom} from "../../../pages/Custom";
import MetaTags from "../../MetaTags/MetaTags";

const HomePagePublic = () => {
    const navigate = mzUseNavigate();
    const {pages} = useCMSStore();

    if (!pages?.homepage && !AuthService.authorized) navigate(getNavPathFromType(NavigationTypes.NOT_FOUND));

    const cmsConfig: CMSConfiguration | undefined = useMemo(() => pages?.homepage, [pages]);
    const [options, setOptions] = useState<any>();
    const customProject = HttpService?.customProject;

    useEffect(() => {
        if (cmsConfig?.page?.options) {
            setOptions(cmsConfig?.page?.options)
        }
    }, [cmsConfig?.page]);

    if (!cmsConfig?.page) return <></>

    return (
        <JPage {...cmsConfig?.page} projectIntroChildren={<Login/>}>

            <MetaTags pageConfiguration={cmsConfig.page}/>

            {!!cmsConfig?.section?.sections?.length && getHomeSectionDom(cmsConfig?.section?.sections)}

            {customProject?.customFrontend && options?.custom &&
                <Custom customFrontendUrl={customProject?.customFrontendUrl} type={options?.customComponent}/>}

        </JPage>
    );
};

export default observer(HomePagePublic);
