import { action, makeObservable, observable } from "mobx";
import { WalletPoints } from "@jnext/ts-axios-wallet";
import { WalletService } from "service/WalletService";
import { useContext, createContext } from "react";

export class WalletStore {
    wallet: WalletPoints[] = [];

    constructor() {
        makeObservable(this, {
            wallet: observable,
            setWallet: action
        });
    }

    setWallet = (walletWallet: WalletPoints[]) => {
        this.wallet = walletWallet;
    }

    updateWallet = async () => {
        const data = await WalletService.retrieveWalletPoints();
        this.setWallet(data);
    }
    findWalletConfig = (logicId: string | undefined) => {
        return this.wallet.find(w => w.logicId?.toLowerCase() === logicId?.toLowerCase())
    }
    getFirst() {
        return this.wallet?.[0];
    }
}


export const walletStore = new WalletStore();

export const useWalletStore = () => useContext(
    createContext<WalletStore>(walletStore)
);


