import React, {Suspense, useEffect, useMemo, useState} from "react";
import {catalogSlickConfig, mzUseNavigate} from "../../utils";
import {
    CMSSection,
    getNavPathFromType,
    JSection,
    JSkeletonSlider,
    JSlider,
    NavigationTypes,
    SLIDER_4X,
} from "@jnext/commons";
import {observer} from "mobx-react";
import {CatalogService} from "../../service/CatalogService";
import {GetProductsPreviewPagedResponse, ProductPreviewDto} from "@jnext/ts-axios-formatdigitalcollection";
import {CatalogType} from "../../type/catalogTypes";
import {LoyaltyCollectionCardItem} from "../CatalogList/LoyaltyCollectionCardItem";
import {AuthService} from "../../service/AuthService";
import {InitiativeDetails, ProductDetails} from "@jnext/ts-axios-mz";

export interface Options {
    initiativeLogicId: string,
    page?: number,
    size?: number,
    slidesToScroll?: number,
    showDescription?: boolean
}


function LoyaltyCollectionSlider({ section, initiativeLogicIdProps, loyaltyInitiative }: {section?: CMSSection, initiativeLogicIdProps?: string, loyaltyInitiative?: ProductDetails }) {

    const navigate = mzUseNavigate();
    const [catalog, setCatalog] = useState<GetProductsPreviewPagedResponse>({results: []});
    const [loaded, setLoaded] = useState<boolean>(false);
    const sectionOptions = section?.options as unknown as Options;
    let {initiativeLogicId, page, size, slidesToScroll} = sectionOptions;
    const [logicIdInit, setLogicIdInit] = useState<string>(initiativeLogicId);
    const obtainableOptions = useMemo(()=> (sectionOptions as any)?.obtainable ?? undefined,[sectionOptions])

    useEffect(() => {
        if(initiativeLogicIdProps) {
            setLogicIdInit(initiativeLogicIdProps);
        }
    }, [initiativeLogicIdProps]);

    useEffect(() => {
        if(loyaltyInitiative){
            setCatalog(loyaltyInitiative as GetProductsPreviewPagedResponse);
            setLoaded(true);
        }else{
            (async () => {
                const data = await CatalogService.getProducts(initiativeLogicId, {}, {
                    page: page ?? 0,
                    pageSize:size ?? 10
                }, AuthService.authorized).finally(()=> setLoaded(true));
                if(data){
                    setCatalog(data)
                }
            })();
        }
    }, []);


    if (!loaded) {
        return <JSkeletonSlider hasTitle={true} />;
    }

    const openProduct = (productId: string) => {
        const paths = {
            [CatalogType.loyaltyCollection]: AuthService?.authorized ? NavigationTypes.CATALOG_LOYALTY_COLLECTION_DETAILS : NavigationTypes.PUBLIC_CATALOG_LOYALTY_COLLECTION_DETAILS
        }

        if(logicIdInit){
            return navigate(getNavPathFromType(paths[CatalogType.loyaltyCollection], {initiativeLogicId: logicIdInit, productId}));
        }
    }

    const showPrice: boolean = (typeof section?.options?.showItemPrice !== 'undefined')  ? ( (section?.options?.showItemPrice) as boolean) : true;
    return (
        <>
            {
                !!section && section?.enabled && <Suspense fallback={null}>
                    <>
                        {!!catalog?.results && catalog?.results?.length > 0 && <JSection {...section}>
                            <JSlider {...catalogSlickConfig(SLIDER_4X, {slidesToScroll: slidesToScroll})}>
                                {catalog.results?.map((el: ProductPreviewDto, i) => (
                                    <LoyaltyCollectionCardItem
                                        key={i}
                                        data={el}
                                        pricePosition={'TOPRIGHT'}
                                        obtainableOptions={obtainableOptions}
                                        showTag={true}
                                        showPrice={showPrice}
                                        onClick={() => openProduct(el?.logicId as string)}
                                        navigationUrl={getNavPathFromType(NavigationTypes.CATALOG_LOYALTY_COLLECTION_DETAILS, {initiativeLogicId: logicIdInit, productId: el?.logicId })}
                                    />
                                ))}
                            </JSlider>
                        </JSection>}
                    </>
                </Suspense>
            }
        </>
    );
}

export default observer(LoyaltyCollectionSlider);