import React from "react";
import {mzUseNavigate} from "../../utils";
import MicroApp from "commons/MicroApp";
import {CMSSection} from "@jnext/commons";
import {NavigationType} from "@jnext/commons";
import {HttpService} from "../../service/HttpService";
import {observer} from "mobx-react";
import {ContestDetailResponse} from "@jnext/ts-axios-contestformat";

function Contests({ component, section, contests }: { component?: string, section?: CMSSection, contests?: ContestDetailResponse[] }) {
  const navigate = mzUseNavigate();

  return (
      <>
        {HttpService.env?.formatContest && (
            <MicroApp
                entry={`${HttpService.env?.formatContest}`}
                minHeight={component ? 10 : 0}
                loaderRows={component ? 4 : 10}
                props={{
                  type: component || "page",
                  section,
                  contests: contests,
                  onNavigate: (path: NavigationType) => {
                    return navigate(path);
                  },
                }}
            />
        )}
      </>
  );
}

export default observer(Contests);
