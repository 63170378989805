import { ContestApi } from "@jnext/ts-axios-contestformat";
import axios from "axios";
import { HttpService } from "./HttpService";

export class ContestService {

    static async retrievePrivateContestList() {
        // @ts-ignore
        const contestPrivateApi = new ContestApi(undefined, HttpService.servicePath("format-contest"), axios);
        const res = await contestPrivateApi.getPrivateContests();
        return res.data;

    }

}