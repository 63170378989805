import {EventApprovalDto, EventApprovalStatusEnum} from "@jnext/ts-axios-eventformat";

interface CustomData {
    [key: string]: any;
}

interface Issue {
    customData: CustomData;
    logicId: string;
    title: string;
    description: string;
}

interface Tracking {
    customData: CustomData;
    status: string;
    operationTimestamp: string;
    note: string;
    issue: Issue;
    referredBoUserId: string;
}

interface RewardWalletCms {
    customData: CustomData;
    title: string;
    text: string;
    imageRef: string;
}

interface RewardWallet {
    customData: CustomData;
    type: string;
    walletConfigurationLogicId: string;
    amount: number;
    walletCms: RewardWalletCms;
}

interface CatalogGroup {
    customData: CustomData;
    logicId: string;
    externalId: string;
}

interface Catalog {
    customData: CustomData;
    logicId: string;
    externalId: string;
    currency: string;
    catalogGroups: CatalogGroup[];
}

interface Supplier {
    customData: CustomData;
    logicId: string;
    externalId: string;
}

interface Partner {
    customData: CustomData;
    logicId: string;
    externalId: string;
}

interface Brand {
    customData: CustomData;
    logicId: string;
    externalId: string;
    title: string;
    description: string;
    brandLogo: string;
}

interface Sector {
    customData: CustomData;
    logicId: string;
    externalId: string;
    title: string;
}

interface MediaContent {
    customData: CustomData;
    mediaType: string;
    flDefault: boolean;
    mediaUrl: string;
    flPreview: boolean;
}

interface Attribute {
    customData: CustomData;
    logicId: string;
    externalId: string;
    title: string;
    values: string[];
}

interface RelatedProduct {
    customData: CustomData;
    logicId: string;
    attributes: Attribute[];
}

interface RedemptionModality {
    customData: CustomData;
    logicId: string;
    pointsPrice: number;
    discountPoint: number;
    selfPoints: number;
    discountSelfPoints: number;
    selfCash: number;
    discountSelfCash: number;
    sellPrice: number;
    discountSellPrice: number;
    walletConfigurationLogicId: string;
}

interface Tag {
    customData: CustomData;
    position: string;
    title: string;
    value: string;
    imgUrl: string;
    colorCode: string;
}

interface Product {
    customData: CustomData;
    logicId: string;
    externalId: string;
    beginTimestamp: string;
    endTimestamp: string;
    availability: number;
    purchaseEnabled: boolean;
    title: string;
    longDescription: string;
    shortDescription: string;
    termsAndConditions: string;
    catalog: Catalog;
    supplier: Supplier;
    partner: Partner;
    brands: Brand[];
    sectors: Sector[];
    mediaContents: MediaContent[];
    type: string;
    relatedProducts: RelatedProduct[];
    redemptionModalities: RedemptionModality[];
    tags: Tag[];
    attributes: Attribute[];
}

interface ValueWallet {
    customData: CustomData;
    type: string;
    walletConfigurationLogicId: string;
    amount: number;
    walletCms: RewardWalletCms;
}

interface AdditionalCost {
    customData: CustomData;
    value: string;
    currency: string;
}

interface Value {
    customData: CustomData;
    wallets: ValueWallet[];
    additionalCost: AdditionalCost;
}

interface RewardItem {
    customData: CustomData;
    product: Product;
    quantity: number;
    value: Value;
    redemptionLogicId: string;
}

interface Reward {
    customData: CustomData;
    logicId: string;
    externalId: string;
    creationDate: string;
    referredBillingId: string;
    rewardWallets: RewardWallet[];
    rewardItems: RewardItem[];
}

interface AssignedBehaviour {
    customData: CustomData;
    type: string;
    status: string;
    referredLogicId: string;
    reward: Reward;
    errorMessage: string;
    amount: number;
}

interface EventExecution {
    customData: CustomData;
    externalId: string;
    logicId: string;
    projectId: string;
    key: string;
    eventConfigurationType: string;
    eventConfigurationLogicId: string;
    eventType: string;
    referredUserId: string;
    referredProfileId: string;
    occurrenceTimestamp: string;
    occurrenceCount: number;
    occurrenceParentId: string;
    value: number;
    status: string;
    payload: string;
    errorCode: string;
    errorMessage: string;
    channel: string;
    assignedBehaviourList: AssignedBehaviour[];
    formConfiguration: FormConfiguration;
}

interface FormFieldCmsConfig {
    customData: CustomData;
    logicId: string;
    tenant: string;
    project: string;
    language: string;
    hurlId: string;
    title: string;
    text: string;
    imageRef: string;
    imageUrl: string;
    additionalText: string;
    order: number;
    validationMessage: string;
}

interface FormField {
    customData: CustomData;
    hurlId: string;
    type: string;
    cmsConfig: FormFieldCmsConfig;
    values: string[];
    mandatory: boolean;
    confirmed: boolean;
    readOnly: boolean;
}

interface FormConfiguration {
    customData: CustomData;
    fields: FormField[];
}

export const PDAStatusLabels: Record<EventApprovalStatusEnum, string> = {
    [EventApprovalStatusEnum.Pending]: "In attesa di approvazione",
    [EventApprovalStatusEnum.Approved]: "Approvato",
    [EventApprovalStatusEnum.Rejected]: "Rifiutato",
    [EventApprovalStatusEnum.Error]: "Errore",
}

export interface EventApproval extends EventApprovalDto {
    customData: CustomData;
    trackingList: Tracking[];
    payload?: string;
    creationDate: string;
}
