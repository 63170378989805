import { CMSSection } from "@jnext/commons";
import { observer } from "mobx-react";
import { useEffect, useState } from 'react';
import '../../style/T1/HeroCard/index.scss';
import AvatarSection from "./sections/avatarSection";
import { CtaProfileSection } from "./sections/ctaProfileSection";
import { UserSection } from "./sections/userSection";
import { WalletSection } from "./sections/walletSection";

type HeroCardProps = {
    sections?: CMSSection[];
    inNavbar?: boolean
}

export enum HeroSectionType {
    'HERO_SECTION_AVATAR' = 'HERO_SECTION_AVATAR',
    'HERO_SECTION_USER' = 'HERO_SECTION_USER',
    'HERO_SECTION_WALLET' = 'HERO_SECTION_WALLET',
    'HERO_SECTION_PROFILE' = 'HERO_SECTION_PROFILE',
}

const HeroCard = ({sections, inNavbar}: HeroCardProps) => {
    // Tracking dello scrolling
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => window.scrollY === 0 ? setScrolled(false) : setScrolled(true));
    }, [scrolled]);

    const getSectionDom = (sections?: CMSSection[] | undefined, inNavbar?:boolean, scrolled?:boolean) => {
        return sections?.map((section: CMSSection, i)=> {
            if(inNavbar && section?.type === HeroSectionType.HERO_SECTION_AVATAR) return;
            switch (section?.type) {
                case HeroSectionType.HERO_SECTION_AVATAR: return <AvatarSection section={section} key={i} />
                case HeroSectionType.HERO_SECTION_USER: return <UserSection section={section} key={i} inNavbar={inNavbar}/>
                case HeroSectionType.HERO_SECTION_WALLET: return <WalletSection section={section} key={i} inNavbar={inNavbar}/>
                case HeroSectionType.HERO_SECTION_PROFILE: return <CtaProfileSection section={section} key={i} inNavbar={inNavbar}/>
            }
        });
    }


    return (
        <div className={`HeroSectionContainer`}>
            <div className={`HeroCardContainer ${inNavbar ? 'fixed' : ''} ${scrolled ? 'scrolled' : ''}`}>
                {getSectionDom(sections, inNavbar)}
            </div>
        </div>
    )
}

export default observer(HeroCard);