import {CMSSection} from "@jnext/commons";

export enum StepperSectionTypes {
    STEPS = 'STEPS',
    STEP = 'STEP',
}

export interface StepperSection extends CMSSection {
    type: StepperSectionTypes.STEPS,
    sections?: (CMSSection & { type: StepperSectionTypes.STEP })[]
}