import React from "react";
import {CatalogCardItem, CatalogCardItemProps} from "./CatalogCardItem";


export const LoyaltyCollectionCardItem: React.FC<CatalogCardItemProps> = props => {

    props = {
        ...props,
        pricePosition: 'TOPRIGHT',
        showTag: true,
    }
    return (<CatalogCardItem {...props} />);
}