import {useTranslationWord} from "../../../../utils";
import {VasService} from "../../../../service/VasService";
import {modalMessageDialog} from "../../../../commons/ModalMessage/ModalMessage";

const VasInvoice = ({transactionLogicId, hasInvoice = false}: { transactionLogicId: string, hasInvoice: boolean }) => {
    const translateWord = useTranslationWord();

    const handleInvoiceClick = async () => {
            try {
                const data = await VasService.getInvoice(transactionLogicId);
                if (data.hosted_invoice_url) {
                    window.open(data.hosted_invoice_url, '_blank');
                } else {
                    modalMessageDialog(
                        {type: 'error', message: {title: "WeAreSorry", text: 'Invoice_not_available'}},
                        () => {},
                        undefined,
                        translateWord
                    );
                }

            } catch (e: any) {
                if (e.response && e.response.status === 500) {
                    modalMessageDialog(
                        {type: 'error', message: {title: "WeAreSorry", text: 'Invoice_not_available'}},
                        () => {},
                        undefined,
                        translateWord
                    );
                } else {
                    console.log('Error fetching subscription details:', e);
                }
            }
    };

    return (
        <div className={"request_invoice"} onClick={()=> hasInvoice && handleInvoiceClick()}>
            {translateWord( hasInvoice ? "Request_invoice" : "Request_not_invoice")}
        </div>
    );
};

export default VasInvoice;
