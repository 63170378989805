import React, {useState} from "react";
import {Col, Row} from "antd";
import {ContainerBox} from "../../ContainerBox";
import {CheckoutConfirmSection, CheckoutConfirmSectionTypes, CheckoutSubMainSection} from "../../models";
import {ProductsBox} from "./ProductsBox";
import {DataBox} from "./DataBox";
import {ModalForm} from "./ModalForm";
import {CartSummary} from "../../../Cart/components";
import {
    BasketResponse,
    BasketTypeEnum,
    CheckoutRequest,
    FormatCourierDto,
    GetBasketResponseItem
} from "@jnext/ts-axios-formatdigitalcollection";
import {JCourierSelect} from "@jnext/commons";
import {useTranslationWord} from "../../../../utils";

interface DataSummaryProps {
    cmsConfig: CheckoutSubMainSection;
    dataFormCmsConfig: CheckoutSubMainSection;
    basket: BasketResponse;
    initiativeLogicId: string,
    removeItem: (item: GetBasketResponseItem) => void;
    dataForm: CheckoutRequest;
    onCheckoutDataUpdate: (formState: CheckoutRequest) => void;
    completeCheckout: () => void;
    couriersList?: FormatCourierDto[] | undefined,
    checkoutWithCourier: boolean
    showDeliveryDaysInfo: boolean
    genericDeliveryDays?: number
}

export const DataSummary: React.FC<DataSummaryProps> = ({
                                                            cmsConfig,
                                                            dataFormCmsConfig,
                                                            basket,
                                                            initiativeLogicId,
                                                            removeItem,
                                                            dataForm,
                                                            onCheckoutDataUpdate,
                                                            completeCheckout,
                                                            couriersList,
                                                            checkoutWithCourier= false,
                                                            showDeliveryDaysInfo = false,
                                                            genericDeliveryDays

                                                        }) => {
    const translateWord = useTranslationWord();
    const cms = (cmsConfig as CheckoutConfirmSection);
    const ordersSection = cms?.sections?.find((el: any) => el.type == CheckoutConfirmSectionTypes.ORDERS);
    const userDataSection = cms?.sections?.find((el: any) => el.type == CheckoutConfirmSectionTypes.USER_DATA);
    const cartInfoSection = cms?.sections?.find((el: any) => el.type == CheckoutConfirmSectionTypes.CART_INFO);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const isItems = basket?.shipments?.find(item => item.productType === 'ITEM');

    return <>
        <div className={'data-summary-step'}>
            <Row className={'sides-container'}>
                <Col className={'left-side'}>
                    <div className={'side-row'}>
                        <ContainerBox>
                            {
                                ordersSection &&
                                <ProductsBox cmsSection={ordersSection} removeItem={removeItem} basket={basket} showDeliveryDaysInfo={showDeliveryDaysInfo} genericDeliveryDays={genericDeliveryDays} />
                            }
                        </ContainerBox>
                    </div>
                    <div className={'side-row'}>
                        <ContainerBox>
                            {
                                userDataSection &&
                                <DataBox dataForm={dataForm} cmsSection={userDataSection}
                                         onFormEdit={() => setModalOpen(true)}
                                />
                            }
                        </ContainerBox>
                    </div>
                    {checkoutWithCourier && isItems && <div className={'side-row'}>
                        <ContainerBox>
                            <JCourierSelect
                                label={translateWord('COURIER_CHOICE')}
                                couriers={couriersList}
                                courierChoice={dataForm?.shippingCourier}
                                setCourierChoice={(courierInfo)=>{
                                    onCheckoutDataUpdate({
                                        shippingCourier: courierInfo} as CheckoutRequest)
                                }}/>
                        </ContainerBox>
                    </div>}
                </Col>
                <Col className={'right-side'}>
                    <div className={'total-box-container'}>
                        <CartSummary
                            section={cartInfoSection as any}
                            basket={basket}
                            initiativeLogicId={initiativeLogicId!}
                            basketType={BasketTypeEnum.BasketPurchase}
                            completeCheckout={completeCheckout}
                        />
                    </div>
                </Col>
            </Row>
        </div>

        {
            modalOpen &&
            <ModalForm
                cmsConfig={dataFormCmsConfig}
                onClose={() => setModalOpen(false)}
                onComplete={(formState) => {
                    onCheckoutDataUpdate(formState);
                    setModalOpen(false);
                }}
                basket={basket}
                initiativeLogicId={initiativeLogicId}
                initialValues={dataForm}
            />
        }
    </>;
}

