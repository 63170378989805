import React, {useMemo} from "react";
import {breakpointsMin, CMSSection, JCMSCTA} from "@jnext/commons";
import moment from "moment/moment";
import {FORMAT_DATE, generatePathUrl, useTranslationWord} from "../../../../utils";
import {UserSubscriptionDetailDto, UserSubscriptionChangeDto} from "@jnext/ts-axios-formatdigitalcollection";
import {useWindowSize} from "@react-hook/window-size";

interface SubscriptionDetailProps  {
    subscriptionDetail?: UserSubscriptionDetailDto;
    scheduledChange?: UserSubscriptionChangeDto;
    onClick: () => void;
    cmsSection: CMSSection | undefined;
}

// Definisce il componente SubscriptionDetail che accetta le proprietà subscriptionDetail, scheduledChange, onClick e cmsSection
const SubscriptionDetail = ({subscriptionDetail, scheduledChange, onClick, cmsSection}: SubscriptionDetailProps) => {
    // Ottiene la funzione di traduzione
    const translateWord = useTranslationWord();

    const [width] = useWindowSize();
    const isUnderLg = useMemo<boolean>(() => width < breakpointsMin?.lg, [width]);

    // Ottiene il percorso dell'immagine dal CMS
    const imagePath = cmsSection?.image?.[0]?.src;
    // Ottiene le CTA (Call To Action) dal CMS
    const ctaCms = cmsSection?.cta;
    const goToVas = ctaCms?.find(cta => cta.scope === 'VAS');
    const submitCta = ctaCms?.find(cta => cta.scope === 'MANAGE_VAS');
    const submitAnnulledCta = ctaCms?.find(cta => cta.scope === 'CANCEL_VAS');
    const annulledCta = ctaCms?.find(cta => cta.scope === 'CANCEL');

    // Ottiene il titolo del prodotto dall'abbonamento o dal cambio programmato
    const title = subscriptionDetail?.product?.title || scheduledChange?.product?.title;
    // Ottiene le modalità di riscatto del prodotto dall'abbonamento o dal cambio programmato
    const redemptionModalities = subscriptionDetail?.product?.redemptionModalities?.[0] || scheduledChange?.product?.redemptionModalities?.[0];

    return (
        <>
            <div className="details-wrapper">
                <div className="details-header">
                    <div className="icon-text-container">
                        <div className="icon icon-bg">
                            {/* Mostra l'immagine se disponibile */}
                            {imagePath ? (
                                <img src={generatePathUrl(imagePath)} aria-hidden={true} alt="icon"/>
                            ) : null}
                        </div>
                        <div className="text-container">
                            <p className="subscription-label">{translateWord("SUBSCRIPTION_LABEL_TEXT")}</p>
                            {/* Mostra il titolo del piano di abbonamento */}
                            {title && <p className="subscription-plan">{translateWord("vas-card-title")} {title}</p>}
                            <div className="next-payment">
                                {/* Mostra la data del prossimo pagamento */}
                                {subscriptionDetail?.nextCharge && <>
                                    <span className={'translate_label'}>{translateWord("NEXT_PAYMENT")}</span>
                                    <span>{moment(subscriptionDetail?.nextCharge)?.format(FORMAT_DATE)} </span>
                                </>}
                                {/* Mostra la data di attivazione del prossimo abbonamento */}
                                {scheduledChange?.activationDate && <>
                                    <span>
                                        {translateWord("NEXT_ACTIVATION", {date: moment(scheduledChange?.activationDate)?.format(FORMAT_DATE)})}
                                    </span>
                                </>}
                            </div>
                            {isUnderLg &&
                                <div className="price-container">
                                    <span>€</span>
                                    {redemptionModalities?.sellPrice && <span>{redemptionModalities?.sellPrice}</span>}
                                    {redemptionModalities?.pricingModel?.cronDecription &&
                                        <span
                                            className={'cronDescription'}>{redemptionModalities?.pricingModel?.cronDecription.toLowerCase()}</span>}
                                </div>}
                        </div>
                    </div>
                    {!isUnderLg &&
                        <div className="price-container">
                            <span className={'price'}>€</span>
                            {redemptionModalities?.sellPrice &&
                                <span className={'price'}>{redemptionModalities?.sellPrice}</span>}
                            {redemptionModalities?.pricingModel?.cronDecription &&
                                <span
                                    className={'price cronDescription'}>{redemptionModalities?.pricingModel?.cronDecription.toLowerCase()}</span>}
                        </div>}
                </div>
                {/* Mostra i pulsanti di azione se c'è un abbonamento */}
                {!!subscriptionDetail && <div className="button-container">
                    {goToVas && <JCMSCTA {...goToVas}/>}
                    {submitCta && <JCMSCTA {...submitCta} action={onClick}/>}
                    {submitAnnulledCta && <JCMSCTA {...submitAnnulledCta} action={onClick}/>}
                </div>}
                {/* Mostra i pulsanti di azione se c'è un abbonamento pianificato */}
                {!!scheduledChange && annulledCta &&
                    <div className="button-container-end">
                        <JCMSCTA {...annulledCta} action={onClick}/>
                    </div>}
            </div>
        </>
    );
};

export default SubscriptionDetail;
