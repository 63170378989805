import axios from "axios";
import {HttpService} from "./HttpService";
import {TransactionApi} from "@jnext/ts-axios-mz";

export class PointService {

    static async retrieveHistoryPoints(page: number, size: number, walletLogicId?: string | undefined, period?: {transactionDateStart:string, transactionDateEnd: string},  initiativeTypes?: string) {
        // @ts-ignore
        const transactionApi: TransactionApi = new TransactionApi(undefined,  HttpService.servicePath('solution-mz'), axios);
        try {
            const res = await transactionApi.searchTransaction(
                page,
                size,
                undefined,
                walletLogicId ? [walletLogicId] : undefined,
                initiativeTypes? [initiativeTypes]: undefined,
                period?.transactionDateStart,
                period?.transactionDateEnd);
            return res?.data;
        }
        catch(e: any) {}
    };

}