import React from "react";
import {JModal} from "@jnext/commons";
import {DataForm} from "../DataForm/DataForm";
import {CheckoutDataSection, CheckoutSubMainSection} from "../../models";
import {BasketResponse, CheckoutRequest} from "@jnext/ts-axios-formatdigitalcollection";

interface ModalFormProps {
    cmsConfig: CheckoutSubMainSection;
    basket: BasketResponse;
    initiativeLogicId: string;
    onComplete: (formState: CheckoutRequest) => void;
    onClose: () => void;
    initialValues: CheckoutRequest;
}

export const ModalForm: React.FC<ModalFormProps> = ({cmsConfig, basket, initiativeLogicId, onClose, onComplete, initialValues}) => {

    return (
        <JModal
            width={800}
            isModalVisible={true}
            footer={null}
            handleCancel={onClose}
        >
            <div className={''}>
                <DataForm cmsConfig={cmsConfig as CheckoutDataSection} onComplete={onComplete} basket={basket}
                          initiativeLogicId={initiativeLogicId} initialValues={initialValues} />
            </div>
        </JModal>
    );
}