import axios from "axios";
import {HttpService} from "./HttpService";
import {EventsApi, GetEventApprovalsResponse} from "@jnext/ts-axios-eventformat";
import {EventApprovalsParams} from "../type/interfaces";

export class PdaService {

    static searchPdaHistoryList = async (config: EventApprovalsParams): Promise<GetEventApprovalsResponse> => {

        try {
            // @ts-ignore
            const eventApi = new EventsApi(undefined, HttpService.servicePath("format-event"), axios);
            // @ts-ignore
            const res = await eventApi.getEventApprovals(config.page, config.size, config.eventConfigurationLogicId, config.includeMetadata, config.sort, config.fromDate, config.toDate, config.userIds, config.eventExecutionIds, config.status, config.referredBoUserId, config.key, config.options);
            return res.data;

        } catch (ex: any) {
            throw ex;
        }
    }

    static async searchPdaDetails(eventApprovalLogicId: string) {

        try {
            // @ts-ignore
            const eventApi = new EventsApi(undefined, HttpService.servicePath("format-event"), axios);
            // @ts-ignore
            const res = await eventApi.getApprovalDetail(eventApprovalLogicId);
            return res.data;

        } catch (ex: any) {
            console.log(ex)
        }
    }

}