import React from "react";
import {mzUseNavigate} from "../../utils";
import MicroApp from "../../commons/MicroApp";
import {getNavPathFromType, NavigationType} from "@jnext/commons";
import {HttpService} from "../../service/HttpService";
import {useConsumerStore} from "../../store/consumerStore";
import {useParams} from "react-router-dom";

export const ActivateUser = () => {
    const customProject = HttpService?.customProject;
    const navigate = mzUseNavigate();
    const { consumerInfo } = useConsumerStore();

    const params = useParams();

    return (
        <>
            {
                HttpService.env?.coreUser &&
                <MicroApp
                    entry={`${HttpService.env?.coreUser}`}
                    props={{
                        type: 'activate-user',
                        customProject: customProject,
                        JNextNavigate: navigate,
                        useParams: params,
                        userProfile: consumerInfo,
                        onNavigate(type: NavigationType) {
                           return navigate(getNavPathFromType(type))
                        },
                        keyCloakClientId: HttpService.env?.keycloakClientID as string,
                    }}
                />
            }
        </>
    )
}