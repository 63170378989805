import React from "react";
import {ProductPreviewDto} from "@jnext/ts-axios-formatdigitalcollection";
import "./cardSubscription.scss";
import { CMSSection, JSlider} from "@jnext/commons";
import {CardSubscription} from "../CardSubcription";

export const JCardSubscription = ({products, section}: {
    products: ProductPreviewDto[];
    section?: CMSSection;
}) =>{

    return(<JSlider
            slidesToShow={1}
            dots={false}>

            {products.map((product, index) => {
                    return (
                        <CardSubscription key={index} product={product} section={section} />
                    )
                }
            )}

        </JSlider>
    )
}