import {CMSSection, getBrand, getProductImage} from "@jnext/commons";
import React, {Fragment, useEffect} from "react";
import {BasketResponse, GetBasketResponseItem} from "@jnext/ts-axios-formatdigitalcollection";
import {CatalogItemValue} from "../../../../commons/ItemValue/CatalogItemValue";
import {generatePathUrl, useTranslationWord} from "../../../../utils";
import {ProductType} from "../../../../enums/ProductType";

interface ProductsBoxProps {
    cmsSection: CMSSection;
    basket: BasketResponse;
    removeItem: (item: GetBasketResponseItem) => void;
    showDeliveryDaysInfo: boolean;
    genericDeliveryDays?: number
}
export const ProductsBox: React.FC<ProductsBoxProps> = ({cmsSection, basket, removeItem, showDeliveryDaysInfo = false, genericDeliveryDays}) => {

    const translateWord = useTranslationWord();

    return <div className={'products-box'}>
        <div className={'box-head'}>
            <div className={'box-head-left'}>
                <h3 className={'box-title'}>{cmsSection?.title}</h3>
            </div>
            <div className={'box-head-right'}>
                <div className={'products-qty'}>{basket?.items?.length} {cmsSection?.table?.products?.label}</div>
            </div>

        </div>

        <div className={'list-container'}>
            <ul>
                {
                    basket?.shipments?.sort((a,b) => a?.productType?.localeCompare(b?.productType ?? '') || (a?.deliveryDays ?? 0) - ( b?.deliveryDays ?? 0 ))
                    .map((items, y, row) => (
                       <Fragment key={y}>

                            {
                               showDeliveryDaysInfo &&

                                <Fragment>
                                    {
                                        (items?.productType ?? 'ITEM') === ProductType.PHYSICAL
                                            ?
                                            <p className={'delivery_description'}>
                                                <strong>
                                                    { items.deliveryDays ? translateWord('deliveryDaysCartConfirm', { count: items.deliveryDays }) : translateWord('deliveryDaysCartConfirmGeneric' , { count: genericDeliveryDays }) }
                                                </strong>
                                            </p>
                                            :
                                            <p className={'delivery_description'}>
                                                <strong>{ translateWord('deliveryDaysCartConfirm_vouchers') }
                                                </strong>
                                            </p>
                                    }
                                </Fragment>
                            }

                            {
                                items?.items?.map((item, i) => (
                                    <li key={i}>
                                        <ProductRow
                                            item={item}
                                            cmsSection={cmsSection}
                                            removeItem={() => removeItem(item)}
                                        />
                                    </li>
                                ))
                            }
                        </Fragment>
                    ))
                }
            </ul>
        </div>
    </div>
}

interface ProductRowProps {
    cmsSection?: CMSSection;
    item: GetBasketResponseItem;
    removeItem?: () => void;
}

export const ProductRow: React.FC<ProductRowProps> = ({cmsSection, item, removeItem}) => {
    const brand = getBrand(item);
    const img = generatePathUrl(getProductImage(item?.cms));

    return <div className={'product-row'}>
        <div className={'img-container'}>
            <figure>
                <img src={img} alt={""} aria-hidden={true}/>
            </figure>
        </div>
        <div className={'product-data'}>
            <div className={'product-brand'}>{brand?.title}</div>
            <div className={'product-name'}>
                <span className={"product-name__quantity"}>{item?.quantity} x</span>
                <span className={"product-name__title"}>{item?.cms?.title}</span>
            </div>
            {/*<div className={'product-cta'} onClick={removeItem}>{cmsSection?.table?.delete?.label}</div>*/}
        </div>
        {
            item?.redemptionModality &&
            <div className={'product-value'}>
                <CatalogItemValue redemptionModality={item?.redemptionModality}/>
            </div>
        }

    </div>;
}
