import React, {useState} from "react";
import {Row} from "antd";
import {FORMAT_DATE} from "../../../../../../utils";
import moment from "moment";
import {PointRow} from "../../PointRow";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import {ComponentStatusTag, StatusTagType} from "../../../../../../commons/ComponentStatusTag/ComponentStatusTag";
import {CMSSection} from "@jnext/commons";
import VasInvoice from "../../VasInvoice";


type TableDesktopViewProps = {
    historyPoints: any[],
    historyPointsCMS: CMSSection,
}
export const VasTableMobileView: React.FC<TableDesktopViewProps> = ({historyPoints, historyPointsCMS}) => {
    const [collapseStatus, setCollapseStatus] = useState<Record<number, any>>({});

    const toggleCollapse = (index: number) => {
        const status = collapseStatus[index] || false;

        setCollapseStatus({
            ...collapseStatus,
            [index]: !status,
        });
    }

    return <>
        {
            historyPoints.length > 0 && historyPoints?.map((list: any, index: number) => {
                const initiative = list?.initiative;
                return (
                    <Row key={index} className='mobileRowTable'>
                        <p className="default-label">{historyPointsCMS.table?.initiative?.label.toUpperCase()}</p>

                        <div className='headTable vas-flex-column'>
                            <div className={"vas-mobile-spacing"}>
                                <div className='infoContainer vas-flex-column'>
                                    <Row>
                                        {initiative?.cms?.title && <div className='vas-flex-column'>
                                            <label className='causalText'>{initiative?.cms?.title}</label>
                                        </div>}
                                    </Row>
                                </div>
                                <div style={{display: 'flex', justifyContent: "end", width: '100%'}} className={'arrowCell'}
                                     onClick={() => toggleCollapse(index)}>
                                    {!collapseStatus[index] && <DownOutlined/>}
                                    {collapseStatus[index] && <UpOutlined/>}
                                </div>
                            </div>
                        </div>
                        {
                            collapseStatus[index] && <div className='vas-flex-column'>
                                <div className={`pointDetails vas-mobile-spacing`}>
                                    <div>
                                        {list?.transactionDate && (
                                            <>
                                                <label className='dateTransactionText default-label'>
                                                    {historyPointsCMS.table?.start_date?.label.toUpperCase()}
                                                </label>
                                                <span>
                                                    {moment(list?.transactionDate).format(FORMAT_DATE)}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                    <div>
                                        {list?.amount &&
                                            <div className="val-alignment">
                                                <span
                                                    className="default-label">{historyPointsCMS.table?.points?.label.toUpperCase()}</span>
                                                <PointRow
                                                    amount={list?.amount}
                                                    src={list?.cmsDto?.imageRef}/>
                                            </div>}
                                    </div>
                                </div>

                                <div className={`pointDetails default-label vas-mobile-spacing`}>
                                    {list.statusExternalId
                                        && <div className="default-label val-alignment">
                                            <label
                                                className="default-label">{historyPointsCMS.table?.status?.label.toUpperCase()}</label>
                                            <ComponentStatusTag type={StatusTagType.PURCHASE_VAS}
                                                                orderStatus={list.statusExternalId}/>
                                        </div>}
                                    {<div className="default-label val-alignment">
                                        <label
                                            className="default-label">{historyPointsCMS?.table?.invoice?.label}</label>
                                        <VasInvoice transactionLogicId={list?.logicId} hasInvoice={list.hasInvoice}/>
                                    </div>
                                    }
                                </div>
                            </div>
                        }
                    </Row>
                )
            })
        }
    </>
}