import {REDEMPTION_FIELDS, RedemptionFieldsHurlIds} from "@jnext/commons";
import {DigitalCollectionApi, GetProductResponse} from "@jnext/ts-axios-formatdigitalcollection";
import axios from "axios";
import {PRODUCT_CMS} from "pages/ProductDetail/cms";
import {HttpService} from "./HttpService";

export class ProductService {

    static getRelatedPrivateProducts(initiativeLogicId: string, relatedProductsIds: string[]): Promise<GetProductResponse[]> {
        return Promise.all(relatedProductsIds.map(relatedProductsId => this.getPrivateProduct(initiativeLogicId, relatedProductsId)))
    }

    static async getPrivateProduct(initiativeLogicId: string, logicId: string) {

        // @ts-ignore
        const instance = new DigitalCollectionApi(undefined, HttpService.servicePath('format-digital-collection'), axios);
        const res = await instance.getProductPrivate(initiativeLogicId, logicId);
        if (process.env.NODE_ENV !== 'production') { // TODO REMOVE
            res.data.purchaseEnabled = true
        }
        return res.data
    }

    static getRelatedPublicProducts(initiativeLogicId: string, relatedProductsIds: string[]): Promise<GetProductResponse[]> {
        return Promise.all(relatedProductsIds.map(relatedProductsId => this.getPublicProduct(initiativeLogicId, relatedProductsId)))
    }

    static async getPublicProduct(initiativeLogicId: string, id: string) {
        // @ts-ignore
        const instance = new DigitalCollectionApi(undefined, HttpService.servicePath('format-digital-collection'), axios);

        const res = await instance.getProductPublic(initiativeLogicId, id);
        return res.data
    }

    static getProductCMS() {
        return Promise.resolve(PRODUCT_CMS)
    }

    static getRedeemFields() {
        return Promise.resolve(REDEMPTION_FIELDS.filter(field => field.hurlId.startsWith('delivery.') && field.hurlId !== RedemptionFieldsHurlIds.DELIVERY_USE_PERSONAL_DATA))
    }
}