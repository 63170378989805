import { loadMicroApp } from "qiankun";
import React, { useEffect, useMemo, useRef, useState } from "react";
import "./style.scss";
import { addMicroAppInstance, randomId, removeMicroAppDuplicatedStyles } from "../../utils";
import { Col, Row, Skeleton } from "antd";
import { useMicroApp } from "../../hooks/useMicroApp";
import { MicroAppProps } from "../../type/MicroAppInterfaces";

interface microAppInterface {
    entry: string; // url
    props: MicroAppProps;
    loaderRows?: number;
    minHeight?: number; // each block 50px
}

const MicroApp: React.FC<microAppInterface> = ({ entry, props, loaderRows = 0, minHeight = 0 }) => {
    if(!entry) return <></>;
    const child = useRef(null);
    const {baseProps} = useMicroApp();
    const [fetched, setFetched] = useState(false);
    const rowsArray = useMemo(() => Array.from({ length: loaderRows }, (value, index) => index), [loaderRows]);
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {

        // If base props not ready -> return
        if(!baseProps || loaded) {
            return;
        }

        // set as loaded
        setLoaded(true);

        const container = (child.current as unknown) as HTMLElement;

        const divId = `microApp_Id_${randomId()}`;
        container.setAttribute('id', divId);

        // Set is loading
        baseProps.setLoading?.(true);

        const app = loadMicroApp({
            name: randomId().toString(),
            entry,
            // container: (child.current as unknown) as HTMLElement,
            container: container,
            props: {
                ...baseProps,
                ...props,
                containerId: divId,
            }
        } as any);

        // Show after load
        app.loadPromise
            .then(() => {

                // Remove duplicated styles in MicroApp
                removeMicroAppDuplicatedStyles();

            }).catch(error=> {
                return console.log(error)
        })
            .finally(() => {


                setTimeout(() => {
                    baseProps.setLoading?.(false);
                    // Loaded
                    setFetched(true);
                }, 500);
            });

        // Save instance
        addMicroAppInstance(app);
    }, [child, baseProps]);

    return (
        <>
            <div className={"microapp-container"} style={{minHeight: loaderRows > 0 ? loaderRows * 50 : minHeight * 50}}>
                {
                    !fetched &&
                    <Row className={'microapp-loader'} gutter={[0, 12]} justify={'center'}>
                        {
                            rowsArray.map((el, i) => (
                                <Col span={18} key={i}>
                                    <Skeleton.Button active={true} size={'large'} block={true} />
                                </Col>
                            ))
                        }
                    </Row>
                }
                <div
                    ref={child}
                    className={`microapp-content ${fetched ? "visible" : ""}`}
                />
            </div>
        </>
    );
};

export default MicroApp;
