import {BalancesApi} from "@jnext/ts-axios-wallet";
import axios from "axios";
import {HttpService} from "./HttpService";
import {WalletPoints} from "@jnext/ts-axios-wallet/models";

export class WalletService {

    static async retrieveWalletPoints(): Promise<WalletPoints[]> {
        // @ts-ignore
        const balanceApi: BalancesApi = new BalancesApi(undefined,  HttpService.servicePath('core-wallet'), axios);
        const res = await balanceApi.getPointWallets();
        return res.data?.filter(wallet => wallet?.cmsConfig && wallet.cmsConfig?.isVisible === true);
    };
}