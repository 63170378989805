import {CMSConfiguration, CMSStaticSection, JCMSStaticSections, JPage} from "@jnext/commons";
import React, {FC, Fragment, useMemo} from 'react';
import {Navigate} from 'react-router-dom';
import {useCMSStore} from 'store/cmsStore';
import MetaTags from "../../components/MetaTags/MetaTags";


const Faq: FC = () => {
  const { pages } = useCMSStore();
  const config: CMSConfiguration | undefined = useMemo(() => pages?.faq, [pages?.faq]);

  if (!config) {
    return <Navigate to='/404' />
  }

  return <>
    <Fragment>
      {
        config.page && (
          <JPage {...config.page} options={config.page.options || { headerAlign: 'center' }}>
            <MetaTags pageConfiguration={config.page}/>
            <JCMSStaticSections.Content {...config.section as CMSStaticSection.Content} />
          </JPage>
        )
      }
    </Fragment>
  </>
}

export default Faq;
