import React from "react";
import {observer} from "mobx-react";
import MicroApp from "../../../../commons/MicroApp";
import {ErrorResponse} from "@jnext/ts-axios-mz";
import {HttpService} from "../../../../service/HttpService";
import {useParams} from "react-router-dom";


const HistoryContest = () => {
    const { contestId } = useParams();

    return(<>
            {HttpService.env?.formatContest && (
                <MicroApp
                    entry={`${HttpService.env?.formatContest}`}
                    props={{
                        type: "history-beats",
                        contestId,
                        initiativeFormat: HttpService?.initiativeFormat,
                        onContestSuccess: () => {},
                        onContestError: (error: ErrorResponse) => {},
                    }}
                />
            )}
        </>
    );
}

export default observer(HistoryContest);