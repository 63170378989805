import React from "react";
import {AdditionalCost, Points} from "@jnext/ts-axios-mz";
import {ItemValue, ItemValueProps, ItemValueString} from "./ItemValue";
import {add} from "husky";
import {useWalletStore} from "../../store/walletStore";

interface RewardTotalsItemValueProps {
    points?: Points[];
    additionalCost?: AdditionalCost;
}

export const RewardTotalsItemValue: React.FC<RewardTotalsItemValueProps> = ({points, additionalCost}) => {

    /**
     * If no data exis
     */
    if(
        (!points && !additionalCost)
    ) {
        return <></>;
    }

    const { wallet: wallets } = useWalletStore();

    const findWallet = (walletLogicId: string) => wallets?.find(wallet => wallet?.logicId?.toLowerCase() === walletLogicId?.toLowerCase());

    const props: ItemValueProps = {}

    if(!!points && points?.length > 0) {
        props.points = points.map(el =>
            ({
                value: el.amount,
                image: findWallet(el.walletConfigurationLogicId)?.cmsConfig?.imageRef,
            })
        );
    }

    if(additionalCost?.value) {
        props.cost = {
            value: parseInt(additionalCost?.value),
            currency: additionalCost?.currency ? additionalCost?.currency : '€',
        };
    }

    return <ItemValue {...props}/>
}


export const RewardTotalsItemValueString: React.FC<RewardTotalsItemValueProps> = ({points, additionalCost}) => {

    /**
     * If no data exis
     */
    if(
        (!points && !additionalCost)
    ) {
        return <></>;
    }

    const { wallet: wallets } = useWalletStore();

    const findWallet = (walletLogicId: string) => wallets?.find(wallet => wallet?.logicId?.toLowerCase() === walletLogicId?.toLowerCase());

    const props: ItemValueProps = {}

    if(!!points && points?.length > 0) {
        props.points = points.map(el =>
            ({
                value: el.amount,
                image: findWallet(el.walletConfigurationLogicId)?.cmsConfig?.imageRef,
            })
        );
    }

    if(additionalCost?.value) {
        props.cost = {
            value: parseInt(additionalCost?.value),
            currency: additionalCost?.currency ? additionalCost?.currency : '€',
        };
    }

    return <ItemValueString {...props}/>
}