import { CMSSection } from "@jnext/commons"
import {
    CartInfoSection,
    CartMainInfoSection,
    CartProductsListSection,
    CartSectionType,
    CartSectionTypes
} from "./models"

export const findCartSection = (type: CartSectionType) => {
    return (section: CMSSection) => section.type === type && section.enabled !== false 
}

export function isMainInfoSection(section: any): section is CartMainInfoSection {
    return section.type === CartSectionTypes.CART_MAIN_INFO
}
export function isProductsListSection(section: any): section is CartProductsListSection {
    return section.type === CartSectionTypes.CART_PRODUCTS_LIST
}
export function isInfoSection(section: any): section is CartInfoSection {
    return section.type === CartSectionTypes.CART_INFO
}