import { CMSSection, JCMSCTA } from "@jnext/commons";
import React from "react";

export const CtaProfileSection = ({ section, inNavbar }: { section: CMSSection, inNavbar?:boolean }) => {
    
    if (!section?.enabled) return <React.Fragment />;

    const cta = section?.cta?.[0];

    if (!cta) return <React.Fragment />;

    return (
        <div className={`HeroCardButton ${inNavbar ? 'fixed': ''}`} >
            <div className="goToProfile">
                <JCMSCTA {...cta} />
            </div>
        </div>
    );
}