import {CMSSection, HowTo, JSection} from "@jnext/commons";

type HowToSectionectionProps = {
    cmsSection?: CMSSection,
    onNavigate: (type: string, params?: Record<string, any>) => void;
}

export const HowToSection = ({cmsSection, onNavigate}: HowToSectionectionProps) => {
    if(!cmsSection?.enabled){
        return <></>
    }

   return (<JSection>
         <HowTo {...cmsSection} onNavigate={onNavigate}/>
    </JSection>)



}