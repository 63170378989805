import React from "react";
import {ProductPreviewTagDto} from "@jnext/ts-axios-formatdigitalcollection";

interface CardTagProps {
    tag: ProductPreviewTagDto
}

export const CardTag: React.FC<CardTagProps> = ({tag}) => {
    return (
        <div
            className={'card-tag'}
            dangerouslySetInnerHTML={{__html: tag.title || ''}}
            style={{background: tag?.colorCode}}
        />
    )
}