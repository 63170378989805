import {CMSSection, JTextField} from "@jnext/commons";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {CatalogCMSFilters} from "../CatalogPage";
import {Col} from "antd";
import {CheckOutlined, DownOutlined} from "@ant-design/icons";
import {onClickOutside} from "../../../utils";
import {FilterBox} from "./FilterBox";
import {CatalogService} from "../../../service/CatalogService";
import {GetFilterValuesResponse} from "@jnext/ts-axios-formatdigitalcollection";


interface TagFilterProps {
    section: CMSSection;
    onChange: (tags: GetFilterValuesResponse[]) => void;
    initiativeLogicId: string;
    isPrivate: boolean;
}

export const TagFilter: React.FC<TagFilterProps> = ({section, onChange, initiativeLogicId, isPrivate}) => {
    const filters = section?.filters as any as CatalogCMSFilters;
    const [open, setOpen] = useState<boolean>(false);
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [selectedOptionsTmp, setSelectedOptionsTmp] = useState<string[]>([]);
    const [tags, setTags] = useState<GetFilterValuesResponse[]>([]);
    const [filteredTags, setFilteredTags] = useState<GetFilterValuesResponse[]>([]);

    /**
     * Load Tags
     */
    useEffect(() => {
        (async () => {
            const data = await CatalogService.getTags(initiativeLogicId, isPrivate);
            setTags(data);
            setFilteredTags(data);
        })();
    }, [initiativeLogicId]);

    // String with selected tags to show in text input
    const selectedTagsString = useMemo(() => {

        /**
         * If not selected options -> return empty
         */
        if (selectedOptions.length == 0) {
            return '';
        }

        return tags
            .filter(tag => selectedOptions.indexOf(tag.logicId) !== -1)
            .map(el => el.title)
            .join(', ');

    }, [selectedOptions]);

    // Tag toggle in select
    const toggleTag = (tagId: string) => {
        const options = selectedOptionsTmp.slice(0);
        const index = options.indexOf(tagId);
        if (index !== -1) {
            options.splice(index, 1);
        } else {
            options.push(tagId);
        }

        setSelectedOptionsTmp(options);
    }

    // Filter tags
    const filterTags = (value: string) => {
        setFilteredTags(
            tags.filter(tag => tag.title.toLowerCase().trim().includes(value.toLowerCase().trim()))
        );
    }

    // Click Outside handler
    const wrapperRef = useRef(null);
    onClickOutside(wrapperRef, () => {
        setOpen(false);
    });

    return (
        <Col className={'freetext-field-container form-control'} xs={24} md={6}>
            <div className={'freetext-field form-control'} ref={wrapperRef}>
                <div onClick={()=>setOpen(!open)}>
                    <JTextField
                        name={'tag'}
                        placeholder={filters?.tags?.placeholder?.[0]}
                        value={selectedTagsString}
                        onChange={() => {

                        }}
                    />
                </div>
                <div className={`search-icon ${open ? "rotate" : ""}`} onClick={()=>setOpen(!open)}>
                    <DownOutlined/>
                </div>

                {
                    tags.length > 0 &&
                    open &&
                    <FilterBox
                        onReset={() => {
                            setSelectedOptionsTmp([]);
                        }}
                        onConfirm={() => {
                            onChange(tags.filter(tag => selectedOptionsTmp.indexOf(tag.logicId) !== -1));

                            // Update selectedOptions
                            setSelectedOptions(selectedOptionsTmp);

                            // CLose also box
                            setTimeout(() => {
                                setOpen(false);
                            }, 200);
                        }}
                    >
                        <div className={'tag-filter'}>

                            <JTextField
                                name={'tag-filter'}
                                placeholder={'Cerca'}
                                onChange={(e) => {
                                    filterTags(e.target.value);
                                }}
                            />

                            <ul className={'tag-list'}>
                                {
                                    filteredTags.map((tag) => {
                                        const selected = selectedOptionsTmp.indexOf(tag.logicId) !== -1;
                                        return (
                                            <li
                                                key={tag.logicId}
                                                onClick={() => toggleTag(tag.logicId)}
                                                className={`
                                                    ${selected ? 'selected' : ''}
                                                `}>
                                                <div className={'tag-name'}>{tag.title}</div>
                                                {
                                                    selected &&
                                                    <div className={'checked-icon'}>
                                                        <CheckOutlined/>
                                                    </div>
                                                }
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </FilterBox>
                }

            </div>

        </Col>
    )
}
