import React, {useEffect, useMemo, useState} from "react";
import {
    ClusteredInitiative,
    GetProductsPreviewPagedResponse,
    ProductPreviewDto
} from "@jnext/ts-axios-formatdigitalcollection";
import {
    breakpointsMin,
    CMSCTA,
    CMSSection,
    getNavPathFromType,
    JCardViewAll,
    JCMSCTA,
    JHTML,
    JSlider,
    NavigationTypes,
    SLIDER_3X
} from "@jnext/commons";
import "./cardSubscriptionPurchased.scss";
import {VasService} from "../../../../service/VasService";
import {getBackgroundCard, mzUseNavigate, useTranslationWord} from "../../../../utils";
import {ContestReward} from "@jnext/ts-axios-mz";
import {forEach} from "lodash";
import {DigitalWalletCardItem} from "../../../CatalogList/DigitalWalletCardItem";
import {CatalogType} from "../../../../type/catalogTypes";
import {AuthService} from "../../../../service/AuthService";
import {JModalVas} from "../JModalVas";
import {useWindowSize} from "@react-hook/window-size";
import {useCMSStore} from "../../../../store/cmsStore";


const EligibleProductsDesktop = ({rewards, onClickProduct, onClickViewAll, isDesktopLayout = true}: {
    rewards: ContestReward[],
    onClickProduct: (productId: string) => void,
    onClickViewAll: () => void,
    isDesktopLayout: boolean,
}) => {
    const translateWord = useTranslationWord();
    return (
        <JSlider
            slidesToShow={isDesktopLayout ? 3 : 1}
            dots={false}
            arrows={!isDesktopLayout}
        >
            {rewards?.map((el, i) => (
                <DigitalWalletCardItem
                    positionCard={!isDesktopLayout ? 'horizontal' : 'vertical'}
                    noOverlay={true}
                    key={el.logicId}
                    isDigital
                    data={el}
                    pricePosition={'TOPRIGHT'}
                    showTag={true}
                    onClick={()=> onClickProduct(el?.logicId as string)}/>
            ))}
            {isDesktopLayout && <JCardViewAll
                title={translateWord('viewAll')}
                onClick={onClickViewAll}
            />}
        </JSlider>
    )
}

export const JCardSubscriptionPurchased = ({product, section}: {
    product: ProductPreviewDto;
    section?: CMSSection;
}) =>
{
    const navigate = mzUseNavigate();
    const translateWord = useTranslationWord();

    // Ottiene la struttura del CMS dal CMSStore
    const {structure} = useCMSStore();

    // Stato per gestire la visibilità del Modal
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    // Ottiene la larghezza della finestra
    const [width] = useWindowSize();

    // Determina se il layout è mobile
    const isDesktopLayout = useMemo(() =>(
        width > breakpointsMin.md
    ), [width]);

    const catalogBtn = section?.cta?.find((cta: CMSCTA ) => (
        (cta?.scope === NavigationTypes.CATALOG_LOYALTY_COLLECTION
            || cta?.scope === NavigationTypes.CATALOG_DIGITAL_WALLET) && cta?.options?.isPurchased));

    const moreInfoBtn = section?.cta?.find((cta: CMSCTA ) => cta?.scope === 'MORE_INFO');

    // Stato per memorizzare i rewards
    const [rewards, setRewards] = React.useState<ContestReward[]>([]);

    const clusterInitiative : ClusteredInitiative | undefined = useMemo(()=> product?.serviceDetails?.clusteredInitiatives?.find(cluster => cluster.refInitiativeRegistryType === 'CATALOG'), [product?.serviceDetails?.clusteredInitiatives])

    useEffect(() => {
        (async () => {
            if(clusterInitiative?.refInitiativeRegistryLogicId && clusterInitiative?.refClusterLogicId){

                const data = await VasService.getProductsEligible(clusterInitiative?.refInitiativeRegistryLogicId, clusterInitiative?.refClusterLogicId);

                forEach(data?.results, (item) => {
                    // @ts-ignore
                    data.rewardItems = item;
                })

                if(data?.results){
                    if(data?.results.length > 2) {
                        return setRewards(data?.results.slice(0, 2));
                    }
                    setRewards(data?.results)
                }
            }
        })();
    }, [clusterInitiative]);

    const openProduct = (refInitiativeRegistryLogicId:string, productId: string) => {
        const paths = {
            [CatalogType.digitalWallet]: AuthService.authorized ? NavigationTypes.CATALOG_DIGITAL_WALLET_DETAILS : NavigationTypes.PUBLIC_CATALOG_DIGITAL_WALLET_DETAILS
        }
        return navigate(getNavPathFromType(paths[CatalogType.digitalWallet], {  initiativeLogicId: refInitiativeRegistryLogicId, productId }));

    }

    return(
        <div className={'card-subscripition-purchased'} style={{background : `${getBackgroundCard(structure, product.logicId as string)?.backgroundLinear}`}}>
            <JHTML.Text html={translateWord(`vas-card-title_upper`)}/>
            <div className={'card-subscripition-purchased-container'}>
                <div className="card-leftSide">
                    {/* Mostra il titolo del prodotto */}
                    {product?.title && <JHTML.Title level={1} html={product?.title}/>}

                    {/* Mostra la descrizione del prodotto */}
                    {product?.description && <JHTML.SubTitle  html={product?.description}/>}

                    <div className="btn-container" >

                        {moreInfoBtn && <div className={'btn-more-info'}>
                            {/* Pulsante per scoprire di più */}
                            <JCMSCTA
                                {...moreInfoBtn}
                                action={() => setIsModalVisible(true)}/>
                        </div>}

                        {catalogBtn && <div className={'btn-catalog'}>
                            {/* Pulsante per il catalogo */}
                            <JCMSCTA
                                {...catalogBtn}
                                action={()=> {
                                    sessionStorage.setItem('refClusterLogicId', product?.serviceDetails?.clusteredInitiatives?.[0]?.refClusterLogicId as string);
                                    navigate(getNavPathFromType(catalogBtn.scope as NavigationTypes, {initiativeLogicId: catalogBtn?.scopeParameters?.initiativeLogicId}));
                                }}
                            />
                        </div>}
                    </div>
                </div>

                {/* Mostra i rewards se presenti */}
                {!!rewards && rewards?.length > 0 && <div className="card-rightSide">
                    {product?.title && <JHTML.Title level={1} html={translateWord('title_eligible_products')}/>}

                    <EligibleProductsDesktop
                        isDesktopLayout={isDesktopLayout}
                        rewards={rewards}
                        onClickProduct={(productId: string) => {
                            if(clusterInitiative?.refInitiativeRegistryLogicId && productId){
                                return openProduct(clusterInitiative?.refInitiativeRegistryLogicId, productId)
                            }
                        }}
                        onClickViewAll={() => catalogBtn?.scope &&
                            navigate(getNavPathFromType(catalogBtn.scope, {initiativeLogicId: catalogBtn?.scopeParameters?.initiativeLogicId}))
                        }
                    />


                </div>}
            </div>

            {/* Mostra il modal se isModalVisible è true */}
            {isModalVisible &&
                <JModalVas
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    infoProducts={product?.cardMetadata} />}

        </div>
    )
}