import {observer} from "mobx-react";
import React from "react";
import {JHTML} from "@jnext/commons";
import {InfoProgressSection, Position} from "../commons/InfoProgressSection";
import {MgmProgress} from "@jnext/ts-axios-mz/models/mgm-progress";

interface ProgressSectionProps {
    progressCms: Record<string, any>;
    progress: MgmProgress;
    invitedFriends: number;
    maxAssignable:number
}

const ProgressSection = ({progressCms, progress, invitedFriends, maxAssignable}: ProgressSectionProps) => {
    const title = progressCms.title;
    const titleColumnOne = progressCms?.columnOne;
    const titleColumnTwo = progressCms?.columnTwo;

    const numberOfFriends = progress?.findIndex((prog)=> prog.requiredFriends === invitedFriends);

    //altezza calcolata della progress bar
    const dotPosition = ((80 * (numberOfFriends + 1)) - 40);

    return(
        <div className={'progressContainer'}>
            {title && <div className={'titleProgressContainer'}>
                <JHTML.Title html={title}/>
            </div>}
            <div className={'titleContainer'}>
                {titleColumnOne && <JHTML.SubTitle html={titleColumnOne}/>}
                {titleColumnTwo && <JHTML.SubTitle html={titleColumnTwo}/>}
            </div>
            <div className={'graphContainer'}>
                <div className={'graphLeftContainer'}>
                    <div className={'section'}>
                        {progress?.map((option: any, key: number) => {
                            return (<InfoProgressSection key={key} position={Position.left} option={option}/>)
                        })}
                    </div>
                </div>
                <div id={'progress'} style={{height: `${(80 * progress?.length) -10}px`}} >
                    {dotPosition > 0 &&<div className={`progressStatus`} style={{height: `${dotPosition}px`}}/>}
                    <div className={'dotPointer'} style={{top: `${dotPosition > 0 ? (dotPosition -10) : 0}px`}} />
                </div>
                <div className={'graphRightContainer'}>
                    <div className={'section'}>
                        {progress?.map((option: any, key: number) => {
                            return (<InfoProgressSection key={key} option={option}/>)
                        })}
                    </div>
                </div>
            </div>
        </div>)
}

export default observer(ProgressSection);