import React from "react";
import {CMSSection, CMSStaticSection, JCMSStaticSections} from "@jnext/commons";
import '../style.scss'
import Types = CMSStaticSection.Types;

function AdvertisementsCarousel({section}:{section?:CMSSection}) {
    if(!section?.sections?.length) return <></>
    const sectionAdv = section?.sections.find(sec => sec?.type === Types.STATIC_CONTENT);
    return (
        <div className="section-advertisement">
        <JCMSStaticSections.Content {...sectionAdv as CMSStaticSection.Content} />
        </div>
    );
}

export default AdvertisementsCarousel;
