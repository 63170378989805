import React, {useEffect, useState} from "react";
import {Col, Row} from "antd";
import {CMSSection, JTextField} from "@jnext/commons";
import {CatalogCMSFilters} from "../CatalogPage";
import {CloseCircleFilled, SearchOutlined} from "@ant-design/icons";
import {PriceFilter} from "./PriceFilter";
import {BrandFilter} from "./BrandFilter";
import {GetProductsFilters} from "../../../service/CatalogService";
import {TagFilter} from "./TagFilter";
import {Categories} from "../Categories";
// @ts-ignore
import _ from "lodash";


interface FiltersProps {
    section: CMSSection;
    onChange: (props: GetProductsFilters) => void;
    initiativeLogicId: string;
    isPrivate: boolean;
}

export const Filters: React.FC<FiltersProps> = ({section, onChange, initiativeLogicId, isPrivate}) => {
    const filters = section?.filters as any as CatalogCMSFilters;

    /**
     * If not filters return
     */
    if(!filters) {
        return <></>;
    }

    // Filters value
    const [searchText, setSearchText] = useState<string>('');
    const [searchTextTmp, setSearchTextTmp] = useState<string>('');
    const [price, setPrice] = useState<{minAmount?: number, maxAmount?: number}>({});
    const [choosedBrands, setChoosedBrands] = useState<string[]>([]);
    const [choosedTags, setChoosedTags] = useState<string[]>([]);

    // useDebounce hook
    const [typingTimer, setTypingTimer] = useState<any>();

    /**
     * On filters value change -> send value to parent
     */
    useEffect(() => {

        onChange({
            ...price,
            searchText: searchText ,
            brandLogicIds: choosedBrands,
            tagLogicIds: choosedTags,

        });

    }, [searchText, price, choosedBrands, choosedTags]);

    return (
        <Row className={'catalog-filters'} justify={'center'} gutter={32}>
            {
                filters?.freeText &&
                <Col className={'freetext-field-container form-control'} xs={24} md={6}>
                    <div className={'freetext-field form-control'}>
                        <JTextField
                            name={'search'}
                            placeholder={filters?.freeText?.placeholder?.[0]}
                            value={searchTextTmp}
                            onChange={(e) => {
                                const searchString = e.target.value;
                                setSearchTextTmp(searchString);

                                // remove old timeout
                                if(typingTimer) {
                                    clearTimeout(typingTimer);
                                }

                                const t = setTimeout(() => {
                                    setSearchText(searchString);
                                }, 500);


                                // save in state
                                setTypingTimer(t);

                            }}
                        />

                        <div className={'search-icon'}>
                            {searchTextTmp.length == 0 && <SearchOutlined />}
                            {
                                searchTextTmp.length > 0 &&
                                <CloseCircleFilled
                                    className={'empty-search-input'}
                                    onClick={() => {

                                        // Empty search string
                                        setSearchTextTmp('');
                                        setSearchText('');

                                    }}
                                />
                            }

                        </div>
                    </div>
                </Col>
            }
            {
                filters?.price &&
                <PriceFilter
                    section={section}
                    min={0}
                    max={1000}
                    onChange={(hasReset, from, to) => {
                        setPrice(hasReset ? {} : {
                            minAmount: from,
                            maxAmount: to,
                        })
                    }}
                />
            }
            {
                filters?.brand &&
                <BrandFilter
                    section={section}
                    onChange={(brands) => {
                        setChoosedBrands(brands.map(el => el.logicId));
                    }}
                    initiativeLogicId={initiativeLogicId}
                    isPrivate={isPrivate}
                />
            }

            {
                filters?.tags &&
                <TagFilter
                    section={section}
                    onChange={(tags) => {
                        setChoosedTags(tags.map(el => el.logicId));
                    }}
                    initiativeLogicId={initiativeLogicId}
                    isPrivate={isPrivate}
                />
            }

            {
                section?.options?.hasCategoryFilters &&
                <Col className={'categories-container-mobile'} xs={24} md={6}>
                    <Categories
                        initiativeLogicId={initiativeLogicId}
                        isPrivate={isPrivate}
                        onChoose={sectorId => {
                            // Need to clone to prevent update child data
                            const newFilters = _.clone(filters);

                            // remove old sectors from filter
                            if (!sectorId && newFilters?.sectorLogicIds) {
                                delete newFilters.sectorLogicIds;
                            }
                            // set new sectors for filter
                            else if (sectorId) {
                                // @ts-ignore
                                newFilters.sectorLogicIds = [sectorId];
                            }
                            // @ts-ignore
                            onChange(newFilters);
                        }} />
                </Col>
            }
        </Row>
    );
}