import React, {FC} from "react";
import {JCollapse, JModal, JSkeletonSlider} from "@jnext/commons";
import {Collapse, Skeleton,} from 'antd'
import {  DownOutlined } from '@ant-design/icons'
import "./vasModal.scss";

const { Panel } = Collapse;

interface JModalVasProps {
    isModalVisible: boolean;
    setIsModalVisible: (isModalVisible: boolean)=> void;
    infoProducts?: any[];
    title?: string
}

export const JModalVas: FC<JModalVasProps> = ({title, isModalVisible, setIsModalVisible, infoProducts}) => {
    return (<JModal
            title={title}
            width={800}
            isModalVisible={isModalVisible}
            footer={null}
            handleCancel={() => setIsModalVisible(false)}
        >
            {!infoProducts ? <Skeleton />
                :
                <div className={'vas-modal'}>
                    <JCollapse
                        ghost
                        expandIcon={(acc: any) =>
                            <DownOutlined aria-hidden={true}
                                          className="jcms-static-faq-second-icon"
                                          rotate={acc?.isActive ? 0 : 180}/>
                        }
                        expandIconPosition="end">
                        {
                            infoProducts.map((data, i) => {
                                return (

                                    <Panel
                                        className={"jcms-vas-sub-panel"}
                                        key={`${i}-${data.title}`}
                                        header={
                                            <div className="jcms-vas-sub-panel-title">
                                                {data.title || ''}
                                            </div>
                                        }>
                                        <div className="jcms-vas-sub-panel-text">
                                            {data.description || ''}
                                        </div>
                                    </Panel>
                                )
                            })}
                    </JCollapse>
                </div>
            }
        </JModal>
    )
}